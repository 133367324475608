import React, { MouseEventHandler } from 'react'
import cx from 'classnames'
import { LinkProps } from 'react-router-dom'
import { Variant } from '@toasttab/buffet-pui-buttons'
import { EmployeesIcon, GuestIcon, SplitIcon } from '@toasttab/buffet-pui-icons'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import Loading from '../Loading/Loading'
import { RouterLinkButton } from '../Button/RouterLinkButton'
import Dollars from '../Dollars/Dollars'

import styles from './CloseOrderTabLink.module.css'
import { CTA } from '@/il8n/en'

const getIconFromSplitPaymentMethod = (
  splitPaymentMethod?: SplitPaymentMethod
) => {
  switch (splitPaymentMethod) {
    case SplitPaymentMethod.PAY_FOR_SELF:
      return <GuestIcon className='mr-0' />
    case SplitPaymentMethod.SPLIT_EVENLY:
      return <SplitIcon className='mr-2' />
    case SplitPaymentMethod.PAY_FOR_PARTY:
    default:
      return <EmployeesIcon />
  }
}

const getCopyFromSplitPaymentMethod = (
  splitPaymentMethod?: SplitPaymentMethod,
  splitAlreadyStarted?: boolean
) => {
  switch (splitPaymentMethod) {
    case SplitPaymentMethod.PAY_FOR_SELF:
      return CTA.CLOSE_CHECK_MY_ITEMS
    case SplitPaymentMethod.SPLIT_EVENLY:
      return splitAlreadyStarted
        ? CTA.CLOSE_CHECK_SELECT_PORTIONS
        : CTA.CLOSE_CHECK_SPLIT_EVENLY
    case SplitPaymentMethod.PAY_FOR_PARTY:
    default:
      return CTA.CLOSE_CHECK_GROUP
  }
}

const getTestIdFromSplitPaymentMethod = (
  splitPaymentMethod?: SplitPaymentMethod
) => {
  switch (splitPaymentMethod) {
    case SplitPaymentMethod.PAY_FOR_SELF:
      return 'close-tab-link-pay-for-self'
    case SplitPaymentMethod.SPLIT_EVENLY:
      return 'close-tab-link-split-even'
    case SplitPaymentMethod.PAY_FOR_PARTY:
    default:
      return 'close-tab-link-pay-for-party'
  }
}

export interface CloseOrderTabLinkButtonProps {
  splitPaymentMethod?: SplitPaymentMethod
  hasIcon?: boolean
  /** Forces buttons to have the given variant rather than their default coloring */
  variantOverride?: Variant
  defaultVariant?: Variant
  applySplitTypeLoading?: boolean
  compact?: boolean
  to?: LinkProps['to']
  onClick?: MouseEventHandler<HTMLElement>
  dollarAmount?: number
  numberOfSelections?: number
  splitAlreadyStarted?: boolean
  disabled?: boolean
}

export const CloseOrderTabLinkButton = ({
  splitPaymentMethod = SplitPaymentMethod.PAY_FOR_PARTY,
  hasIcon = true,
  variantOverride,
  defaultVariant,
  applySplitTypeLoading = false,
  compact = false,
  to,
  onClick,
  dollarAmount,
  splitAlreadyStarted = false,
  disabled = false
}: CloseOrderTabLinkButtonProps) => {
  const icon = getIconFromSplitPaymentMethod(splitPaymentMethod)
  const copy = getCopyFromSplitPaymentMethod(
    splitPaymentMethod,
    splitAlreadyStarted
  )
  const testId = getTestIdFromSplitPaymentMethod(splitPaymentMethod)

  return (
    <RouterLinkButton
      onClick={onClick}
      to={to}
      variant={variantOverride || defaultVariant}
      compact={compact}
      disabled={disabled || applySplitTypeLoading}
      data-testid={testId}
    >
      {applySplitTypeLoading ? (
        <Loading />
      ) : (
        <>
          <span
            className={cx(
              'flex w-full',
              dollarAmount ? 'justify-start' : 'justify-center'
            )}
          >
            {hasIcon && (
              <div className={cx({ [styles.compactIcon]: compact })}>
                {icon}
              </div>
            )}
            {copy}
          </span>
          {dollarAmount && (
            <span>
              <Dollars amount={dollarAmount} />
            </span>
          )}
        </>
      )}
    </RouterLinkButton>
  )
}
