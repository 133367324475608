import React, { useRef, useState } from 'react'
import { CTABanner } from '@toasttab/do-secundo-cta-banner'
import { getCtaStorage } from './helpers'
import { track } from '@toasttab/do-secundo-analytics'
import { useGetDeepLink } from './use-get-deep-link'
import { ShowForUS } from '../ShowForUS/ShowForUS'

const CTA_KEY = 'takeout-cta'

export enum CTA_REASON {
  TRACK_CLOSED = 'TTO CTA Manually Closed',
  TRACK_SEEN = 'TTO CTA Observed',
  TRACK_FOLLOWED = 'TTO CTA Link Clicked'
}

export const TakeoutCTA = React.memo(() => {
  const [hasSeen, setHasSeen] = useState(Boolean(getCtaStorage().get(CTA_KEY)))
  const trackOnceHasSeenRef = useRef(false) // Track that the guest has seen the CTA
  const trackOnceHasClosed = useRef(false) // Explicitly closed the CTA
  const trackOnceHasFollowed = useRef(false) // Followed the CTA link

  const deepLink = useGetDeepLink()

  const dismiss = (reason: CTA_REASON) => () => {
    try {
      getCtaStorage().set(CTA_KEY, true)
      setHasSeen(true)
      if (reason === CTA_REASON.TRACK_CLOSED) {
        if (!trackOnceHasClosed.current) {
          trackOnceHasClosed.current = true
          track(CTA_REASON.TRACK_CLOSED)
        }
      } else if (reason === CTA_REASON.TRACK_FOLLOWED) {
        if (!trackOnceHasFollowed.current) {
          trackOnceHasFollowed.current = true
          track(CTA_REASON.TRACK_FOLLOWED)
        }
      }
    } catch (err) {
      // Being cautious to catch any possible errors
    }
  }

  React.useEffect(() => {
    if (!trackOnceHasSeenRef.current && !hasSeen) {
      trackOnceHasSeenRef.current = true
      track(CTA_REASON.TRACK_SEEN)
    }
  }, [hasSeen])

  const dismissClosed = dismiss(CTA_REASON.TRACK_CLOSED)
  const dismissFollowed = dismiss(CTA_REASON.TRACK_FOLLOWED)
  return (
    <ShowForUS>
      <div data-testid='takeout-cta'>
        {!hasSeen && (
          <CTABanner
            prompt={'Order ahead with the Toast app'}
            onClose={dismissClosed}
            onFollow={dismissFollowed}
            link={deepLink}
          />
        )}
      </div>
    </ShowForUS>
  )
})
