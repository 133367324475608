import React, { useCallback } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './CheckButton.module.css'

const CheckButton = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue, setFieldTouched },
  disabled,
  id,
  label,
  ...props
}) => {
  const toggleChecked = useCallback(() => {
    setFieldValue(name, !value)
  }, [setFieldValue, name, value])
  const handleClick = useCallback(() => {
    toggleChecked()
    setFieldTouched(name, true)
  }, [toggleChecked, setFieldTouched, name])

  return (
    <button
      className={cx(styles.checkButton, {
        [styles.checkButtonSelected]: value
      })}
      variant='secondary'
      disabled={disabled}
      type='button'
      name={name}
      id={id}
      value={value}
      {...props}
      onClick={handleClick}
    >
      {label}
    </button>
  )
}
const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

CheckButton.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool
}
export default CheckButton
