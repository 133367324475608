import * as React from 'react'
import { toast } from 'react-toastify'

export const ListenerError = ({ errorMsg }: ListenerErrorProps) => {
  return (
    <div
      data-testid='tabs-spa-error'
      className='text-center text-white break-words bg-gray'
    >
      {errorMsg}
    </div>
  )
}

export const toastError = (
  errorMessage: any,
  autoClose: number | false | undefined = 5000
) => {
  toast(<ListenerError errorMsg={errorMessage} />, {
    className: 'rounded-lg w-48 mt-4 bg-gray-125',
    autoClose
  })
}

export interface ListenerErrorProps {
  errorMsg: string
}
