import React from 'react'
import { Confirmation } from '../../Confirmation/Confirmation'
import Progress from '../../Progress/Progress'
import { DontForgetV2 } from '../DontForget/DontForget'

export const ConfirmPageHeader = ({ completedOrder, party }) => {
  if (!completedOrder || !party) {
    return <Progress />
  }
  const isGroupDone = party.members.length > 1 && completedOrder.isClosed

  const headerCopy = isGroupDone
    ? 'Thank you!\nYour group check is fully paid.'
    : 'Thank you for dining with us!'

  const bodyCopy = isGroupDone
    ? "Your server will be notified that your payment is complete. Feel free to leave when you're ready."
    : 'Your payment has been made. We sent the receipt to your email.'

  return (
    <>
      <div className='px-4 pt-4'>
        <DontForgetV2 />
        <Confirmation headerCopy={headerCopy} bodyCopy={bodyCopy} />
        {!completedOrder.isClosed && (
          <hr
            data-testid='horizontal-rule'
            className='mx-4 my-8 border-0 border-b border-solid border-gray-50'
          />
        )}
      </div>
    </>
  )
}
