import React, { useState } from 'react'
import { useHistory } from 'react-router'

import { PartyCarts, PartyCartsMode } from '../PartyCarts/PartyCarts'
import { useCreateHandleSubmit } from '../../utils/checkout-helpers'
import { CheckoutMode } from '../CheckoutForm/checkout-modes'
import {
  useGetPartyMember,
  useGetPartyMemberCart,
  useGetPartyRefresh
} from '../PartyQuery/PartyQuery'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useHandleFirePartyTabRound } from '../../hooks/place-order/use-fire-party-round'
import Progress from '../Progress/Progress'
import { StartTabButton } from '../CreateOrderTab/CallToStartATab/StartATabButton/StartTabButton'
import { partyCartsHaveItems } from '../../utils/cart-helpers'
import { usePreauthEnabled } from '../../hooks/preauth/use-preauth-enabled'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'

import styles from './TabPromptCartView.module.css'
import { CartUpsells } from '../CartUpsells/CartUpsells'

const TabPromptCartView = ({ mode }) => {
  const preauthEnabled = usePreauthEnabled()

  const history = useHistory()
  const { getRestaurantPath } = useRestaurant()

  const partyMemberInfo = useGetPartyMember()
  const {
    error: cartError,
    loading: cartLoading,
    cart
  } = useGetPartyMemberCart()
  const {
    loading: partyLoading,
    error: partyError,
    refetch: partyRefetch,
    partyRefresh
  } = useGetPartyRefresh()
  const carts = partyRefresh?.carts ?? [cart]
  let { user, loading: authLoading } = useAuth()
  user = {
    ...user,
    firstName: partyMemberInfo
      ? partyMemberInfo.name
      : user
      ? user.firstName
      : ''
  }

  const [submitting, setSubmitting] = useState(false)
  const [firePartyRound, { error: firePartyRoundError }] =
    useHandleFirePartyTabRound()

  const handleCreateTabSubmit = () =>
    handleCreateTab({ ...user }, { setSubmitting })
  const handleCreateTab = useCreateHandleSubmit({
    submitFunction: firePartyRound,
    mode: CheckoutMode.CREATE_TAB_MODE
  })

  const handlePreauthInit = async () => {
    await partyRefetch()
    history.replace(getRestaurantPath(`tab/new`))
  }

  const error = cartError || firePartyRoundError || partyError
  const loading = authLoading || cartLoading || !cart || partyLoading
  if (loading) {
    return <Progress />
  }

  return (
    <>
      <div className={styles.wrap}>
        <PartyCarts mode={mode} hideOpenCheck={true} />
      </div>
      {mode !== PartyCartsMode.ADD_TO_TAB && (
        <>
          <div className={'mt-6'}>
            <StartTabButton
              onClick={
                preauthEnabled ? handlePreauthInit : handleCreateTabSubmit
              }
              error={error}
              loading={submitting}
              disabled={!partyCartsHaveItems(carts)}
            />
          </div>
          <div className='mb-16'>
            <CartUpsells />
          </div>
        </>
      )}
    </>
  )
}

export default TabPromptCartView
