import React, { useMemo } from 'react'
import { Field } from 'formik'
import cx from 'classnames'
import {
  ApplePayPaymentMethod,
  PaymentMethod,
  SavedCreditCardPaymentMethod
} from '../../hooks/place-order/use-available-payment-methods'
import Radio from '../Form/Radio/Radio'
import { SavedCreditCardLabelV2 } from './SavedCreditCardLabelV2/SavedCreditCardLabelV2'
import ApplePay from '../../assets/apple-pay.svg'

import styles from '../SavedCreditCardPicker/SavedCreditCardPicker.module.css'

interface SavedPaymentMethodFieldProps {
  paymentMethod: PaymentMethod
  fieldName: string
}

interface SavedCCFieldProps {
  savedCC: SavedCreditCardPaymentMethod
  fieldName: string
}
const SavedCCField: React.FC<SavedCCFieldProps> = ({ savedCC, fieldName }) => {
  const { paymentMethodDetails: card } = savedCC
  return (
    <Field
      data-testid='saved-credit-card'
      disabled={card.expired}
      component={Radio}
      id={`${fieldName}_saved_cc_${card.guid}`}
      name={fieldName}
      label={<SavedCreditCardLabelV2 card={card} />}
      value={card.guid}
    />
  )
}

interface ApplePayFieldProps {
  applePay: ApplePayPaymentMethod
  fieldName: string
}
const ApplePayField: React.FC<ApplePayFieldProps> = ({
  applePay,
  fieldName
}) => {
  return (
    <Field
      data-testid='saved-apple-pay'
      disabled={false}
      component={Radio}
      id={`savedCardGuid_saved_cc_${applePay.guid}`}
      name={fieldName}
      label={
        <div className='flex flex-row items-center'>
          <div className='h-full w-12 block mr-3'>
            <ApplePay />
          </div>
          <div className='font-bold'>Apple Pay</div>
        </div>
      }
      value={applePay.guid}
    />
  )
}

export const SavedPaymentMethodField: React.FC<SavedPaymentMethodFieldProps> =
  ({ paymentMethod, fieldName }) => {
    const savedMethodField = useMemo(() => {
      if (paymentMethod.type === 'SavedCreditCard') {
        return <SavedCCField fieldName={fieldName} savedCC={paymentMethod} />
      } else if (paymentMethod.type === 'ApplePay') {
        return <ApplePayField applePay={paymentMethod} fieldName={fieldName} />
      }
      return null
    }, [paymentMethod, fieldName])

    if (!savedMethodField) {
      return null
    }
    return (
      <div
        data-testid='saved-credit-cards'
        className={cx(styles.separator, 'fs-mask')}
        key={paymentMethod.guid}
      >
        {savedMethodField}
      </div>
    )
  }
