import React from 'react'
import PropTypes from 'prop-types'

import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'

import { IfAuthenticated } from '../IfAuthenticated/IfAuthenticated'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useGetPartyMemberCart } from '../PartyQuery/PartyQuery'
import Drawer from '../Drawer/Drawer'
import NavigationDrawerHeader from './NavigationDrawerHeader/NavigationDrawerHeader'
import { Link } from 'react-router-dom'
import { GiftCardLinks } from './GiftCardLinks/GiftCardLinks'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { track } from '@toasttab/do-secundo-analytics'

import styles from './NavigationDrawer.module.css'

import { useLeavePartyAndScan } from '../LeaveParty/use-leave-party'
import { useLeavePartyButtonEnabled } from '../../hooks/party/use-leave-party-button-enabled'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { CTA } from '@/il8n/en'

const NavigationDrawer = ({ onClose }) => {
  const { passwordlessLogout, setIsAuthenticated } = useAuth()
  const { getRestaurantPath } = useRestaurant()
  const openData = {
    category: 'Drawer',
    action: 'open'
  }
  const closeData = {
    category: 'Drawer',
    action: 'close'
  }

  const { cart } = useGetPartyMemberCart()

  const logoutInput = {
    variables: {
      input: {
        cart
      }
    }
  }

  const logoutAndTrack = async () => {
    await passwordlessLogout(logoutInput)
    setIsAuthenticated(false)
    track('User Account Logout')
  }

  const leaveParty = useLeavePartyAndScan()
  const leavePartyEnabled = useLeavePartyButtonEnabled()
  const { uniqueIdentifier } = useDDIGlobals()

  return (
    <Drawer
      openData={openData}
      closeData={closeData}
      header={<NavigationDrawerHeader onClose={onClose} />}
      onClose={onClose}
      footer={<PoweredByToast className={styles.poweredBy} />}
    >
      <ul className={styles.list}>
        <IfAuthenticated>
          <li>
            <Link
              data-testid='nav-drawer-my-account'
              className={styles.listItem}
              to={getRestaurantPath('account')}
              onClick={onClose}
            >
              {CTA.NAV_MY_ACCOUNT}
            </Link>
          </li>
        </IfAuthenticated>
        <GiftCardLinks />
        <li>
          <a
            data-testid='nav-drawer-link-privacy'
            className={styles.listItem}
            href='https://pos.toasttab.com/privacy'
            target='_blank'
            rel='noreferrer noopener'
          >
            Privacy statement
          </a>
        </li>
        <li>
          <a
            data-testid='nav-drawer-link-terms'
            className={styles.listItem}
            href='https://pos.toasttab.com/terms-of-service'
            target='_blank'
            rel='noreferrer noopener'
          >
            Terms of service
          </a>
        </li>
        {leavePartyEnabled && (
          <li>
            {/* TODO: convert this to a link-like button */}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              data-testid='nav-drawer-leave-party'
              className={styles.listItem}
              onClick={() => {
                onClose()
                leaveParty()
              }}
            >
              Leave table {uniqueIdentifier} group
            </a>
          </li>
        )}

        <IfAuthenticated>
          <li>
            <button
              data-testid='nav-drawer-logout'
              className={styles.listItem}
              onClick={logoutAndTrack}
            >
              {CTA.LOGOUT}
            </button>
          </li>
        </IfAuthenticated>
      </ul>
    </Drawer>
  )
}

NavigationDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default NavigationDrawer
