import isNil from 'lodash/isNil'
import { PaymentFormKeys, GetTipInitialValuesParams } from '../../types/types'
import { getStoredTip } from '../TipComponent/helpers'

/**
 * Tip slice of payment forms
 */
export interface TipFormValues {
  [PaymentFormKeys.CREDIT_CARD_TIP]: string
}

export const getInitialValues = ({
  preComputedTips,
  tipEnabled
}: GetTipInitialValuesParams): TipFormValues => {
  /*
  0 versus "" initial values:
  * 0 will pre-populate the customer tip field with zero,
    and therefore allow 'No Tip' to be pre-selected by default
    for restaurants that receive that want that treatment
  * "" will have none of the tip selections pre-selected
  */

  if (!tipEnabled) {
    return {
      [PaymentFormKeys.CREDIT_CARD_TIP]: '0.00'
    }
  }

  const previouslySelectedTip = getStoredTip()

  if (previouslySelectedTip) {
    const matchingPrecomputedTip = preComputedTips.find(
      (precomputedTip) =>
        precomputedTip.percentV2 === previouslySelectedTip.percent
    )

    const tipValue = matchingPrecomputedTip
      ? matchingPrecomputedTip.value.toFixed(2)
      : previouslySelectedTip.value

    return { [PaymentFormKeys.CREDIT_CARD_TIP]: tipValue }
  }

  const defaultTip = preComputedTips.find((p) => p.isDefault)?.value
  return {
    [PaymentFormKeys.CREDIT_CARD_TIP]: isNil(defaultTip)
      ? ''
      : defaultTip.toFixed(2)
  }
}
