import { DDIMode } from '../../types/DDIGlobals'
import { useRestaurantInfo } from '../restaurant-info/use-restaurant-info'
import { useGetPartyMode } from '../../components/PartyQuery/PartyQuery'

export const useJoinablePartyEnabled = () => {
  const { loading, data, error } = useRestaurantInfo()
  const { mode } = useGetPartyMode()
  if (loading || error || !data) {
    return false
  }
  return Boolean(
    data?.orderAndPayConfig?.allowJoinableParty &&
      (mode === DDIMode.OPT || mode === DDIMode.TTS)
  )
}
