import React, { useEffect, useMemo } from 'react'
import { Field } from 'formik'

import Input from '../../../Form/Input/Input'
import { useFormik } from '../../../FormikProvider/FormikProvider'
import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'
import { getMessage } from '../../../Error/error-utils'
import { FieldLabel } from '@/il8n/en'
import { GiftCardPaymentLabel } from './GiftCardPaymentLabel/GiftCardPaymentLabel'
import CloseButton from '../../../CloseButton/CloseButton'

const rxGiftCardMaskFrag = [/\w/, /\w/, /\w/, /\w/, ' ']

const MdsChip = ({ label, clickHandler }) => {
  return (
    <div className='inline-block mr-1'>
      <div className='flex border bg-primary-0 justify-center rounded text-color-link font-semibold type-caption pt-1 pl-2 mt-2'>
        {label}
        <div className=' -mt-1 pl-1'>
          <CloseButton onClick={() => clickHandler()} />
        </div>
      </div>
    </div>
  )
}

export const GiftCardPaymentContainer = ({ hasSufficientFunds }) => {
  const { values, touched, setFieldError, setFieldTouched, submitForm } =
    useFormik()

  const { giftCardInput } = values

  const {
    clearGlobalGiftCard,
    clearRxGiftCard,
    rxGiftCard,
    globalGiftCard,
    rxGiftCardEnabled,
    error,
    loading
  } = useGiftCard()

  useEffect(() => {
    if (error) {
      const message = getMessage(error)
      setFieldError('giftCardInput', message)
    }
  }, [error, setFieldError])

  const shouldMask = useMemo(() => {
    return /^[\d]{4}/.test(giftCardInput)
  }, [giftCardInput])

  const isDirty = useMemo(() => {
    return giftCardInput?.replace(/\s/g).length > 5
  }, [giftCardInput])

  const inputLabel = useMemo(() => {
    if (rxGiftCardEnabled) {
      if (globalGiftCard) {
        return FieldLabel.CHECKOUT_GIFT_CARD_INPUT
      } else if (rxGiftCard) {
        return FieldLabel.CHECKOUT_TOAST_CASH_INPUT
      } else {
        return FieldLabel.CHECKOUT_GIFT_CARD_AND_TOAST_CASH_INPUT
      }
    } else {
      return FieldLabel.CHECKOUT_TOAST_CASH_INPUT
    }
  }, [rxGiftCardEnabled, globalGiftCard, rxGiftCard])

  const shouldRenderInput = useMemo(() => {
    return (
      values.payWithGiftCard &&
      !hasSufficientFunds &&
      ((rxGiftCardEnabled && !Boolean(rxGiftCard)) || !Boolean(globalGiftCard))
    )
  }, [
    values,
    rxGiftCardEnabled,
    rxGiftCard,
    globalGiftCard,
    hasSufficientFunds
  ])

  // submit card identifier on enter press
  const handleKeyPress = (evt) => {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      submitForm()
    }
  }

  const handleFocus = () => {
    // set field touched manually, default happens on blur
    if (!touched['giftCardInput']) {
      setFieldTouched('giftCardInput', true)
    }
  }

  return (
    <>
      <div className='flex'>
        {rxGiftCard ? (
          <MdsChip
            label='GIFT CARD APPLIED'
            clickHandler={() => clearRxGiftCard()}
          />
        ) : null}
        {globalGiftCard ? (
          <MdsChip
            label='TOAST CASH APPLIED'
            clickHandler={() => clearGlobalGiftCard()}
          />
        ) : null}
      </div>
      {shouldRenderInput ? (
        <div className='flex'>
          <div className='w-5/6 pt-2'>
            <Field
              id='gc_number_input'
              component={Input}
              type='text'
              name='giftCardInput'
              guide={false}
              mask={
                shouldMask
                  ? [
                      ...rxGiftCardMaskFrag,
                      ...rxGiftCardMaskFrag,
                      ...rxGiftCardMaskFrag,
                      /\w/,
                      /\w/,
                      /\w/,
                      /\w/
                    ]
                  : null
              }
              label={inputLabel}
              value={values.giftCardInput}
              autoFocus
              onFocus={handleFocus}
              onKeyDown={handleKeyPress}
            />
          </div>
          <div className='pl-1'>
            <GiftCardPaymentLabel
              canSubmit={isDirty}
              loading={loading}
              noAdornmentMargin
            />
          </div>
        </div>
      ) : null}
    </>
  )
}
