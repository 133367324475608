import React from 'react'
import { LoyaltyIcon } from '@toasttab/buffet-pui-icons'
import { Notification } from '../Notification/Notification'
import { getLoyaltyProgramName } from '../TermsModal/TermsModal'

export const LoyaltyEarnedNotification = ({
  isNewAccount = false,
  restaurant
}) => {
  return (
    <div className='p-10 text-center max-w-md mx-auto'>
      <LoyaltyIcon className='text-secondary fill-current' />
      <div className='font-bold type-headline-5 mt-2'>
        {isNewAccount ? 'Welcome to' : 'Welcome back to'}
        <br />
        {getLoyaltyProgramName(restaurant)}!
      </div>
      <div className='text-gray-100 mt-2 text-subhead leading-relaxed'></div>
      <Notification className='mt-2' variant='success' marginBottom={false}>
        You're earning rewards!
      </Notification>
    </div>
  )
}
