import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'

export const useGiftCardEnabled = () => {
  const { data } = useRestaurantInfo()
  return Boolean(data?.giftCardConfig?.redemptionAllowed)
}

export const useGiftCardTipEnabled = () => {
  const { data } = useRestaurantInfo()
  return Boolean(data?.giftCardConfig?.tipAllowed)
}
