import { useGetMyPreauthInfo } from './../../components/PartyQuery/PartyQuery/PartyQuery'
import { useRestaurantInfo } from './../restaurant-info/use-restaurant-info'
import { useCanMakeApplePayment } from './../apple-pay/useCanMakeApplePayment/useCanMakeApplePayment'
import { useMemo } from 'react'
import {
  AppliedPreauthInfo,
  CardType,
  SavedCreditCard,
  RestaurantCreditCardConfig,
  useCustomer_Credit_CardsQuery
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useAuth } from '../../components/AuthProvider/AuthProvider'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { useClick2PayEnabled } from '../use-click-2-pay-enabled'
import { ApolloClient } from '@apollo/client'
import { removeDuplicateOfPreauthedCard } from './removeDuplicateOfPreauthedCard'
import { isExpirationDateValid } from '../../utils/credit-card-date-validation'
import { isApplePayPreauth } from '../../components/CheckoutForm/PaymentInfo/MFEPaymentInfoComponent/MFEPaymentInfoComponent'

export interface SavedCreditCardWithPreauth extends SavedCreditCard {
  isPreauthed?: boolean
}

interface ApplePayDetails {
  canMakePaymentsWithActiveCard: boolean
}

interface BasePaymentMethod {
  guid?: string
  isPrimary: boolean
}

export interface ApplePayPaymentMethod extends BasePaymentMethod {
  guid: 'ApplePay'
  type: 'ApplePay'
  paymentMethodDetails: ApplePayDetails
}

export interface SavedCreditCardPaymentMethod extends BasePaymentMethod {
  guid: string
  type: 'SavedCreditCard'
  paymentMethodDetails: SavedCreditCardWithPreauth
}

interface NewCardPaymentMethod extends BasePaymentMethod {
  guid?: undefined
  type: 'NewCreditCard'
}

interface ClickToPayPaymentMethod extends BasePaymentMethod {
  type: 'ClickToPay'
}

/**
 * All available payment method types for checking out.
 * Sort order is arbitrary, and duty to consumer to sort
 * payment methods as required.
 */
export type PaymentMethod =
  | ApplePayPaymentMethod
  | SavedCreditCardPaymentMethod
  | NewCardPaymentMethod
  | ClickToPayPaymentMethod

/**
 * Rules for how to display cards and choose a primary
 * | Preauth | Apple Pay | Saved  | Left Tab | Left Tab Title | Primary Card | Right Tab |
 * ---------------------------------------------------------------------------------------
 * | Yes     | Yes       | Yes    | P, AP, S | saved payments | preauth      | new card  |
 * | Yes     | Yes       | No     | P, AP    | saved payments | preauth      | new card  |
 * | Yes     | No        | Yes    | P, S     | saved payments | preauth      | new card  |
 * | Yes     | No        | No     | P        | saved payments | preauth      | new card  |
 * | No      | Yes       | Yes    | AP, S    | saved payments | apple pay    | new card  |
 * | No      | Yes       | No     | AP       | apple pay      | preauth      | new card  |
 * | No      | No        | Yes    | S        | saved payments | primary s    | new card  |
 * | No      | No        | No     | none     | n/a            | new card     | new card  |
 */

function prependPreauthedCC(
  creditCards: SavedCreditCard[],
  appliedPreauthInfo: Pick<
    AppliedPreauthInfo,
    | 'cardType'
    | 'cardHolderExpirationMonth'
    | 'cardHolderExpirationYear'
    | 'guid'
    | 'last4CardDigits'
  >
): SavedCreditCardWithPreauth[] {
  return [
    {
      cardType: appliedPreauthInfo.cardType,
      expirationMonth: appliedPreauthInfo.cardHolderExpirationMonth,
      expirationYear: appliedPreauthInfo.cardHolderExpirationYear.slice(-2),
      guid: appliedPreauthInfo.guid,
      maskedPan: `XXXX-${appliedPreauthInfo.last4CardDigits}`,
      expired: false,
      isPrimary: true,
      isPreauthed: true,
      __typename: 'SavedCreditCard'
    },
    ...creditCards.map((cc) => ({ ...cc, isPrimary: false }))
  ]
}

/**
 * Get saved credit cards, with preauth'd card prepended to list
 * if it is mine.
 */
export const useCreditCardsWithPreauth = () => {
  const { preauthInvalidated } = useParty()
  const { authenticated, apolloClient } = useAuth() as {
    authenticated: boolean
    apolloClient?: ApolloClient<any>
  }
  const creditCardsQueryResult = useCustomer_Credit_CardsQuery({
    skip: !authenticated,
    client: apolloClient
  })

  const { data: creditCardsData } = creditCardsQueryResult
  const appliedPreauthInfo = useGetMyPreauthInfo()

  const creditCards = useMemo(() => {
    const mySavedCCs = creditCardsData?.customer.creditCards ?? []
    const ccWithPreauth =
      appliedPreauthInfo && !preauthInvalidated
        ? prependPreauthedCC(mySavedCCs, appliedPreauthInfo)
        : mySavedCCs

    const decoratedCards = ccWithPreauth.map((cc) => {
      if (isApplePayPreauth(cc)) {
        return cc
      }

      return {
        ...cc,
        expired: !isExpirationDateValid(
          cc.expirationMonth,
          cc.expirationYear,
          new Date(Date.now())
        )
      }
    })

    return removeDuplicateOfPreauthedCard(decoratedCards)
  }, [creditCardsData, appliedPreauthInfo, preauthInvalidated])

  return useMemo(() => {
    return {
      ...creditCardsQueryResult,
      creditCards
    }
  }, [creditCardsQueryResult, creditCards])
}

export function cardsToPaymentMethods(
  creditCards: SavedCreditCard[],
  {
    canMakePaymentsWithActiveCard,
    creditCardConfig
  }: {
    canMakePaymentsWithActiveCard: boolean
    creditCardConfig: RestaurantCreditCardConfig
  }
): SavedCreditCardPaymentMethod[] {
  // If every saved card is AMEX but Rx does not accept
  // Don't bother including them in options
  if (
    !creditCardConfig.amexAccepted &&
    creditCards.every((cc) => cc.cardType === CardType.Amex)
  ) {
    return []
  }

  const deduplicatedCreditCards = removeDuplicateOfPreauthedCard(creditCards)
  const creditCardMap = deduplicatedCreditCards.map((scc) => {
    const isAmexAndNotAccepted =
      !creditCardConfig.amexAccepted && scc.cardType === 'AMEX'

    const filteredGuid = (isAmexAndNotAccepted ? '!' : '') + scc.guid
    return {
      guid: filteredGuid,
      type: 'SavedCreditCard',
      paymentMethodDetails: {
        ...scc,
        guid: filteredGuid
      },
      isPrimary:
        canMakePaymentsWithActiveCard || isAmexAndNotAccepted
          ? false
          : scc.isPrimary
    }
  })

  return creditCardMap as SavedCreditCardPaymentMethod[]
}

/**
 * Evaluates all available payment methods, including Apple Pay
 */
export const useAvailablePaymentMethods = (
  shouldUseSpi: boolean
): PaymentMethod[] => {
  const {
    canMakeApplePayPayment,
    canMakePaymentsWithActiveCard: { canMakePaymentsWithActiveCard }
  } = useCanMakeApplePayment()
  const { data: restaurantData } = useRestaurantInfo()

  const { creditCards: creditCardsWithPreauth } = useCreditCardsWithPreauth()

  const clickToPayEnabled = useClick2PayEnabled()

  const filteredCreditCards = useMemo(() => {
    if (restaurantData?.creditCardConfig) {
      return cardsToPaymentMethods(creditCardsWithPreauth, {
        canMakePaymentsWithActiveCard: Boolean(canMakePaymentsWithActiveCard),
        creditCardConfig: restaurantData.creditCardConfig
      })
    }
    return []
  }, [
    creditCardsWithPreauth,
    canMakePaymentsWithActiveCard,
    restaurantData?.creditCardConfig
  ])

  const hasNoSavedPaymentMethods = filteredCreditCards.length < 1

  return useMemo((): PaymentMethod[] => {
    const applePayPaymentMethod: ApplePayPaymentMethod = {
      type: 'ApplePay',
      guid: 'ApplePay',
      isPrimary: true,
      paymentMethodDetails: {
        canMakePaymentsWithActiveCard: Boolean(canMakePaymentsWithActiveCard)
      }
    }
    const clickToPayPaymentMethod: ClickToPayPaymentMethod = {
      type: 'ClickToPay',
      guid: 'ClickToPay',
      isPrimary: false
    }
    if (shouldUseSpi) {
      return clickToPayEnabled ? [clickToPayPaymentMethod] : []
    }

    return [
      ...(canMakeApplePayPayment ? [applePayPaymentMethod] : []),
      ...(clickToPayEnabled ? [clickToPayPaymentMethod] : []),
      {
        type: 'NewCreditCard',
        isPrimary:
          hasNoSavedPaymentMethods &&
          !(canMakeApplePayPayment && applePayPaymentMethod.isPrimary)
      },
      ...filteredCreditCards
    ]
  }, [
    canMakeApplePayPayment,
    filteredCreditCards,
    hasNoSavedPaymentMethods,
    clickToPayEnabled,
    canMakePaymentsWithActiveCard,
    shouldUseSpi
  ])
}
