import { HandlePlaceOrderValuesV2 } from '../../../../utils/checkout-helpers'
import { CheckoutMode } from '../../checkout-modes'
import { getRawTipValue } from '../TipComponent/getRawTipValue'
import { CheckoutFormValues } from './../../types/types'

export const getArgsForSubmit = ({
  values,
  tipEnabled,
  mode
}: {
  values: CheckoutFormValues
  tipEnabled: boolean
  mode: CheckoutMode
}): Pick<HandlePlaceOrderValuesV2['paymentInfo'], 'tipAmount'> => {
  let tipAmount = 0
  if (tipEnabled) {
    tipAmount = getRawTipValue(values.creditCardTip)
  }

  switch (mode) {
    case CheckoutMode.CREATE_TAB_MODE:
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE:
      // FIXME: Should this be undefined for non-closeout cases?
      return {
        tipAmount: 0
      }
    default:
      return { tipAmount }
  }
}
