import React, { useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Alert } from '@toasttab/buffet-pui-alerts'

import styles from './Notification.module.css'

interface NotificationProps {
  children: React.ReactNode
  isCloseable?: boolean
  onClose?(): void
  isActionable?: boolean
  actionContent?: React.ReactNode
  actionLink?: string
  onAction?(): void
  variant?: 'info' | 'success' | 'warning' | 'error'
  showIcon?: boolean
  marginBottom?: boolean
  testId?: string
}

export const Notification = ({
  children,
  isCloseable = false,
  onClose = () => {},
  isActionable = false,
  actionContent = null,
  actionLink = undefined,
  onAction = undefined,
  variant = 'info',
  showIcon = true,
  marginBottom = true,
  testId = undefined
}: NotificationProps) => {
  const [isVisible, toggleVisibility] = useState(true)

  const handleClose = useCallback(() => {
    toggleVisibility(false)
    onClose()
  }, [onClose])
  const handleAction = useCallback(() => {
    if (onAction) {
      onAction()
    }
  }, [onAction])

  if (isVisible) {
    return (
      <Alert
        testId={testId || 'notification'}
        variant={variant}
        showIcon={showIcon}
        onDismiss={isCloseable ? handleClose : undefined}
        className={cx(styles.notification, {
          'mb-6': marginBottom
        })}
      >
        {children}
        {isActionable && (
          <span>
            {(actionLink && (
              <Link
                data-testid='notification-action'
                className='block underline font-semibold'
                to={actionLink}
              >
                {actionContent}
              </Link>
            )) ||
              (onAction && (
                <button
                  type='button'
                  data-testid='notification-action'
                  className='px-0 underline font-semibold'
                  onClick={handleAction}
                >
                  {actionContent}
                </button>
              ))}
          </span>
        )}
      </Alert>
    )
  }
  return null
}
