import React from 'react'
import { useIsIntlRestaurant } from '../../hooks/use-is-intl-restaurant'

interface ShowForUSProps {
  children: React.ReactNode
}
export const ShowForUS = ({ children }: ShowForUSProps) => {
  if (useIsIntlRestaurant()) {
    return null
  }
  return <>{children}</>
}
