import React from 'react'
import PropTypes from 'prop-types'

import styles from '../Link/Link.module.css'

export const ExternalLink = React.forwardRef(
  ({ variant, onClick, href, target, ...props }, ref) => {
    const rel = target === '_blank' ? 'noopener noreferrer' : undefined

    return (
      <a
        ref={ref}
        className={styles[variant]}
        href={href}
        target={target}
        rel={rel}
        {...props}
      >
        {props.children}
      </a>
    )
  }
)

ExternalLink.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primaryWide',
    'secondaryWide'
  ])
}
