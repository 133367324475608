import { Subject } from 'rxjs'
import { ErrorResponse, Response } from '../cornucopia.types'

export interface ContactInfo {
  name?: string
  phoneNumber?: string
}

export enum JoinOrCreatePartyMode {
  JOIN = 'JOIN',
  CREATE = 'CREATE'
}

export enum PartyVisibility {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export interface JoinOrCreatePartyInfo {
  user?: ContactInfo
  mode: JoinOrCreatePartyMode
  partyGuid?: string
  visibility?: PartyVisibility
  checkGuid?: string
}

export type JoinOrCreatePartyResponse = Response | ErrorResponse

export interface JoinOrCreateHeader {
  response$: Subject<JoinOrCreatePartyResponse>
}

export interface JoinOrCreatePartyRequest {
  header: JoinOrCreateHeader
  joinOrCreatePartyInfo: JoinOrCreatePartyInfo
}
