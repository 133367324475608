import React from 'react'
import { useAvailability } from '../../utils/availability'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'

import styles from './TableNumber.module.css'

const translateName = (name) => {
  switch (name) {
    default:
      return name
  }
}

export const TableNumber = () => {
  const { uniqueIdentifier: name } = useDDIGlobals()
  const { orderingAvailable } = useAvailability()

  if (!orderingAvailable) {
    return null
  }

  return (
    <span className={styles.container}>
      <div className={styles.tableNumber}>{translateName(name)}</div>
    </span>
  )
}
