import namespacedStorage from '../../../../client/utils/namespaced-storage.js'

export const passwordlessStorage = namespacedStorage('passwordless-auth')

export const passwordlessStorageSet = (key: string, value: any) => {
  try {
    passwordlessStorage.set(key, value)
  } catch (error) {}
}

export const passwordlessStorageGet = (key: string) => {
  try {
    return passwordlessStorage.get(key)
  } catch (error) {}
}

export const passwordlessStorageRemove = (key: string) => {
  try {
    passwordlessStorage.remove(key)
  } catch (error) {}
}
