import React from 'react'
import cx from 'classnames'
import { useAvailability } from '../../utils/availability'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import Link from '../Link/Link'
import { Badge } from '@toasttab/buffet-pui-badge'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import { DDIMode } from '../../types/DDIGlobals'

import styles from './DiningAtTable.module.css'
import { CTA } from '@/il8n/en'

type DiningAtTableScanLinkVariant =
  | 'this_isnt_my_table'
  | 'number_only'
  | 'no_link'

export interface DiningAtTableProps {
  scanLinkVariant?: DiningAtTableScanLinkVariant
}

export const DiningAtTable: React.FC<DiningAtTableProps> = ({
  scanLinkVariant = 'number_only'
}) => {
  const { getRestaurantPath } = useRestaurant()
  const { menuAvailable } = useAvailability()
  const { uniqueIdentifier, mode } = useDDIGlobals()

  if (!menuAvailable || mode === DDIMode.TTS) {
    return null
  }

  const textFragment = (
    <div className={styles.tableIdentityContainer}>
      <Badge color='neutral' variant={Badge.Variant.statusLg}>
        {`${CTA.DINING_AT_TABLE} ${uniqueIdentifier}`}
      </Badge>
    </div>
  )

  /* FIXME(EG): Table switching currently only available on initial splash. */
  if (scanLinkVariant === 'no_link') {
    return (
      <div data-testid='dining-at-table-static' className={styles.tableInfo}>
        {textFragment}
      </div>
    )
  } else if (scanLinkVariant === 'this_isnt_my_table') {
    return (
      <div className={cx(styles.tableInfo, styles.isntMyTableContainer)}>
        {textFragment}
        <Link
          to={getRestaurantPath('/scan')}
          data-testid='dining-at-table-scan-link'
          tabIndex={-1}
        >
          <EditIcon />
        </Link>
      </div>
    )
  } else if (scanLinkVariant === 'number_only') {
    return (
      <div className={styles.tableInfo}>
        <Link
          to={getRestaurantPath('/scan')}
          data-testid='dining-at-table-scan-link'
        >
          {textFragment}
        </Link>
      </div>
    )
  }

  return null
}
