import React from 'react'
import cx from 'classnames'
import { FieldLabel } from '@/il8n/en'

type LegalLinksInput = {
  showTwilioCompliance?: boolean
  showInternationalSMSVerbiage?: boolean
}

export const LegalLinks = ({
  showTwilioCompliance = false,
  showInternationalSMSVerbiage = false
}: LegalLinksInput) => (
  <p
    className={cx('type-subhead mb-3 text-center text-gray-100')}
    data-testid='legal-links'
  >
    By placing an order, you agree to Toast's&nbsp;
    <a
      className='type-subhead'
      href='https://pos.toasttab.com/terms-of-service'
      target='_blank'
      rel='noreferrer noopener'
      data-testid='link-terms-of-service'
    >
      Terms of Service
    </a>
    &nbsp;and acknowledge that you have read our&nbsp;
    <a
      className='type-subhead'
      href='https://pos.toasttab.com/privacy'
      target='_blank'
      rel='noreferrer noopener'
      data-testid='link-privacy'
    >
      Privacy Statement.
    </a>
    {showTwilioCompliance && (
      <>
        <br />
        <sup>1 </sup>
        {FieldLabel.INITIALIZE_PARTY_PHONE_DISCLAIMER_FEES}{' '}
        {FieldLabel.INITIALIZE_PARTY_PHONE_DISCLAIMER_RATE}{' '}
        {FieldLabel.INITIALIZE_PARTY_PHONE_DISCLAIMER_STOP}
      </>
    )}
    {showInternationalSMSVerbiage && (
      <>
        <br />
        <sup>1 </sup>
        {FieldLabel.INITIALIZE_PARTY_PHONE_INSTRUCTIONS_INTL}
      </>
    )}
  </p>
)
