import {
  Maybe,
  OptCartFragment
} from './../../../../apollo/generated/OptWebGraphQLOperations'
import { useCallback } from 'react'
import { useGetPartyRefresh } from './../PartyQuery'
import { getPrimaryCheckFromOrder } from '../../../../utils/check-helpers/check-helpers'

const allCartsHaveItems = (carts: Maybe<OptCartFragment>[]): boolean => {
  return carts.every((cart) => {
    const primaryCartCheck = getPrimaryCheckFromOrder(cart?.order)
    const numberOfSelections = primaryCartCheck?.numberOfSelections ?? 0
    return numberOfSelections > 0
  })
}

type GetEveryPartyMemberHasCartItemsRefetch = () => Promise<boolean>
export const useRefetchEveryPartyMemberHasCartItems =
  (): GetEveryPartyMemberHasCartItemsRefetch => {
    const { refetch } = useGetPartyRefresh()
    return useCallback(async () => {
      const res = await refetch()
      if (res?.data.optPartyRefresh.__typename === 'OPTPartyRefreshV2') {
        const carts = res.data.optPartyRefresh.carts
        return allCartsHaveItems(carts)
      }
      return false
    }, [refetch])
  }
