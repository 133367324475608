import React, { ReactNode } from 'react'

import { getMessage } from './error-utils'

import { Notification } from '../Notification/Notification'

import './Error.module.css'
import { ApolloError } from '@apollo/client'

interface GenericError {
  message: string
  name?: string
}
interface ErrorProps {
  error?: GenericError | ApolloError | string
  retry?: () => any
  withoutPleaseTryAgain?: boolean
  appendErrorMessage?: ReactNode
}

const Error: React.FC<ErrorProps> = ({
  error,
  retry,
  withoutPleaseTryAgain = false,
  appendErrorMessage
}) => {
  const errorMessage = getMessage(error)
  return (
    <Notification
      variant='error'
      isActionable={Boolean(retry)}
      actionContent='Click here to retry'
      onAction={() => retry && retry()}
    >
      <span data-testid='error-message'>
        {errorMessage}
        {appendErrorMessage || null}
        {!withoutPleaseTryAgain && !retry && (
          <>{!errorMessage?.endsWith('.') && '.'} Please try again.</>
        )}
      </span>
    </Notification>
  )
}

export default Error
