import * as React from 'react'
import { preauthRequest$ } from 'cornucopia-apis'
import { useSentry } from 'banquet-runtime-modules'
import { Subject } from 'rxjs'
import { useHandleAddPreauthAndFire } from '../../../../client/hooks/tabs/use-add-preauth-and-fire'
import { getRawPhoneNumber } from '../../../../client/utils/form-utils'
import { useHandleApplePayPayment } from '../../../../client/hooks/apple-pay/useHandleApplePayPayment'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { CheckoutMode } from '../../../../client/components/CheckoutForm/checkout-modes'
import { HandlePlaceOrderValuesV2 } from '../../../../client/utils/checkout-helpers'
import { PreauthRequest, PreauthResponse } from './CheckoutListener.types'
import { ResponseTypes } from '../cornucopia.types'
import { toastError } from '../ListenerError'
import { getErrorMsg } from './CheckoutListener.helper'

export const CheckoutListener = () => {
  const { captureMessage } = useSentry()
  const [addPreauthAndFire] = useHandleAddPreauthAndFire()

  const {
    initApplePayPayment,
    error: applePayError,
    loading: applePayLoading,
    response: applePayResponse
  } = useHandleApplePayPayment({
    submitFunction: addPreauthAndFire as (
      values: HandlePlaceOrderValuesV2
    ) => Promise<any>,
    mode: CheckoutMode.CREATE_PREAUTH_TAB_MODE
  })
  const [applePayFired, setApplePayFired] = React.useState<boolean>(false)
  const response$Ref = React.useRef<Subject<PreauthResponse>>()

  React.useEffect(() => {
    const subscription = preauthRequest$.subscribe(
      async (request: PreauthRequest) => {
        if (typeof addPreauthAndFire !== 'function') return
        if (request.checkoutInfo.paymentMethod === 'Credit Card') {
          try {
            const {
              email,
              firstName,
              lastName,
              phone,
              encryptedCard,
              saveCard,
              selectedCard
            } = request.checkoutInfo
            const customer = {
              email: email,
              phone: getRawPhoneNumber(phone),
              firstName: firstName,
              lastName: lastName
            }

            // if used a saved card (selectedCard) vs. new card
            const res = await addPreauthAndFire({
              paymentInfo: {
                customer,
                savedCardInput: selectedCard
                  ? { cardGuid: selectedCard }
                  : null,
                newCardInput: selectedCard
                  ? null
                  : { ...encryptedCard, saveCard }
              }
            })

            const { OPTPartyError, OPTPartyRefreshV2 } = dataByTypename(
              res.data?.optAddPreauthAndFire
            )

            if (OPTPartyError) {
              const { code } = OPTPartyError
              const errorMsg = getErrorMsg(code)

              toastError(errorMsg)

              captureMessage(OPTPartyError.message, 'warning', {
                captureContext: (scope: any) =>
                  scope.setTags({ source: 'corn-tabs-request' })
              })

              request.header.response$.next({
                kind: ResponseTypes.ERROR,
                message: 'An error has occurred',
                code
              })
            }

            if (OPTPartyRefreshV2) {
              request.header.response$.next({
                kind: ResponseTypes.OK,
                info: [{ code: '200', message: 'Paid with Credit Card' }],
                warnings: []
              })
            }
          } catch (err: any) {
            toastError('Something went wrong.')
            captureMessage(err.message, 'warning', {
              captureContext: (scope: any) =>
                scope.setTags({ source: 'corn-tabs-request' })
            })

            request.header.response$.next({
              kind: ResponseTypes.ERROR,
              message: 'An error has occurred',
              code: ''
            })
          }
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [addPreauthAndFire, captureMessage])

  React.useEffect(() => {
    const subscription = preauthRequest$.subscribe(
      (request: PreauthRequest) => {
        if (request.checkoutInfo.paymentMethod === 'Apple Pay') {
          initApplePayPayment({ paymentInfo: {} })
          setApplePayFired(true)
          response$Ref.current = request.header.response$
        }
      }
    )
    return () => {
      subscription.unsubscribe()
    }
  }, [initApplePayPayment])

  React.useEffect(() => {
    if (applePayFired && !applePayLoading) {
      const { OPTPartyError } = dataByTypename(
        applePayResponse?.data?.optAddPreauthAndFire
      )

      if (applePayError || OPTPartyError) {
        const message = applePayError
          ? applePayError.message
          : OPTPartyError.message
        toastError(message || 'Something went wrong. Please try again.')

        if (OPTPartyError) {
          captureMessage(OPTPartyError.message, 'warning', {
            captureContext: (scope: any) =>
              scope.setTags({ source: 'corn-tabs-request' })
          })
        }

        response$Ref.current?.next({
          kind: ResponseTypes.ERROR,
          message: 'An error has occurred',
          code: ''
        })
      } else {
        response$Ref.current?.next({
          kind: ResponseTypes.OK,
          info: [{ code: '200', message: 'Paid with Apple Pay' }],
          warnings: []
        })
      }

      setApplePayFired(false)
    }
  }, [
    applePayFired,
    applePayLoading,
    applePayError,
    applePayResponse,
    captureMessage
  ])

  return <></>
}
