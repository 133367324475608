import React, { useMemo } from 'react'
import cx from 'classnames'
import {
  useGetAllChecks,
  useGetPartyRefresh,
  useGetSortedMemberAndServerBucketsFromCheck,
  useGetMainCheck
} from '../PartyQuery/PartyQuery'
import { CheckBucketsView } from '../CheckBucketsView/CheckBucketsView'
import Fieldset from '../Fieldset/Fieldset'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { FieldLabel } from '@/il8n/en'
import { CheckFooter } from '../CheckFooter/CheckFooter'
import { combineChecks, combineChecksAsBucket } from '../../utils/check-helpers'
import Progress from '../Progress/Progress'
import Error from '../Error/Error'
import {
  OptCheckV2GuidFragment,
  OptPartyMemberV2,
  ServiceChargeCategory,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import { SurchargeDisclaimer } from '../SurchargeDisclaimer/SurchargeDisclaimer'

export const CheckBreakdownAccordionV2 = () => {
  const { allChecks, loading, error, refetch } = useGetAllChecks()
  const allChecksCombined: OptCheckV2GuidFragment | null =
    combineChecks(allChecks)
  const { serverBuckets, sortedMemberBuckets, me } =
    useGetSortedMemberAndServerBucketsFromCheck(allChecksCombined)
  const numberOfPartyMembers = sortedMemberBuckets.length
  const { partyRefresh } = useGetPartyRefresh()
  const { mainCheck } = useGetMainCheck()
  const isSplitEven =
    partyRefresh?.splitPaymentData?.splitMode === SplitMode.Even

  // If the order has been evenly split, we want to combine quantities of items back together for the full bill section
  const combinedChecksBucket = me ? combineChecksAsBucket(me, allChecks) : null

  // Filter all checks to hide members without items
  const filteredBuckets = useMemo(() => {
    return sortedMemberBuckets.filter((b) => {
      return b.check.numberOfSelections > 0
    })
  }, [sortedMemberBuckets])

  // Combine my checks for display on CheckFooter
  const myClaimedChecks = allChecks.filter((check) => {
    const myClaimedCheckGuids = me?.claimedCheckGuids || []
    return myClaimedCheckGuids.includes(check.guid)
  })

  const combinedMyCheck = combineChecks(
    myClaimedChecks.length ? myClaimedChecks : [mainCheck]
  )
  const myCheck = {
    member: me as OptPartyMemberV2,
    check: combinedMyCheck as OptCheckV2GuidFragment
  }

  const paidForFullCheck =
    allChecksCombined?.isClosed && allChecks.length === 1 && combinedMyCheck

  const myPaidPortions = myCheck.member?.claimedPortionsPaid || 0
  const totalPortions = partyRefresh?.splitPaymentData?.evenSplitPortions || 0

  const paidSurcharge = combinedMyCheck?.appliedServiceCharges?.find(
    (sc) =>
      sc.serviceChargeCategory === ServiceChargeCategory.CreditCardSurcharge
  )
  const surchargeRatePercentage =
    paidSurcharge?.serviceChargeDetails?.percent ?? 0

  if (!me) {
    return null
  }

  if (loading) {
    return <Progress />
  }
  return (
    <div className='mt-14 mx-4 mb-8 border border-solid border-gray-50 rounded-lg'>
      {combinedMyCheck && (
        <Fieldset
          label={FieldLabel.YOUR_ORDER_ACCORDION_LABEL}
          collapsable
          collapsed={true}
          variant='secondary'
          labelCentered={true}
        >
          <div className='mb-6'>
            {isSplitEven ? (
              <div
                className='mb-6'
                data-testid='thank-you-page-split-even-details'
              >
                You've paid for{' '}
                <b>
                  {myPaidPortions} out of {totalPortions}
                </b>{' '}
                people splitting the bill.
              </div>
            ) : (
              <CheckBucketsView
                me={me}
                buckets={
                  isSplitEven && combinedChecksBucket
                    ? combinedChecksBucket
                    : [myCheck]
                }
                serverBuckets={[]}
                enableReorder={false}
                splitPaymentMethod={SplitPaymentMethod.PAY_FOR_SELF}
                claimServerItems={false}
                isOrderClosed={true}
                checksCollapsible={false}
                displayUnpricedMods={false}
              />
            )}

            {error && <Error error={error} retry={refetch} />}
            {combinedMyCheck && (
              <CheckFooter
                check={combinedMyCheck}
                tip={combinedMyCheck.tip}
                totalMode='SOLO'
              />
            )}
            {paidSurcharge && (
              <div className='my-4'>
                <SurchargeDisclaimer
                  surchargeRatePercentage={surchargeRatePercentage}
                  // don't show amount on confirm page disclaimer
                />
              </div>
            )}
          </div>
        </Fieldset>
      )}
      {numberOfPartyMembers > 1 && !paidForFullCheck && (
        <div
          className={cx({
            'border-0 border-t border-solid border-gray-50': combinedMyCheck
          })}
        >
          <Fieldset
            label={FieldLabel.GROUP_ORDER_ACCORDION_LABEL}
            collapsable
            collapsed={true}
            variant='secondary'
            labelCentered={true}
          >
            <div className='mb-6'>
              <CheckBucketsView
                me={me}
                buckets={
                  isSplitEven && combinedChecksBucket
                    ? combinedChecksBucket
                    : filteredBuckets
                }
                serverBuckets={isSplitEven ? [] : serverBuckets}
                enableReorder={false}
                splitPaymentMethod={SplitPaymentMethod.PAY_FOR_PARTY}
                claimServerItems={false}
                isOrderClosed={allChecksCombined?.isClosed}
                checksCollapsible={false}
                displayUnpricedMods={false}
              />
              {allChecksCombined !== null && (
                <CheckFooter check={allChecksCombined} totalMode='GROUP' />
              )}
              {paidSurcharge && (
                <div className='my-4'>
                  <SurchargeDisclaimer
                    surchargeRatePercentage={surchargeRatePercentage}
                    // don't show amount on confirm page disclaimer
                  />
                </div>
              )}
            </div>
          </Fieldset>
        </div>
      )}
    </div>
  )
}
