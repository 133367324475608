export type InputChangedEvent = {
  content: {
    isValid: boolean
    selectedPaymentMethod: SpiPaymentType | null
  }
}

export type TokenizeResultEvent = {
  content: {
    paymentMethodId: string
    error?: string
    billingDetails: {
      email: string
      phoneNumber: string
      name: string
    }
  }
}

export type ConfirmPaymentResultEvent = {
  content: {
    payment: {
      externalReferenceId: string
      email: string
    }
    error?: string
  }
}

export type ConfirmPaymentParams = {
  email?: string
}

export type InitFrameData = {
  domElementId: string
  merchantId: string
  sessionSecret: string
  acceptAmex: boolean
  zipRequired: boolean
  oauthToken: string
}

export type LineItem = {
  label: string
  amount: number
}

export type SpiSdk = {
  /**
   * This function will render the SPI iFrame. It takes a set of callbacks to be triggered on a successful initialization
   * and for a failure.
   * @param onInitSuccessCallback
   * @param onInitErrorCallback
   * @param initFrameData
   */
  initialize: (
    onInitSuccessCallback: (e: InputChangedEvent) => void,
    onInitErrorCallback: () => void,
    initFrameData: InitFrameData
  ) => HTMLIFrameElement
  /**
   * This method will allow for monitoring the events inside the iFrame
   * @param onInputChangedEvent
   */
  monitor: (onInputChangedEvent: (e: InputChangedEvent) => void) => void
  /**
   * Once the user has selected a payment method and/or entered their credit card details, this method, when invoked,
   * will create a payment method representing the user's payment data. It will return the payment method id. This must
   * be called prior to calling confirm payment. Accepts callbacks that will be invoked on a successful and failed
   * execution.
   *
   * For digital wallets, such as Apple Pay and Google Pay, you can pass along an array of line items to display on the
   * amount breakdown.
   * @param onConfirmSuccessCallback
   * @param onConfirmFailureCallback
   * @param lineItems
   */
  createPaymentMethod: (
    onConfirmSuccessCallback: (r: TokenizeResultEvent) => Promise<void>,
    onConfirmFailureCallback: (e: any) => Promise<void>,
    lineItems: LineItem[],
    surchargeAmount?: number
  ) => Promise<void>
  /**
   * Confirm payment will use the payment method from createPaymentMethod and authorize a transaction based on the
   * details present on the payment intent.Accepts callbacks that will be invoked on a successful and failed
   * execution.
   * @param onConfirmSuccessCallback
   * @param onConfirmFailureCallback
   */
  confirmPayment: (
    onConfirmSuccessCallback: (r: ConfirmPaymentResultEvent) => Promise<void>,
    onConfirmFailureCallback: (e: any) => Promise<void>,
    confirmPaymentParams?: ConfirmPaymentParams
  ) => Promise<void>
  /**
   * This should only be invoked when the guest is selecting a payment method outside the iframe, such as Click to Pay.
   * We do not intend to support this function long term and will be removed once all required payment methods are
   * integrated to the iframe.
   */
  clearPaymentSelection: () => void
  /**
   * This method will cause the iframe to retrieve any updates to the payment intent. This should be invoked whenever
   * the payment intent has an amount change or a customerId is attached to it.
   */
  fetchUpdates: () => void
}

export enum SpiSdkLoadingState {
  READY = 'ready',
  ERROR = 'error',
  LOADING = 'loading'
}

export enum SpiInputState {
  VALID = 'spi_valid',
  INVALID = 'spi_invalid'
}

export enum SpiPaymentType {
  APPLE_PAY = 'APPLE_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  NEW_CARD = 'NEW_CARD',
  SAVED_CARD = 'SAVED_CARD'
}

export const SpiFrameId = 'toast-checkout'

declare global {
  interface Window {
    Toast: SpiSdk | undefined
  }
}
