import React from 'react'
import Modal from '../Modal/Modal'
import { Button } from '@toasttab/buffet-pui-buttons'
import Dollars from '../Dollars/Dollars'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useHistory } from 'react-router'
import { FieldLabel, HeaderText } from '@/il8n/en'

interface PortionsChangedDialogProps {
  showDialog: boolean
  amountDue?: number
  /**
   * Function to fire when the modal closes.
   * Turning the showDialog boolean to false
   * and any necessary cleanup work can be handled here.
   */
  handleClose: () => void
  splitRemoved?: boolean
}

export const PortionsChangedDialog = ({
  showDialog,
  amountDue = 0,
  handleClose,
  splitRemoved = false
}: PortionsChangedDialogProps) => {
  const history = useHistory()
  const { getRestaurantPath } = useRestaurant()

  if (!showDialog) {
    return null
  }

  const redirectToTab = () => {
    if (splitRemoved) {
      history.push({
        pathname: getRestaurantPath('tab')
      })
    }
  }

  return (
    <Modal
      header={
        <h4 className='font-medium whitespace-pre-wrap text-center'>
          {splitRemoved ? HeaderText.SPLIT_REMOVED : HeaderText.SPLIT_CHANGED}
        </h4>
      }
      responsive={false}
      onClose={async () => {
        handleClose()
        redirectToTab()
      }}
      compactHeader={true}
    >
      <div className='flex flex-col px-4 space-y-6 text-center items-center mb-6'>
        <div className='type-default'>
          {splitRemoved ? (
            FieldLabel.SPLIT_REMOVED
          ) : (
            <>
              You are now paying <Dollars amount={amountDue} />
            </>
          )}
        </div>
        <Button
          onClick={async () => {
            handleClose()
            redirectToTab()
          }}
          variant='secondary'
          className='w-full'
          testId='portions-changed-button'
        >
          Got it
        </Button>
      </div>
    </Modal>
  )
}
