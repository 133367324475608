import React, { useEffect } from 'react'

// source: Adapted from https://stackoverflow.com/a/54292872
type ClickHandler = (e: MouseEvent) => void
export function useOuterClick<ElementType extends HTMLElement>(
  callback: ClickHandler
) {
  const innerRef = React.useRef<ElementType | null>(null)
  const callbackRef = React.useRef<ClickHandler | undefined>()
  callbackRef.current = callback // keeps callback stable for useEffect

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (e.target instanceof Element) {
        if (!innerRef?.current?.contains(e.target) && callbackRef.current) {
          callbackRef.current(e)
        }
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  }, [])

  return innerRef
}
