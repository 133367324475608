import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom'

import styles from './Link.module.css'

export type LinkVariant =
  | 'primary'
  | 'secondary'
  | 'primaryWide'
  | 'secondaryWide'
  | 'secondaryWideButton'

export interface LinkProps extends RouterLinkProps {
  variant?: LinkVariant
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      variant = 'secondary',
      onClick = () => {},
      children,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <RouterLink
        ref={ref}
        className={cx(styles[variant], className)}
        onClick={onClick}
        {...props}
      >
        {children}
      </RouterLink>
    )
  }
)

Link.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'primaryWide',
    'secondaryWide',
    'secondaryWideButton'
  ])
}

export default Link
