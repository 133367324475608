import React from 'react'

import styles from './Progress.module.css'

import isEqual from 'lodash/isEqual'

interface ProgressProps {
  /**
   * minHeight (default) - Good for use as a centered block
   * element in a container;
   * uses an opinionated minimum height
   *
   * initialHeight - Good for use as a smaller block element.
   * Uses the initial height of the contained element
   * (30px x 30px).
   */
  variant?: 'minHeight' | 'initialHeight'
}

const Progress = ({ variant = 'minHeight' }: ProgressProps) => {
  return (
    <div
      className={variant === 'minHeight' ? styles.wrapper : undefined}
      // Since testing for styles is hard, better to expose for tests for this attribute
      data-style-variant={variant}
      role='progressbar'
      aria-label='loading...'
      data-testid='loading-progress'
    >
      <svg className={styles.svg} viewBox='-200 -200 400 400'>
        <g className={styles.g}>
          <circle className={styles.circle} r='130' pathLength='100' />
        </g>
      </svg>
    </div>
  )
}

export default React.memo(Progress, isEqual)
