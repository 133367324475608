import React, { useState } from 'react'
import { CTA } from '@/il8n/en'
import { useSplitPaymentsEnabled } from '../../hooks/split-payments/use-split-payments-enabled'
import {
  useGetServerExclusivelySplitCheck,
  useGetPartyRefresh
} from '../PartyQuery/PartyQuery'
import {
  SplitPaymentMethod,
  SplitPaymentMode
} from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { RouterLinkButton } from '../Button/RouterLinkButton'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import {
  OptCheckV2GuidFragment,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import { Variant } from '@toasttab/buffet-pui-buttons'
import { useApplySplitType } from '../../hooks/split-payments/use-apply-split-type'
import {
  CloseOrderTabLinkButton,
  CloseOrderTabLinkButtonProps
} from './CloseOrderTabLinkButton'
import { track } from '@toasttab/do-secundo-analytics'
import { useAvailability } from '../../utils/availability'
import { FormatCurrency } from '../Currency/FormatCurrency'
import { ShowForUS } from '../ShowForUS/ShowForUS'
import { SplitEvenBottomSheet } from '../SplitEvenBottomSheet/SplitEvenBottomSheet'
import { CannotApplyChangeDialog } from '../SplitEvenBottomSheet/CannotApplyChangeDialog'
import { AlreadySplitDialog } from '../SplitEvenBottomSheet/AlreadySplitDialog'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { isTaxInclusive } from '../../utils/tax-inclusive'

interface CloseOrderTabLinkProps {
  mainCheck: OptCheckV2GuidFragment
  testId?: string
  /** Forces buttons to have the given variant rather than their default */
  variantOverride?: Variant
}

export const CloseOrderTabLink: React.FC<CloseOrderTabLinkProps> = ({
  mainCheck,
  testId,
  variantOverride
}) => {
  const [showAlreadySplitDialog, setShowAlreadySplitDialog] = useState(false)
  const [showCannotChangeSplitDialog, setShowCannotChangeSplitDialog] =
    useState(false)
  const { partyRefresh } = useGetPartyRefresh()
  const preSelectedSplitMethod = partyRefresh?.splitPaymentData?.splitMode
  const isServerSplitCheck = useGetServerExclusivelySplitCheck()
  const splitPaymentsUIEnabled =
    useSplitPaymentsEnabled() && !isServerSplitCheck

  const { getRestaurantPath } = useRestaurant()
  const { data } = useRestaurantInfo()
  const { orderingAvailable } = useAvailability()
  const displayPayMyPart =
    !orderingAvailable && preSelectedSplitMethod === 'EVEN'
  const [showDrawer, setShowDrawer] = useState(displayPayMyPart)
  const { loading: applySplitTypeLoading } = useApplySplitType()

  const buttonProps: Partial<CloseOrderTabLinkButtonProps> = {
    variantOverride,
    applySplitTypeLoading
  }

  const basePathname = getRestaurantPath('tab/close')
  const toPayParty = {
    pathname: basePathname,
    search: `?paymentMode=${SplitPaymentMode.PARTY}`
  } as const

  const isOnlyOneSplitPortionLeftToPay = (): boolean => {
    const splitPaymentData = partyRefresh?.splitPaymentData

    if (!splitPaymentData) {
      return false
    }

    const portionsLeft =
      splitPaymentData.evenSplitPortions -
      splitPaymentData.evenSplitPaidPortions

    return portionsLeft === 1
  }

  const mainCheckSubtotal =
    mainCheck.preDiscountedSubtotal + (isTaxInclusive(data) ? mainCheck.tax : 0)

  const splitEvenSiblingComponents = (
    <>
      <AlreadySplitDialog
        showDialog={showAlreadySplitDialog}
        setShowDialog={setShowAlreadySplitDialog}
        setShowDrawer={setShowDrawer}
        toSplitMode={SplitMode.MyItems}
      />
      <CannotApplyChangeDialog
        showDialog={showCannotChangeSplitDialog}
        setShowDialog={setShowCannotChangeSplitDialog}
      />
      {splitPaymentsUIEnabled && (
        <SplitEvenBottomSheet
          subtotal={mainCheckSubtotal}
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
        />
      )}
    </>
  )

  // Another party member has already started the split
  if (preSelectedSplitMethod && SplitMode.Even === preSelectedSplitMethod) {
    return (
      <>
        {splitEvenSiblingComponents}
        <CloseOrderTabLinkButton
          splitPaymentMethod={SplitPaymentMethod.SPLIT_EVENLY}
          onClick={
            isOnlyOneSplitPortionLeftToPay()
              ? () => {
                  return track('joinSplitPayment', {
                    numberOfClaimedPortions: 1
                  })
                }
              : () => {
                  setShowDrawer(true)
                }
          }
          to={isOnlyOneSplitPortionLeftToPay() ? toPayParty : undefined}
          splitAlreadyStarted
          hasIcon={false}
          {...buttonProps}
        />
      </>
    )
  }

  if (splitPaymentsUIEnabled) {
    return (
      <>
        {splitEvenSiblingComponents}
        <div className='flex space-x-2' data-testid={testId}>
          <CloseOrderTabLinkButton
            to={toPayParty}
            compact
            hasIcon={false}
            {...buttonProps}
          />
          <CloseOrderTabLinkButton
            splitPaymentMethod={SplitPaymentMethod.SPLIT_EVENLY}
            onClick={() => {
              setShowDrawer(true)
            }}
            compact
            {...buttonProps}
            hasIcon={false}
          />
        </div>
      </>
    )
  }

  // Otherwise, Split payment UI not available
  return (
    <RouterLinkButton
      to={basePathname}
      data-testid={testId}
      variant={variantOverride}
    >
      <span>
        {CTA.CLOSE_CHECK_NO_SPLIT_PAY}{' '}
        {!isServerSplitCheck && (
          <>
            (
            {
              mainCheck.selections?.filter((selection) =>
                'voided' in selection ? !selection.voided : true
              ).length
            }
            )
          </>
        )}
      </span>
      <ShowForUS>
        <span>
          <span className='font-normal'>Subtotal&nbsp;</span>
          <FormatCurrency amount={mainCheck.preDiscountedSubtotal} />
        </span>
      </ShowForUS>
    </RouterLinkButton>
  )
}
