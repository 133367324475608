import React from 'react'
import { PropTypes } from 'prop-types'

import {
  countItemsInPartyCarts,
  partyCartsHaveItems
} from '../../utils/cart-helpers'
import Dollars from '../Dollars/Dollars'
import CartButton from '../CartButton/CartButton'
import { combineCartChecks } from '../../utils/check-helpers/check-helpers'

export const PartyTabButton = ({ check, carts, tip = null, to, title }) => {
  const combinedCartsAsCheck = combineCartChecks(
    carts.flatMap((c) => c.order.checks)
  )

  const showCartsInfo = partyCartsHaveItems(carts)

  const amount = showCartsInfo
    ? combinedCartsAsCheck?.preDiscountedSubtotal
    : check?.preDiscountedSubtotal

  const numOfSelections = showCartsInfo
    ? countItemsInPartyCarts(carts)
    : check.numberOfSelections

  return (
    <CartButton
      testId='button-checkout-party-tab'
      to={to}
      left={
        <span>
          {title} ({numOfSelections})
        </span>
      }
      right={<Dollars amount={amount} />}
    />
  )
}

PartyTabButton.propTypes = {
  check: PropTypes.shape({
    numberOfSelections: PropTypes.number
  }).isRequired,
  tip: PropTypes.number,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  carts: PropTypes.arrayOf(PropTypes.object)
}
