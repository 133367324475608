import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ModeLink } from '../ModeRouter/ModeRouter'
import { PoweredByToastModal } from '../PoweredByToastModal/PoweredByToastModal'
import { RestaurantShape } from '../RestaurantHeader/RestaurantPropShape'
import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import Progress from '../Progress/Progress'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { cdnUrl } from '../../utils/cdn'
import { useUniqueUserId } from '../../utils/use-unique-user-id'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import InitializePartyModal from './InitializePartyModal/InitializePartyModal'
import {
  PARTY_INIT_STATE_ENUM,
  PARTY_INIT_ACTIONS,
  USER_ACTIONS
} from './model/opt_party_initialization_model'
import { usePartySplashState } from './OPTPartySplashContainer'
import { useParty } from '../PartyProvider/PartyProvider'
import { usePartyByUniqueIdentifiersOrInvite } from '../../hooks/party/use-party-by-unique-identifiers'
import ErrorComponent from '../Error/Error'
import { NetworkStatus } from '@apollo/client'
import { useHistory, useLocation } from 'react-router'
import { TabClosed } from '../TabClosed/TabClosed'
import { useGetOrderFromParty, useGetPartyMode } from '../PartyQuery/PartyQuery'
import { useTab } from '../TabProvider/TabProvider'
import isEqual from 'lodash/isEqual'
import InviteIcon from '../../assets/icon-24-communication.svg'
import { track } from '@toasttab/do-secundo-analytics'
import { Badge } from '@toasttab/buffet-pui-badge'

import styles from './OPTPartySplash.module.css'
import { CTA, HeaderText } from '@/il8n/en'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { DDIMode } from '../../types/DDIGlobals'
import { AutoInitializePartyModal } from './AutoInitializePartyModal/AutoInitializePartyModal'
import { OPTPartyErrorComponent } from './OPTPartyError'
import ToastLogo from '../../assets/powered-by/grey-horizontal.svg'
import { MenuPageRedirect } from '../MenuPageRedirect/MenuPageRedirect'
import { ShowForUS } from '../ShowForUS/ShowForUS'

export const LoadingModal = () => (
  <div
    data-testid='loading-modal'
    className='absolute top-0 bottom-0 left-0 right-0 z-50 flex flex-col w-full h-full bg-white'
    tabIndex='0'
  >
    <Progress />
    <div className='mx-auto my-5 w-36'>
      <ToastLogo />
    </div>
  </div>
)

const OPTPartySplash = ({ inviteCode }) => {
  const { data: restaurant } = useRestaurantInfo()
  const { name: restaurantName } = restaurant || {}
  const uniqueUserId = useUniqueUserId()
  const { paygOrderGuid } = useParty()
  const { authenticated, user } = useAuth()
  const { mode } = useGetPartyMode()
  const hasUserFirstName = authenticated && user?.firstName

  const {
    dispatch: initPartyDispatch,
    partyInitState,
    ...partySplashState
  } = usePartySplashState()

  const {
    party,
    loading: stubLoading,
    error,
    networkStatus,
    refetch
  } = usePartyByUniqueIdentifiersOrInvite({ inviteCode })
  const { primaryCheck } = useGetOrderFromParty()

  const loading = stubLoading || networkStatus === NetworkStatus.refetch

  useEffect(() => {
    //If there is a valid inviteCode change state, otherwise do not trigger a dispatch
    if (inviteCode && inviteCode !== partySplashState.inviteCode) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_INVITE_CODE,
        value: inviteCode
      })
    }
  }, [inviteCode, partySplashState.inviteCode, initPartyDispatch])

  useEffect(() => {
    if (
      !inviteCode &&
      error &&
      !isEqual(partySplashState.partyStubError, error)
    ) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_PARTY_STUB_ERROR,
        value: error
      })
    } else if (
      inviteCode &&
      error &&
      !isEqual(partySplashState.partyStubError, error)
    ) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_PARTY_INVITE_STUB_ERROR,
        value: error
      })
    }
  }, [error, initPartyDispatch, inviteCode, partySplashState.partyStubError])

  useEffect(() => {
    //If Customer doesn't want to join party do not reload partyStub
    //keep in mind that re-loading partyStub will end up with the same values as before loading
    //hasBeenDeniedToJoinParty avoids displaying PROMPT_IS_THIS_YOUR_PARTY twice
    const hasBeenDeniedToJoinParty =
      partyInitState.previous ===
      PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM
    if (
      partySplashState.loadingPartyStub !== loading &&
      !hasBeenDeniedToJoinParty
    ) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_PARTY_STUB_LOADING,
        value: loading
      })
    }
    // TODO: See if this exhaustive deps override is necessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPartyDispatch, loading, partySplashState.loadingPartyStub])

  useEffect(() => {
    if (paygOrderGuid !== partySplashState.paygOrderGuid) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_PAYG_GUID,
        value: paygOrderGuid
      })
    }
  }, [paygOrderGuid, partySplashState.paygOrderGuid, initPartyDispatch])

  const currentPartyInitState = partyInitState.current
  const previousPartyInitState = partyInitState.previous

  useEffect(() => {
    switch (currentPartyInitState) {
      case PARTY_INIT_STATE_ENUM.UNINITIALIZED:
      case PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY:
      case PARTY_INIT_STATE_ENUM.PROMPT_SSAT_JOIN_OR_CREATE:
        if (
          party &&
          !loading &&
          networkStatus !== NetworkStatus.refetch &&
          !error
        ) {
          initPartyDispatch({
            type: PARTY_INIT_ACTIONS.SET_PARTY_STUB,
            value: party.guid ? party : false
          })
        }
        break
      case PARTY_INIT_STATE_ENUM.CREATE_UNIQUE_IDENTITY:
        if (uniqueUserId && partySplashState.uniqueUserId !== uniqueUserId) {
          initPartyDispatch({
            type: PARTY_INIT_ACTIONS.SET_UNIQUE_USER_ID,
            value: uniqueUserId
          })
        }
        break
      default:
        break
    }
  }, [
    party,
    initPartyDispatch,
    loading,
    uniqueUserId,
    networkStatus,
    error,
    currentPartyInitState,
    partySplashState.uniqueUserId
  ])

  // keep refetch side-effects in own useEffect to avoid repetitious refetch
  useEffect(() => {
    switch (currentPartyInitState) {
      case PARTY_INIT_STATE_ENUM.RETRY_LOAD_STUB:
        refetch && refetch()
        break
      case PARTY_INIT_STATE_ENUM.TAB_IS_CLOSED:
        initPartyDispatch({
          type: PARTY_INIT_ACTIONS.RESET_PARTY_STATE
        })
        refetch && refetch()
        break
      default:
        break
    }
  }, [refetch, currentPartyInitState, initPartyDispatch])

  const isClosed = Boolean(primaryCheck?.isClosed)
  useEffect(() => {
    if (partySplashState.partyClosed !== isClosed) {
      initPartyDispatch({
        type: PARTY_INIT_ACTIONS.SET_PARTY_CLOSED,
        value: isClosed
      })
    }
  }, [isClosed, initPartyDispatch, partySplashState.partyClosed])
  switch (currentPartyInitState) {
    case PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_STUB_ERROR:
      return <PartyStubError restaurant={restaurant} />
    case PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR:
      return <PartyInviteStubError />
    case PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB:
    case PARTY_INIT_STATE_ENUM.UNINITIALIZED:
    case PARTY_INIT_STATE_ENUM.CREATE_UNIQUE_IDENTITY:
    case PARTY_INIT_STATE_ENUM.RETRY_LOAD_STUB:
      return <LoadingModal />
    case PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY:
      return (
        <>
          {/* Ensure that we are on menu page */}
          <MenuPageRedirect />
          {(mode === DDIMode.OPT ||
            mode === DDIMode.QR ||
            mode === DDIMode.TTS) && (
            <InitializePartyModal
              initPartyGreeting={
                previousPartyInitState ===
                PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM
                  ? `No problem${hasUserFirstName ? ` ${user.firstName}` : ''}`
                  : `Welcome${restaurantName ? ` to ${restaurantName}` : ''}${
                      hasUserFirstName ? `, ${user.firstName}` : ''
                    }`
              }
            />
          )}
          {(mode === DDIMode.STP || mode === DDIMode.MNP) && (
            <AutoInitializePartyModal mode={mode} />
          )}
        </>
      )
    case PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY:
    case PARTY_INIT_STATE_ENUM.PROMPT_SSAT_JOIN_OR_CREATE:
      return <SsatPartyModal />
    case PARTY_INIT_STATE_ENUM.PROMPT_JOIN_PARTY:
      return <JoinPartyModal />
    case PARTY_INIT_STATE_ENUM.PROMPT_REQUIRE_INVITE:
      return <RequireInviteModal restaurant={restaurant} />
    case PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM:
      return <NotMyPartyModal members={party && party.members} />
    case PARTY_INIT_STATE_ENUM.PROMPT_TAB_IS_CLOSED:
      return <PartyTabClosed />
    default:
      return null
  }
}

// regular expression for pathname of confirm/{guid}
const confirmationPageRegexp =
  /^\/confirm\/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i
const PartyTabClosed = () => {
  const { deleteTab } = useTab()
  const { deletePartyProperties } = useParty()
  const { order } = useGetOrderFromParty()
  const { mode } = useGetPartyMode()

  const handleCloseAndDelete = useCallback(() => {
    deleteTab && deleteTab()
    deletePartyProperties()
  }, [deleteTab, deletePartyProperties])

  const location = useLocation()

  if (!order) {
    return null
  }

  if (confirmationPageRegexp.test(location.pathname)) {
    return null
  }
  const onClose = mode !== DDIMode.STP ? handleCloseAndDelete : undefined

  return <TabClosed onClose={onClose} order={order} />
}

const PartyStubError = ({ restaurant }) => {
  const { partyStubError, dispatch } = usePartySplashState()

  const retry = useCallback(() => {
    return dispatch({
      type: PARTY_INIT_ACTIONS.USER_ACTION,
      forState: PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_STUB_ERROR,
      value: USER_ACTIONS.RETRY
    })
  }, [dispatch])

  return (
    <OPTPartyErrorComponent
      error={partyStubError}
      retry={retry}
      restaurant={restaurant}
    />
  )
}

const PartyInviteStubError = () => {
  const history = useHistory()
  const { partyStubError, dispatch } = usePartySplashState()
  const { uniqueIdentifier } = useDDIGlobals()

  return (
    <PoweredByToastModal header={HeaderText.INVALID_INVITE_LINK}>
      <>
        {partyStubError && (
          <ErrorComponent error={partyStubError} withoutPleaseTryAgain />
        )}
        The invitation link is either expired or is invalid.
        <div className={styles.buttonWrapper}>
          <Button
            data-testid='party-modal-button-init-party'
            variant='primaryWide'
            onClick={() => {
              dispatch({
                type: PARTY_INIT_ACTIONS.USER_ACTION,
                value: USER_ACTIONS.CLEAR_INVITE_CODE,
                forState: PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR
              })
              history.push({
                ...history.location,
                state: null
              })
            }}
          >
            {CTA.PARTY_LOOKUP_ERROR_CONTINUE_TABLE} {uniqueIdentifier}
          </Button>
        </div>
      </>
    </PoweredByToastModal>
  )
}

const RequireInviteModal = ({ restaurant }) => {
  const { partyStub } = usePartySplashState()

  return (
    <Modal responsive>
      <div className={styles.bodyWrapper}>
        <div className='px-6 pb-6'>
          <OPTPartySplashHeader restaurant={restaurant} />
          <div className={styles.greeting}>
            <h3 className={styles.greetingHeading}>
              Ask to be invited{' '}
              <InviteIcon className={styles.greetingHeadingIcon} />
            </h3>
          </div>
          <p className={styles.requireInviteText}>
            This group was started a while ago. To join, ask someone in your
            group to invite you.
          </p>
          <div className={styles.spacer} />
          <p className={styles.secondRequireInviteText}>
            If you are not dining with the group below, confirm to start a new
            group.
          </p>
          <EnumeratedMembers members={partyStub && partyStub.members} />
          <SignInFooter onCloseLocation={'?mode=partySplash'} />
        </div>
      </div>
    </Modal>
  )
}

const JoinPartyModal = () => {
  const { partyStub } = usePartySplashState()
  const { authenticated, user } = useAuth()
  const hasUserFirstName = authenticated && user?.firstName

  return (
    <InitializePartyModal
      initPartyGreeting={`Great${hasUserFirstName ? ` ${user.firstName}` : ''}`}
      existingPartyMembers={(partyStub && partyStub.members) || []}
      joiningPartyGuid={partyStub && partyStub.guid}
    />
  )
}

const SsatPartyModal = () => {
  const { partyStub } = usePartySplashState()
  const { authenticated, user } = useAuth()
  const hasUserFirstName = authenticated && user?.firstName

  return (
    <InitializePartyModal
      initPartyGreeting={`Welcome${
        hasUserFirstName ? ` ${user.firstName}` : ''
      }`}
      // Although we are letting the backend decide which party / order to join,
      // we want to limit duplicate name errors, so we predict which party
      // will be joined and compare against those members.
      existingPartyMembers={(partyStub && partyStub.members) || []}
    />
  )
}

const NotMyPartyModal = ({ members }) => {
  const { deletePartyProperties } = useParty()
  const { dispatch: initPartyDispatch } = usePartySplashState()

  return (
    <Modal
      responsive
      data-testid='not-my-party-modal'
      header={
        <div className={styles.greeting}>
          <h3 className={styles.greetingHeading}>Not your group?</h3>
          <p className={cx('mt-4', styles.greetingSubheading)}>
            Are you sure you're not dining with:
          </p>
        </div>
      }
      footer={
        <div className={styles.superModalFooter}>
          <Button
            data-testid='party-supermodal-button-back-to-party'
            variant='secondaryWide'
            onClick={() => {
              initPartyDispatch({
                type: PARTY_INIT_ACTIONS.USER_ACTION,
                forState: PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM,
                value: USER_ACTIONS.CONFIRM_JOIN_PARTY
              })
            }}
          >
            {CTA.GO_BACK}
          </Button>
          <Button
            data-testid='party-modal-link-init-party-new'
            variant='primaryWide'
            mode='partyInit'
            onClick={() => {
              deletePartyProperties()
              initPartyDispatch({
                type: PARTY_INIT_ACTIONS.USER_ACTION,
                forState: PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM,
                value: USER_ACTIONS.DENY_JOIN_PARTY
              })
            }}
          >
            {CTA.PARTY_CONFIRM_DENY_JOIN_PARTY}
          </Button>
        </div>
      }
    >
      <div className={styles.container}>
        <EnumeratedMembers members={members} />
      </div>
    </Modal>
  )
}

export const EnumeratedMembers = ({ members }) => {
  return members ? (
    <div className={styles.enumeratedMembers}>
      {members.map((member) => (
        <Badge
          key={member.name}
          color='neutral'
          variant={Badge.Variant.statusLg}
        >
          {member.name}
        </Badge>
      ))}
    </div>
  ) : null
}

export const SignInFooter = () => {
  const {
    user,
    authenticated,
    passwordlessLogout,
    setIsAuthenticated = () => {}
  } = useAuth()
  const hasUserFirstName = authenticated && user?.firstName
  const history = useHistory()

  const logoutAndTrack = () => {
    const logoutInput = {
      variables: {
        input: {}
      }
    }
    passwordlessLogout(logoutInput)
      .then(() => setIsAuthenticated(false))
      .finally(() => track('User Account Logout'))
  }

  return (
    <div className={styles.signInFooter} data-testid='sign-in-footer'>
      <div className={styles.spacer} />
      <div data-testid='login-link-container'>
        <ShowForUS>
          {hasUserFirstName ? (
            <ModeLink
              variant='primary'
              data-testid='opt-splash-logout'
              mode='login'
              state={{
                signupSource: 'SPLASH'
              }}
              onClick={logoutAndTrack}
            >
              <p
                className='type-large text-semibold fs-mask'
                data-testid='footer-greeting'
              >
                Not {user.firstName}?
              </p>
            </ModeLink>
          ) : (
            <ModeLink
              variant='primary'
              data-testid='opt-splash-login'
              mode='login'
              state={{
                signupSource: 'SPLASH'
              }}
              // There is an exisiting issue in with formik validating onBlur and killing the event: https://github.com/jaredpalmer/formik/issues/2062
              onMouseDown={(e) => {
                history.push('?mode=login', {
                  signupSource: 'SPLASH'
                })
              }}
            >
              {CTA.LOG_IN_TO_TOAST}
            </ModeLink>
          )}
        </ShowForUS>
      </div>
      <div className={styles.poweredByToastContainer}>
        <PoweredByToast />
      </div>
    </div>
  )
}

export const OPTPartySplashHeader = ({ restaurant }) => {
  const {
    name: restaurantName,
    bannerUrls,
    imageUrl,
    logoUrls
  } = restaurant || {}

  const logoUrl = logoUrls && logoUrls.small && `${logoUrls.small}`
  const bannerUrl =
    (bannerUrls && bannerUrls.raw) || (imageUrl && cdnUrl(imageUrl))
  const hasImg = Boolean(logoUrl || bannerUrl)

  return (
    <header className={styles.header}>
      {bannerUrl ? (
        <div
          className={styles.bannerImage}
          style={{ backgroundImage: `url(${bannerUrl})` }}
        />
      ) : (
        <div className={styles.noBannerImage} />
      )}

      {hasImg && (
        <img
          className={styles.logoImg}
          src={logoUrl || bannerUrl}
          alt={restaurantName}
        />
      )}
    </header>
  )
}

OPTPartySplash.propTypes = {
  inviteCode: PropTypes.string
}

OPTPartySplashHeader.propTypes = {
  restaurant: RestaurantShape
}

EnumeratedMembers.propTypes = {
  members: PropTypes.array
}

NotMyPartyModal.propTypes = {
  members: PropTypes.array
}

export default OPTPartySplash
