import { NetworkStatus } from '@apollo/client'
import {
  OptPartyError,
  useOpt_Remove_Split_Payment_DataMutation
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { ErrorText } from '@/il8n/en'
import { useGetPartyRefresh } from '../../components/PartyQuery/PartyQuery'
import { useSentry } from 'banquet-runtime-modules'

interface RemoveSplitInput {
  onComplete(): void
  onError(error: OptPartyError): void
  setUserFacingError(value: string): void
}

export const useRemoveSplit = () => {
  const { captureMessage } = useSentry()
  const [removeSplitMutation, { loading: removeSplitLoading }] =
    useOpt_Remove_Split_Payment_DataMutation()
  const {
    partyGuid,
    partyMemberGuid,
    memberAuthToken,
    updateSupplementalPartyProperties
  } = useParty()
  const {
    refetch: refetchPartyRefresh,
    networkStatus: partyRefreshNetworkStatus
  } = useGetPartyRefresh()
  const refetching =
    partyRefreshNetworkStatus === NetworkStatus.refetch ||
    partyRefreshNetworkStatus === NetworkStatus.setVariables

  const removeSplit = async ({
    onComplete,
    onError,
    setUserFacingError
  }: RemoveSplitInput) => {
    if (!partyGuid || !partyMemberGuid || !memberAuthToken) {
      captureMessage(
        'Missing one of partyGuid, partyMemberGuid, memberAuthToken',
        'warning'
      )
      setUserFacingError(ErrorText.MISSING_PARTY_DATA)
      return
    }

    removeSplitMutation({
      variables: {
        partyGuid,
        partyMemberGuid,
        memberAuthToken
      }
    }).then(async (result) => {
      const applySplitTypeError =
        result.data?.optPartyRefresh.__typename === 'OPTPartyError'
          ? result.data.optPartyRefresh
          : undefined

      if (applySplitTypeError) {
        onError(applySplitTypeError)
      } else {
        updateSupplementalPartyProperties({
          portionsToBePaid: undefined,
          totalPortions: undefined
        })
        await refetchPartyRefresh()
        onComplete()
      }
    })
  }

  return {
    removeSplit,
    loading: removeSplitLoading || refetching
  }
}
