import React from 'react'
import { useDDIGlobals } from '../../../../client/components/DDIGlobalsProvider/DDIGlobalsProvider'
import { CartListener } from '../CartListener'
import { CheckoutListener } from '../CheckoutListener'
import { useParty } from '../../../../client/components/PartyProvider/PartyProvider'
import {
  dispatchFulfillmentChange,
  dispatchRestaurantConfig,
  dispatchCurrentUser,
  dispatchPartyChange,
  dispatchUpsells,
  dispatchCreditCards,
  dispatchPartiesByTable,
  dispatchPopularItems,
  dispatchPartiesByCheck
} from 'cornucopia-apis'
import { useAvailability } from '../../../../client/utils/availability'
import { useRestaurantInfo } from '../../../../client/hooks/restaurant-info/use-restaurant-info'
import { useSendMenus } from '../../../../client/hooks/use-send-menus/use-send-menus'
import { useGetPopularItems } from '../../../../client/hooks/use-get-popular-items/use-get-popular-items'
import {
  useGetPartyMember,
  useGetPartyRefresh
} from '../../../../client/components/PartyQuery/PartyQuery'
import { OrderListener } from '../OrderListener'
import { useUpsells } from '../../../../client/components/UpsellsProvider/UpsellsProvider'
import { useCreditCard } from '../../../../client/components/CreditCardProvider/CreditCardProvider'
import { SplitPaymentListener } from '../SplitPaymentListener/SplitPaymentListener'
import { useLookupPartiesByTable } from '../../../../client/hooks/party/use-lookup-parties-by-table'
import { useLookupPartiesByCheck } from '../../../../client/hooks/party/use-lookup-parties-by-check'
import { PartyListener } from '../PartyListener/PartyListener'
import { PartyMemberListener } from '../PartyMemberListener/PartyMemberListener'
import { ToastContainer } from 'react-toastify'
import qs from 'qs'
import { useLocation } from 'react-router'
import { LocationWithState } from '../../../../client/hooks/use-handle-rejoin'
import styles from './CornucopiaProvider.module.css'

const CornucopiaStateContext = React.createContext({})

interface CornucopiaProviderParams {
  restaurantGuid?: string
  children: React.ReactNode
}

const getQueryProps = (search: Location['search']) => {
  return qs.parse(search, { ignoreQueryPrefix: true })
}

function CornucopiaProvider({
  restaurantGuid,
  children
}: CornucopiaProviderParams) {
  const { mode, uniqueIdentifier } = useDDIGlobals()
  const { partyGuid, partyMemberGuid, memberAuthToken, deletePartyProperties } =
    useParty()
  const partyRefresh = useGetPartyRefresh()

  const me = useGetPartyMember()
  const { orderingAvailable } = useAvailability()
  const { data: restaurantData } = useRestaurantInfo()
  const { upsells } = useUpsells()
  const { creditCards } = useCreditCard()
  const location: LocationWithState = useLocation()

  const { checkGuid } = getQueryProps(location.search) as {
    checkGuid?: string
  }

  const partiesAtTableResponse = useLookupPartiesByTable({
    skip: mode !== 'OPT' || uniqueIdentifier === 'welcome'
  })
  const partiesByCheckResponse = useLookupPartiesByCheck(checkGuid, {
    skip: !checkGuid || mode !== 'TTS' || uniqueIdentifier === 'welcome'
  })

  const popItemsData = useGetPopularItems()

  dispatchFulfillmentChange({
    selected: true,
    // always fulfill ASAP
    fulfillmentTime: null
  })

  React.useEffect(() => {
    dispatchUpsells(upsells)
  }, [upsells])

  React.useEffect(() => {
    dispatchCreditCards(creditCards)
  }, [creditCards])

  const hasItemsInCart = partyRefresh.partyRefresh?.carts.some(
    (cart) => cart.order.checks[0].selections.length > 0
  )

  React.useEffect(() => {
    dispatchRestaurantConfig({
      orderingAvailable: orderingAvailable || hasItemsInCart,
      specialRequestsConfig: restaurantData?.specialRequestsConfig,
      creditCardConfig: restaurantData?.creditCardConfig,
      orderAndPayConfig: restaurantData?.orderAndPayConfig,
      bannerUrls: restaurantData?.bannerUrls,
      logoUrls: restaurantData?.logoUrls,
      name: restaurantData?.name,
      i18n: restaurantData?.i18n,
      giftCardLinks: restaurantData?.giftCardLinks
    })
  }, [
    orderingAvailable,
    restaurantData?.specialRequestsConfig,
    restaurantData?.creditCardConfig,
    restaurantData?.orderAndPayConfig,
    restaurantData?.bannerUrls,
    restaurantData?.logoUrls,
    restaurantData?.name,
    restaurantData?.i18n,
    restaurantData?.giftCardLinks,
    hasItemsInCart
  ])

  React.useEffect(
    () => dispatchPartyChange({ ...partyRefresh, deletePartyProperties }),
    [partyRefresh, deletePartyProperties]
  )

  React.useEffect(() => dispatchCurrentUser(me), [me])

  React.useEffect(
    () => dispatchPartiesByTable(partiesAtTableResponse),
    [partiesAtTableResponse]
  )

  React.useEffect(
    () => dispatchPartiesByCheck(partiesByCheckResponse),
    [partiesByCheckResponse]
  )

  useSendMenus()

  React.useEffect(
    () =>
      dispatchPopularItems({
        result: popItemsData,
        popularMenuItems: popItemsData.popularMenuItems
      }),
    [popItemsData]
  )

  return (
    <CornucopiaStateContext.Provider value={{}}>
      <ToastContainer
        hideProgressBar
        enableMultiContainer
        closeButton={false}
        position='top-center'
        className={styles.toastContainer}
        toastClassName={styles.toastWrapper}
      />
      {
        <>
          <CartListener
            restaurantGuid={restaurantGuid}
            partyGuid={partyGuid}
            partyMemberGuid={partyMemberGuid || ''}
            memberAuthToken={memberAuthToken || ''}
          />
          <CheckoutListener />
          <OrderListener />
          <SplitPaymentListener />
          <PartyListener />
          <PartyMemberListener />
        </>
      }
      {children}
    </CornucopiaStateContext.Provider>
  )
}

function useCornucopiaState() {
  const context = React.useContext(CornucopiaStateContext)
  if (context === undefined) {
    throw new Error(
      'useCornucopiaState must be used within a CornucopiaProvider'
    )
  }
  return context
}

export { CornucopiaProvider, useCornucopiaState }
