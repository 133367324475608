import React from 'react'
import PropTypes from 'prop-types'

export const formatUSD = (n = 0, options = {}) => {
  if (typeof n !== 'number' || Number.isNaN(n)) {
    throw new TypeError(`Value "${n}" is not number`)
  }

  return n.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    ...options
  })
}

function Dollars({
  amount = 0,
  showLeadingSign,
  renderWhenZero,
  truncateZeroCents
}) {
  if (amount === 0 && renderWhenZero !== undefined) {
    return renderWhenZero
  }

  let leadingSign = ''
  if (showLeadingSign && amount >= 0) {
    leadingSign = '+'
  }

  let truncateOptions
  if (truncateZeroCents && amount % 1 === 0) {
    truncateOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
  }

  return `${leadingSign}${formatUSD(amount, truncateOptions)}`
}

Dollars.displayName = 'Dollars'

Dollars.propTypes = {
  amount: PropTypes.number,
  /** If `amount` is 0 or not provided, render this instead */
  renderWhenZero: PropTypes.node,
  /** If true, always show a leading +/- sign, otherwise only - is shown when `amount` is negative */
  showLeadingSign: PropTypes.bool,
  /** If true, truncate cents value if zero. Otherwise, show cents in standard two-digit format */
  truncateZeroCents: PropTypes.bool
}

Dollars.defaultProps = {
  showLeadingSign: false,
  truncateZeroCents: false
}

export default React.memo(Dollars)
