import React from 'react'

import PropTypes from 'prop-types'

import { Field } from 'formik'
import Input from '../Input/Input'

const InputGroup = ({ inputConfigs }) =>
  inputConfigs
    ? inputConfigs.map((input) => (
        <Field
          data-testid={input.id}
          key={input.id}
          {...input}
          component={input.component || Input}
        />
      ))
    : null

InputGroup.propTypes = {
  inputConfigs: PropTypes.array.isRequired
}

export default InputGroup
