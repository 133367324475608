import * as React from 'react'
import { orderRequest$ } from 'cornucopia-apis'
import { OrderRequest } from './OrderListener.types'
import { useSentry } from 'banquet-runtime-modules'
import { ResponseTypes } from '../cornucopia.types'
import { toastError } from '../ListenerError'
import { useHandleFirePartyTabRound } from '../../../../client/hooks/place-order/use-fire-party-round'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'

export const OrderListener = () => {
  const { captureMessage } = useSentry()
  const [firePartyRound, { data, error, loading }] =
    useHandleFirePartyTabRound()
  const responseRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (!responseRef.current || loading) return
    if (error) {
      toastError(
        'An error occurred attempting to fire order, please try again.'
      )
      captureMessage(error.message, 'warning')
      responseRef.current.next({
        kind: ResponseTypes.ERROR,
        message: 'An error has occurred',
        code: '400'
      })
      responseRef.current = null
      return
    }

    const { OPTPartyRefreshV2 } = dataByTypename(data.optPartyRefresh)

    if (OPTPartyRefreshV2) {
      responseRef.current.next({
        kind: ResponseTypes.OK,
        info: [{ code: '200', message: 'Fired party round.' }],
        warnings: []
      })
    }
  }, [data, error, loading, captureMessage])

  React.useEffect(() => {
    const subscription = orderRequest$.subscribe(
      async (request: OrderRequest) => {
        if (typeof firePartyRound !== 'function') return
        const { response$ } = request.header
        try {
          responseRef.current = response$
          await firePartyRound()
        } catch (error: any) {
          toastError(
            'An error occurred attempting to fire order, please try again.'
          )

          captureMessage(error.message, 'warning')
          response$.next({
            kind: ResponseTypes.ERROR,
            message: 'Unknown error',
            code: '400'
          })
        }
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [firePartyRound, captureMessage])

  return <></>
}
