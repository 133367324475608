/**
 * Handles displaying the native share dialog or returns false if
 * the native API is not available.
 * @param {string} baseUrl The OPAT URL of the restaurant and table
 *   based on the current environment. e.g. https://www.toasttab.com/foo-bar.
 * @param {string} shareCode The generated invite code.
 * @returns {boolean} True if the native share API was used or false if
 *   a fallback is needed.
 */
export const handleShareRequest = async (url, restaurantName) => {
  const hasShare = window.navigator && window.navigator.share

  if (!hasShare) {
    // Use fallback dialog
    return false
  }

  try {
    await navigator.share({
      url,
      title: `Invite to Party Ordering at ${restaurantName}`,
      text:
        `You have been invited to order at ${restaurantName}. ` +
        'Click on the link to start your order.\n'
    })
  } catch (e) {
    return false
  }

  return true
}
