import * as React from 'react'
import { BannerHeader } from './BannerHeader'
import { BasicHeader } from './BasicHeader'

export interface RestaurantHeaderV2Props {
  bannerUrl?: string
  logoUrl?: string
  restaurantName: string
  tableName: string
}

export const RestaurantHeaderV2 = ({
  bannerUrl,
  logoUrl,
  restaurantName,
  tableName
}: RestaurantHeaderV2Props) =>
  bannerUrl ? (
    <BannerHeader
      bannerUrl={bannerUrl}
      logoUrl={logoUrl}
      restaurantName={restaurantName}
      tableName={tableName}
    />
  ) : (
    <BasicHeader
      logoUrl={logoUrl}
      restaurantName={restaurantName}
      tableName={tableName}
    />
  )
