import { Observable } from '@apollo/client'

// useful for parsing response errors
// https://artsy.github.io/blog/2018/10/19/where-art-thou-my-error/
export const dataByTypename = (data) =>
  data && data.__typename ? { [data.__typename]: data } : {}

export const promiseToObservable = (promise) =>
  new Observable(async (subscriber) => {
    try {
      const value = await promise
      if (!subscriber.closed) {
        subscriber.next(value)
        subscriber.complete()
      }
    } catch (err) {
      subscriber.error(err)
    }
  })
