import * as React from 'react'
import { dispatchMenus } from 'cornucopia-apis'
import { MenuV2 } from '../../apollo/generated/OptWebGraphQLOperations'
import { useMenuQuery } from '../../components/UseMenuQuery/UseMenuQuery'
import { dataByTypename } from '../../utils/apollo-helpers'

export const useSendMenus = () => {
  const {
    loading,
    error,
    data: response,
    refetch,
    networkStatus
  } = useMenuQuery()

  const menus = React.useMemo(() => {
    if (!response) {
      return null
    }

    const menusResponse = response.getMenus?.menus

    // Do not display menus or menu groups with no items
    return menusResponse?.filter((menu: MenuV2) => {
      menu.groups = menu.groups?.filter((group) => {
        return group.items?.length > 0
      })
      return menu.groups?.length > 0
    })
  }, [response])

  const menusError = React.useMemo(() => {
    const { GetMenusError } = dataByTypename(response?.getMenus)
    return GetMenusError
  }, [response])

  React.useEffect(
    () =>
      dispatchMenus({
        result: {
          loading,
          error: error || menusError,
          data: menus,
          refetch,
          networkStatus
        }
      }),
    [loading, error, menus, refetch, menusError, networkStatus]
  )
}
