import React from 'react'

import CartButton from '../CartButton/CartButton'
import Dollars from '../Dollars/Dollars'
import { useGetTab } from '../TabQuery/TabQuery'
import { useGetPartyMemberCart } from '../PartyQuery/PartyQuery'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'

import { CTA } from '@/il8n/en'

// handles 4 cases:
// no cart no tab, cart no tab, tab no cart, cart and tab
export const CartOrTabButton = () => {
  const { getRestaurantPath } = useRestaurant()
  const {
    error: tabError,
    loading: tabLoading,
    primaryCheck: check
  } = useGetTab()
  const {
    error: cartError,
    loading: cartLoading,
    cartCheck
  } = useGetPartyMemberCart()

  const hasCart = Boolean(
    !cartError && !cartLoading && cartCheck && cartCheck.numberOfSelections > 0
  )

  const hasTab = Boolean(
    !tabError && !tabLoading && check && check.selections.length > 0
  )

  if (hasCart && hasTab) {
    return (
      <CartButton
        testId='button-checkout-tab-round'
        to={getRestaurantPath('tab/add')}
        left={
          <span>
            {CTA.NAV_FIRE_TAB_ROUND} ({cartCheck.numberOfSelections})
          </span>
        }
        right={<Dollars amount={cartCheck.preDiscountedSubtotal} />}
      />
    )
  } else if (!hasCart && hasTab) {
    return (
      <CartButton
        testId='button-checkout-tab-initial'
        to={getRestaurantPath('tab')}
        left={<span>{CTA.NAV_TAB_VIEW}</span>}
        right={<Dollars amount={check.preDiscountedSubtotal} />}
      />
    )
  } else if (hasCart && !hasTab) {
    return (
      <CartButton
        testId='button-checkout-cart'
        to={getRestaurantPath('cart')}
        left={
          <span>
            {CTA.NAV_TAB_NEW} ({cartCheck.numberOfSelections})
          </span>
        }
        right={<Dollars amount={cartCheck.preDiscountedSubtotal} />}
      />
    )
  } else {
    return null
  }
}

export default CartOrTabButton
