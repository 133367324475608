import * as React from 'react'
import { CheckSelectionFragment } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { FormatCurrency } from '../../../Currency/FormatCurrency'

export interface SubtotalDisplayProps {
  selections: Pick<CheckSelectionFragment, 'preDiscountPrice'>[]
}

export const SubtotalDisplay = ({ selections }: SubtotalDisplayProps) => {
  if (!selections || !selections.length) {
    return null
  }

  const selectionPriceSubtotal = selections.reduce(
    (
      accumulator: number,
      current: Pick<CheckSelectionFragment, 'preDiscountPrice'>
    ) => {
      const sum = accumulator + (current?.preDiscountPrice || 0)
      return Number(sum.toFixed(2)) // prevents javascript number precision problem
    },
    0
  )

  return (
    <div className='px-4 pt-4 pb-4 flex justify-between font-bold'>
      <span>Subtotal</span>
      <FormatCurrency amount={selectionPriceSubtotal} />
    </div>
  )
}
