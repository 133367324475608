import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { useExpiringStateWithNamespacedStorage } from '../../utils/namespaced-storage'
import { restaurantStorageKeys } from '../../utils/restaurant-storage'
import { useTabEnabledWithLoading } from '../../hooks/tabs/useTabEnabled'
import { EXPIRATION_DURATION } from '../../utils/restaurant-storage'
import { useAvailability } from '../../utils/availability'
import { FullScreenError } from '../FullScreenError/FullScreenError'
import { ErrorText } from '../../il8n/en'

const noop = () => {}
const emptyTabContext = {
  tabCheckGuid: undefined,
  updateTabCheckGuid: noop,
  tabOrderGuid: undefined,
  updateTabOrderGuid: noop,
  deleteTab: noop,
  email: undefined,
  setEmail: noop,
  firstName: undefined,
  setFirstName: noop,
  lastName: undefined,
  setLastName: noop
}
const TabContext = React.createContext(emptyTabContext)
const TabProvider = ({ restaurantStorage, children }) => {
  const [tabCheckGuid, updateTabCheckGuid] =
    useExpiringStateWithNamespacedStorage(
      restaurantStorageKeys.TAB_CHECK_GUID,
      undefined,
      restaurantStorage,
      EXPIRATION_DURATION
    )
  const [tabOrderGuid, updateTabOrderGuid] =
    useExpiringStateWithNamespacedStorage(
      restaurantStorageKeys.TAB_ORDER_GUID,
      undefined,
      restaurantStorage,
      EXPIRATION_DURATION
    )

  const [email, setEmail] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.TAB_EMAIL,
    undefined,
    restaurantStorage,
    EXPIRATION_DURATION
  )
  const [firstName, setFirstName] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.TAB_FIRST_NAME,
    undefined,
    restaurantStorage,
    EXPIRATION_DURATION
  )
  const [lastName, setLastName] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.TAB_LAST_NAME,
    undefined,
    restaurantStorage,
    EXPIRATION_DURATION
  )

  const deleteTab = useCallback(() => {
    updateTabCheckGuid(undefined)
    updateTabOrderGuid(undefined)
    setEmail(undefined)
    setFirstName(undefined)
    setLastName(undefined)
  }, [
    updateTabCheckGuid,
    updateTabOrderGuid,
    setEmail,
    setFirstName,
    setLastName
  ])

  let context = {
    tabCheckGuid,
    tabOrderGuid,
    updateTabCheckGuid,
    updateTabOrderGuid,
    deleteTab,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName
  }

  return <TabContext.Provider value={context}>{children}</TabContext.Provider>
}

const TabProviderFlagged = (props) => {
  const [tabEnabledAttemptCount, setTabEnabledAttemptCount] = useState(0)
  const { isEnabled, loading, error, refetch } = useTabEnabledWithLoading()
  const { shouldCreateParty, updateShouldCreateParty } = useAvailability()
  const retryFailed = error && tabEnabledAttemptCount >= 1 && shouldCreateParty

  useEffect(() => {
    if (retryFailed && error.message !== 'Failed to fetch') {
      updateShouldCreateParty(false)
    }
  })

  if (loading) {
    return null
  }
  if (error && tabEnabledAttemptCount < 1 && shouldCreateParty) {
    return (
      <FullScreenError
        retry={() => {
          refetch()
          setTabEnabledAttemptCount(tabEnabledAttemptCount + 1)
        }}
      >
        {error.message === 'Failed to fetch'
          ? ErrorText.FAILED_TO_FETCH
          : ErrorText.RESTAURANT_INFO_UNAVAILABLE}
      </FullScreenError>
    )
  }

  if (retryFailed && error.message === 'Failed to fetch') {
    return (
      <FullScreenError hasRefreshSuggestion title={ErrorText.SERVICES_OFFLINE}>
        {ErrorText.SERVER_HELP}
      </FullScreenError>
    )
  }

  if (!isEnabled) {
    return (
      <TabContext.Provider value={emptyTabContext}>
        {props.children}
      </TabContext.Provider>
    )
  }

  return <TabProvider {...props} />
}

export { TabProviderFlagged as TabProvider }

TabProvider.propTypes = {
  children: PropTypes.node.isRequired,
  restaurantStorage: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired
  }).isRequired
}

TabProviderFlagged.propTypes = {
  children: PropTypes.node
}

export const TabConsumer = ({ children }) => (
  <TabContext.Consumer>{(context) => children(context)}</TabContext.Consumer>
)

TabConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

export const useTab = () => React.useContext(TabContext)
