import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Required from './Required/Required'

import DropDown from '../../assets/fieldset-drop-down.svg'

import styles from './Fieldset.module.css'

const Fieldset = ({
  label,
  labelCentered = false,
  children,
  instructions,
  name,
  required,
  collapsable,
  collapsed: initialCollapsed,
  rightAdornment,
  variant = 'primary'
}) => {
  const prevCollapsableRef = useRef(collapsable)
  const [collapsed, collapse] = useState(
    prevCollapsableRef.current && initialCollapsed
  )

  const isPrimary = variant === 'primary' ? true : false

  // If collapsable state changes,
  // then reset collapse state based on new collapsable & collapsed values
  useEffect(() => {
    if (collapsable !== prevCollapsableRef.current) {
      collapse(prevCollapsableRef.current && initialCollapsed)
      prevCollapsableRef.current = collapsable
    }
  }, [collapsable, initialCollapsed])

  return (
    <fieldset
      data-testid='fieldset-group'
      className={cx(styles.fieldset, {
        [styles.collapsed]: collapsed
      })}
    >
      <legend
        data-testid='fieldset-legend'
        className={cx(styles.legend, { 'bg-transparent': !isPrimary })}
      >
        <div
          data-testid='fieldset-collapser'
          className={styles.flex}
          onClick={() => collapsable && collapse(!collapsed)}
        >
          <span>
            <div
              data-testid='fieldset-label'
              className={cx(styles.label, {
                'font-normal bg-transparent flex': !isPrimary,
                'mx-auto': labelCentered
              })}
            >
              <div>{label}</div>
              <div className={styles.dropDown}>
                {collapsable && !isPrimary ? <DropDown /> : null}
              </div>
            </div>
            {instructions ? (
              <span
                data-testid='fieldset-instructions'
                className={styles.instructions}
              >
                {instructions}
              </span>
            ) : null}
          </span>
          {required ? <Required name={name} /> : null}
          {rightAdornment}
          {collapsable && isPrimary ? (
            <DropDown className={styles.dropDown} />
          ) : null}
        </div>
      </legend>
      <div data-testid='fieldset-children' className={styles.children}>
        {children}
      </div>
    </fieldset>
  )
}

export default Fieldset

Fieldset.defaultProps = {
  collapsable: false,
  collapsed: false
}

Fieldset.propTypes = {
  label: PropTypes.node.isRequired,
  labelCentered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  required: PropTypes.bool,
  instructions: PropTypes.node,
  collapsable: PropTypes.bool,
  collapsed: PropTypes.bool,
  rightAdornment: PropTypes.node,
  id: PropTypes.string,
  variant: PropTypes.string
}
