import { FieldLabel } from '@/il8n/en'
import { useIntlEmailEnabled } from '../../hooks/party/use-email-input'

export const useSplashScreenInstructionsLabel = () => {
  const intlEmail = useIntlEmailEnabled()
  if (intlEmail) {
    return FieldLabel.INITIALIZE_PARTY_EMAIL_INSTRUCTIONS
  } else {
    return FieldLabel.INITIALIZE_PARTY_PHONE_INSTRUCTIONS
  }
}
