import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'

import { useInitializeParty } from '../../PartyProvider/PartyProvider'
import { usePartySplashState } from '../OPTPartySplashContainer'
import { PARTY_INIT_ACTIONS } from '../model/opt_party_initialization_model'
import { useFlag } from '../../FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'
import { Button } from '@toasttab/buffet-pui-buttons'
import { CTA, HeaderText } from '@/il8n/en'
import { MenuLoading } from './LoadingStates/MenuLoading'
import Modal from '../../Modal/Modal'
import {
  ErrorCallToActionSection,
  ErrorContainer
} from '@toasttab/buffet-pui-error-pages'
import { Error500MobileIllustration } from '@toasttab/buffet-pui-illustrations'
import { HeaderLoading } from './LoadingStates/HeaderLoading'
import { DDIMode } from '../../../types/DDIGlobals'
import TabLoader from '../../TabCartPage/TabLoader'

export interface AutoInitializePartyModalProps {
  mode?: DDIMode
  joiningPartyGuid?: string
}

export const AutoInitializePartyModal: React.ComponentType<AutoInitializePartyModalProps> =
  ({ joiningPartyGuid, mode }) => {
    const pinRequired = useFlag(LDFlags.OPT_PARTY_PINS)

    const { dispatch: initPartyDispatch, inviteCode } = usePartySplashState()
    const history = useHistory()
    const hasInitializedRef = useRef(false)
    const location = useLocation()

    // @ts-ignore
    const [initialize, { loading, error }] = useInitializeParty(
      joiningPartyGuid,
      pinRequired,
      inviteCode,
      {
        // @ts-ignore
        onCompleted: (_, { guestName, guestPhone }) => {
          // @ts-ignore
          initPartyDispatch({
            type: PARTY_INIT_ACTIONS.SET_GUEST_NAME,
            value: guestName
          })

          guestPhone &&
            // @ts-ignore
            initPartyDispatch({
              type: PARTY_INIT_ACTIONS.SET_GUEST_PHONE,
              value: guestPhone
            })
          if (mode === DDIMode.STP) {
            history.push({
              pathname: '/tab',
              search: location?.search
            })
          } else {
            history.push({ pathname: '/' })
          }
        }
      }
    )

    useEffect(() => {
      // double-ensure that submission is not handled multiple times
      if (hasInitializedRef.current) {
        return
      }
      hasInitializedRef.current = true
      initialize({})
    }, [inviteCode, initialize])

    const onSubmit = () => {
      initialize({})
    }

    return (
      <Modal responsive focus={false}>
        {Boolean(error) && !Boolean(loading) ? (
          <div className='flex flex-col items-center justify-center h-2/3'>
            <ErrorContainer>
              <div className='flex flex-col items-center space-y-5'>
                <Error500MobileIllustration />
                <div className='type-headline-5'>
                  {HeaderText.SOMETHING_WENT_WRONG}
                </div>
                <div className='type-default'>
                  {HeaderText.TRY_AGAIN_OR_ASK_YOUR_SERVER}
                </div>
                <ErrorCallToActionSection spacing='flex flex-col items-center space-y-4 md:space-y-0 md:space-x-4'>
                  <Button onClick={onSubmit} variant='primary' type='submit'>
                    {CTA.TRY_AGAIN}
                  </Button>
                </ErrorCallToActionSection>
              </div>
            </ErrorContainer>
          </div>
        ) : (
          <>
            <HeaderLoading />
            {mode === DDIMode.MNP && (
              <MenuLoading showTableIndicatorLoading showSearchBarLoading />
            )}
            {/* Tabs page skeleton loader, since STP lands on the tabs page */}
            {mode === DDIMode.STP && <TabLoader />}
          </>
        )}
      </Modal>
    )
  }
