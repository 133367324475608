import React, { useCallback, useMemo } from 'react'
import { withRouter, Redirect, useLocation, useHistory } from 'react-router'

import {
  PasswordlessAuthUI,
  PasswordlessAuthUIVariant
} from '@toasttab/do-secundo-passwordless-authentication-ui'

import Link from '../Link/Link'
import { MenuSearch } from '../MenuSearch/MenuSearch'
import { DDIMode } from '../../types/DDIGlobals'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useAuthEventsTracking } from '../../utils/use-auth-events-tracking'
import { getUserPhoneFromLocalStorage } from '../../utils/namespaced-storage'
import { DeleteAccountModal } from '../AuthModals/DeleteAccountModal'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'

// Mode routes for which it is acceptable for us to skip the OPTPartySplashContainer
const accountRoutesNoCreate = ['login']
const accountRoutes = ['login', 'create']

const useAccountRoutes = () => {
  const shouldDisableCreateAccount = useFlag(LDFlags.OPT_DISABLE_CREATE_ACCOUNT)

  return shouldDisableCreateAccount ? accountRoutesNoCreate : accountRoutes
}

const ModeRouter = withRouter(({ location, history, type }) => {
  const closeLocation = {
    ...location,
    search: null
  }

  const shouldAllowDeleteAccount = useFlag(LDFlags.OPT_DELETE_ACCOUNT)
  const auth = useAuth()
  const { restaurantConfig } = useDDIGlobals()
  const guestPhone = getUserPhoneFromLocalStorage()

  const {
    trackAccountVerificationEvent,
    trackCompleteIdentity,
    trackFailedCodeEntry,
    trackSendCodeEvent
  } = useAuthEventsTracking()

  const pushLocation = () => history.push(closeLocation)
  const replaceLocation = () => history.replace(closeLocation)

  if (location.state && location.state.onClose) {
    location.state.onClose = null
  }
  const routes =
    type === 'ACCOUNT'
      ? {
          login: (
            <PasswordlessAuthUI
              initialPhoneNumber={guestPhone}
              variant={PasswordlessAuthUIVariant.HEADLESS}
              auth={auth}
              onClose={pushLocation}
              trackSendCodeEvent={trackSendCodeEvent}
              trackCompleteIdentity={trackCompleteIdentity}
              trackAccountVerificationEvent={trackAccountVerificationEvent}
              trackFailedCodeEntry={trackFailedCodeEntry}
              rxCountry={restaurantConfig?.country}
            />
          ),
          create: (
            <PasswordlessAuthUI
              initialPhoneNumber={guestPhone}
              variant={PasswordlessAuthUIVariant.HEADLESS}
              auth={auth}
              onClose={pushLocation}
              trackSendCodeEvent={trackSendCodeEvent}
              trackCompleteIdentity={trackCompleteIdentity}
              trackAccountVerificationEvent={trackAccountVerificationEvent}
              trackFailedCodeEntry={trackFailedCodeEntry}
              rxCountry={restaurantConfig?.country}
            />
          ),
          ...(shouldAllowDeleteAccount && {
            delete: <DeleteAccountModal onClose={replaceLocation} />
          })
        }
      : type === 'SEARCH'
      ? {
          search: <MenuSearch onClose={pushLocation} />
        }
      : {}

  const search = new URLSearchParams(location.search)
  const mode = search.get('mode')
  return routes[mode] || null
})

const getModeComponent =
  (Component) =>
  ({ mode, state, ...props }) => {
    const search = new URLSearchParams()
    search.set('mode', mode)
    // handle special routing for being on or /tab or /tab/close page for STP at all times (if you remove this mfa verification won't work)
    if (props.ddiMode === DDIMode.STP) {
      return (
        <Component
          {...props}
          to={{ pathname: '/tab', search: search.toString(), state }}
        />
      )
    } else {
      return <Component {...props} to={{ search: search.toString(), state }} />
    }
  }

const ModeRedirect = getModeComponent(Redirect)
const ModeLink = getModeComponent(Link)

export const useGetMode = () => {
  const location = useLocation()
  return useMemo(
    () => new URLSearchParams(location.search).get('mode'),
    [location.search]
  )
}

export const useHasMode = (value) => {
  const mode = useGetMode()
  return mode === value
}

/**
 *
 * @param {Object} useModeHistoryPushCallbackParams
 * @param {string} useModeHistoryPushCallbackParams.mode
 * @param {import('history').LocationDescriptorObject|string} useModeHistoryPushCallbackParams.to - A location object or pathname string (note: replaces search with 'mode' parameter)
 */
export const useModeHistoryPushCallback = ({ mode, to }) => {
  const history = useHistory()
  const search = history.location.search

  const paramsWithNewMode = useMemo(() => {
    const params = new URLSearchParams(search)
    params.set('mode', mode)
    return params.toString()
  }, [mode, search])

  if (typeof to === 'string') {
    to = { pathname: to }
  }

  return useCallback(() => {
    history.push({
      ...to,
      search: '?' + paramsWithNewMode.toString()
    })
  }, [history, paramsWithNewMode, to])
}

/**
 *
 * @param {Object} useModeHistoryPushCallbackParams
 * @param {string} useModeHistoryPushCallbackParams.mode
 * @param {import('history').LocationDescriptorObject|string} useModeHistoryPushCallbackParams.to - A location object or pathname string (note: replaces search with 'mode' parameter)
 */
export const useModeHistoryReplace = ({ mode, to }) => {
  const history = useHistory()
  const search = history.location.search

  const paramsWithNewMode = useMemo(() => {
    const params = new URLSearchParams(search)
    params.set('mode', mode)
    return params.toString()
  }, [mode, search])

  if (typeof to === 'string') {
    to = { pathname: to }
  }

  return useCallback(() => {
    history.replace({
      ...to,
      search: '?' + paramsWithNewMode
    })
  }, [history, paramsWithNewMode, to])
}

const AccountModeRouter = () => <ModeRouter type={'ACCOUNT'} />
const SearchModeRouter = () => <ModeRouter type={'SEARCH'} />

export {
  AccountModeRouter,
  SearchModeRouter,
  ModeRedirect,
  ModeLink,
  useAccountRoutes
}
