import { useEffect } from 'react'

export const useScrollToTop = () =>
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])

const ScrollToTop = () => {
  useScrollToTop()
  return null
}

export default ScrollToTop
