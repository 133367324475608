import * as React from 'react'
import cx from 'classnames'
import uniqBy from 'lodash/uniqBy'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { UpsellItem, ItemShape } from '../UpsellItem/UpsellItem'
import { useUpsells } from '../UpsellsProvider/UpsellsProvider'

import styles from './CartUpsells.module.css'

interface CartUpsellsProps {
  mode?: string
}
export const CartUpsells = ({ mode }: CartUpsellsProps) => {
  const { getRestaurantPath } = useRestaurant()
  const { upsells } = useUpsells()

  if (upsells.length === 0) {
    return null
  }
  const itemsToRender = uniqBy(upsells.slice(0, 10), 'guid')

  return (
    <div>
      <div className={`${mode === 'cart' ? 'mb-4' : 'mb-8'} text-center`}>
        <div className='type-headline-4'>Complete your meal</div>
      </div>
      <ul className={cx(styles.carousel, 'overflow-auto whitespace-nowrap')}>
        {itemsToRender.map((item: ItemShape) => {
          const { guid, itemGroupGuid } = item
          return (
            <UpsellItem
              key={guid}
              addItemUrl={getRestaurantPath(`add/${guid}/${itemGroupGuid}`)}
              item={item}
            />
          )
        })}
      </ul>
    </div>
  )
}
