import { EDIT_ITEM_IN_ORDER_AT_TABLE_CART } from './cart.graphql'
import { selectionItemDetails } from '../menu/menuitem.graphql'

export const cartResolvers = {
  Mutation: {
    editQuantityOfItemInCart: async (_, { input, quantity }, { client }) => {
      const { cartGuid, selectionGuid, itemGuid, itemGroupGuid } = input
      const {
        data: { selectionItemDetails: selection }
      } = await client.query({
        query: selectionItemDetails,
        variables: { input },
        fetchPolicy: 'no-cache'
      })
      selection.quantity = quantity
      // Todo: remove this remapping when editItemInCart mutation is replaced
      selection.specialInstructions = selection.specialRequest
      delete selection.specialRequest
      // Generate modifierGroups structure that only contains selected modifiers
      // and non-empty modifierGroups
      selection.modifierGroups = selection.modifierGroups
        .map(({ guid, modifiers }) => ({
          guid,
          modifiers: modifiers
            .filter((m) => m.selected)
            .map(({ itemGuid, itemGroupGuid }) => ({
              itemGroupGuid,
              itemGuid,
              modifierGroups: [],
              quantity
            }))
        }))
        .filter(({ modifiers }) => modifiers.length > 0)
      delete selection.guid
      delete selection.name
      delete selection.__typename
      const editItemInCartResult = await client.mutate({
        mutation: EDIT_ITEM_IN_ORDER_AT_TABLE_CART,
        variables: {
          input: { itemGuid, itemGroupGuid, selectionGuid, cartGuid }
        }
      })
      return editItemInCartResult.data.editItemInCart
    }
  }
}
