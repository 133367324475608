import React from 'react'
import { CTA } from '@/il8n/en'
import { useGetPartyMemberCart } from '../PartyQuery/PartyQuery'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { RouterLinkButton } from '../Button/RouterLinkButton'

export const NewTabOrCheckoutButton = () => {
  const { getRestaurantPath } = useRestaurant()
  const {
    error: cartError,
    loading: cartLoading,
    cartCheck
  } = useGetPartyMemberCart()

  const hasCart =
    !cartError && !cartLoading && cartCheck && cartCheck.numberOfSelections > 0

  if (!hasCart) {
    return null
  }

  return (
    <div
      className='fixed bottom-0 left-0 z-10 w-full bg-white border-t border-b-0 border-l-0 border-r-0 border-solid shadow'
      data-testid='button-container'
    >
      <div className='w-11/12 mb-4 mt-5 ml-4 mr-4'>
        <RouterLinkButton
          data-testid='button-checkout-new-tab-or-checkout'
          to={getRestaurantPath('tab/new')}
        >
          <span className='w-full flex align-center justify-center'>
            {CTA.NAV_TAB_CREATE_ANY_CONFIG}
          </span>
        </RouterLinkButton>
      </div>
    </div>
  )
}
