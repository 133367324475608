// Translates "$XX.XX" input to raw number value as a Float (e.g. "$10.20" -> 10.2)
// Returns a fallback value for unexpectedly formatted strings.

export function getRawTipValue(
  tipInput: string,
  nanFallback: number = 0.0
): number {
  if (tipInput) {
    return parseFloat(tipInput.replace(/[^0-9.]/g, '')) || nanFallback
  }
  return nanFallback
}
