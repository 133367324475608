import {
  CheckLineItem,
  CheckLineItemTypes
} from '../../../../utils/check-helpers'
import { PaymentRequestDetailItem } from '@toasttab/digital-payment-methods-poc/types/src/payment-flows/types'

function adaptType(
  type: CheckLineItem['type']
): PaymentRequestDetailItem['type'] {
  switch (type) {
    case CheckLineItemTypes.SubtotalAmount:
      return 'SUBTOTAL'
    case CheckLineItemTypes.TaxAmount:
      return 'TAX'
    case CheckLineItemTypes.PromoCodeAmount:
    case CheckLineItemTypes.LoyaltyDiscountAmount:
    case CheckLineItemTypes.GiftCardAmount:
    case CheckLineItemTypes.GlobalGiftCardAmount:
    case CheckLineItemTypes.ToastCashPayment:
    case CheckLineItemTypes.GiftCardPayment:
      return 'DISCOUNT'
    default:
      return 'LINE_ITEM'
  }
}

export const adaptCheckDisplayDetails = (
  lineItems: CheckLineItem[],
  restaurantName: string
): {
  orderTotal: PaymentRequestDetailItem
  displayDetails: PaymentRequestDetailItem[]
} => {
  const displayDetails: PaymentRequestDetailItem[] = lineItems
    .filter((li) => li.type !== CheckLineItemTypes.TotalOrderAmount) // filter out total-order-amount to avoid duplication with hard-coded total
    .map((li) => {
      return {
        amount: li.amount,
        label: li.label,
        type: adaptType(li.type),
        status: 'final'
      }
    })

  const totalAmount = lineItems.find(
    (li) => li.type === CheckLineItemTypes.TotalOrderAmount
  )?.amount

  if (totalAmount === undefined) {
    throw new Error('Unable to compute total order amount')
  }

  return {
    displayDetails,
    orderTotal: {
      label: `Order Total: ${restaurantName}`,
      amount: totalAmount,
      type: 'LINE_ITEM',
      status: 'final'
    }
  }
}
