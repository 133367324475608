import React from 'react'

import GreyHorizontal from '../../assets/powered-by/grey-horizontal.svg'

// Coming soon:
// import OrangeHorizontal from '../../assets/powered-by/orange-horizontal.svg'
// import WhiteHorizontal from '../../assets/powered-by/white-horizontal.svg'
// import GreyStacked from '../../assets/powered-by/grey-stacked.svg'
// import WhiteStacked from '../../assets/powered-by/white-stacked.svg'
// import OrangeStacked from '../../assets/powered-by/orange-stacked.svg'

interface PoweredByToastProps {
  className?: string
}

export const PoweredByToast = React.memo<PoweredByToastProps>(
  ({ className, ...props }) => {
    return <GreyHorizontal className={className} {...props} />
  }
)
