import * as React from 'react'
import { MenuItemPrice } from '../Menus/MenuItems/MenuItemPrice/MenuItemPrice'
import { imgInPreprod, lowResImage } from '../../utils/img-url'
import { StateLink } from '../StateLink/StateLink'
import ItemPlaceholder from '../../assets/item-placeholder.svg'
import AddToCart from '../../assets/circle-plus.svg'

export interface ItemShape {
  name: string
  price: number
  imageUrl?: string
  guid: string
  itemGroupGuid: string
}

interface UpsellItemProps {
  item: ItemShape
  addItemUrl: string
}

export const UpsellItem = ({ item, addItemUrl }: UpsellItemProps) => {
  const { name, price, imageUrl } = item
  const url = lowResImage(imgInPreprod(imageUrl))

  return (
    <StateLink
      data-testid='upsell-item-link'
      rel='nofollow'
      isExternal={false}
      to={addItemUrl}
    >
      <li className='inline-block mx-2 mb-2 list-none'>
        {url ? (
          <div className='relative'>
            <img
              className='mb-2 rounded-md'
              height='150'
              width='150'
              src={url}
              alt={name}
            />
            <div className='absolute bottom-1 right-2 '>
              <AddToCart />
            </div>
          </div>
        ) : (
          <div className='relative'>
            <ItemPlaceholder
              className='mb-2 rounded-md'
              data-testid='placeholder-image'
            />
            <div className='absolute bottom-1 right-2 '>
              <AddToCart />
            </div>
          </div>
        )}
        <div className='w-40 font-semibold truncate text-primary-75 '>
          {name}
        </div>
        <MenuItemPrice price={price} />
      </li>
    </StateLink>
  )
}
