import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { LazyLoad } from '../../../LazyLoad/LazyLoad'
import { imgInPreprod } from '../../../../utils/img-url'

export const MenuItemImage = ({ imageUrl, outOfStock }) => {
  if (!imageUrl) {
    return <div data-testid='no-image-spacer' className='w-[86px]' />
  }

  return (
    <LazyLoad rootMargin={'0px 0px 100% 0px'}>
      {(loaded) => (
        <div
          data-testid='menu-item-image'
          style={{
            backgroundImage: loaded && `url(${imgInPreprod(imageUrl)})`
          }}
          className={cx(
            'flex-shrink-0 w-[88px] h-[88px] bg-cover bg-center relative rounded-lg border border-solid border-gray-30',
            'before:z-0 before:absolute before:top-0 before:bottom-0 before:right-0 before:left-0 before:bg-gradient-to-br before:from-transparent before:to-darken-8 dark before:rounded-[7px]',
            { 'saturate-0 opacity-50': outOfStock }
          )}
        />
      )}
    </LazyLoad>
  )
}

MenuItemImage.propTypes = {
  imageUrl: PropTypes.string,
  outOfStock: PropTypes.bool
}
