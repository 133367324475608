import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import cx from 'classnames'
import { Formik, Form, Field, yupToFormErrors } from 'formik'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import { phoneNumberMinLength } from '@toasttab/buffet-pui-phone-utilities'

import Button from '../../Button/Button'
import Modal from '../../Modal/Modal'
import {
  EnumeratedMembers,
  OPTPartySplashHeader,
  SignInFooter
} from '../OPTPartySplash.jsx'
import { DiningAtTable } from '../../DiningAtTable'
import { useAuth } from '../../AuthProvider/AuthProvider'
import Input from '../../Form/Input/Input'
import { useInitializeParty } from '../../PartyProvider/PartyProvider'
import { usePartySplashState } from '../OPTPartySplashContainer'
import {
  PARTY_INIT_STATE_ENUM,
  PARTY_INIT_ACTIONS
} from '../model/opt_party_initialization_model'
import { useFlag } from '../../FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'
import { useGuestInfo } from '../../../hooks/use-guest-info'
import { useRestaurantInfo } from '../../../hooks/restaurant-info/use-restaurant-info'
import { getRawPhoneNumber } from '../../../utils/form-utils'
import { usePermalinkEnabled } from '../../../hooks/party/use-permalink-enabled'
import { CTA, FieldLabel } from '@/il8n/en'
import { LegalLinks } from '../../CheckoutForm/LegalLinks/LegalLinks'
import { useServerStartsATabEnabled } from '../../../hooks/party/use-server-starts-a-tab-enabled'
import { useAvailability } from '../../../utils/availability'
import { Notification } from '../../Notification/Notification'
import { useTabEnabled } from '../../../hooks/tabs/useTabEnabled'
import { TextToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'
import {
  PhoneInputField,
  SubmitButton,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import {
  getCountry,
  checkCountryIsoCode
} from '@toasttab/buffet-pui-country-utilities'
import { defaultStrings } from '@toasttab/buffet-pui-phone-input'

import styles from './InitializePartyModal.module.css'
import { useIsIntlRestaurant } from '../../../hooks/use-is-intl-restaurant'
import { useIntlSmsEnabled } from '../../../hooks/party/use-intl-sms-enabled'
import { useIntlEmailEnabled } from '../../../hooks/party/use-email-input'
import { useSplashScreenInstructionsLabel } from '../../../utils/splash-screen-helpers/splash-screen-helpers'

export const internationalPhoneSchema = Yup.object({
  countryCode: Yup.string().required(),
  nationalNumber: Yup.string()
    .required('error.required.phone')
    .test({
      name: 'min',
      exclusive: false,
      params: {},
      message: 'error.min.phone',
      test: (value, context) => {
        const { countryIsoCode } = context.parent

        if (!value) {
          return true
        }

        return value?.length >= phoneNumberMinLength(countryIsoCode, value)
      }
    })
})

const getValidationSchema = (
  members,
  pinRequired,
  permalinkEnabled,
  tabsEnabled,
  intlSMS,
  isEmailSelected
) => {
  const names = members.map(({ name }) => String(name).toLowerCase())

  const pinSchema = pinRequired
    ? Yup.string()
        .trim()
        .matches(/^\d{6}$/, 'PIN must be exactly 6 digits.')
        .required(FieldLabel.INITIALIZE_PARTY_PIN_MISSING)
    : Yup.string()

  const phoneSchema = permalinkEnabled
    ? Yup.string()
        .transform(getRawPhoneNumber)
        .trim()
        .matches(/^\d{10}$/, 'must be a 10 digit number')
        .required(FieldLabel.INITIALIZE_PARTY_PHONE_MISSING)
    : Yup.string()

  const internationalEmailSchema =
    isEmailSelected && tabsEnabled
      ? Yup.string().required('Email is required').email('Must be valid email')
      : Yup.string()

  const internationalPhoneSchemaWithToggle =
    isEmailSelected && tabsEnabled
      ? Yup.object({
          countryCode: Yup.string().required(),
          nationalNumber: Yup.string()
        })
      : internationalPhoneSchema

  return Yup.object().shape({
    name: Yup.string()
      .trim()
      .test(
        'is-not-existing-member',
        FieldLabel.INITIALIZE_PARTY_NAME_IN_USE,
        (value) => !names.includes(String(value).toLowerCase())
      )
      .required(FieldLabel.INITIALIZE_PARTY_NAME_MISSING),
    phone: intlSMS ? internationalPhoneSchemaWithToggle : phoneSchema,
    email: internationalEmailSchema,
    pinInput: pinSchema
  })
}

const InitializePartyModal = ({
  onClose,
  initPartyGreeting,
  joiningPartyGuid,
  existingPartyMembers = []
}) => {
  const { data: restaurant } = useRestaurantInfo()
  const { user, authenticated } = useAuth()
  const { guestName, guestPhone, guestEmail } = useGuestInfo()
  const pinRequired = useFlag(LDFlags.OPT_PARTY_PINS)
  const permalinkEnabled = usePermalinkEnabled()
  const ssatEnabled = useServerStartsATabEnabled()
  const {
    dispatch: initPartyDispatch,
    inviteCode,
    partyInitState
  } = usePartySplashState()
  const tabsEnabled = useTabEnabled()
  const history = useHistory()
  const isInitializing = useRef(false)
  const [initializationAttemptCount, setInitializationAttemptCount] =
    useState(0)
  const isIntlRx = useIsIntlRestaurant()
  const intlEmail = useIntlEmailEnabled()
  const [isEmailSelected, setEmail] = useState(intlEmail)
  const { updateShouldCreateParty } = useAvailability()
  const splashScreenInstructionsLabel = useSplashScreenInstructionsLabel()

  useEffect(() => {
    if (initializationAttemptCount > 1) {
      updateShouldCreateParty(false)
    }
  })

  const [initialize, { loading, error: partyInitializationError }] =
    useInitializeParty(joiningPartyGuid, pinRequired, inviteCode, {
      onCompleted: (_, name, phone, email) => {
        if (onClose) {
          onClose()
        }
        initPartyDispatch({
          type: PARTY_INIT_ACTIONS.SET_GUEST_NAME,
          value: name
        })
        guestPhone &&
          initPartyDispatch({
            type: PARTY_INIT_ACTIONS.SET_GUEST_PHONE,
            value: phone
          })
        guestEmail &&
          initPartyDispatch({
            type: PARTY_INIT_ACTIONS.SET_GUEST_EMAIL,
            value: email
          })
        history.push({ pathname: '/' })
      }
    })

  const intlSMS = useIntlSmsEnabled() && tabsEnabled

  const preferredCountryCode = checkCountryIsoCode(restaurant.i18n?.country)

  const countryNameStrings = {
    'country.name.united_states': 'United States',
    'country.name.canada': 'Canada',
    'country.name.ireland': 'Ireland',
    'country.name.united_kingdom': 'United Kingdom'
  }

  const countryInfo = getCountry(preferredCountryCode, countryNameStrings)

  const formatPhoneNumber = (countryCode, nationalNumber) => {
    return `+${countryCode}${parseInt(nationalNumber)}`
  }

  const initialValues = useMemo(() => {
    return {
      name: guestName || user?.firstName || '',
      phone: intlSMS
        ? {
            countryCode: countryInfo.diallingCode,
            countryIsoCode: countryInfo.countryIsoCode,
            nationalNumber: intlSMS
              ? ''
              : getRawPhoneNumber(guestPhone || user?.phone || '')
          }
        : getRawPhoneNumber(guestPhone || user?.phone || ''),
      pinInput: inviteCode || '',
      email: guestEmail || user?.email || ''
    }
  }, [
    guestName,
    guestPhone,
    guestEmail,
    inviteCode,
    user?.firstName,
    user?.phone,
    user?.email,
    countryInfo,
    intlSMS
  ])

  const validationSchema = useMemo(
    () =>
      getValidationSchema(
        existingPartyMembers,
        joiningPartyGuid && pinRequired,
        permalinkEnabled,
        tabsEnabled,
        intlSMS,
        isEmailSelected
      ),
    [
      existingPartyMembers,
      joiningPartyGuid,
      permalinkEnabled,
      tabsEnabled,
      pinRequired,
      intlSMS,
      isEmailSelected
    ]
  )

  const onSubmit = useCallback(
    async (rawValues) => {
      const values = { ...rawValues }
      if (intlSMS) {
        if (values?.phone?.nationalNumber && !isEmailSelected) {
          const { countryCode, nationalNumber } = values.phone
          values.phone = formatPhoneNumber(countryCode, nationalNumber)
          values.email = ''
        } else if (values?.email && isEmailSelected) {
          values.phone = ''
        }
      }
      // double-ensure that submission is not handled multiple times
      if (isInitializing.current) {
        return
      }
      if (values && inviteCode && !values.pinInput) {
        values.pinInput = inviteCode
      }
      try {
        isInitializing.current = true
        await initialize(values)
      } finally {
        isInitializing.current = false
        setInitializationAttemptCount((c) => c + 1)
      }
    },
    [inviteCode, initialize, intlSMS, isEmailSelected]
  )

  const initialErrors = useMemo(() => {
    try {
      validationSchema.validateSync(initialValues)
      return {}
    } catch (e) {
      return yupToFormErrors(e)
    }
  }, [initialValues, validationSchema])

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      initialErrors={initialErrors}
      initialTouched={{
        name: Boolean(initialValues.name),
        phone: Boolean(initialValues.phone),
        email: Boolean(initialValues.email)
      }}
      enableReinitialize
    >
      {({ isValid, initialErrors, handleSubmit, isSubmitting }) => {
        const isInitialValid = Object.keys(initialErrors).length === 0
        const isJoiningScreen = [
          PARTY_INIT_STATE_ENUM.PROMPT_JOIN_PARTY,
          PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY
        ].includes(partyInitState.current)
        const canExpediteLogin = Boolean(authenticated && initialValues.name)

        if (isJoiningScreen && canExpediteLogin && isInitialValid) {
          if (!isSubmitting) {
            handleSubmit()
          }
        }

        const getIntlPreferenceComponent = () => {
          if (isEmailSelected && intlEmail) {
            return (
              <TextInputField
                name='email'
                label={FieldLabel.INITIALIZE_PARTY_EMAIL}
                placeholder='example@email.com'
                strings={defaultStrings}
                data-testid='party-init-modal-email'
                id='party-init-email'
              />
            )
          } else {
            return (
              <PhoneInputField
                name='phone'
                label={FieldLabel.INITIALIZE_PARTY_PHONE}
                preferredCountryIsoCodes={[preferredCountryCode]}
                countryNameStrings={countryNameStrings}
                strings={defaultStrings}
                testId={'party-init-modal-phone'}
                id='party-init-tel'
              />
            )
          }
        }

        return (
          <Modal wrapper={(children) => <Form>{children}</Form>} responsive>
            <div className={styles.bodyWrapper}>
              <div className='px-6 pb-6'>
                <OPTPartySplashHeader restaurant={restaurant} />
                <div className={styles.greetingSection}>
                  <h3 className='font-medium' data-testid='propful-greeting'>
                    {initPartyGreeting}!
                  </h3>
                  {!joiningPartyGuid &&
                    (!ssatEnabled || !existingPartyMembers?.length > 0) && (
                      <DiningAtTable scanLinkVariant={'this_isnt_my_table'} />
                    )}
                </div>
                {ssatEnabled && existingPartyMembers?.length > 0 && (
                  <div className='my-0 text-center'>
                    {inviteCode
                      ? `You've been invited to dine with`
                      : 'You are dining with'}
                    :
                    <EnumeratedMembers members={existingPartyMembers} />
                  </div>
                )}
                {!canExpediteLogin && tabsEnabled && (
                  <p className='mb-4 text-center'>
                    {permalinkEnabled ? (
                      <>
                        {splashScreenInstructionsLabel}
                        <sup>1</sup>
                      </>
                    ) : (
                      FieldLabel.INITIALIZE_PARTY_NAME_INSTRUCTIONS
                    )}
                  </p>
                )}
                <div
                  className={cx('mb-4 mt-6', {
                    [styles.hidden]: isInitialValid && canExpediteLogin
                  })}
                >
                  {intlSMS ? (
                    <TextInputField
                      className='border'
                      autoFocus={!isInitialValid}
                      autoComplete='section-customer given-name'
                      name='name'
                      label={FieldLabel.INITIALIZE_PARTY_FIRST_NAME}
                      testId='party-init-modal-name'
                      id='party-init-name'
                    />
                  ) : (
                    <Field
                      autoFocus={!isInitialValid}
                      autoComplete='section-customer given-name'
                      component={Input}
                      data-testid='party-init-modal-name'
                      id='party-init-name'
                      label={FieldLabel.INITIALIZE_PARTY_FIRST_NAME}
                      name='name'
                      type='text'
                      compact
                      fsUnmask
                    />
                  )}
                </div>
                {permalinkEnabled && (
                  <div className={'mb-6'}>
                    {intlSMS ? (
                      <div>
                        {intlEmail && (
                          <div className='mb-2' style={{ width: '250px' }}>
                            <label className='inline-flex flex-row font-semibold type-default text-default mb-1'>
                              {FieldLabel.INITIALIZE_PARTY_TOGGLE}
                            </label>
                            <TextToggleSwitch
                              name='toggle'
                              labels={[
                                FieldLabel.INITIALIZE_PARTY_PHONE,
                                FieldLabel.INITIALIZE_PARTY_EMAIL
                              ]}
                              isActive={isEmailSelected}
                              onChange={() => setEmail(!isEmailSelected)}
                              data-testid='phone-email-toggle-preference'
                              value={isEmailSelected}
                            />
                          </div>
                        )}
                        {getIntlPreferenceComponent()}
                      </div>
                    ) : (
                      <Field
                        autoComplete='section-customer tel-national'
                        component={Input}
                        data-testid='party-init-modal-phone'
                        id='party-init-tel'
                        label={FieldLabel.INITIALIZE_PARTY_PHONE}
                        name='phone'
                        type='tel'
                        mask={[
                          '(',
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ')',
                          ' ',
                          /\d/,
                          /\d/,
                          /\d/,
                          '-',
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/
                        ]}
                        compact
                      />
                    )}
                  </div>
                )}
                {joiningPartyGuid && pinRequired && (
                  <div className='mb-4'>
                    <Field
                      component={Input}
                      data-testid='party-init-modal-pin'
                      id='party-init-pin'
                      label={FieldLabel.INITIALIZE_PARTY_PIN}
                      name='pinInput'
                      type='text'
                      maxLength='6'
                      disabled={Boolean(inviteCode)}
                    />
                  </div>
                )}
                {partyInitializationError && (
                  <Notification
                    testId='initialize-party-error-message'
                    variant='error'
                    marginBottom={false}
                  >
                    {'We encountered a problem setting up your party.'.concat(
                      joiningPartyGuid
                        ? ' Please ensure that your name is unique, and that the PIN is correct.'
                        : ' Please try again.'
                    )}
                  </Notification>
                )}
                <div className={styles.buttonFooter}>
                  {intlSMS ? (
                    <div className='block'>
                      <SubmitButton
                        style={{ width: '100%' }}
                        size='lg'
                        disabled={loading || !isValid}
                        testId={'party-init-submit'}
                        isInProgress={loading}
                      >
                        {CTA.JOIN_OR_CREATE_PARTY_CONFIRM_MEMBER_NAME}
                      </SubmitButton>
                    </div>
                  ) : (
                    <Button
                      data-testid='party-init-submit'
                      disabled={!isValid}
                      responsive
                      variant='primary'
                      type='submit'
                      loading={loading}
                    >
                      {CTA.JOIN_OR_CREATE_PARTY_CONFIRM_MEMBER_NAME}
                    </Button>
                  )}
                </div>
                <LegalLinks
                  showTwilioCompliance={!isIntlRx && tabsEnabled}
                  showInternationalSMSVerbiage={
                    isIntlRx && tabsEnabled && !isEmailSelected
                  }
                />
              </div>
              <SignInFooter onCloseLocation={'?mode=partyInit'} />
            </div>
          </Modal>
        )
      }}
    </Formik>
  )
}

InitializePartyModal.propTypes = {
  onClose: PropTypes.func,
  initPartyGreeting: PropTypes.string,
  joiningPartyGuid: PropTypes.string,
  existingPartyMembers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  )
}

export default InitializePartyModal
