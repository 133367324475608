import React, { MouseEventHandler } from 'react'
import cx from 'classnames'
import { Button, Variant } from '@toasttab/buffet-pui-buttons'
import { Link, LinkProps } from 'react-router-dom'

import styles from './Button.module.css'

interface RouterLinkButtonProps {
  children: React.ReactNode
  to?: LinkProps['to']
  onClick?: MouseEventHandler<HTMLElement>
  testId?: string
  variant?: Variant
  disabled?: boolean
  compact?: boolean
}

export const RouterLinkButton: React.FC<RouterLinkButtonProps> = ({
  to,
  onClick,
  children,
  testId,
  variant = 'primary',
  disabled = false,
  compact = false
}) => {
  if (!to && !onClick) {
    console.warn(
      'RouterLinkButton requires either a "to" or an "onClick" prop but received neither.'
    )
    return null
  }

  if (!to) {
    return (
      <div data-testid={testId} className={cx({ [styles.compact]: compact })}>
        <Button
          onClick={onClick}
          variant={variant}
          disabled={disabled}
          className={cx('w-full', styles.linkButton)}
        >
          {children}
        </Button>
      </div>
    )
  }

  return (
    <Link
      onClick={onClick}
      to={to}
      data-testid={testId}
      className={cx({ [styles.compact]: compact })}
    >
      <Button
        variant={variant}
        disabled={disabled}
        className={cx('w-full', styles.linkButton)}
      >
        {children}
      </Button>
    </Link>
  )
}
