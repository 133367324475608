import { useCallback, useEffect, useRef } from 'react'
import { useStateWithRestaurantStorage } from '../restaurant-storage'

import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'

interface OrderingEnabledState {
  timeout: number | undefined
  orderingEnabled: boolean
}

const getDefaultState = (): OrderingEnabledState => ({
  timeout: undefined,
  orderingEnabled: true
})

function getIsExpired(timeout: number) {
  return Date.now() > timeout
}

function minsFromNow(epochMillis?: number): string {
  return (((epochMillis ?? Date.now()) - Date.now()) / 60000).toFixed(2)
}

interface UseExternalBooleanToggleFactoryParams {
  /**
   * Timeout while value is false
   */
  falseTimeout: number
  /**
   * Key for localStorage
   */
  localStorageKey: string
}

/**
 * Utilizes a factory pattern to return a hook with static parameters.
 * Be sure to call this outside of your component and name the returned value
 * useXXX for consumption within it.
 *
 * Creates a manipulable function on the window called toggleExternalOrderingAvailable
 * that can toggle a simulated ordering on and off.
 *
 * In production, has no side-effects and simply returns true from the hook
 * always.
 */
export const createUseExternalOrderingAvailableToggleFactory = ({
  falseTimeout,
  localStorageKey
}: UseExternalBooleanToggleFactoryParams): (() => boolean) => {
  const isProduction = envFromOrderHostname(window.location.hostname) === 'prod'

  if (isProduction) {
    return function useStaticOrderingAvailable() {
      return true
    }
  }

  function unsetWindowToggleFunction() {
    window.toggleExternalOrderingAvailable = () => {
      console.error('toggleExternalOrderingAvailable invoked before ready')
    }
  }
  unsetWindowToggleFunction()

  return function useExternalOrderingAvailableToggle() {
    const [orderingEnabledState, setOrderingEnabledState] =
      useStateWithRestaurantStorage(localStorageKey, getDefaultState()) as [
        OrderingEnabledState,
        React.Dispatch<React.SetStateAction<OrderingEnabledState>>
      ]
    const orderingEnabledStateRef = useRef(orderingEnabledState)

    // keep ref in sync
    useEffect(() => {
      orderingEnabledStateRef.current = orderingEnabledState
    })

    const setExternalOrderingAvailable = useCallback(
      (stateValue: boolean | ((currentValue: boolean) => boolean)) => {
        if (typeof stateValue === 'function') {
          const stateCallback = stateValue
          return setOrderingEnabledState((currentValue) => {
            const isEnabled = stateCallback(currentValue.orderingEnabled)
            return {
              timeout: isEnabled ? undefined : falseTimeout + Date.now(),
              orderingEnabled: isEnabled
            }
          })
        } else {
          const isEnabled = stateValue
          setOrderingEnabledState({
            orderingEnabled: isEnabled,
            timeout: isEnabled ? undefined : falseTimeout + Date.now()
          })
        }
      },
      [setOrderingEnabledState]
    )

    // set toggle function on window
    useEffect(() => {
      window.toggleExternalOrderingAvailable = () => {
        setExternalOrderingAvailable((b) => {
          const newValue = !b
          window.alert(`Ordering has been ${newValue ? 'enabled' : 'disabled'}`)
          return newValue
        })
      }
      return () => unsetWindowToggleFunction()
    }, [setExternalOrderingAvailable])

    // Alerts if a simulation has begun
    useEffect(() => {
      if (
        !orderingEnabledState.orderingEnabled &&
        orderingEnabledState.timeout
      ) {
        window.alert(
          `Ordering disabled simulation is currently active, and will time out in ${minsFromNow(
            orderingEnabledState.timeout
          )} minutes`
        )
      }
    }, [orderingEnabledState])

    /**
     * Sets up a loop based on timeoutCron
     * to check for timeout condition
     */
    const timeoutRef = useRef<number>(0)
    const timeoutEpochMillis = orderingEnabledStateRef.current.timeout
    useEffect(() => {
      function createTimeout(timeoutLength: number) {
        return window.setTimeout(() => {
          const timeout = orderingEnabledStateRef.current.timeout
          if (timeout) {
            if (!getIsExpired(timeout)) {
              console.log(
                `Ordering disabled timeout checked. Ordering will be re-enabled in ${minsFromNow(
                  timeout
                )} minutes`
              )
              createTimeout(timeout - Date.now())
            } else {
              // handle expired by turning ordering enabled to true
              setExternalOrderingAvailable(true)
              window.alert(
                'Ordering disabled simulation timed out. Ordering is re-enabled'
              )
            }
          }
        }, timeoutLength)
      }
      if (timeoutEpochMillis) {
        const timeoutLength = timeoutEpochMillis - Date.now()
        timeoutRef.current = createTimeout(timeoutLength)
      }
      return () => window.clearTimeout(timeoutRef.current)
    }, [setExternalOrderingAvailable, timeoutEpochMillis])

    return orderingEnabledState.orderingEnabled
  }
}
