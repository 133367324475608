import React, { useMemo, useState } from 'react'
import QrReader from 'react-qr-reader'

import Button from '../Button/Button'
import Link from '../Link/Link'
import ErrorComponent from '../Error/Error'
import QRImage from '../../assets/qr.svg'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { ExternalLink } from '../ExternalLink/ExternalLink'

import styles from './QRScannerPage.module.css'
import { CTA } from '@/il8n/en'

const isLinkValid = (link) => {
  const url = new URL(link)
  return (
    url.hostname.endsWith('toasttab.com') ||
    url.hostname.endsWith('toastteam.com')
  )
}

const isStp = (link) => {
  try {
    const url = new URL(link)
    return url.pathname.includes('pay-at-table')
  } catch (e) {
    console.error(e.message)
    return null
  }
}

const getTableNumber = (link) => {
  try {
    const url = new URL(link)
    if (url.searchParams && url.searchParams.has('t')) {
      return url.searchParams.get('t')
    } else if (url.searchParams && url.searchParams.has('tableName')) {
      return url.searchParams.get('tableName')
    } else {
      const paths = link.split('/')
      // 'double pop' handles potential trailing slash
      const tableName = paths.pop() || paths.pop()
      return tableName && !tableName.includes('check?') ? tableName : ''
    }
  } catch (e) {
    console.error(e.message)
    return null
  }
}

const getURLWithSource = (urlString, isInRestaurant = false) => {
  const src = isInRestaurant ? 'intQRScan' : 'extQRScan'
  const url = new URL(urlString)
  url.searchParams.append('src', src)
  return url.toString()
}

export const QRScannerPage = () => {
  const [scanQR, setScanQR] = useState(false)
  const [linkURL, setLinkURL] = useState(null)
  const [error, setError] = useState(null)
  const { getRestaurantPath, shortUrl } = useRestaurant()
  const isInRestaurant = Boolean(shortUrl)

  const linkUrlWithSource = useMemo(
    () => linkURL && getURLWithSource(linkURL, isInRestaurant),
    [linkURL, isInRestaurant]
  )
  const linkPrompt = useMemo(
    () => (linkURL && isStp(linkURL) ? 'Pay for' : 'Start'),
    [linkURL]
  )

  return (
    <div className={styles.qrContainer}>
      <h1>Order {'&'} Pay by Phone</h1>
      {scanQR ? (
        <div>
          <p>Point directly at the QR code on the table.</p>
          <QrReader
            delay={300}
            onError={(error) => {
              setError(error)
              setLinkURL(null)
            }}
            onScan={(link) => {
              if (link) {
                if (isLinkValid(link)) {
                  setLinkURL(link)
                  setError(null)
                  setScanQR(false)
                } else {
                  setError({
                    message: `${link} is not a valid URL. Must be a Toast restaurant URL`
                  })
                  setLinkURL(null)
                }
              }
            }}
            style={{ maxWidth: '500px', margin: '30px auto' }}
          />
        </div>
      ) : (
        !linkURL && (
          <div>
            <p>Get started by connecting your table with a QR code scanner.</p>
            <QRImage
              className={styles.qrImage}
              onClick={(_) => setScanQR(true)}
            />
          </div>
        )
      )}
      {error && <ErrorComponent error={error} />}
      <div className={styles.buttonContainer}>
        {Boolean(linkURL) && (
          <ExternalLink variant='primaryWide' href={linkUrlWithSource}>
            {getTableNumber(linkURL)
              ? `${linkPrompt} Order at Table ${getTableNumber(linkURL)}`
              : `${linkPrompt} your order`}
          </ExternalLink>
        )}
        {scanQR ? (
          <Button
            data-testid='cancel-qr-scan-button'
            variant='linkLargeText'
            responsive
            onClick={(_) => setScanQR(false)}
          >
            {CTA.GO_BACK}
          </Button>
        ) : (
          <div className={styles.scanButton}>
            {linkURL ? (
              getTableNumber(linkURL) && <p>Not your table?</p>
            ) : (
              <p>Be sure to allow camera access if prompted.</p>
            )}
            <Button
              data-testid='scan-qr-button'
              type='button'
              // use style to not override className down the line
              style={{ margin: '8px auto' }}
              variant={!linkURL ? 'primary' : 'secondary'}
              responsive
              onClick={() => {
                setScanQR(true)
                setLinkURL(null)
              }}
            >
              {CTA.SCAN_QR_CODE}
            </Button>
            {isInRestaurant && !linkURL && (
              <Link
                data-testid='qr-scan-back-to-table'
                to={getRestaurantPath()}
                variant='secondaryWide'
              >
                {CTA.GO_BACK}
              </Link>
            )}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <PoweredByToast />
      </div>
    </div>
  )
}

export default QRScannerPage
