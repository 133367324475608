import React from 'react'
import { PropTypes } from 'prop-types'

import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'
import { Notification } from '../../../Notification/Notification'
import { useFormik } from '../../../FormikProvider/FormikProvider'

export const GiftCardNotification = ({ hasSufficientFunds }) => {
  const { rxGiftCard, globalGiftCard } = useGiftCard()
  const { values } = useFormik()

  const { expectedAvailableBalance: rxeb } = rxGiftCard || {
    expectedAvailableBalance: 0
  }
  const { expectedAvailableBalance: tceb } = globalGiftCard || {
    expectedAvailableBalance: 0
  }

  if (rxeb + tceb === 0) {
    return null
  }
  if (hasSufficientFunds) {
    return null
  }

  const methodStr =
    rxeb > 0 && tceb > 0
      ? 'gift card and Toast Cash cover'
      : rxeb > 0
      ? 'gift card covers'
      : 'Toast Cash covers'

  // checks if a valid payment method is available
  if (values.encryptedCard || values.savedCardGuid) {
    return (
      <div className='pl-3 pt-2 pr-2'>
        <Notification variant='info'>
          Your {methodStr} part of the total. The selected payment method below
          will be used unless another one is chosen.
        </Notification>
      </div>
    )
  }

  return (
    <div className='pl-3 pr-2'>
      <Notification variant='warning'>
        Your gift card covers part of the total. Choose another payment method
        below.
      </Notification>
    </div>
  )
}

GiftCardNotification.propTypes = {
  hasSufficentFunds: PropTypes.bool
}
