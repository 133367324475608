import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  TypePolicies
} from '@apollo/client'
import getHttpLink from './links/http-link'
import getErrorLink from './links/error-link'
import trimEnd from 'lodash/trimEnd'
import envFromHostname from '@toasttab/env-from-hostname'
import PossibleTypes from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedPossibleTypes'
import { getCornAuthLink, getAuthorizationHeaderLink } from 'cornucopia-apis'
import { getAuthLink } from './authentication/authentication-helpers'

type ConfigShape = {
  cache: InMemoryCache
  GATEWAY_BASE_URI: string
  BFF_BASE_URI: string
  RESTAURANT_GUID: string
}

type IdMapper = (object: any) => string | undefined
type CreateClientParams = {
  ddiGlobals: {
    bffBaseUri: string
    gatewayBaseUri: string
    restaurantGuid: string
    pkgName: string
    pkgVersion: string
  }
  linkFactories: ReadonlyArray<(inputConfig: ConfigShape) => ApolloLink>
  typePolicies?: TypePolicies
  dataIdFromObjectMap?: Record<string, IdMapper>
}

const createClient = ({ ddiGlobals, linkFactories }: CreateClientParams) => {
  const cache = new InMemoryCache({
    possibleTypes: PossibleTypes.possibleTypes
  })
  const getApolloClientOptionsLink = (
    apolloLinks: typeof linkFactories,
    config: ConfigShape
  ) => {
    return ApolloLink.from(apolloLinks.map((fn) => fn(config)))
  }

  const config: ConfigShape = {
    cache,
    BFF_BASE_URI: ddiGlobals.bffBaseUri,
    GATEWAY_BASE_URI: ddiGlobals.gatewayBaseUri,
    RESTAURANT_GUID: ddiGlobals.restaurantGuid
  }

  return new ApolloClient({
    link: getApolloClientOptionsLink(linkFactories, config),
    cache: cache,
    name: ddiGlobals.pkgName,
    version: ddiGlobals.pkgVersion
  })
}

const ddiGlobals = window.OO_GLOBALS || {}
const env = envFromHostname(window.location.hostname)
const prefix = env === 'prod' ? 'ws-api' : 'ws-preprod-api.eng'
const defaultGatewayBaseUri = `https://${prefix}.toasttab.com`
const evaluatedGatewayBaseUri = trimEnd(
  ddiGlobals.gatewayBaseUri || defaultGatewayBaseUri,
  '/'
)

export const doFederatedApolloClient = createClient({
  ddiGlobals: {
    pkgName: process.env.PKG_NAME as string,
    pkgVersion: process.env.PKG_VERSION as string,
    gatewayBaseUri: evaluatedGatewayBaseUri,
    bffBaseUri: trimEnd(
      process.env.DO_FEDERATED_GATEWAY_BASE_URI ||
        `${evaluatedGatewayBaseUri}/do-federated-gateway/v1`,
      '/'
    ),
    restaurantGuid: ddiGlobals.restaurantGuid
  },
  linkFactories: [
    getErrorLink,
    getCornAuthLink,
    getAuthLink,
    getAuthorizationHeaderLink,
    getHttpLink
  ]
})
