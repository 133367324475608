import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Modal from '../Modal/Modal'

import InfoImg from '../../assets/info.svg'

import styles from './TermsModal.module.css'
import { CTA, HeaderText } from '@/il8n/en'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { formatUSD } from '../Dollars/Dollars'

export const SAVE_CARD = 'saveCard'
export const LOYALTY = 'loyalty'
export const CONTACTLESS = 'contactless'
export const PASSWORDLESS_ACCOUNT = 'passwordlessAccount'

const termsModalEnums = [SAVE_CARD, LOYALTY, CONTACTLESS, PASSWORDLESS_ACCOUNT]

export const getLoyaltyProgramName = (restaurant) => {
  if (restaurant) {
    const { name, loyaltyConfig } = restaurant
    return loyaltyConfig?.programName || `${name} Rewards`
  } else {
    return HeaderText.PROGRAM_REWARDS
  }
}

const getLoyaltyDescription = (restaurant) => {
  const {
    loyaltyConfig: { programDescription }
  } = restaurant
  return programDescription || ''
}

const getTextFromTermsEnum = (terms, restaurant) => {
  switch (terms) {
    case SAVE_CARD:
      return {
        responsive: false,
        header: HeaderText.REMEMBER_THIS_CARD_TERMS,
        body: (
          <div className={styles.body}>
            {/* TODO: Investigate whether button copy has to match place order button */}
            By selecting "Remember this card" and clicking on "Place Order" you
            are authorizing Toast, Inc. to store your credit card information
            provided above (the “Card"), and to charge your Card for future
            purchases initiated by you. You may update your Card information or
            cancel this authorization any time via the "My Account" page of the
            Toast Online Ordering website. This authorization will remain in
            effect until updated or canceled.
          </div>
        )
      }
    case LOYALTY:
      return {
        responsive: false,
        header: getLoyaltyProgramName(restaurant),
        body: (
          <div className={styles.body}>
            {Boolean(restaurant) && getLoyaltyDescription(restaurant)}
            <br />
            You are agreeing to participate in the rewards program and to be
            contacted through this email as part of the program.
            <br />
            By enrolling in rewards, you agree to Toast's{' '}
            <a
              href='https://pos.toasttab.com/terms-of-service'
              target='_blank'
              rel='noreferrer noopener'
              data-testid='link-terms-of-service'
            >
              Terms of Service
            </a>{' '}
            and acknowledge that you have read our{' '}
            <a
              href='https://pos.toasttab.com/privacy'
              target='_blank'
              rel='noreferrer noopener'
              data-testid='link-privacy'
            >
              Privacy Statement.
            </a>
          </div>
        )
      }
    case CONTACTLESS:
      return {
        responsive: false,
        header: 'Request contactless delivery',
        body: (
          <div className={styles.body}>
            <p>
              Get your order in a way that promotes safety for you and your
              delivery person with a no-contact option.
            </p>
            <p className={cx('type-large font-bold', styles.termsListHeader)}>
              1. Add a note to your delivery instructions
            </p>
            <p>
              Let your delivery person know where you would like your order to
              be placed for pickup. Try to be as specific as possible.
            </p>
            <p className={cx('type-large font-bold', styles.termsListHeader)}>
              2. Be on the lookout for a call
            </p>
            <p>
              Your delivery person may use the phone number you provided to get
              in touch when they arrive with your food.
            </p>
          </div>
        )
      }
    case PASSWORDLESS_ACCOUNT:
      return {
        responsive: false,
        header: 'Create a Toast Account',
        body: (
          <div className={styles.body}>
            By selecting “Create a Toast Account for a faster checkout next
            time” and clicking on “Submit” you are authorizing Toast, Inc. to
            store your personal contact information provided above (the “Your
            Information” and "Delivery Info") and your credit card information
            provided above (the “Card”), and to charge your Card for future
            purchases initiated by you. You may update your Card information or
            cancel this authorization any time via the “My Account” page of the
            Toast Online Ordering website. This authorization will remain in
            effect until updated or canceled. By creating a Toast Account you
            agree to the Toast{' '}
            <a
              href='https://pos.toasttab.com/terms-of-service#diner-tos'
              rel='noopener noreferrer'
              target='_blank'
              data-testid='link-terms-of-service'
            >
              Diner Terms of Service
            </a>
            .
          </div>
        )
      }
    default:
      break
  }
}

export const TermsModal = ({ onClose, terms }) => {
  const { data: restaurant } = useRestaurantInfo()
  const { header, body, responsive } = getTextFromTermsEnum(terms, restaurant)

  return (
    <Modal header={header} onClose={onClose} responsive={responsive}>
      {body}
    </Modal>
  )
}

export const useTermsModal = ({ terms, show = false }) => {
  const [showModal, setShowModal] = useState(show)

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [])

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return useMemo(
    () => ({
      openModal: handleOpen,
      TermsModal: (props) =>
        showModal && (
          <TermsModal terms={terms} onClose={handleClose} {...props} />
        )
    }),
    [handleClose, handleOpen, showModal, terms]
  )
}

export const LearnMoreButton = ({ onClick, ...props }) => {
  return (
    <button
      type='button'
      className={styles.infoButton}
      onClick={onClick}
      {...props}
    >
      <InfoImg className={styles.infoImage} />
      {CTA.LEARN_MORE}
    </button>
  )
}

LearnMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

TermsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  terms: PropTypes.oneOf(termsModalEnums)
}
