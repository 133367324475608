export enum ResponseTypes {
  OK = 'OK',
  ERROR = 'ERROR'
}

export interface Response {
  kind: ResponseTypes.OK
  info: Array<{
    code: any
    message: string
  }>
  warnings: Array<{
    code: any
    message: string
  }>
}

export interface ErrorResponse {
  kind: ResponseTypes.ERROR
  code: any
  message: string
}
