import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './TargetedNotification.module.css'

type TargetedNotificationVariants = 'navy' | 'roundedOrange'
export interface TargetedNotificationProps
  extends React.ComponentPropsWithoutRef<'div'> {
  message: ReactNode
  variant?: TargetedNotificationVariants
  testId?: string
  isOpen?: boolean
  notificationClass?: string
  notificationStyle?: React.CSSProperties
  position?: 'above' | 'below'
  alignment?: 'left' | 'right'
}

const getCircleClass = (
  variant: TargetedNotificationVariants
): 'navyCircle' | 'roundedOrangeCircle' => {
  switch (variant) {
    case 'navy':
      return 'navyCircle'
    case 'roundedOrange':
      return 'roundedOrangeCircle'
    default:
      return 'navyCircle'
  }
}

const getBodyClass = (
  variant: TargetedNotificationVariants
): 'navyBody' | 'roundedOrangeBody' => {
  switch (variant) {
    case 'navy':
      return 'navyBody'
    case 'roundedOrange':
      return 'roundedOrangeBody'
    default:
      return 'navyBody'
  }
}

export const TargetedNotification = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<TargetedNotificationProps>
>(
  (
    {
      children,
      message,
      variant = 'navy',
      testId,
      isOpen = true,
      position = 'below',
      alignment = 'right',
      notificationClass,
      notificationStyle,
      ...props
    },
    ref
  ) => {
    const circleClass = getCircleClass(variant)
    const bodyClass = getBodyClass(variant)

    const content = (
      <div
        className={cx(styles.contentArea, {
          [styles.alignRight]: alignment === 'right',
          [styles.alignLeft]: alignment === 'left'
        })}
      >
        <div
          className={cx(
            styles.backgroundCircle,
            circleClass && styles[circleClass]
          )}
        >
          {children}
        </div>
      </div>
    )

    return (
      <div
        className={styles.notification}
        data-testid={testId}
        ref={ref}
        {...props}
      >
        {position === 'below' && content}
        {isOpen && (
          <div
            className={cx(
              styles.notificationBody,
              bodyClass && styles[bodyClass],
              {
                [styles.positionBelow]: position === 'below',
                [styles.positionAbove]: position === 'above',
                [styles.alignRight]: alignment === 'right',
                [styles.alignLeft]: alignment === 'left'
              },
              notificationClass
            )}
            style={notificationStyle}
          >
            <div className={styles.notificationText}>{message}</div>
          </div>
        )}
        {position === 'above' && content}
      </div>
    )
  }
)

TargetedNotification.propTypes = {
  children: PropTypes.node.isRequired,
  message: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['navy', 'roundedOrange']),
  position: PropTypes.oneOf(['above', 'below']),
  alignment: PropTypes.oneOf(['right', 'left']),
  notificationClass: PropTypes.string,
  testId: PropTypes.string,
  isOpen: PropTypes.bool
}
