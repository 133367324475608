import { useMutation } from '@apollo/client'
import { UPDATE_PARTY_MEMBER } from '../../apollo/party-member/party-member.graphql'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { ContactInfo } from '../../apollo/generated/OptWebGraphQLOperations'

interface UpdatableProperties {
  name?: string
  emoji?: string
  contactInfo?: ContactInfo
}

interface UpdatePartyMemberArgs {
  updatableProperties?: UpdatableProperties
  onSuccess?: (e?: any) => void | any
  onError?: (e?: any) => void | any
}

export const useUpdatePartyMember = () => {
  const [updatePartyMember] = useMutation(UPDATE_PARTY_MEMBER)
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  return async ({
    updatableProperties = {},
    onSuccess,
    onError
  }: UpdatePartyMemberArgs) => {
    if (partyGuid && partyMemberGuid && memberAuthToken) {
      const updatePartyMemberResponse = await updatePartyMember({
        variables: {
          input: {
            ...updatableProperties,
            partyGuid,
            partyMemberGuid,
            memberAuthToken
          }
        }
      })

      const { OPTPartyMemberV2, OPTPartyError } = dataByTypename(
        updatePartyMemberResponse.data.updatePartyMember
      )
      if (OPTPartyMemberV2) {
        onSuccess && onSuccess()
      } else if (OPTPartyError) {
        onError && onError(OPTPartyError)
      }
    }
  }
}
