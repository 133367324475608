import React from 'react'
import PropTypes from 'prop-types'
import { useFormik } from '../../../../FormikProvider/FormikProvider'

import Button from '../../../../Button/Button'

import styles from './GiftCardPaymentLabel.module.css'
import { CTA } from '@/il8n/en'

export const GiftCardPaymentLabel = ({ canSubmit, loading = false }) => {
  const { submitForm } = useFormik()
  return (
    <div className={styles.giftCardPaymentLabel}>
      <Button
        data-testid='apply-gift-card-button'
        onClick={submitForm}
        disabled={!canSubmit}
        loading={loading}
        variant='secondary'
      >
        {CTA.APPLY_GIFT_CARD_PAYMENT}
      </Button>
    </div>
  )
}

GiftCardPaymentLabel.propTypes = {
  canSubmit: PropTypes.bool.isRequired,
  loading: PropTypes.bool
}
