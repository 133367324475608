import React, { useCallback, useEffect } from 'react'
import { useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useHistory, useRouteMatch } from 'react-router'
import { useParty } from './PartyProvider'
import { useAvailability } from '../../utils/availability'

export const HandlePartySideEffects: React.FC = () => {
  const { partyGuid } = useParty()
  const { getRestaurantPath } = useRestaurant()
  const rootPath = getRestaurantPath('/')
  const { push: historyPush } = useHistory()
  const cartMatch = useRouteMatch('/cart')
  const tabMatch = useRouteMatch('/tab')
  const addItemMatch = useRouteMatch('/add')
  const pushToRoot = useCallback(() => {
    historyPush({ hash: '', state: null, pathname: rootPath, search: '' })
  }, [historyPush, rootPath])

  const { refetch: partyRefetch } = useGetPartyRefresh()

  const refetchOnFocus = useCallback(() => {
    if (document.visibilityState === 'visible') {
      partyRefetch()
    }
  }, [partyRefetch])

  const { orderingAvailable } = useAvailability()
  // if no party initialized, ensure we go back to app root
  useEffect(() => {
    if (!orderingAvailable) {
      return
    }
    if (!partyGuid && (cartMatch || tabMatch || addItemMatch)) {
      pushToRoot()
    }
  }, [
    addItemMatch,
    cartMatch,
    partyGuid,
    pushToRoot,
    tabMatch,
    orderingAvailable
  ])

  useEffect(() => {
    // apollo with auto-magically batch refetch events if a refetch is in-flight
    // which will prevent multiple network requests on overlapping event types
    window.addEventListener('focus', refetchOnFocus)
    window.addEventListener('pageshow', refetchOnFocus)
    document.addEventListener('visibilitychange', refetchOnFocus)
    return () => {
      window.removeEventListener('focus', refetchOnFocus)
      window.removeEventListener('pageshow', refetchOnFocus)
      document.removeEventListener('visibilitychange', refetchOnFocus)
    }
  }, [refetchOnFocus])

  return null
}
