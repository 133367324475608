import React, { useEffect, useCallback } from 'react'

import { useTab } from '../TabProvider/TabProvider'
import { useErrorModal } from '../ErrorModalProvider/ErrorModalProvider'
import { useGetOrderFromParty } from '../PartyQuery/PartyQuery'
import { useParty } from '../PartyProvider/PartyProvider'
import { TabClosed } from '../TabClosed/TabClosed'

export const useGetTab = () => {
  const result = useGetOrderFromParty()
  // side-effect for closed tab handled here
  useTabClosedWarningModal(result.order, result.primaryCheck)
  return result
}

/**
 * Side-effect to check if tab is already closed, and if so delete the tab and
 * show an error modal
 */
const useTabClosedWarningModal = (tab, primaryCheck) => {
  const { partyGuid } = useParty()
  const { deleteTab } = useTab()
  const { handleClose, setModal } = useErrorModal()
  const handleCloseModalAndDeleteTab = useCallback(() => {
    if (deleteTab) {
      deleteTab()
    }
    handleClose()
  }, [deleteTab, handleClose])

  const isClosed = (primaryCheck && primaryCheck.isClosed) || false
  useEffect(() => {
    // only perform this if no partyGuid. Otherwise, defers to OPTPartySplash state machine
    if (!partyGuid && isClosed) {
      setModal(<TabClosed onClose={handleCloseModalAndDeleteTab} />)
    }
  }, [
    handleCloseModalAndDeleteTab,
    isClosed,
    setModal,
    primaryCheck,
    partyGuid,
    tab
  ])
}
