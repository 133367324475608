import React, { ReactNode } from 'react'
import PropTypes from 'prop-types'

const getRestaurantPath = (subpath = '') => {
  if (subpath && subpath[0] !== '/') {
    subpath = `/${subpath}`
  }

  return subpath || '/'
}

interface RestaurantContextInterface {
  readonly restaurantGuid: string
  readonly shortUrl: string
  getRestaurantPath(subpath?: string): string
}

interface InitialRestaurantContext {
  restaurantGuid: undefined
  shortUrl: undefined
  getRestaurantPath(subpath?: string): string
}

type RestaurantContextValues =
  | RestaurantContextInterface
  | InitialRestaurantContext

const RestaurantContext = React.createContext<RestaurantContextValues>({
  restaurantGuid: undefined,
  shortUrl: undefined,
  getRestaurantPath
})

interface RestaurantProviderProps {
  restaurantGuid: string
  shortUrl: string
  children: React.ReactNode
}

export const RestaurantProvider: React.FC<RestaurantProviderProps> = ({
  restaurantGuid,
  shortUrl,
  children
}) => {
  const context = {
    getRestaurantPath,
    restaurantGuid,
    shortUrl
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

RestaurantProvider.propTypes = {
  children: PropTypes.node.isRequired,
  restaurantGuid: PropTypes.string.isRequired,
  shortUrl: PropTypes.string.isRequired
}

interface RestaurantConsumerComponent {
  children(context: RestaurantContextValues): ReactNode
}

export const RestaurantConsumer: React.FC<RestaurantConsumerComponent> = ({
  children
}) => (
  <RestaurantContext.Consumer>
    {(context) => children(context)}
  </RestaurantContext.Consumer>
)

RestaurantConsumer.propTypes = {
  children: PropTypes.func.isRequired
}

interface MockRestaurantProviderProps {
  mocks: {
    restaurantGuid: string
    shortUrl: string
  }
  children: ReactNode
}

export const MockRestaurantProvider: React.FC<MockRestaurantProviderProps> = ({
  mocks: { restaurantGuid, shortUrl },
  children
}) => {
  const context = {
    restaurantGuid,
    shortUrl,
    getRestaurantPath: () => shortUrl
  }

  return (
    <RestaurantContext.Provider value={context}>
      {children}
    </RestaurantContext.Provider>
  )
}

export const useRestaurant = () => React.useContext(RestaurantContext)

MockRestaurantProvider.propTypes = {
  children: PropTypes.element.isRequired,
  mocks: PropTypes.shape({
    restaurantGuid: PropTypes.string.isRequired,
    shortUrl: PropTypes.string.isRequired
  }).isRequired
}
