import * as yup from 'yup'
import {
  PaymentMethod,
  SavedCreditCardPaymentMethod
} from '../../hooks/place-order/use-available-payment-methods'

import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'

declare global {
  interface Window {
    ApplePaySession?: typeof ApplePaySession
  }
}

interface MerchantSessionHandlerParams {
  validationURL: string
}

type MerchantSessionHandler = (
  params: MerchantSessionHandlerParams
) => Promise<void>

type OpaqueMerchantSessionObject = object
type GetMerchantSession = (
  validationURL: string
) => Promise<OpaqueMerchantSessionObject>
interface GetMerchantSessionHandlerParams {
  session: ApplePaySession
  getMerchantSession: GetMerchantSession
}

function getMerchantValidationHandler({
  session,
  getMerchantSession
}: GetMerchantSessionHandlerParams): MerchantSessionHandler {
  return async ({ validationURL }) => {
    if (!validationURL) {
      throw new Error('No Validation Url')
    }
    const merchantSession = await getMerchantSession(validationURL)
    session.completeMerchantValidation(merchantSession)
  }
}

type PerformTransaction = (event: ApplePayJS.ApplePayPayment) => Promise<void>
interface GetPaymentAuthorizationHandlerParams {
  session: ApplePaySession
  performTransaction: PerformTransaction
}

function getPaymentAuthorizationHandler({
  session,
  performTransaction
}: GetPaymentAuthorizationHandlerParams): typeof session.onpaymentauthorized {
  return async ({ payment }) => {
    if (!window.ApplePaySession) {
      throw new Error('No ApplePay available')
    }
    if (!payment) {
      throw new Error('No payment')
    }
    const { STATUS_SUCCESS, STATUS_FAILURE } = window.ApplePaySession
    try {
      await performTransaction(payment)
      session.completePayment(STATUS_SUCCESS)
    } catch (error) {
      session.completePayment(STATUS_FAILURE)
    }
  }
}

export const canMakeApplePayPayment = () =>
  Boolean(window?.ApplePaySession?.canMakePayments())

export function getIsApplePayPreauthAvailable(
  paymentMethods: PaymentMethod[]
): boolean {
  const preauth = paymentMethods.find(
    (pm) =>
      pm.type === 'SavedCreditCard' &&
      pm.paymentMethodDetails.isPreauthed === true
  ) as SavedCreditCardPaymentMethod

  return (
    preauth &&
    preauth.paymentMethodDetails &&
    !preauth.paymentMethodDetails.expirationMonth &&
    !preauth.paymentMethodDetails.expirationYear
  )
}

/**
 * Determines whether an active payment method is available on the user's
 * device.
 * Requires asynchronous call to Apple Pay services, handled by Safari.
 * Note: Untested whether works in a local dev environment with
 * merchant identifier merchant.com.toasttab.eng.preprod
 * See more: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778000-canmakepaymentswithactivecard
 */
export const canMakePaymentsWithActiveCard = async () => {
  let merchantIdentifier = 'merchant.com.toasttab.eng.preprod'
  if (envFromOrderHostname(window.location.hostname) === 'prod') {
    merchantIdentifier = 'merchant.com.toasttab.www'
  }
  return Boolean(
    await window?.ApplePaySession?.canMakePaymentsWithActiveCard(
      merchantIdentifier
    )
  )
}

interface MakeApplePayPaymentParams {
  getMerchantSession: GetMerchantSession
  performTransaction: PerformTransaction
  config: ApplePayJS.ApplePayPaymentRequest
  initSession(session?: ApplePaySession): void
  onCancel(event: ApplePayJS.Event): void
}

export function makeApplePayPayment({
  getMerchantSession,
  performTransaction,
  config,
  initSession = () => {},
  onCancel = () => {}
}: MakeApplePayPaymentParams): void {
  if (!window.ApplePaySession) {
    throw new Error('Apple Pay session not defined')
  }
  const APPLE_PAY_VERSION = 6
  const session = new window.ApplePaySession(APPLE_PAY_VERSION, config)
  session.onvalidatemerchant = getMerchantValidationHandler({
    session,
    getMerchantSession
  })
  session.onpaymentauthorized = getPaymentAuthorizationHandler({
    session,
    performTransaction
  })
  session.oncancel = onCancel
  initSession(session)
  session.begin()
}

/**
 *
 * Validates apple pay contact fields.
 * Resolves async and only throws first error encountered.
 */
export async function validateApplePayContactFields(values: {
  emailAddress?: string
  givenName?: string
  familyName?: string
  phoneNumber?: string
}) {
  const updateMessage = 'Update your Apple Pay account information to continue.'
  const requiredMessage = (key: string) =>
    `Apple Pay ${key} is required. ${updateMessage}`

  return yup
    .object()
    .shape({
      emailAddress: yup
        .string()
        .email(`Apple Pay email address not valid. ${updateMessage}`)
        .required(requiredMessage('email address')),
      givenName: yup.string().required(requiredMessage('first name')),
      familyName: yup.string().required(requiredMessage('last name')),
      phoneNumber: yup
        .string()
        .trim()
        .required(requiredMessage('phone number'))
        .matches(
          /^\d{10}$/,
          `Apple Pay phone number must be a 10 digit number. ${updateMessage}`
        )
    })
    .validate(values)
}
