import {
  AppliedServiceCharge,
  AppliedServiceChargeFragment,
  CheckSelectionGuid,
  Maybe,
  OptCheckV2Fragment,
  OptCheckV2GuidFragment,
  OptCheckV2NoGuidFragment,
  OptOrderFragment,
  OptPartyMemberV2,
  OptPartyRefreshV2Fragment,
  PaymentType,
  ServiceChargeCategory,
  ServiceChargeType
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useGiftCard } from '../../components/GiftCardProvider/GiftCardProvider'
import {
  useGiftCardEnabled,
  useGiftCardTipEnabled
} from '../../components/IfGiftCard/use-gift-card-enabled'
import { v4 as uuid } from 'uuid'

import {
  MemberPayment,
  useGetPartyRefresh
} from '../../components/PartyQuery/PartyQuery'
import { Bucket } from '../order-helpers'
import { FieldLabel } from '@/il8n/en'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { isTaxInclusive } from '../tax-inclusive'

export enum CheckStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export const getPrimaryCheckFromOrder = (
  order?: OptOrderFragment
): Maybe<OptCheckV2Fragment> => {
  if (!order || !order.checks || !order.checks.length) {
    return null
  }
  if (order.__typename === 'OPTOrderGuid') {
    return order.checks.find((chk) => chk.guid === order.checkGuid) ?? null
  }
  return order.checks[0] ?? null
}

export const combineCommonServiceCharges = (
  charges: AppliedServiceChargeFragment[]
): AppliedServiceChargeFragment[] => {
  if (!charges) {
    return []
  }

  const presentChargeGuids = new Set(
    charges.map((chg) => {
      return chg.serviceChargeDetails?.guid
    })
  )

  const newCharges: AppliedServiceChargeFragment[] = []
  presentChargeGuids.forEach((chargeGuid) => {
    const matchingCharges: AppliedServiceChargeFragment[] = charges.filter(
      (s) => s.serviceChargeDetails?.guid === chargeGuid
    )
    const totalChargeAmt: number = matchingCharges.reduce<number>((acc, s) => {
      return acc + (s?.chargeAmount || 0)
    }, 0)

    const primaryCharge = matchingCharges[0]
    if (primaryCharge) {
      newCharges.push({
        ...primaryCharge,
        chargeAmount: totalChargeAmt
      })
    }

    charges = charges.filter((c) => c.serviceChargeDetails?.guid !== chargeGuid)
  })
  return newCharges
}

/**
 * combines multiple checks into 1 for display.
 * non-combinable fields (such as guid) use the first present in the list
 * @param checks array of OPTCheckV2
 * @param checkStatus optional; filters out checks not matching given status
 */
export const combineChecks = (
  checks?: OptCheckV2GuidFragment[],
  checkStatus?: CheckStatus
): Maybe<OptCheckV2GuidFragment> => {
  if (!checks) {
    return null
  }
  // if length of checks is undefined, that means checks passed in is a singular check
  if (checks.length === undefined) {
    return checks as OptCheckV2GuidFragment
  }
  const filteredChecks = checks.filter((chk) => {
    switch (checkStatus) {
      case CheckStatus.CLOSED:
        return chk.isClosed
      case CheckStatus.OPEN:
        return !chk.isClosed
      default:
        return true
    }
  })

  if (!filteredChecks.length) {
    return null
  }

  let combinedCheck = filteredChecks.reduce((acc, check) => {
    const {
      payments,
      selections,
      expectedPaymentAmount,
      appliedPreauthInfo,
      discounts,
      numberOfSelections,
      appliedServiceCharges,
      serviceChargeTotal,
      total,
      tax,
      taxWithoutSurchargeTax,
      customer,
      isClosed,
      subtotal,
      tip,
      preDiscountedSubtotal
    } = check

    if (!acc) {
      return check
    } else {
      return {
        guid: acc.guid,
        payments: acc.payments.concat(payments),
        selections: acc.selections.concat(selections),
        expectedPaymentAmount:
          acc.expectedPaymentAmount + expectedPaymentAmount,
        numberOfSelections: acc.numberOfSelections + numberOfSelections,
        serviceChargeTotal: acc.serviceChargeTotal + serviceChargeTotal,
        total: acc.total + total,
        tax: acc.tax + tax,
        taxWithoutSurchargeTax:
          acc.taxWithoutSurchargeTax + taxWithoutSurchargeTax,
        subtotal: acc.subtotal + subtotal,
        preDiscountedSubtotal:
          acc.preDiscountedSubtotal + preDiscountedSubtotal,
        isClosed: acc.isClosed && isClosed,
        discounts: acc.discounts.restaurantDiscount ? acc.discounts : discounts,
        customer: acc.customer || customer,
        appliedPreauthInfo: acc.appliedPreauthInfo || appliedPreauthInfo,
        appliedServiceCharges: combineCommonServiceCharges(
          (acc.appliedServiceCharges || []).concat(appliedServiceCharges || [])
        ),
        tip: acc.tip + tip,
        // no logical way to combine preComputedTips.
        // Instead, just use first
        preComputedTips: acc.preComputedTips,
        __typename: acc.__typename
      }
    }
  })

  return combinedCheck
}

/**
 * combines multiple checks into 1 for display.
 * non-combinable fields (such as guid) use the first present in the list
 * @param checks array of OPTCheckV2
 * @param checkStatus optional; filters out checks not matching given status
 */
export const combineCartChecks = (
  checks?: OptCheckV2NoGuidFragment[]
): Maybe<OptCheckV2NoGuidFragment> => {
  if (!checks?.length) {
    return null
  }

  return checks.reduce((acc, check) => {
    const {
      selections,
      discounts,
      numberOfSelections,
      appliedServiceCharges,
      serviceChargeTotal,
      total,
      tax,
      taxWithoutSurchargeTax,
      customer,
      subtotal,
      expectedPaymentAmount
    } = check

    if (!acc) {
      return check
    } else {
      return {
        selections: acc.selections.concat(selections),
        numberOfSelections: acc.numberOfSelections + numberOfSelections,
        serviceChargeTotal: acc.serviceChargeTotal + serviceChargeTotal,
        expectedPaymentAmount:
          acc.expectedPaymentAmount + expectedPaymentAmount,
        total: acc.total + total,
        tax: acc.tax + tax,
        taxWithoutSurchargeTax:
          acc.taxWithoutSurchargeTax + taxWithoutSurchargeTax,
        subtotal: acc.subtotal + subtotal,
        discounts: acc.discounts.restaurantDiscount ? acc.discounts : discounts,
        customer: acc.customer || customer,
        appliedServiceCharges: acc.appliedServiceCharges
          ? acc.appliedServiceCharges.concat(appliedServiceCharges || [])
          : appliedServiceCharges,
        // no logical way to combine preComputedTips.
        // Instead, just use first
        preComputedTips: acc.preComputedTips,
        preDiscountedSubtotal:
          acc.preDiscountedSubtotal + check.preDiscountedSubtotal
      }
    }
  })
}

/**
 * combines multiple selections of the same item into 1 for display.
 * non-combinable fields (such as guid) use the first present in the list
 * @param selections array of CheckSelectionGuid
 */
export const combineSelectionsOfSameItem = (
  selections: CheckSelectionGuid[]
) => {
  if (!selections) {
    return []
  }

  const presentItemGuids = new Set(
    selections.map((sel) => {
      return sel.itemGuid
    })
  )

  const newSelections: CheckSelectionGuid[] = []
  presentItemGuids.forEach((selectionGuid) => {
    const matchingSelections: CheckSelectionGuid[] = selections.filter(
      (s) => s.itemGuid === selectionGuid
    )
    const totalQuantity: number = matchingSelections.reduce<number>(
      (acc, s) => {
        return acc + s.quantity
      },
      0
    )
    const totalPreDiscountPrice: number = matchingSelections.reduce<number>(
      (acc, s) => {
        return acc + (s?.preDiscountPrice || 0)
      },
      0
    )

    const selection = matchingSelections[0]
    if (selection) {
      newSelections.push({
        ...selection,
        modifiers: [],
        quantity: totalQuantity,
        preDiscountPrice: totalPreDiscountPrice
      })
    }
    selections = selections.filter((s) => s.itemGuid !== selectionGuid)
  })

  return newSelections
}

export const combineChecksAsBucket = (
  me: OptPartyMemberV2 | undefined,
  allChecks: OptCheckV2GuidFragment[]
): Bucket[] => {
  const combinedCheck = combineChecks(allChecks)
  const combinedSelections = combineSelectionsOfSameItem(
    combinedCheck?.selections as CheckSelectionGuid[]
  )
  return [
    {
      member: me as OptPartyMemberV2,
      check: {
        ...combinedCheck,
        selections: combinedSelections
      } as OptCheckV2GuidFragment
    }
  ]
}

function getPmtTypeLabel(paymentType?: Maybe<PaymentType | `${PaymentType}`>) {
  switch (paymentType) {
    case PaymentType.Cash:
      return 'Cash Payment'
    case PaymentType.Credit:
      return 'Credit Card Payment'
    case PaymentType.Giftcard:
      return 'Gift Card Payment'
    case PaymentType.HouseAccount:
      return 'House Account Payment'
    case PaymentType.Levelup:
      return 'Level Up Payment'
    case PaymentType.Rewardcard:
      return 'Reward Card Payment'
    case PaymentType.Undetermined:
    case PaymentType.Other:
    default:
      return 'Other Payment'
  }
}

export const CheckLineItemTypes = {
  SubtotalAmount: 'subtotal-amount',
  TaxAmount: 'tax-amount',
  PromoCodeAmount: 'promo-code-amount',
  LoyaltyDiscountAmount: 'loyalty-discount-amount',
  GiftCardAmount: 'gift-card-amount',
  GlobalGiftCardAmount: 'global-gift-card-amount',
  ToastCashPayment: 'toast-cash-payment',
  GiftCardPayment: 'gift-card-payment',
  TipAmount: 'tip-amount',
  OtherPaymentAmount: 'other-payment-amount',
  OtherPaymentTipAmount: 'other-payment-tip-amount',
  OtherMemberPaymentAmount: 'other-member-payment-amount',
  AppliedServiceCharge: 'applied-service-charge',
  GroupTotalOrderAmount: 'group-total-order-amount',
  TotalOrderAmount: 'total-order-amount',
  YourTotalOrderAmount: 'your-total-order-amount',
  TotalOrderRemainingAmount: 'total-order-remaining-amount'
} as const

export type CheckLineItemType =
  typeof CheckLineItemTypes[keyof typeof CheckLineItemTypes]
export interface CheckLineItem {
  /**
   * aribitrary unique identifier for a line item
   */
  id: string
  /**
   * well-defined functionality associated with a line item
   */
  type: CheckLineItemType
  label: string
  amount: number
}

const serviceChargeIsSurcharge = (
  serviceCharge: Pick<AppliedServiceCharge, 'serviceChargeCategory'>
) =>
  serviceCharge.serviceChargeCategory ===
  ServiceChargeCategory.CreditCardSurcharge

function serviceChargeToLabel(
  serviceCharge: Pick<AppliedServiceCharge, 'name' | 'serviceChargeDetails'>
): string {
  const scPercent = serviceCharge.serviceChargeDetails?.percent
  if (scPercent) {
    return [serviceCharge.name, `(${scPercent.toFixed(2)}%)`].join(' ')
  }
  return serviceCharge.name
}

function serviceChargeToId(
  serviceCharge: Pick<
    AppliedServiceCharge,
    'gratuity' | 'chargeType' | 'name' | 'guid' | 'serviceChargeCategory'
  >
): string {
  const isGratuity = serviceCharge.gratuity
  const isUbp =
    !isGratuity &&
    serviceCharge.chargeType === ServiceChargeType.Fixed &&
    serviceCharge.name === 'Order processing fee'

  const guid = serviceCharge.guid ?? uuid()
  if (isGratuity) {
    return `gratuity-service-charge-${guid}`
  } else if (isUbp) {
    return `ubp-service-charge-${guid}`
  } else if (serviceChargeIsSurcharge(serviceCharge)) {
    return `surcharge-service-charge-${guid}`
  } else {
    return `non-gratuity-service-charge-${guid}`
  }
}

const getPaymentAmountsCombined = (
  check: OptCheckV2Fragment
): {
  amount: number
  tipAmount: number
} => {
  const initialAmounts = { amount: 0.0, tipAmount: 0.0 }
  if (check.__typename === 'OPTCheckV2Guid') {
    return check.payments.reduce(
      (acc, p) => ({
        amount: acc.amount + p.amount,
        tipAmount: acc.tipAmount + p.tipAmount
      }),
      initialAmounts
    )
  }
  return initialAmounts
}

const serviceChargeToLineItem = (
  serviceCharge: Pick<
    AppliedServiceCharge,
    'gratuity' | 'chargeAmount' | 'chargeType' | 'name' | 'guid'
  >
) => ({
  label: serviceChargeToLabel(serviceCharge),
  amount: serviceCharge.chargeAmount,
  id: serviceChargeToId(serviceCharge),
  type: CheckLineItemTypes.AppliedServiceCharge
})

export interface GetCheckLineItemsParams {
  check: OptCheckV2Fragment
  // frontend tip to be added to the check
  tip?: number | null
  // gift card payment to be added to a due check
  giftCardPayment: number
  globalGiftCardPayment: number
  completedGiftCardPayment: number | undefined
  completedGlobalGiftCardPayment: number | undefined
  totalMode: 'DUE' | 'POST_PAYMENT' | 'SOLO' | 'GROUP' | 'NONE'
  giftCardEnabled?: boolean
  memberPayments?: MemberPayment[]
  party?: OptPartyRefreshV2Fragment
  includeTaxInSubtotal?: boolean
}

export const getCheckLineItems = ({
  check,
  tip,
  giftCardPayment,
  globalGiftCardPayment,
  completedGiftCardPayment,
  completedGlobalGiftCardPayment,
  totalMode,
  giftCardEnabled = false,
  memberPayments = [],
  party,
  includeTaxInSubtotal = false
}: GetCheckLineItemsParams): CheckLineItem[] => {
  const showGiftCard = Boolean(giftCardPayment > 0)
  const showGlobalGiftCard = Boolean(globalGiftCardPayment > 0)
  const completedGiftCardTotal =
    (completedGlobalGiftCardPayment || 0) + (completedGiftCardPayment || 0)

  let displayTotal = (() => {
    if (totalMode === 'DUE') {
      return check.expectedPaymentAmount
    }
    return check.total
  })()

  const totals: CheckLineItem[] = []

  if (check.preDiscountedSubtotal) {
    totals.push({
      label: FieldLabel.SUBTOTAL,
      amount: includeTaxInSubtotal
        ? check.preDiscountedSubtotal + check.taxWithoutSurchargeTax
        : check.preDiscountedSubtotal,
      id: 'subtotal-amount',
      type: CheckLineItemTypes.SubtotalAmount
    })
  }

  if (check.taxWithoutSurchargeTax && !includeTaxInSubtotal) {
    totals.push({
      label: FieldLabel.TAX,
      amount: check.taxWithoutSurchargeTax,
      id: 'tax-amount',
      type: CheckLineItemTypes.TaxAmount
    })
  }

  check.appliedServiceCharges?.forEach((sc) => {
    totals.push(serviceChargeToLineItem(sc))
    if (serviceChargeIsSurcharge(sc)) {
      // show surcharge tax as a separate line item
      if (sc.taxAmount) {
        totals.push({
          label: FieldLabel.SURCHARGE_TAX,
          amount: sc.taxAmount,
          id: 'surcharge-tax',
          type: CheckLineItemTypes.AppliedServiceCharge
        })
      }
    }
  })

  if (check.discounts.restaurantDiscount) {
    totals.push({
      label: FieldLabel.PROMO_APPLIED,
      amount: -check.discounts.restaurantDiscount?.amount,
      id: 'promo-code-amount',
      type: CheckLineItemTypes.PromoCodeAmount
    })
  }

  const loyaltyDiscounts = check.discounts.loyaltyDiscounts

  if (loyaltyDiscounts?.length) {
    const loyaltyDiscountTotal = loyaltyDiscounts.reduce(
      (sum, loyaltyDiscount) => {
        if (loyaltyDiscount?.amount) {
          return sum + loyaltyDiscount?.amount
        }
        return sum
      },
      0
    )

    const numDiscounts = loyaltyDiscounts.length
    const rewardsLabel =
      numDiscounts > 1
        ? numDiscounts + ' rewards applied'
        : FieldLabel.LOYALTY_DISCOUNT_APPLIED

    loyaltyDiscountTotal &&
      totals.push({
        label: rewardsLabel,
        amount: -loyaltyDiscountTotal.toFixed(2),
        id: 'loyalty-discount-amount',
        type: CheckLineItemTypes.LoyaltyDiscountAmount
      })
  }

  const { amount: checkPaymentsCombined, tipAmount: tipPaymentsCombined } =
    getPaymentAmountsCombined(check)

  if (typeof tip === 'number' && ['DUE', 'SOLO', 'GROUP'].includes(totalMode)) {
    displayTotal += tip
    totals.push({
      label: FieldLabel.CHECKOUT_TIP,
      amount: tip,
      id: 'tip-amount',
      type: CheckLineItemTypes.TipAmount
    })
  }

  if (
    check.__typename === 'OPTCheckV2Guid' &&
    !['SOLO', 'GROUP'].includes(totalMode)
  ) {
    check.payments.forEach((p) => {
      if (p.amount) {
        totals.push({
          label: getPmtTypeLabel(p.type),
          amount: p.amount,
          id: `other-payment-${p.guid}-amount`,
          type: CheckLineItemTypes.OtherPaymentAmount
        })
      }
      if (p.tipAmount) {
        totals.push({
          label: getPmtTypeLabel(p.type) + ' (Tip)',
          amount: p.tipAmount,
          id: `other-payment-${p.guid}-tip-amount`,
          type: CheckLineItemTypes.OtherPaymentTipAmount
        })
      }
    })
  }

  // gift card logic
  if (totalMode === 'DUE') {
    const gcPmts = []
    if (giftCardEnabled && showGiftCard) {
      gcPmts.push({
        label: FieldLabel.GIFT_CARD_APPLIED,
        amount: giftCardPayment,
        id: 'gift-card-amount',
        type: CheckLineItemTypes.GiftCardAmount
      })
    }

    if (showGlobalGiftCard) {
      gcPmts.push({
        label: FieldLabel.TOAST_CASH_APPLIED,
        amount: globalGiftCardPayment,
        id: 'global-gift-card-amount',
        type: CheckLineItemTypes.GlobalGiftCardAmount
      })
    }

    gcPmts
      .sort((a, b) => {
        return a.amount - b.amount
      })
      .forEach((gc) => {
        const finalAmt = Math.min(displayTotal, gc.amount)
        totals.push({
          label: gc.label,
          amount: -finalAmt,
          id: gc.id,
          type: CheckLineItemTypes.GiftCardPayment
        })
        displayTotal -= finalAmt
      })

    totals.push({
      label: FieldLabel.TOTAL_DUE,
      // Math.max avoids potentially negative balances due (if bug),
      // and potential '-0.0' floating point issues
      amount: Math.max(displayTotal, 0.0),
      id: 'total-order-amount',
      type: CheckLineItemTypes.TotalOrderAmount
    })
  } else {
    if (completedGiftCardPayment) {
      totals.push({
        label: 'Gift card applied',
        amount: -completedGiftCardPayment,
        id: 'gift-card-payment',
        type: CheckLineItemTypes.GiftCardPayment
      })
    }
    if (completedGlobalGiftCardPayment) {
      totals.push({
        label: 'Toast Cash applied',
        amount: -completedGlobalGiftCardPayment,
        id: 'toast-cash-payment',
        type: CheckLineItemTypes.ToastCashPayment
      })
    }
  }

  if (
    giftCardPayment &&
    checkPaymentsCombined &&
    tipPaymentsCombined &&
    displayTotal -
      giftCardPayment -
      checkPaymentsCombined -
      tipPaymentsCombined >
      0
  ) {
    totals.push({
      label: FieldLabel.TOTAL_REMAINING,
      amount: displayTotal - giftCardPayment - checkPaymentsCombined,
      id: 'total-order-remaining-amount',
      type: CheckLineItemTypes.TotalOrderRemainingAmount
    })
  }

  if (totalMode === 'POST_PAYMENT') {
    totals.push({
      label: FieldLabel.TOTAL_PAID,
      amount:
        checkPaymentsCombined + tipPaymentsCombined - completedGiftCardTotal,
      id: 'total-order-amount',
      type: CheckLineItemTypes.TotalOrderAmount
    })
  }

  if (totalMode === 'SOLO') {
    totals.push({
      label: FieldLabel.SOLO_TOTAL,
      amount:
        checkPaymentsCombined + tipPaymentsCombined - completedGiftCardTotal,
      id: 'your-total-order-amount',
      type: CheckLineItemTypes.YourTotalOrderAmount
    })
  }

  if (totalMode === 'GROUP') {
    totals.push({
      label: FieldLabel.GROUP_TOTAL,
      amount: Math.max(displayTotal - tipPaymentsCombined, 0.0),
      id: 'group-total-order-amount',
      type: CheckLineItemTypes.GroupTotalOrderAmount
    })
  }

  if ((party?.party.members.length ?? 0) > 1 && totalMode === 'DUE') {
    memberPayments.forEach((memberPayment) => {
      totals.unshift({
        amount: memberPayment.amount * -1,
        id: `${memberPayment.name}-payment-${memberPayment.amount}`,
        label: `Paid by ${memberPayment.name}`,
        type: CheckLineItemTypes.OtherMemberPaymentAmount
      })
    })
  }

  return totals
}

export const useLineItemsDeferred = () => {
  const giftCardEnabled = useGiftCardEnabled()
  const { rxGiftCard, globalGiftCard } = useGiftCard()
  const giftCardTipEnabled = useGiftCardTipEnabled()
  const party = useGetPartyRefresh()
  const { data: restaurantInfo } = useRestaurantInfo()

  return ({
    check,
    tip,
    totalMode,
    memberPayments
  }: Pick<
    GetCheckLineItemsParams,
    'check' | 'tip' | 'totalMode' | 'memberPayments'
  >) => {
    const giftCardTip = giftCardTipEnabled ? tip || 0.0 : 0.0
    const giftCardPayment = Math.min(
      rxGiftCard?.expectedAvailableBalance || 0.0,
      check.total + giftCardTip,
      check.expectedPaymentAmount + giftCardTip
    )
    const globalGiftCardPayment = Math.min(
      globalGiftCard?.expectedAvailableBalance || 0.0,
      check.total + (tip || 0.0),
      check.expectedPaymentAmount + (tip || 0.0)
    )
    const completedGiftCardPayment = (
      check as OptCheckV2GuidFragment
    ).payments?.find((pmt) => {
      return pmt.type === PaymentType.Giftcard
    })
    const completedGlobalGiftCardPayment = (
      check as OptCheckV2GuidFragment
    ).payments?.find((pmt) => {
      // While OTHER can reference non-Toast Cash payments, Toast Cash is the only other-type payment we support via MDS
      return pmt.type === PaymentType.Other
    })

    return getCheckLineItems({
      giftCardEnabled,
      check,
      giftCardPayment,
      globalGiftCardPayment,
      completedGiftCardPayment: completedGiftCardPayment
        ? completedGiftCardPayment.amount + completedGiftCardPayment.tipAmount
        : undefined,
      completedGlobalGiftCardPayment: completedGlobalGiftCardPayment
        ? completedGlobalGiftCardPayment.amount +
          completedGlobalGiftCardPayment.tipAmount
        : undefined,
      totalMode,
      tip,
      memberPayments,
      party: party.partyRefresh,
      includeTaxInSubtotal: isTaxInclusive(restaurantInfo)
    })
  }
}

export const useLineItems = ({
  check,
  tip,
  totalMode
}: Pick<GetCheckLineItemsParams, 'check' | 'tip' | 'totalMode'>) => {
  const getLineItems = useLineItemsDeferred()
  // temporarily removing member payments so that the line item does not show. More context in this slack thread:
  // https://toasttab.slack.com/archives/C03HS3N5L8L/p1667857841173479?thread_ts=1667854485.545249&cid=C03HS3N5L8L
  const memberPayments: MemberPayment[] = [] // useGetMemberPayments()
  return getLineItems({
    check,
    tip,
    totalMode,
    memberPayments
  })
}
