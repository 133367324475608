import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import Progress from '../Progress/Progress'

import { useAuth } from '../AuthProvider/AuthProvider'
import { useFlag } from '../FeatureFlag/use-flag'

const AuthenticatedCore = ({
  component: Component,
  test,
  redirect,
  ...rest
}) => {
  const to = redirect
  const { authenticated, loading } = useAuth()
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Progress eventData={{ category: 'AuthenticatedRoute' }} />
        }
        if (test(authenticated)) {
          return <Component {...props} />
        }
        return <Redirect to={to} />
      }}
    />
  )
}

const redirectShape = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.any
  })
])

AuthenticatedCore.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  test: PropTypes.func,
  redirect: redirectShape
}

AuthenticatedCore.defaultProps = {
  redirect: '/'
}

const AuthenticatedRoute = (props) => {
  const test = (authenticated) => authenticated
  return <AuthenticatedCore test={test} {...props} />
}

const UnauthenticatedRoute = (props) => {
  const test = (authenticated) => !authenticated
  return <AuthenticatedCore test={test} {...props} />
}

const AuthenticatedRouteWhenFlagOn = (props) => {
  const { flag, defaultValue, redirect } = props
  const to = redirect
  const flagValue = useFlag(flag, defaultValue)
  if (flagValue === null) {
    return null
  }
  return flagValue ? <AuthenticatedRoute {...props} /> : <Redirect to={to} />
}

AuthenticatedRouteWhenFlagOn.defaultProps = {
  redirect: '/'
}

AuthenticatedRouteWhenFlagOn.propTypes = {
  redirect: redirectShape,
  flag: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool
}

const UnauthenticatedRouteWhenFlagOn = (props) => {
  const { flag, defaultValue, redirect } = props
  const to = redirect || '/'
  const flagValue = useFlag(flag, defaultValue)
  if (flagValue === null) {
    return null
  }
  return flagValue ? <UnauthenticatedRoute {...props} /> : <Redirect to={to} />
}

UnauthenticatedRouteWhenFlagOn.defaultProps = {
  redirect: '/'
}

UnauthenticatedRouteWhenFlagOn.propTypes = {
  redirect: redirectShape,
  flag: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool
}

export {
  AuthenticatedRoute,
  UnauthenticatedRoute,
  AuthenticatedRouteWhenFlagOn,
  UnauthenticatedRouteWhenFlagOn
}
