import React from 'react'
import PropTypes from 'prop-types'

import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import Link from '../Link/Link'
import ReorderIcon from '../../assets/reorder-blue.svg'
import { CTA } from '../../il8n/en'

import styles from './ReorderFromTabButton.module.css'

export const ReorderFromTabButton = ({
  itemGuid,
  itemGroupGuid,
  selectionGuid
}) => {
  const { getRestaurantPath } = useRestaurant()

  return (
    <div className={styles.reorder}>
      <Link
        to={getRestaurantPath(`add/${itemGuid}/${itemGroupGuid}/`)}
        data-testid='tab-item-reorder'
      >
        <div className={styles.reorder}>
          <div className={styles.reorderIcon}>
            <ReorderIcon />
          </div>
          {CTA.REORDER}
        </div>
      </Link>
    </div>
  )
}

ReorderFromTabButton.propTypes = {
  itemGuid: PropTypes.string.isRequired,
  itemGroupGuid: PropTypes.string.isRequired,
  selectionGuid: PropTypes.string.isRequired
}
