import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useReorderItemToCart } from '../CartMutation/CartMutation'
import Progress from '../Progress/Progress'
import { useParty } from '../PartyProvider/PartyProvider'

import styles from './ReorderQuickAdd.module.css'

export const ReorderQuickAdd = ({ selectionGuid, outOfStock }) => {
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  const [reorderItem, { loading }] = useReorderItemToCart({
    onCompleted: () => {}
  })
  const handleReorder = () => {
    return reorderItem({
      variables: {
        input: {
          partyGuid,
          partyMemberGuid,
          memberAuthToken,
          selectionGuid
        }
      }
    })
  }

  return (
    <div
      className='relative mr-1'
      data-testid='reorder-quick-add'
      onClick={(e) => {
        // prevent default ensures our parent, a Link, is not triggered
        e.preventDefault()
        e.stopPropagation()
        !loading && handleReorder()
      }}
      tabIndex={0}
    >
      <div className='flex items-center justify-center h-full px-6 no-underline'>
        {loading ? (
          <div className='absolute right-0'>
            <Progress />
          </div>
        ) : (
          <div className='absolute flex justify-end items-center m-auto w-[30px] h-[30px] right-0 box-content'>
            <button
              data-testid='quick-add-link'
              disabled={outOfStock}
              className={cx(
                styles.reorderQuickAdd,
                'box-content p-0 text-black w-7 h-7 bg-white text-center no-underline border-solid border-[1.5px] border-gray-25 rounded-full font-semibold'
              )}
            >
              <p className='text-[18px] flex items-center justify-center content-center -mt-[1.2px] -mr-[0.8px] text-default'>
                +
              </p>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

ReorderQuickAdd.propTypes = {
  selectionGuid: PropTypes.string.isRequired,
  outOfStock: PropTypes.bool.isRequired
}
