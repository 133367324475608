import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import RestaurantPage from '../RestaurantPage/RestaurantPage'
import { RestaurantProvider } from '../RestaurantProvider/RestaurantProvider'
import { TriggerRoutechange } from '../TriggerRoutechange/TriggerRoutechange'
import { PartyInviteHandler } from '../PartyInviteHandler/PartyInviteHandler'
import { RejoinHandler } from '../RejoinHandler/RejoinHandler'
import { AvailabilityProvider } from '../../utils/availability'

export const OnlineOrderingApp = () => {
  const { restaurantGuid, shortUrl, routerBasename } = useDDIGlobals()
  return (
    <RestaurantProvider restaurantGuid={restaurantGuid} shortUrl={shortUrl}>
      <BrowserRouter basename={routerBasename}>
        <AvailabilityProvider>
          <Route component={TriggerRoutechange} />
          <Switch>
            <Route component={PartyInviteHandler} path='/invite/:code' />
            <Route component={RejoinHandler} path='/rejoin' />
            <Route component={RestaurantPage} path='/' />
            <Redirect to='/' />
          </Switch>
        </AvailabilityProvider>
      </BrowserRouter>
    </RestaurantProvider>
  )
}

export default OnlineOrderingApp
