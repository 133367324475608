import { useQuery, QueryHookOptions } from '@apollo/client'

import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import { LOOKUP_PARTIES_BY_CHECK } from '../../apollo/party/party.graphql'

export const useLookupPartiesByCheck = (
  checkGuid: string | undefined,
  options: QueryHookOptions
) => {
  const { restaurantGuid } = useRestaurant()

  return useQuery(LOOKUP_PARTIES_BY_CHECK, {
    variables: {
      input: {
        restaurantGuid,
        checkGuid
      }
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...options
  })
}
