export const getRawPhoneNumber = (val) => {
  if (!val) {
    return ''
  }
  return val.replace(/\D/g, '').replace(/1?(\d{10})$/, (_, p1) => p1)
}

export const COUNTRY_CODES = {
  USA: '+1',
  PUERTO_RICO: '+1',
  CANADA: '+1'
}

export const getRawPhoneNumberWithCountryCode = (
  phone,
  countryCode = COUNTRY_CODES.USA
) => {
  return `${countryCode}${getRawPhoneNumber(phone)}`
}

export const formatTel = (val = '') => {
  if (!val) {
    return ''
  }
  return val.replace(
    /(\d{3})(\d{3})(\d{4})/,
    (_, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
  )
}

export function getPrimaryCreditCard(creditCards) {
  return (
    creditCards.find(({ isPrimary, expired }) => isPrimary && !expired) || {}
  )
}

export function trimToDecimalValue(str, precision = 0) {
  const [int, frac] = str.split('.')

  if (!frac || precision === 0) {
    return int
  }

  return [int, frac.substring(0, precision)].join('.')
}

export function sanitizeInputString(str) {
  return str.trim()
}

export function capitalizeFirst(str) {
  str = str.trim().toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const formUtils = {
  getRawPhoneNumber,
  formatTel,
  getPrimaryCreditCard,
  trimToDecimalValue,
  capitalizeFirst
}

export const removeNameFromSpecialRequest = (specialRequest) => {
  return (
    specialRequest
      // removes name when followed by another special request
      .replace(/For .+\.\r\n /, '')
      // removes name when it's the only special request
      .replace(/For .+\./, '')
  )
}
