import React from 'react'
import PropTypes from 'prop-types'
import { Mutation, Query } from '@apollo/client/react/components'
import { adopt } from 'react-adopt'

import { CREDIT_CARDS, DELETE_CARD, MAKE_PRIMARY } from './CreditCard.graphql'
import { isExpirationDateValid } from '../../utils/credit-card-date-validation'
import { useAuth } from '../AuthProvider/AuthProvider'

const CreditCardContext = React.createContext({})

// eslint-disable-next-line react/prop-types
const getCardMutation =
  (props) =>
  ({ render }) => {
    const { apolloClient } = useAuth()
    return (
      <Mutation
        {...props}
        refetchQueries={[{ query: CREDIT_CARDS }]}
        awaitRefetchQueries
        client={apolloClient}
      >
        {(mutate, result) => render({ mutate, result })}
      </Mutation>
    )
  }

const CreditCards = ({ render }) => {
  const { authenticated, apolloClient } = useAuth()
  return (
    <Query skip={!authenticated} query={CREDIT_CARDS} client={apolloClient}>
      {(result) => render({ result })}
    </Query>
  )
}

CreditCards.propTypes = {
  render: PropTypes.func
}

const CreditCardContainer = adopt({
  creditCards: CreditCards,
  deleteCard: getCardMutation({ mutation: DELETE_CARD }),
  makePrimary: getCardMutation({ mutation: MAKE_PRIMARY })
})

const getContext = ({ creditCards }) => {
  const { data, loading, error, refetch } = creditCards.result
  if (loading) {
    return { loading }
  }
  if (error) {
    return { error }
  }

  if (data) {
    const cardList = data.customer.creditCards
    const currentDate = new Date(Date.now())
    const decoratedCards = cardList.map((card) => ({
      ...card,
      expired: !isExpirationDateValid(
        card.expirationMonth,
        card.expirationYear,
        currentDate
      )
    }))
    return {
      creditCards: decoratedCards,
      refetch
    }
  }
  return {}
}

export const CreditCardProvider = ({ children }) => {
  return (
    <CreditCardContainer>
      {({ creditCards, deleteCard, makePrimary }) => {
        const cardContext = getContext({ creditCards })
        const context = {
          ...cardContext,
          deleteCard,
          makePrimary
        }
        return (
          <CreditCardContext.Provider value={context}>
            {children}
          </CreditCardContext.Provider>
        )
      }}
    </CreditCardContainer>
  )
}

CreditCardProvider.propTypes = {
  children: PropTypes.node
}

export const CreditCardConsumer = ({ children }) => {
  return (
    <CreditCardContext.Consumer>
      {(context) => children(context)}
    </CreditCardContext.Consumer>
  )
}

CreditCardConsumer.propTypes = {
  children: PropTypes.func
}

export const useCreditCard = () => React.useContext(CreditCardContext)
