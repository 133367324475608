import { toast } from 'react-toastify'
import {
  useAuth,
  AUTH_NOTIFICATION_CONTAINER_ID
} from '../../components/AuthProvider/AuthProvider'

export interface LogoutInput {
  variables: {
    input: {
      cart: any
    }
  }
}

export const useLogout = () => {
  const { passwordlessLogout, setIsAuthenticated } = useAuth()

  return async (
    logoutInput: LogoutInput,
    notificationText = 'You are now logged out.'
  ) => {
    await passwordlessLogout(logoutInput)
    setIsAuthenticated(false)
    toast(notificationText, { containerId: AUTH_NOTIFICATION_CONTAINER_ID })
  }
}
