import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import { object, string } from 'yup'
import { toast } from 'react-toastify'
import { useSentry } from 'banquet-runtime-modules'
import InputGroup from '../../Form/InputGroup/InputGroup'
import { getRawPhoneNumber } from '../../../utils/form-utils'
import Button from '../../Button/Button'
import Error from '../../Error/Error'
import Fieldset from '../../Fieldset/Fieldset'
import { CTA, FieldLabel } from '@/il8n/en'
import { useUpdateBasicInfo } from '../../../hooks/useUpdateBasicInfo'
import styles from './CustomerInformationForm.module.css'

const validationSchema = object().shape({
  customerFirstName: string().trim().required('required'),
  customerLastName: string().trim().required('required'),
  customerEmail: string()
    .trim()
    .email('must be valid email')
    .required('required'),
  customerTel: string()
    .transform(getRawPhoneNumber)
    .trim()
    .matches(/^\d{10}$/, 'must be a 10 digit number')
    .required('required')
})

const customerInputs = [
  {
    autoComplete: 'section-customer given-name',
    id: 'customer_first_name',
    label: FieldLabel.CHECKOUT_CUSTOMER_FIRST_NAME,
    name: 'customerFirstName',
    type: 'text',
    autoFocus: true
  },
  {
    autoComplete: 'section-customer family-name',
    id: 'customer_last_name',
    label: 'Last Name',
    name: 'customerLastName',
    type: 'text'
  },
  {
    autoComplete: 'section-customer email',
    id: 'customer_email',
    label: 'Email',
    name: 'customerEmail',
    type: 'email',
    disabled: true
  },
  {
    autoComplete: 'section-customer tel-national',
    id: 'customer_tel',
    label: 'Phone',
    name: 'customerTel',
    type: 'tel',
    guide: false
  }
]

const CustomerInformationForm = ({ initialValues }) => {
  const { captureException } = useSentry()
  const [updateBasicInfo, { error }] = useUpdateBasicInfo()

  const phoneInput = customerInputs.find((input) => input.id === 'customer_tel')
  phoneInput.disabled = true

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const input = {
          firstName: values.customerFirstName,
          lastName: values.customerLastName,
          phone: values.customerTel
        }
        try {
          await updateBasicInfo(input)
          toast('Information Updated Successfully!')
        } catch (err) {
          captureException(err)
        } finally {
          setSubmitting(false)
        }
      }}
      enableReinitialize
    >
      {({ isSubmitting, isValid, handleSubmit }) => {
        return (
          <Form>
            <Fieldset label={FieldLabel.PERSONAL_INFORMATION} collapsable>
              <InputGroup inputConfigs={customerInputs} />
              <div className={styles.submitWrapper}>
                {error && <Error error={error} />}
                <Button
                  data-testid='customer-information-button-submit'
                  id='SubmitCustomerInformationButton'
                  variant='primary'
                  type='submit'
                  onClick={(event) => {
                    event.preventDefault()
                    handleSubmit()
                  }}
                  disabled={!isValid}
                  loading={isSubmitting}
                  responsive
                >
                  {CTA.UPDATE_MY_INFORMATION}
                </Button>
              </div>
            </Fieldset>
          </Form>
        )
      }}
    </Formik>
  )
}

CustomerInformationForm.propTypes = {
  initialValues: PropTypes.object.isRequired
}

export default CustomerInformationForm
