import React from 'react'

import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

export const HeaderLoading: React.ComponentType = () => {
  return (
    <>
      <div className='flex h-12 pl-3 items-center justify-between shadow-md'>
        <Skeleton className='w-6 h-5' />
      </div>
      <div className='mt-6' />
    </>
  )
}
