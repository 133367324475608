import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CartTable from '../Cart/CartTable/CartTable'
import {
  getFilteredMemberName,
  sortMembersFromCarts
} from '../../utils/cart-helpers'
import { getPrimaryCheckFromOrder } from '../../utils/check-helpers/check-helpers'

// TODO: Convert component to TS, especially to
// for resiliency for unexpected cases
// e.g. when carts and members have mis-aligned data
/**
 * Displays the cart for all of the members in a party.
 */
export const PartyCartsView = ({ members, carts, me, listOnly }) => {
  const cartsMap = useMemo(() => {
    const _cartsMap = new Map()
    carts.forEach((cart) => {
      _cartsMap.set(cart.guid, cart)
    })
    return _cartsMap
  }, [carts])

  const sortedMembers = useMemo(() => {
    return sortMembersFromCarts(cartsMap, members, me)
  }, [members, cartsMap, me])

  return (
    <>
      {sortedMembers.map(({ partyMemberGuid, name, cartGuid }) => (
        <div key={partyMemberGuid}>
          <CartTable
            partyMemberGuid={partyMemberGuid}
            cartCheck={getPrimaryCheckFromOrder(cartsMap.get(cartGuid)?.order)}
            memberName={getFilteredMemberName(
              sortedMembers,
              { partyMemberGuid: partyMemberGuid, name: name },
              me
            )}
            editable={!listOnly && cartGuid === me.cartGuid}
            isInParty={sortedMembers.length > 1}
          />
        </div>
      ))}
    </>
  )
}

const MembersShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  cartGuid: PropTypes.string.isRequired,
  partyMemberGuid: PropTypes.string.isRequired
})

PartyCartsView.propTypes = {
  carts: PropTypes.arrayOf(PropTypes.object),
  members: PropTypes.arrayOf(MembersShape),
  me: PropTypes.object,
  listOnly: PropTypes.bool
}
