import * as React from 'react'
import { Shelf, authContext$ } from 'cornucopia-apis'
import { distinctUntilChanged } from 'rxjs/operators'
import { getAuthStorage, authStorageKeys } from './auth-storage'
import { EXPIRATION_DURATION } from '../../utils/restaurant-storage'

import {
  addEventProperties as heapAddEventProperties,
  analyticsIdentify as heapIdentify,
  analyticsResetIdentity as heapResetIdentity
} from '@toasttab/do-secundo-analytics'
import {
  useExpiringStateWithNamespacedStorage,
  useStateWithNamespacedStorage
} from '../../utils/namespaced-storage'
import { useGuestInfo } from '../../hooks/use-guest-info'
import omit from 'lodash/omit'
import { ToastNotificationContainer } from '../ToastNotificationContainer/ToastNotificationContainer'
import { AuthClient } from '@toasttab/guest-authentication-js'

const initialUnverifiedCustomer = Object.freeze({
  guid: null,
  signupSource: null,
  email: null
})

const initialRecognizedCustomer = Object.freeze({
  email: null
})

export const authContextDefaultValues: AuthContextType = {
  error: null,
  loading: true,
  user: null,
  authenticated: false,
  authClient: null, // Authentication Library Client from MU used only for Passwordless Authentication
  setIsAuthenticated: () => {}, // call this function to cause an app rerender. Needed after calling authClient methods that change logged in/out status
  startPasswordlessLogin: () => {}, // client only mutation to start login
  confirmPasswordlessLogin: () => {}, // client only mutation to confirm code
  completePasswordlessLogin: () => {}, // client only mutation to create/complete profile
  cancelPasswordlessLogin: () => {}, // client only mutation to cancel login
  cancelPasswordlessLoginAndKeepLegacy: () => {}, // client only mutation to cancel login
  passwordlessLogin: () => {}, // client only mutation to handle passwordless login
  passwordlessLogout: () => {} // client only mutation to handle passwordless logout
}
export interface AuthContextType {
  error: null
  loading?: boolean
  user: any | null
  authenticated: boolean
  authClient: AuthClient | null
  setIsAuthenticated: () => void
  startPasswordlessLogin: () => void
  confirmPasswordlessLogin: () => void
  completePasswordlessLogin: () => void
  cancelPasswordlessLogin: () => void
  cancelPasswordlessLoginAndKeepLegacy: () => void
  passwordlessLogin: () => void
  passwordlessLogout: () => void
}

interface AuthProviderPasswordlessParams {
  children: React.ReactNode
}

export const AUTH_NOTIFICATION_CONTAINER_ID = 'auth'

export const AuthContext: React.Context<AuthContextType> = React.createContext(
  authContextDefaultValues
)
const authStorage = getAuthStorage()
export const AuthProviderPasswordless = ({
  children
}: AuthProviderPasswordlessParams) => {
  const [context, setContext] = React.useState<AuthContextType>(
    authContextDefaultValues
  )
  const [, setUnverifiedCustomerValue] = useStateWithNamespacedStorage(
    authStorageKeys.UNVERIFIED_CUSTOMER,
    initialUnverifiedCustomer,
    authStorage
  )
  const [, setRecognizedCustomerValue] = useExpiringStateWithNamespacedStorage(
    authStorageKeys.RECOGNIZED_CUSTOMER,
    initialRecognizedCustomer,
    authStorage,
    EXPIRATION_DURATION
  )

  const { updateGuestInfoFromCustomer } = useGuestInfo()

  const clearUnverifiedCustomer = React.useCallback(() => {
    setUnverifiedCustomerValue(initialUnverifiedCustomer)
    updateGuestInfoFromCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUnverifiedCustomerValue])

  const setRecognizedCustomer = React.useCallback(
    (newState) => {
      if (!newState.email) {
        console.error('setRecognizedCustomer must include an email')
      }

      setRecognizedCustomerValue(newState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRecognizedCustomerValue]
  )

  const clearRecognizedCustomer = React.useCallback(() => {
    setRecognizedCustomerValue(initialRecognizedCustomer)
  }, [setRecognizedCustomerValue])

  React.useEffect(() => {
    const authContextSubscription = authContext$
      .pipe(distinctUntilChanged())
      .subscribe((nextContext: any) => {
        setContext({ ...nextContext })
      })

    if (heapAddEventProperties) {
      heapAddEventProperties({
        passwordless: true
      })
    }

    return () => {
      authContextSubscription.unsubscribe()
    }
    /* eslint-ignore react-hooks/exhaustive-deps */
  }, [])

  React.useEffect(() => {
    if (context.authenticated) {
      clearUnverifiedCustomer()
      clearRecognizedCustomer()
    }
  }, [context.authenticated, clearUnverifiedCustomer, clearRecognizedCustomer])

  React.useEffect(() => {
    if (!context.user) {
      clearRecognizedCustomer()
      updateGuestInfoFromCustomer()
      heapResetIdentity()
      return
    }
    const safeUser = omit(context.user, ['creditCards'])
    setRecognizedCustomer(safeUser)
    updateGuestInfoFromCustomer(safeUser)
    heapIdentify(context.user.guid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.user?.guid])

  return (
    <>
      <Shelf name='corn-guest-auth-spa' />
      <AuthContext.Provider value={context}>
        {children}
        {/** add specific auth notification container as it needs to live outside of page routing */}
        <ToastNotificationContainer
          containerId={AUTH_NOTIFICATION_CONTAINER_ID}
        />
      </AuthContext.Provider>
    </>
  )
}

export const useAuthPasswordless = () => React.useContext(AuthContext)
