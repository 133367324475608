import trimEnd from 'lodash/trimEnd'
import { ApolloClient, ApolloLink } from '@apollo/client'
import { InMemoryCache, defaultDataIdFromObject } from '@apollo/client/cache'

import { getAuthLink } from './authentication/authentication-helpers'
import { authenticationResolvers } from './authentication/authentication-resolvers'
import getHttpLink from './links/http-link'
import getErrorLink from './links/error-link'
import PossibleTypesResult from '../apollo/generated/PossibleTypes'
import { GET_ACTIVE_MENU, menuResolvers } from './menu/menu-resolvers'
import { cartResolvers } from './cart/cart-resolvers'
import { creditCardsPolicy } from './credit-cards/credit-cards-policy'
import { getCornAuthLink, getAuthorizationHeaderLink } from 'cornucopia-apis'

import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'

export const defaultData = {
  query: GET_ACTIVE_MENU,
  data: {
    activeMenu: null
  }
}

const doNotCacheThisType = () => null
const dataIdFromObjectMap = {
  CompletedOrder: defaultDataIdFromObject,
  Menu: defaultDataIdFromObject,
  MenuItem: (object) => `${object.guid}${object.itemGroupGuid || ''}MenuItem`,
  MenuItemV2: (object) =>
    `${object.guid}${object.itemGroupGuid || ''}MenuItemV2`,
  SelectionItemV2: (object) =>
    `${object.guid}${object.itemGroupGuid || ''}SelectionItemV2`,
  CompletedOrderSelection: (object) =>
    `${object.guid}${object.itemGroupGuid || ''}CompletedOrderSelection`,
  ItemTag: (object) => object.guid,
  SelectionItem: doNotCacheThisType,
  SelectionModifierGroup: doNotCacheThisType,
  SelectionModifier: doNotCacheThisType,
  ModifierGroup: doNotCacheThisType,
  Modifier: doNotCacheThisType,
  // CartResponse and Cart share a GUID, so we need a way
  // to differentiate the two responses
  CartResponse: (object) => object.cart.guid + object.__typename,
  AddressSuggestion: (object) => `${object.latitude}+${object.longitude}`,
  LoyaltyDiscount: doNotCacheThisType,
  LoyaltyRedemption: (object) => object.redemptionGuid,
  OPTPartyRefresh: (object) =>
    (object.party?.guid ?? 'NO_GUID') + object.__typename,
  OPTParty: (object) => object.guid + object.__typename,
  OPTPartyV2: (object) => object.guid + object.__typename,
  // handles duplicate bucket guids, but with different owners
  OPTPaymentBucket: doNotCacheThisType,
  OPTPartyMember: (object) => object.partyMemberGuid + object.__typename,
  OPTPartyMemberV2: (object) => object.partyMemberGuid + object.__typename,
  OPTPartyStub: (object) => `${object.guid}${object.__typename}`,
  _default: (object) => object.guid || null
}

export const dataIdFromObject = (object) => {
  const idMapper =
    dataIdFromObjectMap[object.__typename] || dataIdFromObjectMap._default
  return idMapper(object)
}

export const cache = new InMemoryCache({
  dataIdFromObject,
  possibleTypes: PossibleTypesResult.possibleTypes,
  typePolicies: {
    ...creditCardsPolicy
  }
})
cache.writeQuery(defaultData)

export const resolvers = [cartResolvers, authenticationResolvers, menuResolvers]

export const config = {}
config.cache = cache

const ddiGlobals = window.OO_GLOBALS || {}
const env = envFromOrderHostname(window.location.hostname)
// TODO Need to change this to support sandbox once the BFF can be deployed there.
const prefix = env === 'prod' ? 'ws-api' : 'ws-preprod-api.eng'

config.GATEWAY_BASE_URI = trimEnd(
  process.env.GATEWAY_BASE_URI ||
    ddiGlobals.gatewayBaseUri ||
    `https://${prefix}.toasttab.com`,
  '/'
)
config.BFF_BASE_URI = trimEnd(
  process.env.BFF_BASE_URI ||
    ddiGlobals.bffBaseUri ||
    `${config.GATEWAY_BASE_URI}/opt-bff/v1`,
  '/'
)
config.OO_BASE_URI = trimEnd(
  process.env.OO_BASE_URI ||
    ddiGlobals.ooBaseUri ||
    `${config.GATEWAY_BASE_URI}/online-ordering/v1`,
  '/'
)
config.RESTAURANT_GUID = ddiGlobals.restaurantGuid || ''

const linkFactories = [
  getErrorLink,
  getCornAuthLink,
  getAuthLink,
  getAuthorizationHeaderLink,
  getHttpLink
]

const getApolloClientOptionsLink = (apolloLinks) => {
  return ApolloLink.from(apolloLinks.map((fn) => fn(config)))
}

const apolloClientOptions = {
  link: getApolloClientOptionsLink(linkFactories),
  cache: config.cache,
  resolvers,
  name: process.env.PKG_NAME,
  version: process.env.PKG_VERSION
}

config.client = new ApolloClient(apolloClientOptions)
config.client.onResetStore(() => {
  cache.writeQuery(defaultData)
})

export const apolloClient = config.client
