import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { NetworkStatus } from '@apollo/client'
import IntersectionObserver from '@researchgate/react-intersection-observer'
import cx from 'classnames'
import { ToastContainer } from 'react-toastify'

import { CheckBucketsView } from '../CheckBucketsView/CheckBucketsView'
import { PartyCartsMode } from '../PartyCarts'
import CloseButton from '../CloseButton/CloseButton'
import TabPromptCartView from '../TabPromptCartView/TabPromptCartView'
import { TabCartPageHeader } from './TabCartPageHeader'
import { CloseOrderTabLink } from '../CloseOrderTabLink/CloseOrderTabLink'
import { useScrollToTop } from '../ScrollToTop/ScrollToTop'
import {
  useGetAllChecks,
  useGetMainCheck,
  useGetPartyCarts,
  useGetPartyMembers,
  useGetPartyMode,
  useGetPartyRefresh,
  useGetServerExclusivelySplitCheck,
  useGetSortedMemberAndServerBucketsFromCheck
} from '../PartyQuery/PartyQuery'
import { combineChecksAsBucket } from '../../utils/check-helpers'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import {
  doBucketsHaveSelections,
  doCartsHaveSelections
} from '../../utils/cart-helpers'
import { HeaderText } from '@/il8n/en'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { CartUpsells } from '../CartUpsells/CartUpsells'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { SplitMode } from '../../apollo/generated/OptWebGraphQLOperations'
import { useAvailability } from '../../utils/availability'

import styles from './TabCartPage.module.css'
import Progress from '../Progress/Progress'
import { useBrandingStore } from '../../utils/branding-ads-helpers'
import { useIsIntlRestaurant } from '../../hooks/use-is-intl-restaurant'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { RestaurantHeaderV2 } from '../RestaurantHeaderV2/RestaurantHeaderV2'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'

const { toastContainer, toastWrapper } = styles

const TabCartPage = () => {
  useScrollToTop()
  const isIntlRx = useIsIntlRestaurant()
  const { loading: cartsLoading, networkStatus, carts } = useGetPartyCarts()
  const { allChecks } = useGetAllChecks()
  const { partyRefresh } = useGetPartyRefresh()
  const { mainCheck, loading: checkLoading } = useGetMainCheck()
  const { getRestaurantPath } = useRestaurant()
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)
  const { members } = useGetPartyMembers()
  const isGroupOrder = members.length > 1
  const groupCartsHaveItems = doCartsHaveSelections(carts)
  const hasTab = Boolean(mainCheck)
  const { serverBuckets, sortedMemberBuckets, me } =
    useGetSortedMemberAndServerBucketsFromCheck(mainCheck)
  const { menuAvailable } = useAvailability()
  const [showFooter, setShowFooter] = useState(hasTab)
  const combinedChecksBucket = combineChecksAsBucket(me, allChecks)
  const isSplitEven =
    partyRefresh?.splitPaymentData?.splitMode === SplitMode.Even
  const onlyServerItems =
    serverBuckets.length > 0 && !doBucketsHaveSelections(sortedMemberBuckets)
  const showCombinedChecks =
    combinedChecksBucket && (isSplitEven || onlyServerItems)
  const checkText = isGroupOrder
    ? HeaderText.GROUP_CHECK
    : HeaderText.YOUR_CHECK
  const orderText = hasTab
    ? HeaderText.REVIEW_ORDER
    : HeaderText.START_TAB_PAY_LATER
  const isServerSplitCheck = useGetServerExclusivelySplitCheck()
  const { mode } = useGetPartyMode()
  const isPayAndGoEnabled = useFlag(LDFlags.OPT_PAY_AND_GO) && mode === 'MNP'
  const { data } = useRestaurantInfo()
  const { uniqueIdentifier } = useDDIGlobals()

  const getEvaluatedCheckBucket = () => {
    // If mode is StP and serverBuckets exist, we should be combining the buckets on the mainCheck
    // This accounts for scenarios where bill is split by server into 4s and a check wants to pay for 2s
    // Split even is included here because mainCheck buckets should be displayed when bill is split by server only
    // Split even maintains its original functionality in displaying the full combined check like on the receipt
    if (isServerSplitCheck && serverBuckets) {
      return combineChecksAsBucket(me, mainCheck)
    }
    return showCombinedChecks ? combinedChecksBucket : sortedMemberBuckets
  }

  // storing brand from the url query to show ad if applicable on future pages
  useBrandingStore().set()

  if (cartsLoading || checkLoading || networkStatus === NetworkStatus.refetch) {
    return <Progress />
  }

  if (!groupCartsHaveItems && !hasTab && !isPayAndGoEnabled) {
    return <Redirect to={getRestaurantPath('/')} />
  }

  return (
    <div
      style={
        isIntlRx
          ? { minHeight: 'calc(var(--vh100))' }
          : { minHeight: 'calc(var(--vh100) - var(--topHeaderHeight))' }
      }
      className={styles.cartPage}
    >
      {isPayAndGoEnabled ? (
        <>
          <div data-testid='banner-header-banner'>
            <RestaurantHeaderV2
              logoUrl={data?.logoUrls?.small}
              bannerUrl={data?.bannerUrls?.raw}
              restaurantName={data?.whiteLabelName}
              tableName={uniqueIdentifier}
            />
          </div>
          {!hasTab && (
            <div data-testid='pay-and-go-no-check' className='pt-4'>
              <p className='text-secondary px-6 text-center pt-4'>
                Place an order with your server. Come back here to see your
                balance or pay the check.
              </p>
            </div>
          )}
        </>
      ) : (
        <TabCartPageHeader />
      )}

      <div className={styles.long}>
        {groupCartsHaveItems && (
          <>
            <div className={tabsRedesignOn ? styles.cartNoBorder : styles.cart}>
              {tabsRedesignOn ? null : (
                <div
                  className={cx('type-large font-bold pb-6', styles.cartHeader)}
                  data-testid='list-header'
                >
                  {orderText}
                </div>
              )}
              <TabPromptCartView
                mode={
                  hasTab ? PartyCartsMode.ADD_TO_TAB : PartyCartsMode.START_TAB
                }
              />
            </div>
          </>
        )}
        {hasTab && (
          <>
            {groupCartsHaveItems &&
              (tabsRedesignOn ? (
                <div className='mt-8 text-center type-headline-4'>
                  {checkText}
                </div>
              ) : (
                <div className={cx('type-large font-bold', styles.tabHeader)}>
                  {HeaderText.PREVIOUS_ORDERS}
                </div>
              ))}
            <IntersectionObserver
              rootMargin={'0px 0px -64px 0px'}
              onChange={({ isIntersecting }) => setShowFooter(isIntersecting)}
            >
              <div className='mx-6 mt-4 mb-8' data-testid='check-bucketview'>
                {me ? (
                  <CheckBucketsView
                    me={me}
                    buckets={getEvaluatedCheckBucket()}
                    serverBuckets={showCombinedChecks ? [] : serverBuckets}
                    enableReorder
                    splitPaymentMethod={SplitPaymentMethod.PAY_FOR_PARTY}
                    checksCollapsible
                    showAddMore={false}
                    showSubtotals
                    displayUnpricedMods={false}
                  />
                ) : null}
              </div>
            </IntersectionObserver>
          </>
        )}
      </div>
      {hasTab && (
        <div className='mx-4 mb-4'>
          <CartUpsells />
        </div>
      )}

      <div
        className={cx('p-4', styles.sticky, {
          [styles.hidden]: !showFooter
        })}
      >
        {mainCheck ? (
          <CloseOrderTabLink
            testId={'close-tab-button'}
            mainCheck={mainCheck}
            variantOverride={groupCartsHaveItems ? 'secondary' : undefined}
          />
        ) : null}
      </div>
      {menuAvailable && !isPayAndGoEnabled && (
        <div className={styles.closeButton}>
          <CloseButton
            data-testid='close-button-tab-cart-page'
            to={{
              pathname: getRestaurantPath(),
              search: ''
            }}
            onClick={undefined}
            children={undefined}
          />
        </div>
      )}
      <ToastContainer
        enableMultiContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position={'top-center'}
      />
    </div>
  )
}
TabCartPage.propTypes = {}

export default TabCartPage
