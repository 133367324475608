import React from 'react'
import cx from 'classnames'
import { NetworkStatus } from '@apollo/client'
import { Redirect } from 'react-router'

import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import Link from '../Link/Link'
import CloseButton from '../CloseButton/CloseButton'
import { useGetTab } from '../TabQuery/TabQuery'
import { CloseOrderTabLink } from '../CloseOrderTabLink/CloseOrderTabLink'
import { useAuth } from '../AuthProvider/AuthProvider'
import { ModeLink } from '../ModeRouter/ModeRouter'
import { CheckTable } from '../OrderTable/CheckTable'
import Progress from '../Progress/Progress'

import { HeaderText, CTA } from '@/il8n/en'
import styles from './ConfirmTabOpened.module.css'
import {
  OptCheckV2GuidFragment,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useParty } from '../PartyProvider/PartyProvider'
import { Confirmation } from '../Confirmation/Confirmation'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import {
  useGetAllChecks,
  useGetPartyMembers,
  useGetPartyRefresh,
  useGetSortedMemberAndServerBucketsFromCheck
} from '../PartyQuery/PartyQuery'
import { CheckBucketsView } from '../CheckBucketsView/CheckBucketsView'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { combineChecksAsBucket } from '../../utils/check-helpers'

interface ConfirmTabWrapperProps {
  loading: boolean
  body: React.ReactNode
  footer: React.ReactNode
}

const ConfirmTabWrapper: React.FC<ConfirmTabWrapperProps> = ({
  loading,
  body,
  footer
}) => {
  return (
    <>
      <div className={styles.confirmPage}>
        <ScrollToTop />
        {loading ? <Progress /> : body}
      </div>
      <div className={styles.sticky}>{loading ? null : footer}</div>
    </>
  )
}

interface ConfirmTabBodyProps {
  partyMemberGuid: string | null
  authenticated: boolean
  check: OptCheckV2GuidFragment | null
  closePath: string
}

export const ConfirmTabBody = ({
  partyMemberGuid,
  authenticated,
  check,
  closePath
}: ConfirmTabBodyProps) => {
  const tabsRedesignEnabled = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)
  const { serverBuckets, sortedMemberBuckets, me } =
    useGetSortedMemberAndServerBucketsFromCheck(check)
  const { allChecks } = useGetAllChecks()
  const { members } = useGetPartyMembers()
  const { partyRefresh } = useGetPartyRefresh()
  const combinedChecksBucket = me ? combineChecksAsBucket(me, allChecks) : null
  const isGroupOrder = members.length > 1
  const isSplitEven =
    partyRefresh?.splitPaymentData?.splitMode === SplitMode.Even
  const orderConfirmationHeader = isGroupOrder
    ? HeaderText.GROUP_ORDER_PLACED
    : HeaderText.ORDER_PLACED
  const checkHeader = isGroupOrder
    ? HeaderText.GROUP_CHECK
    : HeaderText.YOUR_CHECK

  if (!partyMemberGuid || !check) {
    return null
  }

  return (
    <>
      <div className={styles.main}>
        {tabsRedesignEnabled ? (
          <div className='px-4'>
            <Confirmation
              headerCopy={orderConfirmationHeader}
              bodyCopy={HeaderText.ORDER_MORE_TAB}
            />
          </div>
        ) : (
          <div>
            <h4 data-testid='order-sent-header' className={styles.heading}>
              {HeaderText.ORDER_PLACED}
            </h4>
            <p
              data-testid='guest-communication-text'
              className={styles.guestCommunication}
            >
              <strong>
                {HeaderText.KITCHEN_ITEMS_RECEIVED}
                <br />
              </strong>
              {HeaderText.DONT_FORGET_TO_PAY}
            </p>
          </div>
        )}
        <div className={styles.closeButton}>
          <CloseButton
            data-testid='close-button-tab-page'
            to={closePath}
            onClick={undefined}
            children={undefined}
          />
        </div>
        <div className={styles.actions}>
          <Link
            type='link'
            variant={
              tabsRedesignEnabled ? 'secondaryWideButton' : 'primaryWide'
            }
            to={closePath}
            data-testid='order-more-items-confirm-tab-opened'
          >
            {CTA.ORDER_MORE}
          </Link>
          {!authenticated && !tabsRedesignEnabled && (
            <div className={styles.loginPrompt}>
              Have an account?{' '}
              <ModeLink
                mode='login'
                state={undefined}
                data-testid='confirm-tab-open-login-link'
              >
                {CTA.LOG_IN}
              </ModeLink>
            </div>
          )}
        </div>
        <div className='mt-14'>
          {tabsRedesignEnabled ? (
            <>
              <div className='flex justify-center mb-8'>
                <h4 className='font-normal type-header-4'>{checkHeader}</h4>
              </div>
              <CheckBucketsView
                me={me!}
                buckets={
                  isSplitEven && combinedChecksBucket
                    ? combinedChecksBucket
                    : sortedMemberBuckets
                }
                serverBuckets={isSplitEven ? [] : serverBuckets}
                splitPaymentMethod={SplitPaymentMethod.PAY_FOR_PARTY}
                checksCollapsible
                showAddMore={false}
                showSubtotals
                displayUnpricedMods={false}
              />
            </>
          ) : (
            <>
              <div className={cx('type-large', styles.tabHeader)}>
                Your tab ({check.numberOfSelections})
              </div>
              <CheckTable check={check} partyMemberGuid={partyMemberGuid} />
            </>
          )}
        </div>
      </div>
    </>
  )
}

interface ConfirmTabFooterProps {
  check: OptCheckV2GuidFragment | null
}

export const ConfirmTabFooter = ({ check }: ConfirmTabFooterProps) => {
  if (!check) {
    return null
  }

  return <CloseOrderTabLink mainCheck={check} testId='close-tab-button' />
}

export const ConfirmTabOpened = () => {
  const { getRestaurantPath } = useRestaurant()
  const {
    loading: checkLoading,
    combinedCheck,
    primaryCheck,
    networkStatus
  } = useGetTab()
  const { partyMemberGuid } = useParty()
  const { authenticated } = useAuth()

  if (
    networkStatus === NetworkStatus.ready &&
    !combinedCheck &&
    !primaryCheck
  ) {
    return <Redirect to={getRestaurantPath()} />
  }

  return (
    <ConfirmTabWrapper
      loading={checkLoading}
      body={
        <ConfirmTabBody
          authenticated={authenticated}
          check={combinedCheck ?? null}
          closePath={getRestaurantPath()}
          partyMemberGuid={partyMemberGuid ?? null}
        />
      }
      footer={<ConfirmTabFooter check={primaryCheck ?? null} />}
    />
  )
}
