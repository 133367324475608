import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useGetTab } from '../TabQuery/TabQuery'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useHistory } from 'react-router'
import Dollars from '../Dollars/Dollars'
import Loading from '../Loading/Loading'
import { doesCheckHaveItems } from '../../utils/cart-helpers'

import styles from './OpenCheckLinkWithSubtotal.module.css'
import { CTA } from '@/il8n/en'

export const OpenCheckLinkWithSubtotal = ({ hideSubtotal = false }) => {
  const { loading, primaryCheck } = useGetTab()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()

  const tabHasItems = doesCheckHaveItems(primaryCheck)

  const goToTab = useCallback(() => {
    return history.push(getRestaurantPath('/tab'))
  }, [history, getRestaurantPath])

  return (
    Boolean(primaryCheck) &&
    tabHasItems && (
      <button type='button' className={styles.container} onClick={goToTab}>
        {loading ? (
          <Loading variant='primary' />
        ) : (
          <div>
            <div className={styles.header}>
              {hideSubtotal
                ? CTA.NAV_CHECK_SUBTOTAL_HIDDEN
                : CTA.NAV_CHECK_SUBTOTAL_VISIBLE}
            </div>
            {!hideSubtotal && (
              <div>
                <Dollars amount={primaryCheck.total} />
              </div>
            )}
          </div>
        )}
      </button>
    )
  )
}

OpenCheckLinkWithSubtotal.propTypes = {
  hideSubtotal: PropTypes.bool
}
