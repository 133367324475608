import React from 'react'
import PropTypes from 'prop-types'

import styles from './FractionalQuantity.module.css'

export const FractionalQuantity = ({ unitOfMeasure, quantity }) => {
  const displayUOM = unitOfMeasure.toLowerCase()

  return (
    <div
      id='cart-item-fractional-quantity'
      data-testid='cart-item-fractional-quantity'
    >
      <span className={styles['fractional-quantity']}>
        {quantity} {displayUOM}
      </span>
    </div>
  )
}

FractionalQuantity.propTypes = {
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  unitOfMeasure: PropTypes.oneOf(['LB', 'OZ', 'KG', 'G'])
}

FractionalQuantity.defaultProps = {
  unitOfMeasure: ''
}
