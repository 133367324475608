import { CheckoutMode } from '../../../components/CheckoutForm/checkout-modes'
import { Maybe } from 'graphql/jsutils/Maybe'
import { OptCheckV2Fragment } from '../../../apollo/generated/OptWebGraphQLOperations'
import {
  CheckLineItem,
  GetCheckLineItemsParams
} from '../../../utils/check-helpers'

type ApplePayStrategyResult = {
  amountToPay: string | undefined
  lineItems: CheckLineItem[]
}

type PickedLineItemsInput = Pick<
  GetCheckLineItemsParams,
  'check' | 'tip' | 'totalMode' | 'memberPayments'
>

export default class ApplePayStrategy {
  private static strategies = new Map([
    [CheckoutMode.CREATE_PREAUTH_TAB_MODE, ApplePayStrategy.preauthStrategy],
    [CheckoutMode.CLOSE_PARTY_TAB_MODE, ApplePayStrategy.checkoutStrategy],
    [CheckoutMode.CREATE_TAB_MODE, ApplePayStrategy.checkoutStrategy],
    [CheckoutMode.PLACE_PAYG_ORDER_MODE, ApplePayStrategy.checkoutStrategy],
    [CheckoutMode.CLOSE_PREAUTH_TAB_MODE, ApplePayStrategy.checkoutStrategy]
  ])

  // visible for testing
  static preauthStrategy(): ApplePayStrategyResult {
    return {
      amountToPay: '0.01',
      lineItems: []
    }
  }

  // visible for testing
  static checkoutStrategy(
    check: Maybe<OptCheckV2Fragment>,
    tipAmount: number = 0.0,
    getLineItems: (a: PickedLineItemsInput) => CheckLineItem[]
  ): ApplePayStrategyResult {
    if (!check) {
      throw new Error(
        'Unable to calculate Apple Pay payment amount without a Check.'
      )
    }
    const lineItems = getLineItems({
      check,
      tip: tipAmount,
      totalMode: 'DUE'
    })
    return {
      amountToPay: lineItems
        .find((li) => li.id === 'total-order-amount')
        ?.amount.toFixed(2),
      lineItems
    }
  }

  static getApplePayStrategy(
    mode: CheckoutMode
  ): (
    check: Maybe<OptCheckV2Fragment>,
    tipAmount: number | undefined,
    getLineItems: (a: PickedLineItemsInput) => CheckLineItem[]
  ) => ApplePayStrategyResult {
    return (
      ApplePayStrategy.strategies.get(mode) ?? ApplePayStrategy.checkoutStrategy
    )
  }
}
