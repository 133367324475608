import * as React from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router'
import { CollapsibleList } from '@toasttab/do-secundo-collapsible-list'
import {
  CheckSelectionFragment,
  OptCheckV2Fragment,
  SplitMode
} from '../../../apollo/generated/OptWebGraphQLOperations'
import {
  useGetMemberPayments,
  useGetPartyMember,
  useGetPartyRefresh
} from '../../PartyQuery/PartyQuery'
import { useRestaurant } from '../../RestaurantProvider/RestaurantProvider'
import { EditComponentProps } from '../CheckTable'
import { SelectionCard } from './SelectionCard/SelectionCard'
import styles from './CheckTableV2.module.css'
import { SubtotalDisplay } from './SubtotalDisplay/SubtotalDisplay'
import { DividerLine } from './SelectionCard/DividerLine'
import {
  SplitEvenSubtotal,
  useSplitEvenSubtotalProps
} from '../../SplitEvenSubtotal/SplitEvenSubtotal'

const getKey = (selection: CheckSelectionFragment) => {
  const persistedSelectionGuid =
    selection.__typename === 'CheckSelectionGuid' ? selection.guid : undefined

  const nonPersistedSelectionExternalId =
    selection.__typename === 'CheckSelectionNoGuid'
      ? selection.externalId
      : undefined

  return persistedSelectionGuid || nonPersistedSelectionExternalId
}

export interface CheckTableV2Props {
  check: Pick<OptCheckV2Fragment, 'selections' | 'numberOfSelections'>
  memberName?: string
  partyMemberGuid?: string
  isInParty?: boolean
  editComponent?: React.ComponentType<EditComponentProps>
  checksCollapsible: boolean
  showAddMore?: boolean
  showSubtotals?: boolean
  displayUnpricedMods?: boolean
  hasBorder?: boolean
  showMyEmptyCheck?: boolean
}

export const CheckTableV2 = ({
  check,
  memberName,
  partyMemberGuid,
  isInParty = false,
  editComponent,
  checksCollapsible,
  showAddMore = true,
  showSubtotals = false,
  displayUnpricedMods = false,
  hasBorder = true,
  showMyEmptyCheck = false
}: CheckTableV2Props) => {
  const { partyRefresh } = useGetPartyRefresh()
  const isSplitEven =
    partyRefresh?.splitPaymentData?.splitMode === SplitMode.Even

  const showSplitEvenBreakdown = useSplitEvenSubtotalProps()

  const showCheck = Boolean(check?.numberOfSelections)
  const isMe =
    useGetPartyMember()?.partyMemberGuid === partyMemberGuid &&
    Boolean(partyMemberGuid)

  const showCheckOrAddMore = showCheck || (isMe && showAddMore)
  const showPartyChecks = isInParty && showCheckOrAddMore
  const showNonGroupCheck = showCheck && !isInParty

  const memberPayments = useGetMemberPayments()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()

  const goToMenu = React.useCallback(
    () => history.push(getRestaurantPath()),
    [history, getRestaurantPath]
  )

  // since members cannot have a mix of paid/unpaid items in their bucket, we know that if a member has paid, they've paid for everything.
  // BUT we don't want to display items as paid if the check has been split evenly
  const hasMemberPaid = React.useMemo(() => {
    return (
      memberPayments.some((member) => member.memberGuid === partyMemberGuid) &&
      !isSplitEven
    )
  }, [isSplitEven, memberPayments, partyMemberGuid])

  const { selections } = check || {}

  const nonVoidedSelections = selections.filter(
    (selection) => !selection.voided
  )

  if (!selections) {
    return null
  }

  const showSubtotalDisplay = Boolean(selections.length && showSubtotals)
  const selectionCards = nonVoidedSelections.map((selection, index) => {
    if (!selection) {
      return null
    }

    const isLast = index === nonVoidedSelections.length - 1
    return (
      <React.Fragment key={getKey(selection) || index}>
        <SelectionCard
          data-testid={`selection-${partyMemberGuid}-${index}`}
          EditComponent={editComponent}
          selection={selection}
          isPaid={hasMemberPaid}
          displayUnpricedModifiers={displayUnpricedMods && isMe}
          hasBorderPadding={hasBorder}
        />
        {!isLast && hasBorder && <DividerLine />}
      </React.Fragment>
    )
  })

  const subtotalDisplay = showSubtotalDisplay ? (
    <>
      <DividerLine />
      {showSplitEvenBreakdown ? (
        <div className='bg-gray-25 p-4'>
          <SplitEvenSubtotal {...showSplitEvenBreakdown} />
        </div>
      ) : (
        <SubtotalDisplay selections={nonVoidedSelections} />
      )}
    </>
  ) : null

  return (
    <div
      className={cx(
        (showCheck || (isMe && showMyEmptyCheck)) && hasBorder
          ? 'border border-solid rounded-lg'
          : '',
        styles.border
      )}
    >
      {showPartyChecks && (
        <div data-testid={`ct-party-list-${partyMemberGuid}`}>
          <CollapsibleList
            header={
              <div className='flex justify-between w-full px-2 type-subhead font-bold'>
                {memberName}
                {hasMemberPaid && (
                  <div className='type-subhead text-color-success'>PAID</div>
                )}
              </div>
            }
            defaultCollapsed={checksCollapsible && !isMe}
            indentList={false}
            containerStyles=''
            backgroundColor='gray'
            isCollapsible={checksCollapsible}
          >
            {
              selections.length
                ? selectionCards
                : [
                    <div
                      className='flex flex-col items-center gap-1 p-4'
                      key={partyMemberGuid}
                    >
                      <div className='text-gray-75'>
                        You haven't added any items yet
                      </div>
                      <div
                        className='font-semibold text-primary-75'
                        onClick={goToMenu}
                      >
                        Add Items
                      </div>
                    </div>
                  ] // Collapsible list requires a list so have to do this
            }
            {subtotalDisplay}
          </CollapsibleList>
        </div>
      )}
      {showNonGroupCheck ? selectionCards : null}
      {Boolean(showNonGroupCheck && showSubtotalDisplay)
        ? subtotalDisplay
        : null}
    </div>
  )
}
