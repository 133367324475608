import React, { useEffect } from 'react'
import { NetworkStatus } from '@apollo/client'
import cx from 'classnames'

import { PartyCartsView } from './PartyCartsView'
import EmptyCart from '../EmptyCart/EmptyCart'
import Error from '../Error/Error'
import { CartModificationError } from '../Cart/CartModificationError/CartModificationError'
import Progress from '../Progress/Progress'
import { CartOrTabButton } from '../CartOrTabButton/CartOrTabButton'
import { useGetPartyMember, useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import { useParty } from '../PartyProvider/PartyProvider'
import { useGetTab } from '../TabQuery/TabQuery'
import { OpenCheckLinkWithSubtotal } from '../OpenCheckLinkWithSubtotal/OpenCheckLinkWithSubtotal'
import { PartyPlaceOrderButton } from '../PartyButtons/PartyPlaceOrderButton'
import { partyCartsHaveItems } from '../../utils/cart-helpers'
import Link from '../Link/Link'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { GuestsWithoutOrders } from '../GuestsWithoutOrders/GuestsWithoutOrders'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

import { CTA } from '@/il8n/en'
import styles from './PartyCarts.module.css'

export const PartyCartsMode = {
  START_TAB: 'startTab',
  ADD_TO_TAB: 'addToTab'
}

/**
 * Data controller to grab the cart for all of the members in a party.
 */
export const PartyCarts = ({ mode, listOnly, hideOpenCheck }) => {
  const { partyMemberGuid } = useParty()
  const me = useGetPartyMember(partyMemberGuid) || {}
  const {
    loading: partyLoading,
    networkStatus,
    refetch,
    error,
    partyRefresh
  } = useGetPartyRefresh()
  const { primaryCheck } = useGetTab()
  const isAddToTabMode = mode === PartyCartsMode.ADD_TO_TAB
  const { getRestaurantPath } = useRestaurant()
  const tabsRedesignEnabled = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)

  const {
    party: { members },
    carts
  } = partyRefresh || { party: { members: [] }, carts: [] }

  const hasItems = partyCartsHaveItems(carts)

  useEffect(() => {
    // refetch regardless of number of party members, in case a second person joins
    refetch()
  }, [refetch])

  if (
    partyLoading ||
    !me.partyMemberGuid ||
    networkStatus === NetworkStatus.refetch
  ) {
    return <Progress />
  }

  if (error) {
    return <Error error={error} retry={refetch} />
  }

  return (
    <div
      className={cx(styles.wrap, {
        [styles.compact]: listOnly || isAddToTabMode
      })}
    >
      <CartModificationError />
      <div className={styles.long}>
        {!carts || !carts.length ? (
          <EmptyCart />
        ) : (
          <PartyCartsView
            carts={carts}
            members={members}
            me={me}
            listOnly={listOnly}
          />
        )}
        {tabsRedesignEnabled && (
          <GuestsWithoutOrders me={me} carts={carts} partyMembers={members} />
        )}
      </div>
      {!listOnly && (
        <div className={styles.sticky}>
          <>
            {isAddToTabMode && !hideOpenCheck && (
              <div className={styles.openCheckLink}>
                <OpenCheckLinkWithSubtotal hideSubtotal />
              </div>
            )}
            {isAddToTabMode ? (
              hasItems ? (
                <PartyPlaceOrderButton />
              ) : (
                <div data-testid='order-more-items-party-carts'>
                  <Link
                    type='link'
                    variant='primaryWide'
                    to={getRestaurantPath('/')}
                  >
                    {CTA.ORDER_MORE}
                  </Link>
                </div>
              )
            ) : (
              Boolean(primaryCheck) && <CartOrTabButton />
            )}
          </>
        </div>
      )}
    </div>
  )
}
