import React, { useMemo, useState } from 'react'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { ToggleSwitch } from '@toasttab/buffet-pui-toggle-switches'

import { useSubmit_Guest_FeedbackMutation } from '../../apollo/generated/OptWebGraphQLOperations'
import Button from '../Button/Button'
import CheckButton from '../CheckButton/CheckButton'
import Error from '../Error/Error'
import { usePreauthEnabled } from '../../hooks/preauth/use-preauth-enabled'
import { useTabEnabled } from '../../hooks/tabs/useTabEnabled'
import { useJoinablePartyEnabled } from '../../hooks/party/use-party-enabled'
import LikeButtonSVG from '../../assets/feedback/like-button.svg'
import Progress from '../Progress/Progress'
import { CTA } from '@/il8n/en'
import { usePermalinkEnabled } from '../../hooks/party/use-permalink-enabled'
import { useCanMakeApplePayment } from '../../hooks/apple-pay/useCanMakeApplePayment'
import { useParty } from '../PartyProvider/PartyProvider'

import styles from './GuestFeedback.module.css'
import { useGuestFeedback } from '../../hooks/guest-feedback/use-guest-feedback'
import { useItemFeedbackSurveyEnabled } from '../ConfirmPage/ItemFeedbackSurveyWrapper/hooks'

const BUTTONS = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE'
}

const LikeButton = ({ selected, loading, onClick }) => {
  return (
    <Button onClick={onClick} disabled={selected || loading} aria-label='Like'>
      {selected ? (
        <LikeButtonSVG
          className={styles.likeSelected}
          data-testid='feedback-like-selected'
        />
      ) : (
        <LikeButtonSVG data-testid='feedback-like-unselected' />
      )}
    </Button>
  )
}

const DislikeButton = ({ selected, loading, onClick }) => {
  return (
    <Button
      onClick={onClick}
      disabled={selected || loading}
      aria-label='Dislike'
    >
      {selected ? (
        <LikeButtonSVG
          className={cx(styles.dislike, { [styles.dislikeSelected]: selected })}
          data-testid='feedback-dislike-selected'
        />
      ) : (
        <LikeButtonSVG
          className={styles.dislike}
          data-testid='feedback-dislike-unselected'
        />
      )}
    </Button>
  )
}

const getInitialValues = (tags) => ({
  feedbackText: '',
  feedbackContactPermission: false,
  ...tags.reduce((acc, t) => ({ ...acc, [t.name]: false }), {})
})

const GuestFeedback = ({ orderGuid }) => {
  const itemFeedbackSurveyEnabled = useItemFeedbackSurveyEnabled()
  const [
    submitInitialFeedback,
    {
      data: initialFeedbackData,
      error: feedbackError,
      loading: initialFeedbackLoading
    }
  ] = useSubmit_Guest_FeedbackMutation()
  const [
    submitDetailedFeedback,
    { data: detailedFeedbackData, error: detailedFeedbackError }
  ] = useSubmit_Guest_FeedbackMutation()

  const initialSelectedButton =
    initialFeedbackData?.submitGuestFeedback.feedbackRating
  const feedbackGuid = initialFeedbackData?.submitGuestFeedback.feedbackGuid
  const initialFeedbackSubmitted = Boolean(initialFeedbackData)
  const detailedFeedbackSubmitted = Boolean(detailedFeedbackData)
  const joinablePartiesEnabled = useJoinablePartyEnabled()
  const permalinkEnabled = usePermalinkEnabled()
  const tabEnabled = useTabEnabled()
  const preauthTabEnabled = usePreauthEnabled()
  const { completedPaymentType, partyGuid, partyMemberGuid } = useParty()
  const { canMakeApplePayPayment } = useCanMakeApplePayment()
  const {
    orderGuid: restaurantStorageOrderGuid,
    restaurantGuid,
    feedbackTagsFinal: tags
  } = useGuestFeedback()
  const feedbackMeta = [
    { key: 'tabEnabled', value: `${tabEnabled}` },
    { key: 'preauthTabEnabled', value: `${preauthTabEnabled}` },
    { key: 'joinablePartiesEnabled', value: `${joinablePartiesEnabled}` },
    { key: 'splitPaymentsEnabled', value: `${joinablePartiesEnabled}` },
    { key: 'permalinkEnabled', value: `${permalinkEnabled}` },
    { key: 'completedPaymentType', value: `${completedPaymentType}` },
    { key: 'applePayAvailable', value: `${canMakeApplePayPayment}` }
  ]
  const initialFeedbackValues = useMemo(() => getInitialValues(tags), [tags])

  const orderGuidFinal = orderGuid ?? restaurantStorageOrderGuid

  const selectRating = (feedbackRating) => {
    submitInitialFeedback({
      variables: {
        input: {
          orderGuid: orderGuidFinal,
          partyGuid,
          partyMemberGuid,
          restaurantGuid,
          feedbackRating,
          feedbackText: null,
          feedbackMeta: feedbackMeta,
          feedbackContactPermission: false,
          feedbackGuid
        }
      }
    })
  }

  const error = feedbackError || detailedFeedbackError

  const [contactPermissionActive, setContactPermissionActive] = useState(false)

  const thankYouRedesignStyles = 'type-headline-4 font-normal'

  if (itemFeedbackSurveyEnabled || !restaurantGuid || !orderGuidFinal) {
    return null
  }

  return (
    <>
      {error && <Error error={error} />}
      {!detailedFeedbackSubmitted ? (
        <div className={styles.guestFeedback}>
          <h5 className={cx('mt-8 mx-2 mb-4', thankYouRedesignStyles)}>
            How was your ordering experience using this website?
          </h5>
          <LikeButton
            selected={initialSelectedButton === BUTTONS.LIKE}
            onClick={() => selectRating(BUTTONS.LIKE)}
            loading={initialFeedbackLoading}
          />
          <DislikeButton
            selected={initialSelectedButton === BUTTONS.DISLIKE}
            loading={initialFeedbackLoading}
            onClick={() => selectRating(BUTTONS.DISLIKE)}
          />
          {initialFeedbackLoading ? (
            <Progress />
          ) : (
            initialFeedbackSubmitted && (
              <div className={cx(styles.feedbackForm, 'xs:w-auto mx-2')}>
                <div className='mt-5 mb-2'>
                  {initialSelectedButton === BUTTONS.LIKE ? (
                    <h5>What did we do well?</h5>
                  ) : (
                    <h5>What can we improve on?</h5>
                  )}
                </div>
                <Formik
                  initialValues={initialFeedbackValues}
                  onSubmit={(values) => {
                    const feedbackTags = tags
                      .map((tag) => tag.name)
                      .filter((tag) => values[tag])
                    const { feedbackText } = values
                    const feedbackContactPermission = contactPermissionActive
                    return submitDetailedFeedback({
                      variables: {
                        input: {
                          orderGuid: orderGuidFinal,
                          partyGuid,
                          partyMemberGuid,
                          restaurantGuid,
                          feedbackRating: initialSelectedButton,
                          feedbackTags,
                          feedbackText: feedbackText || null,
                          feedbackMeta: feedbackMeta,
                          feedbackContactPermission,
                          feedbackGuid
                        }
                      }
                    })
                  }}
                >
                  {({ isSubmitting }) => {
                    return (
                      <Form>
                        <div className={styles.tags}>
                          {tags.map((tag) => {
                            const id = `guest-feedback-tag-${tag.name}`
                            return (
                              <Field
                                data-testid={id}
                                key={id}
                                id={id}
                                name={tag.name}
                                component={CheckButton}
                                label={tag.label}
                              />
                            )
                          })}
                        </div>
                        <Field
                          data-testid='guest-feedback-text'
                          id='feedback-text'
                          name='feedbackText'
                          type='textarea'
                          label='Feedback'
                          className={styles.feedbackField}
                          maxLength={200}
                          rows={4}
                          component='textarea'
                          placeholder={
                            'This feedback will be sent to Toast, the ordering system, and is not shared with the restaurant.'
                          }
                        />
                        <div className={styles.contactContainer}>
                          <p>
                            Can our ordering system, Toast, reach out to you to
                            learn more about your experience?
                          </p>
                          <div>
                            <Field
                              data-testid='guest-feedback-contact-permission'
                              id='feedback-contact-permission'
                              name='feedbackContactPermission'
                              component={() => {
                                return (
                                  <div>
                                    <ToggleSwitch
                                      isActive={contactPermissionActive}
                                      size='xxs'
                                      onChange={() =>
                                        setContactPermissionActive(
                                          !contactPermissionActive
                                        )
                                      }
                                      data-testid='guest-feedback-contact-permission-toggle'
                                    />
                                    <span
                                      className={styles.contactPermissionLabel}
                                    >
                                      Toast can reach out to me.
                                    </span>
                                  </div>
                                )
                              }}
                            />
                          </div>
                        </div>
                        <Button
                          data-testid='guest-feedback-button-submit'
                          type='submit'
                          variant='primary'
                          responsive
                          loading={isSubmitting}
                        >
                          {CTA.GUEST_FEEDBACK_SUBMIT}
                        </Button>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            )
          )}
        </div>
      ) : (
        <div className={styles.guestFeedback}>
          <h4>Thank you for your feedback</h4>
          <p>
            We're always looking to improve the experience and your opinion is
            important to us.
          </p>
        </div>
      )}
    </>
  )
}

GuestFeedback.propTypes = {
  orderGuid: PropTypes.string
}

LikeButton.propTypes = {
  selected: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

DislikeButton.propTypes = LikeButton.propTypes

export default GuestFeedback
