import { useState, useEffect } from 'react'
import { useFlag } from '../../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'
import {
  canMakeApplePayPayment,
  canMakePaymentsWithActiveCard
} from './../../../utils/apple-pay/apple-pay-utils'

const canMakeApplePayPaymentValue = canMakeApplePayPayment()
const canMakePaymentsWithActiveCardPromise = canMakePaymentsWithActiveCard()

export const useCanMakeApplePayment = () => {
  const simulateApplePayEnabled = useFlag(LDFlags.OPT_SIMULATE_APPLE_PAY)
  const [loading, setLoading] = useState(true)
  const [canMakePaymentsWithActiveCard, setCanMakePaymentsWithActiveCard] =
    useState<boolean | undefined>(undefined)

  useEffect(() => {
    if (simulateApplePayEnabled) {
      setLoading(false)
      setCanMakePaymentsWithActiveCard(true)
    } else {
      canMakePaymentsWithActiveCardPromise.then((value) => {
        setLoading(false)
        setCanMakePaymentsWithActiveCard(value)
      })
    }
  }, [simulateApplePayEnabled])

  return {
    canMakeApplePayPayment:
      simulateApplePayEnabled || canMakeApplePayPaymentValue,
    canMakePaymentsWithActiveCard: {
      loading,
      canMakePaymentsWithActiveCard
    }
  }
}
