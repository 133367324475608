import {
  AddPreauthToPartyInput,
  OptCloseoutInput
} from '../../apollo/generated/OptWebGraphQLOperations'

export interface UpdateVarsProps {
  vars: {
    variables: {
      addPreauthAndFireInput: AddPreauthToPartyInput
    }
  }
  values: {
    paymentInfo: Pick<
      OptCloseoutInput,
      'pkPaymentToken' | 'newCardInput' | 'savedCardInput'
    >
  }
  preauthWithSavedCardEnabled?: boolean
}

export const updateVars = ({ vars, values }: UpdateVarsProps) => {
  const { paymentInfo } = values
  const updatedVars = {
    variables: {
      addPreauthAndFireInput: {
        ...vars.variables.addPreauthAndFireInput,
        savedCardInput: paymentInfo.savedCardInput
      }
    }
  }

  if (values.paymentInfo.pkPaymentToken) {
    updatedVars.variables.addPreauthAndFireInput.pkPaymentToken =
      values.paymentInfo.pkPaymentToken
  } else {
    updatedVars.variables.addPreauthAndFireInput.newCardInput =
      values.paymentInfo.newCardInput
  }

  return updatedVars
}
