import React from 'react'
import PropTypes from 'prop-types'

import { ModeLink } from '../../ModeRouter/ModeRouter'
import { useAuth } from '../../AuthProvider/AuthProvider'

import styles from './NavigationDrawerHeader.module.css'
import { CTA, HeaderText } from '@/il8n/en'
import { useFlag } from '../../FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'

const NavigationDrawerHeader = ({ onClose }) => {
  const auth = useAuth()
  const { user, authenticated } = auth
  const shouldDisableCreateAccount = useFlag(LDFlags.OPT_DISABLE_CREATE_ACCOUNT)
  const unAuthedExperience = shouldDisableCreateAccount
    ? {
        headerText: HeaderText.LOG_IN,
        subtext: CTA.LOG_IN_SECONDARY_TEXT,
        loginButtonStyle: 'primaryWide'
      }
    : {
        headerText: HeaderText.CREATE_ACCOUNT,
        subtext: CTA.CREATE_ACCOUNT_SECONDARY_TEXT,
        loginButtonStyle: 'secondaryWide'
      }

  if (authenticated) {
    return (
      <h2 data-testid='drawer-header-user-name' className={styles.header}>
        Hi{user.firstName ? ` ${user.firstName}` : ''}!
      </h2>
    )
  }

  return (
    <>
      <h2 data-testid='drawer-header' className={styles.header}>
        {unAuthedExperience.headerText}
      </h2>
      <p data-testid='drawer-text-after-header' className={styles.text}>
        {unAuthedExperience.subtext}
      </p>
      <div className={styles.flex}>
        <ModeLink
          data-testid='drawer-button-create-account'
          variant='primaryWide'
          onClick={onClose}
          mode='login'
        >
          {CTA.LOG_IN} &amp; {CTA.CREATE_ACCOUNT}
        </ModeLink>
      </div>
    </>
  )
}

NavigationDrawerHeader.propTypes = {
  onClose: PropTypes.func
}

export default NavigationDrawerHeader
