import React from 'react'
import { ToastContainer } from 'react-toastify'
import { Redirect } from 'react-router'

import CloseButton from '../CloseButton/CloseButton'
import Cart from '../Cart/Cart'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useScrollToTop } from '../ScrollToTop/ScrollToTop'
import { useGetTab } from '../TabQuery/TabQuery'
import { useTabEnabled } from '../../hooks/tabs/useTabEnabled'
import TabPromptCartView from '../TabPromptCartView/TabPromptCartView'
import { HeaderText } from '@/il8n/en'

import styles from './CartPage.module.css'

const { toastContainer, toastWrapper } = styles

const CartPage = () => {
  useScrollToTop()
  const { getRestaurantPath } = useRestaurant()
  const { order } = useGetTab()
  const tabEnabled = useTabEnabled()

  if (order) {
    return <Redirect to={getRestaurantPath('tab/add')} replace />
  }

  return (
    <div className={styles.cartPage}>
      <div
        data-testid='cart-page-header'
        className='mt-6 text-center type-headline-4'
      >
        {HeaderText.REVIEW_ORDER}
      </div>

      {tabEnabled ? <TabPromptCartView /> : <Cart />}
      <div className={styles.closeButton}>
        <CloseButton
          data-testid='close-button-cart-page'
          to={{
            pathname: getRestaurantPath(),
            search: ''
          }}
        />
      </div>
      <ToastContainer
        enableMultiContainer
        hideProgressBar
        closeButton={false}
        className={toastContainer}
        toastClassName={toastWrapper}
        position={'top-center'}
      />
    </div>
  )
}
CartPage.propTypes = {}

export default CartPage
