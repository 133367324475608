import { useRestaurantInfo } from '../restaurant-info/use-restaurant-info'
import { useGetPartyMode } from '../../components/PartyQuery/PartyQuery'
import { DDIMode } from '../../types/DDIGlobals'

export const useTabEnabled = () => {
  const { isEnabled } = useTabEnabledWithLoading()
  return isEnabled
}

export const useTabEnabledWithLoading = () => {
  const { mode } = useGetPartyMode()
  const { data, loading, error, refetch } = useRestaurantInfo()
  const allowTabs =
    data && data.orderAndPayConfig && data.orderAndPayConfig.allowTabs
  const isEnabled = Boolean(allowTabs)

  return {
    isEnabled:
      !loading &&
      !error &&
      (isEnabled || mode === DDIMode.MNP || mode === DDIMode.STP),
    loading,
    error,
    refetch
  }
}
