import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import ToastLogo from '../../assets/powered-by/grey-horizontal.svg'

import Modal from '../Modal/Modal'

import styles from './PoweredByToastModal.module.css'

export const PoweredByToastModal = ({
  header,
  children,
  onClose,
  darkFooter = false,
  footer,
  ...props
}) => (
  <Modal
    header={header}
    onClose={onClose}
    footer={
      <div className={cx(styles.footer, { [styles.darkFooter]: darkFooter })}>
        {footer}
        <ToastLogo className={styles.logo} />
      </div>
    }
    {...props}
  >
    <div className={styles.modal}>{children}</div>
  </Modal>
)

PoweredByToastModal.propTypes = {
  header: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  darkFooter: PropTypes.bool,
  footer: PropTypes.node
}
