import React from 'react'
import cx from 'classnames'

import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'

import { PartyCartButton } from './PartyCartButton'
import { useParty } from '../PartyProvider/PartyProvider'
import { useGetPartyCarts, useGetPartyMember } from '../PartyQuery/PartyQuery'
import { useGetTab } from '../TabQuery/TabQuery'
import {
  countItemsInPartyCarts,
  doesCheckHaveItems,
  partyCartsHaveItems
} from '../../utils/cart-helpers'
import { PartyTabButton } from './PartyTabButton'
import { CTA } from '@/il8n/en'
import { TargetedNotification } from '@local/do-secundo-target-notification'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useDetectPaymentAlert } from '../../hooks/useDetectPaymentAlert'

import styles from './PartyReviewOrderButton.module.css'
import { DDIMode } from '../../types/DDIGlobals'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import Order from '../../assets/order.svg'
import { useTabSpaEnabled } from '../../hooks/tabs/use-tab-spa-enabled'
import { useHistory } from 'react-router'
import { getTabSpaRedirectUrl } from './helpers'

const PaymentNotificationWrapper = ({ children, isOpen, onClick }) => {
  return (
    <TargetedNotification
      isOpen={isOpen}
      message={
        <div>
          <div>Don’t forget to close your check when you’re done!</div>
          <div className={cx('w-full flex justify-end pr-4 pb-4')}>
            <Button onClick={onClick} className={styles.button}>
              Got it!
            </Button>
          </div>
        </div>
      }
      position='above'
      testId='payment-reminder-notification'
      alignment='right'
      variant='navy'
      notificationClass='max-w-xs p-4 type-large'
    >
      {children}
    </TargetedNotification>
  )
}

const getCTA = (carts, allowJoinableParty, tabsEnabled) => {
  const hasMultipleCartSelections =
    carts.filter((cart) => cart.order?.checks[0].numberOfSelections > 0)
      .length > 1
  if (hasMultipleCartSelections && allowJoinableParty) {
    return 'Continue to place group order'
  }
  if (!tabsEnabled) {
    return CTA.NAV_TAB_NEW
  }
  return 'Continue to place order'
}

export const PartyReviewOrderButton = () => {
  const { getRestaurantPath } = useRestaurant()
  const { data } = useRestaurantInfo()
  const allowJoinableParty = data?.orderAndPayConfig?.allowJoinableParty
  const preauthEnabled = data?.orderAndPayConfig?.allowPreauth
  const tabsEnabled = data?.orderAndPayConfig?.allowTabs
  const { partyMemberGuid } = useParty()
  const me = useGetPartyMember(partyMemberGuid) || {}
  const { carts } = useGetPartyCarts() || []
  const { primaryCheck } = useGetTab()
  const [needsAcknowledgement, acknowledge] = useDetectPaymentAlert()
  const { mode } = useDDIGlobals()
  const tabsSpaEnabled = useTabSpaEnabled()
  const history = useHistory()
  const numOfSelections = countItemsInPartyCarts(carts)

  if (!me) {
    return null
  }

  const cartsHaveItems = partyCartsHaveItems(carts)
  const tabHasItems = doesCheckHaveItems(primaryCheck)

  if (tabsSpaEnabled) {
    const cta = getCTA(carts, allowJoinableParty, tabsEnabled)
    const tabsSpaRedirectUrl = getTabSpaRedirectUrl(
      primaryCheck,
      preauthEnabled,
      tabsEnabled
    )
    return cartsHaveItems ? (
      <Button
        className='flex justify-start w-full m-4 text-left'
        testId='button-checkout-party-tab-spa'
        onClick={() => history.push(tabsSpaRedirectUrl)}
      >
        {cta} ({numOfSelections})
      </Button>
    ) : null
  }

  if (primaryCheck && (cartsHaveItems || tabHasItems)) {
    return (
      <PaymentNotificationWrapper
        onClick={() => acknowledge()}
        isOpen={needsAcknowledgement}
      >
        <PartyTabButton
          check={primaryCheck}
          carts={carts}
          to={getRestaurantPath(
            mode === DDIMode.OPT || mode === DDIMode.TTS
              ? cartsHaveItems
                ? 'tab/add'
                : 'tab'
              : 'tab/close'
          )}
          title={cartsHaveItems ? CTA.NAV_FIRE_TAB_ROUND : CTA.NAV_TAB_CLOSED}
        />
      </PaymentNotificationWrapper>
    )
  }

  if (!primaryCheck && mode === DDIMode.MNP) {
    return (
      <div
        className={styles.payHereLaterFauxButton}
        data-testid='pay-here-later-faux-button'
      >
        <Order className={styles.image} role='img' />
        {CTA.PAY_HERE_LATER}
      </div>
    )
  }

  return (
    <PartyCartButton
      me={me}
      carts={carts}
      to={getRestaurantPath('cart')}
      title={CTA.NAV_TAB_NEW}
    />
  )
}
