import React from 'react'

import Hamburger from '../Hamburger/Hamburger'
import { ModeLink } from '../ModeRouter/ModeRouter'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useGiftCardLinks } from '../use-gift-card-links/use-gift-card-links'
import GiftCardIcon from '../../assets/gift-card.svg'
import { TableNumber } from '../TableNumber/TableNumber'
import styles from './header.module.css'
import { CTA } from '@/il8n/en'
import { LDFlags } from '../../launchdarkly/flags'
import { useFlag } from '../FeatureFlag/use-flag'

const Header = () => {
  const { authenticated } = useAuth()
  const { purchaseLink } = useGiftCardLinks()

  const shouldDisableCreateAccount = useFlag(LDFlags.OPT_DISABLE_CREATE_ACCOUNT)
  return (
    <div className={styles.header}>
      <Hamburger />
      <div className={styles.mobile} />
      <div className={styles.desktop}>
        <div className={styles.tableNumber}>
          <TableNumber />
        </div>
        {purchaseLink && (
          <div className={styles.giftCardLink}>
            <GiftCardIcon />
            <a
              className={styles.link}
              href={purchaseLink}
              data-testid='header-purchase-gc-link'
            >
              Buy a Gift Card
            </a>
          </div>
        )}
        {!authenticated && (
          <>
            {purchaseLink && <span className={styles.spacer} />}
            <ModeLink
              data-testid='header-login-link'
              variant='secondary'
              mode='login'
            >
              {CTA.LOG_IN}
            </ModeLink>
            <span className={styles.spacer} />
            {!shouldDisableCreateAccount && (
              <ModeLink
                data-testid='header-create-link'
                variant='primary'
                mode='create'
              >
                {CTA.CREATE_ACCOUNT}
              </ModeLink>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Header
