import React from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import Modal from '../Modal/Modal'
import { track } from '@toasttab/do-secundo-analytics'
import { OptPartyErrorCode } from '../../apollo/generated/OptWebGraphQLOperations'

interface CannotApplyChangeDialogProps {
  showDialog: boolean
  setShowDialog: (newState: boolean) => void
  onClose?: () => void
}

const noop = () => {}

export const CannotApplyChangeDialog = ({
  showDialog,
  setShowDialog,
  onClose = noop
}: CannotApplyChangeDialogProps) => {
  if (!showDialog) {
    return null
  }

  const handleClose = () => {
    onClose()
    setShowDialog(false)
    track('splitConfigurationModalErrorAcknowledged', {
      errorDescription: OptPartyErrorCode.SplitPaymentAssignmentLockedInError
    })
  }

  return (
    <Modal
      header={
        <h4 className='font-medium whitespace-pre-wrap text-center'>
          Oops, looks like this change can't be applied
        </h4>
      }
      responsive={false}
      compactHeader={true}
      onClose={handleClose}
    >
      <div className='flex flex-col px-4 space-y-6 items-center mb-6'>
        <div className='type-default'>
          Someone from your party has made a payment. You can't make changes to
          the split after a payment is made.
        </div>
        <Button
          onClick={handleClose}
          variant='secondary'
          className='w-full'
          testId='cannot-update-split-button'
        >
          Got it
        </Button>
      </div>
    </Modal>
  )
}
