import React from 'react'
import { DDIGlobals, DDIMode } from '../../types/DDIGlobals'
import { LDFlags } from '../../launchdarkly/flags'
import { useExpiringStateWithNamespacedStorage } from '../../utils/namespaced-storage'
import {
  EXPIRATION_DURATION,
  restaurantStorageKeys,
  useRestaurantStorage
} from '../../utils/restaurant-storage'

const DDIGlobalsContext = React.createContext<DDIGlobals>({} as DDIGlobals)

interface DDIGlobalsProps {
  ddiGlobals: DDIGlobals
  children: React.ReactNode
}

export const DDIGlobalsProvider = ({
  ddiGlobals,
  children
}: DDIGlobalsProps) => {
  const flag = ddiGlobals.enabledFeatureFlags?.includes(
    LDFlags.UNIFIED_ONBOARDING
  )
  const restaurantStorage = useRestaurantStorage(ddiGlobals.restaurantGuid)

  const [cartMode, setCartMode] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.CART_MODE,
    undefined,
    restaurantStorage,
    EXPIRATION_DURATION
  )

  const [tableName, setTableName] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.TABLE_NAME,
    ddiGlobals.uniqueIdentifier,
    restaurantStorage,
    EXPIRATION_DURATION
  )

  // guests with active carts/orders should still be able complete their order when ordering is disabled by rx
  const hasExistingOPTCart =
    cartMode === 'OPT' && tableName === ddiGlobals.uniqueIdentifier

  const isMnp = flag
    ? !ddiGlobals.optConfig.orderingConfig.orderingEnabled &&
      ddiGlobals.mode === 'OPT' &&
      !hasExistingOPTCart
    : ddiGlobals.mode === 'MNP'

  // prevents local storage bleeding to other tables in the browser
  if (tableName !== ddiGlobals.uniqueIdentifier) {
    setCartMode(undefined)
    setTableName(ddiGlobals.uniqueIdentifier)
  }

  const dynamicDDIGlobals = React.useMemo(() => {
    return {
      ...ddiGlobals,
      mode: isMnp ? DDIMode.MNP : ddiGlobals.mode
    }
  }, [ddiGlobals, isMnp])

  return (
    <DDIGlobalsContext.Provider value={dynamicDDIGlobals}>
      {children}
    </DDIGlobalsContext.Provider>
  )
}

export const useDDIGlobals = () => React.useContext(DDIGlobalsContext)
