import * as React from 'react'
import cx from 'classnames'
import Modal from '../../Modal/Modal'
import Button from '../../Button/Button'
import { Notification } from '@toasttab/do-secundo-notification'
import { useDeleteAccount } from '../../../utils/use-delete-account/delete-account-utils'
import { toast } from 'react-toastify'
import { AUTH_NOTIFICATION_CONTAINER_ID } from '../../AuthProvider/AuthProviderPasswordless'
import { useLogout } from '../../../utils/use-logout/use-logout'
import { useGetPartyMemberCart } from '../../PartyQuery/PartyQuery'
import { useSentry } from 'banquet-runtime-modules'

export interface DeleteAccountError {
  message: string
  code?: string
}
export interface DeleteAccountModalProps {
  onClose: () => void
  onSuccess?: () => void
  testId?: string | number
}

export const DeleteAccountModal = ({
  onClose,
  testId = `DeleteAccountModal`
}: DeleteAccountModalProps) => {
  const { captureException } = useSentry()
  const [error, setError] = React.useState<DeleteAccountError | null>(null)
  const handleDeleteAccount = useDeleteAccount()
  const [isDeleting, setIsDeleting] = React.useState(false)

  const logout = useLogout()
  const { cart } = useGetPartyMemberCart()
  const logoutInput = {
    variables: {
      input: {
        cart
      }
    }
  }

  const deleteSuccess = async () => {
    try {
      await logout(logoutInput, 'Your account has been queued to be deleted.')
    } catch (err) {
      toast('Your account has been queued to be deleted.', {
        containerId: AUTH_NOTIFICATION_CONTAINER_ID
      })
      onClose()
    }
  }

  const deleteAccount = async () => {
    setIsDeleting(true)
    try {
      await handleDeleteAccount({ onSuccess: deleteSuccess, onError: setError })
    } catch (err: any) {
      setError({
        message:
          "Sorry we're unable to process your request at this time. Please try again later."
      })
      captureException(err)
      setIsDeleting(false)
    }
  }

  return (
    <div data-testid={testId} className={cx('text-')}>
      <Modal
        header={
          <>
            <h2 className='mx-0 mt-0 mb-3 type-headline-4'>Are you sure?</h2>
            <p className='type-default'>
              Your contact information, order history and payment information
              within your Toast account will be deleted across all Toast
              restaurants. Upon deletion, you will be logged out of all Toast
              products, including the Toast Takeout app, and you will be unable
              to log into this account across any Toast products again. This
              information will not be recoverable. This cannot be undone.
            </p>
          </>
        }
        responsive={false}
      >
        {error?.message && (
          <div className='px-3 -mb-6'>
            <Notification severity='error'>{error.message}</Notification>
          </div>
        )}
        <div className='flex justify-end p-3'>
          <div className='flex justify-between w-60 pr-2.5'>
            <Button
              variant={'link'}
              onClick={deleteAccount}
              data-testid={`${testId}-delete-account-button`}
              disabled={isDeleting}
            >
              Delete account
            </Button>
            <div className='w-24'>
              <Button
                variant={'primaryWide'}
                onClick={onClose}
                data-testid={`${testId}-delete-account-cancel`}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
