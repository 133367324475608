// @ts-ignore
import hash from 'string-hash'
import { useRestaurantInfo } from '../../../../hooks/restaurant-info/use-restaurant-info'
import {
  useGetMainCheck,
  useGetPartyMode
} from '../../../PartyQuery/PartyQuery'
import { useMemo } from 'react'

export const useItemFeedbackSurveyEnabled = (): boolean => {
  const { mode } = useGetPartyMode()
  const { mainCheck } = useGetMainCheck()
  const { data } = useRestaurantInfo()

  const isRandomlyDisabled = useMemo(
    () => mainCheck && hash(mainCheck.guid) % 10 === 0,
    [mainCheck]
  )

  return (
    mode === 'STP' &&
    Boolean(data?.itemLevelFeedbackConfig?.enabled) &&
    !isRandomlyDisabled
  )
}
