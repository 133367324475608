import React from 'react'
import { useSentry } from 'banquet-runtime-modules'
import { SavedCreditCardPicker } from '../../SavedCreditCardPicker/SavedCreditCardPicker'
import Fieldset from '../../Fieldset/Fieldset'
import { Field, Formik, Form } from 'formik'
import Button from '../../Button/Button'
import { useCreditCard } from '../../CreditCardProvider/CreditCardProvider'
import Error from '../../Error/Error'

import { getPrimaryCreditCard } from '../../../utils/form-utils'

import styles from './SavedCreditCardForm.module.css'
import { toast } from 'react-toastify'
import { CTA, FieldLabel } from '@/il8n/en'

export const SavedCreditCardForm = () => {
  const { captureException } = useSentry()
  const { creditCards = [], makePrimary, refetch } = useCreditCard()
  const savedCardGuid = getPrimaryCreditCard(creditCards).guid || ''

  let fieldsetLabel = FieldLabel.PAYMENT_OPTIONS

  React.useEffect(() => {
    // hack to get CC's to reload after adding a new one on the checkout page
    refetch && refetch()
  }, [refetch])

  return (
    <Fieldset label={fieldsetLabel} collapsable>
      <Formik
        initialValues={{ savedCardGuid }}
        onSubmit={async (values, { setSubmitting }) => {
          const input = {
            cardGuid: values.savedCardGuid
          }

          try {
            await makePrimary.mutate({ variables: { input } })
            toast('Primary Card Updated Successfully!')
          } catch (err) {
            captureException(err)
          } finally {
            setSubmitting(false)
          }
        }}
        enableReinitialize
      >
        {({ isSubmitting, dirty, isValid, handleSubmit }) => {
          return (
            <Form>
              <Field
                component={SavedCreditCardPicker}
                name='savedCardGuid'
                creditCards={creditCards}
                isDeletable
              />
              <div className={styles.submitWrapper}>
                {makePrimary.result.error && (
                  <Error error={makePrimary.result.error} />
                )}
                <Button
                  eventData={{
                    category: 'User Account',
                    action: 'Make Primary Credit Card'
                  }}
                  data-testid='button-submit-primary-credit-card'
                  id='SubmitPrimaryCreditCardButton'
                  variant='primary'
                  type='submit'
                  onClick={(event) => {
                    event.preventDefault()
                    handleSubmit()
                  }}
                  loading={isSubmitting}
                  disabled={!dirty || !isValid}
                  responsive
                >
                  {CTA.UPDATE_MY_CARD}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Fieldset>
  )
}

export default SavedCreditCardForm
