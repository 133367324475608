import { OptPartyErrorCode } from '../../../../client/apollo/generated/OptWebGraphQLOperations'

export const getErrorMsg = (
  errorCode: OptPartyErrorCode | `${OptPartyErrorCode}` | null
) => {
  let errorMsg: string

  switch (errorCode) {
    case OptPartyErrorCode.PreauthAlreadyExists:
      errorMsg = `Someone has already started your group's tab. Refresh and try again.`
      break
    case OptPartyErrorCode.AddPreauthFailed:
    case OptPartyErrorCode.MissingCardInput:
      errorMsg = 'Something went wrong. Check your information and try again.'
      break
    default:
      errorMsg = 'Something went wrong. Please try again.'
      break
  }

  return errorMsg
}
