import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'

import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { usePreviewMode } from '../../hooks/use-preview-mode'
import { useGetPartyMode } from '../../components/PartyQuery/PartyQuery'
import { DDIMode } from '../../types/DDIGlobals'
import { createUseExternalOrderingAvailableToggleFactory } from './createUseExternalOrderingAvailableToggleFactory'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

export interface AvailabilityResponse {
  loading: boolean
  orderingAvailable: boolean
  menuAvailable: boolean
  shouldCreateParty: boolean
  updateShouldCreateParty(val: boolean): void
}

const AvailabilityContext = React.createContext<AvailabilityResponse>({
  loading: false,
  menuAvailable: false,
  shouldCreateParty: false,
  orderingAvailable: false,
  updateShouldCreateParty: () => {}
})

const useExternalOrderingAvailableToggle =
  createUseExternalOrderingAvailableToggleFactory({
    falseTimeout: 300000,
    localStorageKey: 'opt-ordering-disabled-toggle'
  })

export const getModeAvailability = (
  mode: DDIMode,
  flag: boolean,
  orderingEnabled: boolean
) => {
  //if guest express did not redirect from MNP or STP route, disable ordering regardless of config to ensure backwards compatibility
  if (
    mode === DDIMode.MNP ||
    mode === DDIMode.OPT_PREVIEW ||
    mode === DDIMode.STP
  ) {
    return false
  }

  if (flag) {
    return orderingEnabled
  }

  return mode === DDIMode.OPT || mode === DDIMode.TTS
}

export const AvailabilityProvider: React.FC = ({ children }) => {
  const isPreviewMode = usePreviewMode()
  const {
    uniqueIdentifier,
    optConfig: { orderingConfig },
    mode
  } = useDDIGlobals()
  const { mode: partyMode } = useGetPartyMode()
  const viewOnlyMode = isPreviewMode || uniqueIdentifier === 'welcome'
  const [shouldCreateParty, setShouldCreateParty] = useState(!viewOnlyMode)
  const history = useHistory()
  const mnpMigrationEnabled = useFlag(LDFlags.UNIFIED_ONBOARDING)
  const externalOrderingAvailable = useExternalOrderingAvailableToggle()

  const value = useMemo(() => {
    const updateShouldCreateParty = (value: boolean) => {
      setShouldCreateParty(value)
    }

    return {
      loading: false,
      // must have a party creatable in order for ordering to be available
      orderingAvailable:
        externalOrderingAvailable &&
        shouldCreateParty &&
        getModeAvailability(
          mode,
          mnpMigrationEnabled,
          orderingConfig.orderingEnabled
        ),
      menuAvailable: partyMode !== DDIMode.STP,
      shouldCreateParty,
      updateShouldCreateParty
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    partyMode,
    shouldCreateParty,
    viewOnlyMode,
    externalOrderingAvailable
  ])

  return (
    <AvailabilityContext.Provider value={value}>
      {children}
    </AvailabilityContext.Provider>
  )
}

export const useAvailability = (): AvailabilityResponse => {
  return React.useContext(AvailabilityContext)
}
