import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { joinFieldByCommas } from '../../../utils/reducer-utils'

import styles from './OrderTableModifiersList.module.css'
import { removeNameFromSpecialRequest } from '../../../utils/form-utils'

export const OrderTableModifiersListComponent = ({ modifiers, editable }) => {
  return (
    <ul
      data-testid='order-item-modifiers'
      className={cx(styles.modifiers, { [styles.readonly]: !editable })}
    >
      {modifiers.map(
        ({ name, voided, modifiers: nestedModifiers = [] }, index) => {
          // must use index here because GUID will be null for special instructions modifier
          const isLast = index === modifiers.length - 1

          if (removeNameFromSpecialRequest(name) === '') {
            return null
          }

          return (
            <li
              data-testid='order-item-modifier'
              key={index}
              className={cx(styles.modifier, { [styles.linethrough]: voided })}
            >
              {voided ? name + ' VOIDED' : name}
              {nestedModifiers.length > 0 && (
                <span className={styles.nestedModifier}>
                  {' '}
                  ({joinFieldByCommas('name')(nestedModifiers)})
                </span>
              )}
              {!editable && !isLast && ', '}
            </li>
          )
        }
      )}
    </ul>
  )
}

OrderTableModifiersListComponent.propTypes = {
  modifiers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      modifiers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string
        })
      )
    })
  ),
  editable: PropTypes.bool
}

export const OrderTableModifiersListTestComponent =
  OrderTableModifiersListComponent

export const OrderTableModifiersList = React.memo(
  OrderTableModifiersListComponent
)
OrderTableModifiersList.displayName = 'OrderTableModifiersList'
