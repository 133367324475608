// matches values from toastweb
// see: https://github.com/toasttab/toastweb/blob/332ffe99dc015d2d398864a200c29d56e2e79501/app/controllers/RestaurantOnlineOrderingV3.java#L294-L301
export enum DDIMode {
  OPT = 'OPT',
  QR = 'QR',
  MNP = 'MNP',
  STP = 'STP',
  TTS = 'TTS',
  OPT_PREVIEW = 'OPT_PREVIEW',
  SHORTENED = 'SHORTENED'
}

export interface DDIGlobals {
  optWebVersion?: string
  toastwebBaseUri: string
  restaurantGuid: string
  routerBasename: string
  shortUrl: string
  managementGroupGuid: string
  enabledFeatureFlags?: string[]
  uniqueIdentifier: string
  mode: DDIMode
  spaVersion: string
  spaPreviousVersion: string
  gatewayBaseUri?: string
  guestExpressBaseUri: string
  devError?: Error
  optConfig: OptConfig
  restaurantConfig: RestaurantConfig
}

export interface RestaurantConfig {
  name: string
  bannerImage?: string
  image?: string
  country: string
  amexAccepted: boolean
  orderingAvailable: boolean
}

export interface OptConfig {
  menuConfig: MenuConfig
  paymentConfig: PaymentConfig
  orderingConfig: OrderingConfig
  spotlightConfig: SpotlightConfig
}

export interface MenuConfig {
  menusEnabled: boolean
  showPopularItems: boolean
  showFeaturedItems: boolean
  viewOnlyMenu: boolean
}

export interface PaymentConfig {
  paymentEnabled: boolean
  allowPreauth: boolean
}

export interface OrderingConfig {
  orderingEnabled: boolean
  allowTabs: boolean
  allowJoinableParty: boolean
}

export interface SpotlightConfig {
  header: string
  body: string
  visible: boolean
}
