import { DDIGlobals } from './types/DDIGlobals'
import { lifecycle } from './utils/lifecycle'

/**
 * Handle any global scope manipulations here. Encapsulates into a single file
 * to better anticipate side-effects that could negatively interact across the application
 */

declare global {
  interface Window {
    OO_GLOBALS: DDIGlobals
    lifecycle: any
    toggleExternalOrderingAvailable?: () => void
  }
}
// Make instance available to external JS
window.lifecycle = lifecycle
window.OO_GLOBALS = {
  optWebVersion: process.env.PKG_VERSION,
  ...window.OO_GLOBALS
}
