import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useRestaurantInfo } from '../restaurant-info/use-restaurant-info'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

export const useTabSpaEnabled = () => {
  const { loading, data, error } = useRestaurantInfo()
  const { mode } = useDDIGlobals()

  const isPaygInTabsEnabled = useFlag(LDFlags.OPT_PAYG_TABS)
  const isMnpInTabsSpaEnabled = useFlag(LDFlags.OPT_MNP_TABS_SPA)

  if (loading || error || !data) {
    return false
  }

  const tabsEnabled = data.orderAndPayConfig?.allowTabs
  const isOpt = mode === 'OPT' || (mode === 'MNP' && isMnpInTabsSpaEnabled)

  return isOpt && (tabsEnabled || isPaygInTabsEnabled)
}
