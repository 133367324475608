import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'
import { HeaderText } from '@/il8n/en'
import { Search } from './Search'
import { useMenus } from '../../hooks/use-menus/use-menus'

export const MenuSearch = ({ onClose }) => {
  const [node, setNode] = useState(null)
  const { menus } = useMenus()

  return (
    <Modal
      onClose={onClose}
      header={HeaderText.SEARCH_MENU}
      ref={(nodeRef) => {
        if (!nodeRef) {
          return
        }
        if (!node) {
          setNode(nodeRef.modalBodyRef.current)
        }
      }}
    >
      <Search node={node} menus={menus} />
    </Modal>
  )
}

MenuSearch.propTypes = {
  onClose: PropTypes.func
}
