import { useDDIGlobals } from '../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { DDIMode } from '../types/DDIGlobals'

export const useClick2PayEnabled = () => {
  const {
    optConfig: { orderingConfig },
    mode
  } = useDDIGlobals()
  const isOptConfig = orderingConfig && orderingConfig.orderingEnabled

  return Boolean(isOptConfig && mode === DDIMode.OPT)
}
