import { useMemo } from 'react'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import qs from 'qs'

export const useGetDeepLink = (): string => {
  const { restaurantGuid, toastwebBaseUri, shortUrl } = useDDIGlobals()

  return useMemo(() => {
    if (!toastwebBaseUri || !restaurantGuid || !shortUrl) {
      return null
    }
    // Account for preproduction functionality
    const dynamicLinkSubdomain =
      toastwebBaseUri.indexOf('preprod') > -1
        ? 'toasttakeoutpreprod'
        : 'toasttakeout'

    // Deep Link docs: https://firebase.google.com/docs/dynamic-links/create-manually
    const queryString = qs.stringify(
      {
        link: `${toastwebBaseUri}/${shortUrl}/v3/${restaurantGuid}`,
        apn: 'com.toasttab.consumer',
        utm_source: 'opt',
        utm_medium: 'web',
        utm_campaign: 'opt_cta_v1',
        isi: 1362180579,
        ibi: 'com.toasttab.consumer',
        at: '1001lQNI',
        ct: 'opt_cta_v1',
        mt: 8,
        pt: 1364164,
        efr: 1
      },
      {
        // ensure proper URI encoding
        encode: true,
        // ensure consistent sort order of keys
        sort(a: string, b: string) {
          const sortOrder = [
            'link',
            'apn',
            'utm_source',
            'utm_medium',
            'utm_campaign'
          ]
          return sortOrder.indexOf(a) - sortOrder.indexOf(b)
        },
        addQueryPrefix: false
      }
    )

    return `https://${dynamicLinkSubdomain}.page.link/?${queryString}`
  }, [restaurantGuid, shortUrl, toastwebBaseUri])
}
