import React from 'react'
import PropTypes from 'prop-types'

// TODO: User part cart button if possible to get total cost
import Button from '../Button/Button'
import Error from '../Error/Error'
import { useHandleFirePartyTabRound } from '../../hooks/place-order/use-fire-party-round'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { useGetPartyMembers } from '../PartyQuery/PartyQuery'

import { CTA } from '@/il8n/en'

export const PartyPlaceOrderButton = ({ disabled = false }) => {
  const [firePartyRound, { loading: firePartyLoading, error }] =
    useHandleFirePartyTabRound()
  const { members } = useGetPartyMembers()
  const isGroupOrder = members.length > 1
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)

  return (
    <>
      {error && (
        <Error
          error={error}
          withoutPleaseTryAgain={error.message.includes(
            'not available to order'
          )}
        />
      )}
      <Button
        data-testid='add-to-tab-button'
        id='add-to-tab-button'
        variant='primary'
        loading={firePartyLoading || firePartyLoading}
        responsive
        onClick={firePartyRound}
        disabled={disabled}
      >
        {tabsRedesignOn &&
          (isGroupOrder ? CTA.FIRE_GROUP_CONFIRM_V2 : CTA.FIRE_TAB_CONFIRM_V2)}
        {!tabsRedesignOn && CTA.FIRE_TAB_CONFIRM}
      </Button>
    </>
  )
}

PartyPlaceOrderButton.propTypes = {
  disabled: PropTypes.bool
}
