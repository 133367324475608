import React from 'react'

import ScrollToTop from '../ScrollToTop/ScrollToTop'
import CloseButton from '../CloseButton/CloseButton'

import styles from './AccountHeader.module.css'

interface AccountHeaderProps {
  title: string
  onClick(): void
}

const AccountHeader = ({ title, onClick }: AccountHeaderProps) => {
  return (
    <div className={styles['account-header']}>
      <ScrollToTop />
      <h3>{title}</h3>
      <div className={styles['close-button-wrapper']}>
        <CloseButton
          data-testid='close-button-account-page'
          onClick={onClick}
        />
      </div>
    </div>
  )
}

export default AccountHeader
