import { LDFlags } from './../../launchdarkly/flags'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { flagOverrides } from '../../launchdarkly/flags'

// In a separate file so we can mock "useFlag"
// in tests
export const useFlag = (name: LDFlags, defaultValue = false): boolean => {
  const hasOverride = flagOverrides[name] !== undefined
  const { enabledFeatureFlags } = useDDIGlobals()

  if (hasOverride) {
    return Boolean(flagOverrides[name])
  }

  if (
    Array.isArray(enabledFeatureFlags) &&
    enabledFeatureFlags.includes(name)
  ) {
    return true
  }

  return defaultValue
}

export const useFlags = (names: LDFlags[], defaultValue = false) => {
  const { enabledFeatureFlags } = useDDIGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.every((name) => {
    if (
      Array.isArray(enabledFeatureFlags) &&
      enabledFeatureFlags.includes(name)
    ) {
      return true
    }
    return defaultValue
  })
}

export const useAtLeastOneFlag = (names: LDFlags[], defaultValue = false) => {
  const { enabledFeatureFlags } = useDDIGlobals()

  if (!Array.isArray(names)) {
    return false
  }

  return names.some((name) => {
    if (
      Array.isArray(enabledFeatureFlags) &&
      enabledFeatureFlags.includes(name)
    ) {
      return true
    }
    return defaultValue
  })
}
