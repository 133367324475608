export enum SplitPaymentMethod {
  PAY_FOR_SELF = 'PAY_FOR_SELF',
  PAY_FOR_PARTY = 'PAY_FOR_PARTY',
  SPLIT_EVENLY = 'SPLIT_EVENLY'
}

export enum SplitPaymentMode {
  // When the user is paying some portion of the check,
  // regardless of the SpiltMode on the party ("MY_ITEMS" or "EVEN")
  // Maps to SplitPaymentMethod.PAY_FOR_SELF or SplitPaymentMethod.SPLIT_EVENLY
  SPLIT = 'SPLIT',
  // When the user is paying for the entirety of the check
  // regardless of the SpiltMode on the party ("MY_ITEMS" or "EVEN")
  // Maps to SplitPaymentMethod.PAY_FOR_PARTY
  PARTY = 'PARTY'
}
