import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KeyHandler from 'react-key-handler'
import FocusTrap from 'focus-trap-react'
import cx from 'classnames'

import Error from '../Error/Error'
import CloseButton from '../CloseButton/CloseButton'

import styles from './Modal.module.css'
let modalCount = 0
let modalRoot = document.getElementById('modal-root')
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.id = 'modal-root'
  modalRoot.classList.add('fs-unmask')
  document.body.appendChild(modalRoot)
}

const stopEventPropagation = (event) => {
  event.stopPropagation()
}

class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.modalBodyRef = React.createRef()
    this.el = document.createElement('div')
    this.scrollWidth = window.innerWidth - document.body.clientWidth
  }

  componentDidMount() {
    modalCount++
    if (modalCount === 1) {
      document.body.classList.add('modal-open')
      document.body.style.margin = `0 ${this.scrollWidth}px 0 0`
    }

    modalRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    modalCount--
    if (modalCount === 0) {
      document.body.classList.remove('modal-open')
      document.body.style.margin = ''
    }
    modalRoot.removeChild(this.el)
  }

  render() {
    const {
      wrapper,
      header,
      onClose,
      footer,
      children,
      error,
      responsive,
      footerShadow,
      compactHeader,
      focus = true
    } = this.props
    const contents = wrapper(
      <FocusTrap active={focus}>
        <div
          className={cx(styles.background, { [styles.responsive]: responsive })}
          onClick={onClose}
        >
          <div
            role='dialog'
            className={styles.modal}
            onClick={stopEventPropagation}
          >
            <div
              className={cx(
                compactHeader
                  ? styles.headerWrapperCompact
                  : styles.headerWrapper,
                {
                  'pt-5 px-2.5 pb-2.5': (header || onClose) && !compactHeader
                }
              )}
            >
              <div
                className={compactHeader ? styles.headerCompact : styles.header}
              >
                {typeof header === 'string' ? (
                  <Modal.Header>{header}</Modal.Header>
                ) : (
                  header
                )}
              </div>
              {onClose && (
                <div
                  className={
                    compactHeader
                      ? styles.closeButtonCompact
                      : styles.closeButton
                  }
                >
                  <CloseButton
                    data-testid='modal-close-button'
                    onClick={onClose}
                  />
                  <KeyHandler
                    keyEventName='keydown'
                    keyValue='Escape'
                    onKeyHandle={onClose}
                  />
                </div>
              )}
            </div>
            {error && <Error error={error} />}
            <div
              data-testid='modal-body'
              className={cx(styles.body, { 'mb-5': footer })}
              ref={this.modalBodyRef}
            >
              {children}
            </div>
            {footer && (
              <div
                className={cx(styles.footer, {
                  [styles.footerShadow]: footerShadow
                })}
              >
                {footer}
              </div>
            )}
          </div>
        </div>
      </FocusTrap>
    )
    return ReactDOM.createPortal(contents, this.el)
  }
}

Modal.propTypes = {
  wrapper: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
  responsive: PropTypes.bool,
  footerShadow: PropTypes.bool,
  compactHeader: PropTypes.bool,
  focus: PropTypes.bool
}

Modal.defaultProps = {
  wrapper: (x) => x,
  responsive: true,
  footerShadow: false,
  compactHeader: false,
  focus: true
}

Modal.Header = ({ children }) => (
  <h4 data-testid='modal-default-header' className={styles.defaultHeader}>
    {children}
  </h4>
)

Modal.Header.propTypes = {
  children: PropTypes.node
}

Modal.Header.displayName = 'Modal.Header'

export default Modal
