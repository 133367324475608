import {
  OptGlobalGiftCard,
  OptRestaurantGiftCard
} from '../../apollo/generated/OptWebGraphQLOperations'

/**
 * Returns whether a combination of gift cards has sufficient
 * funds to cover the pending amount on an unpaid check.
 */
export const hasSufficientFundsV2 = ({
  rxGiftCard,
  globalGiftCard,
  tipAmount = 0.0,
  rxGiftCardTipEnabled = false,
  checkAmount
}: {
  rxGiftCard?: OptRestaurantGiftCard
  globalGiftCard?: OptGlobalGiftCard
  rxGiftCardTipEnabled: Boolean
  /**
   * A pending, unpaid tip amount to be potentially covered by the gift card
   */
  tipAmount?: number
  /**
   * The amount to be paid on the due portion of a check (i.e. excluding tips).
   */
  checkAmount: number
}) => {
  const availableToastCash = globalGiftCard?.expectedAvailableBalance || 0.0
  const availableRxGiftCard = rxGiftCard?.expectedAvailableBalance || 0.0
  const availableTotal = availableRxGiftCard + availableToastCash
  const balTotal = (checkAmount ?? 0.0) + tipAmount
  return (
    (rxGiftCardTipEnabled && availableTotal >= balTotal) ||
    (availableTotal >= balTotal && availableToastCash >= tipAmount)
  )
}
