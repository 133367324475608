import { useSentry } from 'banquet-runtime-modules'
import { useEffect } from 'react'

const globalWindow = window

export const useMessage = ({
  origin,
  messageHandlers = {},
  window = globalWindow
}) => {
  const { captureMessage } = useSentry()
  useEffect(() => {
    const receiveMessage = (event) => {
      if (origin !== event.origin) {
        return
      }

      // cc-form sends exclusively strings, ignore other junk
      if (typeof event.data !== 'string') {
        return
      }

      let messageData
      try {
        messageData = JSON.parse(event.data)
      } catch (err) {
        captureMessage(err.message, 'warning')

        return
      }

      const { name, data } = messageData || {}
      if (messageHandlers[name]) {
        messageHandlers[name](data)
      }
    }

    window.addEventListener('message', receiveMessage, false)
    return () => {
      window.removeEventListener('message', receiveMessage, false)
    }
  }, [messageHandlers, origin, window, captureMessage])
}
