import React from 'react'
import ContentLoader from 'react-content-loader'

import styles from './Cart.module.css'

const CartLoader = () => (
  <div className={styles.fakeItems}>
    {Array.from({ length: 3 }, (_, i) => (
      <ContentLoader
        height={57}
        width={320}
        key={i}
        preserveAspectRatio='xMidYMid meet'
      >
        <rect x='20' y='10' rx='4' ry='4' width='10' height='13' />
        <rect x='40' y='10' rx='4' ry='4' width='210' height='13' />
        <rect x='265' y='10' rx='4' ry='4' width='35' height='13' />
        <rect x='40' y='32' rx='4' ry='4' width='50' height='13' />
      </ContentLoader>
    ))}
  </div>
)
export default CartLoader
