import { dataByTypename } from '../apollo-helpers'
import {
  DeleteAccountError,
  DeleteAccountResponse,
  useDelete_AccountMutation
} from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import { doFederatedApolloClient } from '../../apollo/do-federated-apollo-client'

/**
 * Returns a mutation function that deletes a passwordless account
 */
export const useDeleteAccount = () => {
  const [deleteMutation] = useDelete_AccountMutation({
    client: doFederatedApolloClient
  })

  return async ({
    onSuccess,
    onError
  }: {
    onSuccess?: (e: DeleteAccountResponse) => void | any
    onError?: (e: DeleteAccountError) => void | any
  }) => {
    const deleteResponse = await deleteMutation()

    const { DeleteAccountResponse, DeleteAccountError } = dataByTypename(
      deleteResponse.data?.deleteAccount
    )

    if (DeleteAccountResponse) {
      onSuccess && onSuccess(DeleteAccountResponse)
    } else if (DeleteAccountError) {
      onError && onError(DeleteAccountError)
    }
  }
}
