import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Field } from 'formik'

import Radio from '../Form/Radio/Radio'
import { SavedCreditCardLabel } from './SavedCreditCardLabel/SavedCreditCardLabel'

import styles from './SavedCreditCardPicker.module.css'

export const SavedCreditCardPicker = ({
  creditCards = [],
  field,
  isDeletable
}) => {
  return creditCards.length === 0 ? (
    <p>No Saved Cards</p>
  ) : (
    creditCards.map((card) => (
      <div
        data-testid='saved-credit-cards'
        className={cx(styles.separator, 'fs-mask')}
        key={card.guid}
      >
        <Field
          data-testid='saved-credit-card'
          disabled={card.expired}
          component={Radio}
          id={`${field.name}_saved_cc_${card.guid}`}
          name={field.name}
          label={<SavedCreditCardLabel card={card} isDeletable={isDeletable} />}
          value={card.guid}
        />
      </div>
    ))
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
})

SavedCreditCardPicker.propTypes = {
  creditCards: PropTypes.arrayOf(PropTypes.object),
  field: FieldShape.isRequired,
  isDeletable: PropTypes.bool
}

SavedCreditCardPicker.defaultProps = {
  isDeletable: false
}
