import * as React from 'react'

export const usePaginateArray = (
  list,
  { increment, initial = increment, max = list.size }
) => {
  const [size, setSize] = React.useState(initial)
  const incrementMax = React.useCallback(
    () => setSize((val) => val + increment),
    [increment]
  )

  React.useEffect(() => {
    setSize(initial)
  }, [list, initial])

  return [list.slice(0, size > max ? max : size), incrementMax]
}
