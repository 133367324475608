import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useFlag } from '../../components/FeatureFlag/use-flag'

import { LDFlags } from '../../launchdarkly/flags'

export const useSurchargingEnabled = (usingSpi: boolean = false) => {
  const surchargingEnabledForTabs = useFlag(LDFlags.SURCHARGING_TABS_STP)
  const surchargingEnabledForPayg = useFlag(LDFlags.OPT_PAYG_SURCHARGING)
  const {
    optConfig: {
      orderingConfig: { allowTabs }
    }
  } = useDDIGlobals()

  const enableForPayg = surchargingEnabledForPayg && !allowTabs
  const enableForTabs = surchargingEnabledForTabs && allowTabs

  return usingSpi && (enableForPayg || enableForTabs)
}
