import { useFlag } from '../../components/FeatureFlag/use-flag'
import { useGetPartyRefresh } from '../../components/PartyQuery/PartyQuery'
import { LDFlags } from '../../launchdarkly/flags'
import { useJoinablePartyEnabled } from './use-party-enabled'

export const useLeavePartyButtonEnabled = () => {
  const joinablePartyEnabled = useJoinablePartyEnabled()
  const { partyRefresh } = useGetPartyRefresh()
  const leaveButtonEnabled = useFlag(LDFlags.OPT_LEAVE_PARTY_BUTTON)
  return (
    joinablePartyEnabled &&
    leaveButtonEnabled &&
    partyRefresh &&
    !partyRefresh.order
  )
}
