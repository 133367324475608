import {
  DdiMode,
  OptCheckV2GuidFragment,
  OptPartyV2Fragment
} from '../../../apollo/generated/OptWebGraphQLOperations'
import type { DDIGlobals } from '../../../types/DDIGlobals'
import type { useGetPartyRefresh } from './PartyQuery'

/**
 * Pure function that gets party mode
 * from some party refresh hook results.
 *
 * A small performance "non-pessimization"
 * because it does not create a new point
 * of reactivity
 */
export const getPartyMode = (
  partyData: ReturnType<typeof useGetPartyRefresh>,
  ddiGlobals: Partial<DDIGlobals>
): OptPartyV2Fragment['mode'] => {
  const serverMode = partyData?.partyRefresh?.party?.mode

  // Coerce the frontend value to a valid server-side value
  const toValidPartyMode = (mode: any): DdiMode | null =>
    Object.values(DdiMode).includes(mode) ? mode : null

  const evaluatedMode = (() => {
    switch (serverMode) {
      // prefers TTS server-side values always
      case 'TTS':
        return serverMode
      default:
        return toValidPartyMode(ddiGlobals.mode) || serverMode
    }
  })()

  return evaluatedMode
}

/**
 * Pure function that gets party mode
 * from some party refresh hook results.
 *
 * A small performance "non-pessimization"
 * because it does not create a new point
 * of reactivity
 */
export const getMainCheck = (
  partyRefreshResult: ReturnType<typeof useGetPartyRefresh>
): OptCheckV2GuidFragment | undefined => {
  return partyRefreshResult.partyRefresh?.order?.checks.find((chk) => {
    return chk.guid === partyRefreshResult?.partyRefresh?.party.mainCheckGuid
  })
}
