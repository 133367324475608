import { RestaurantCreditCardConfig } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { useTipEnabled } from '../useTipEnabled/useTipEnabled'

export enum TipConfigStates {
  /** Tip field is visible, but collapsed. Can uncollapse by clicking field header */
  COLLAPSED = 'collapsed',
  /** Tip field is visible, and completely uncollpased. Cannot collapse by clicking field header */
  UNCOLLAPSED = 'uncollapsed',
  /** Tip field is completely hidden. No ability to add a tip */
  HIDDEN = 'hidden'
}

export const useEvaluateTipConfig = (
  creditCardConfig: RestaurantCreditCardConfig
): TipConfigStates => {
  const tipEnabled = useTipEnabled(creditCardConfig)
  // always ensure that tipsDisabled takes highest precedence
  // for Rx who want tips completely hidden from UI
  if (!tipEnabled) {
    return TipConfigStates.HIDDEN
  }

  return TipConfigStates.UNCOLLAPSED
}
