import React from 'react'

import styles from './SmsCommunicationConsent.module.css'
import { CheckoutMode } from '../checkout-modes'

export interface SmsCommunicationConsentPropTypes {
  requireTel: boolean
  mode?: string
}

export const SmsCommunicationConsent: React.FC<SmsCommunicationConsentPropTypes> =
  ({ requireTel, mode }) => {
    if (
      requireTel &&
      (mode === CheckoutMode.CREATE_TAB_MODE ||
        mode === CheckoutMode.CREATE_PREAUTH_TAB_MODE ||
        mode === CheckoutMode.PLACE_PAYG_ORDER_MODE)
    ) {
      return (
        <div className={styles['sms-communication-consent']}>
          <p>
            By providing a mobile number, you give Toast permission to contact
            you using automated text messages to provide transactional messages
            such as order status updates.
          </p>
        </div>
      )
    } else {
      return null
    }
  }
