import { ErrorText } from '@/il8n/en'
import React from 'react'
import {
  OptPartyError,
  OptPartyErrorCode,
  Restaurant
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { FullScreenError } from '../FullScreenError/FullScreenError'

export interface PartyErrorProps {
  error?: OptPartyError | Error | null
  retry?: () => void
  restaurant?: Restaurant
}

function isOptPartyError(error: OptPartyError | Error): error is OptPartyError {
  if ('__typename' in error && error.__typename === 'OPTPartyError') {
    return true
  }
  return false
}

export const OPTPartyErrorComponent = ({
  error,
  retry,
  restaurant
}: PartyErrorProps) => {
  const { toastwebBaseUri, shortUrl, uniqueIdentifier } = useDDIGlobals()

  if (!error) {
    return null
  }

  const isOrderFailedError =
    isOptPartyError(error) && error.code === OptPartyErrorCode.LookupPartyFailed

  const isPartyLookupFailedError =
    isOptPartyError(error) &&
    error.code === OptPartyErrorCode.LookupPartyTableFailed

  return (
    <>
      {isPartyLookupFailedError ? (
        <FullScreenError restaurant={restaurant}>
          <div data-testId='lookup-party-table-failed-warning'>
            Sorry, we couldn't find a table named "{uniqueIdentifier}". You can
            view the menu here and place an order through your server or
            cashier.
          </div>
          <a
            href={`${toastwebBaseUri}/${shortUrl}/order-and-pay/welcome/`}
            className='text-default font-semibold underline'
            data-testid='lookup-party-table-failed-view-menu-link'
          >
            View menu
          </a>
        </FullScreenError>
      ) : isOrderFailedError ? (
        <FullScreenError restaurant={restaurant}>
          <div data-testId='lookup-party-failed-warning'>
            {ErrorText.GENERIC_VIEW_MENUS}
          </div>
          <a
            href={`${toastwebBaseUri}/${shortUrl}/order-and-pay/welcome/`}
            className='text-default font-semibold underline'
            data-testid='lookup-party-table-view-menu-link'
          >
            View menu
          </a>
        </FullScreenError>
      ) : (
        <FullScreenError restaurant={restaurant} retry={retry}>
          <div data-testId='generic-party-error-warning'>{error.message}</div>
        </FullScreenError>
      )}
    </>
  )
}
