import React, { useCallback, useState, useMemo, useEffect } from 'react'
import cx from 'classnames'
import { useHistory } from 'react-router'
import {
  OptPartyError,
  OptPartyErrorCode,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import { Button, IconButton } from '@toasttab/buffet-pui-buttons'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { SplitPaymentMode } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { useParty } from '../PartyProvider/PartyProvider'
import {
  useGetMemberPayments,
  useGetPartyRefresh
} from '../PartyQuery/PartyQuery'
import { TargetedNotification } from '@local/do-secundo-target-notification'
import { useOuterClick } from '../../hooks/useOuterClick'
import { QuantityTicker } from '../QuantityTicker/QuantityTicker'
import { AlreadySplitDialog } from './AlreadySplitDialog'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import { CloseIcon } from '@toasttab/buffet-pui-icons'
import { ConfirmEditSplitDialog } from './ConfirmEditSplitDialog'
import { CannotApplyChangeDialog } from './CannotApplyChangeDialog'
import { useApplySplitType } from '../../hooks/split-payments/use-apply-split-type'
import { useRemoveSplit } from '../../hooks/split-payments/use-remove-split'

import { CTA, FieldLabel, HeaderText } from '@/il8n/en'
import styles from './SplitEvenBottomSheet.module.css'
import { track } from '@toasttab/do-secundo-analytics'
import { useSentry } from 'banquet-runtime-modules'
import { FormatCurrency } from '../Currency/FormatCurrency'

const CustomCloseIcon = (
  <div className={cx('flex flex-row justify-end z-10', styles.closeIcon)}>
    <IconButton size='sm' icon={<CloseIcon className='text-secondary' />} />
  </div>
)

const TOTAL_SHARES_MIN = 1
const TOTAL_SHARES_MAX = 25
const CLAIMED_SHARES_MIN = 1
export interface SplitEvenBottomSheetProps {
  subtotal: number
  showDrawer: boolean
  setShowDrawer: (newState: boolean) => void
  isEdit?: boolean
  setConfirmedChangeSplit?: (newState: boolean) => void
}

export const SplitEvenBottomSheet = ({
  subtotal,
  isEdit = false,
  showDrawer,
  setShowDrawer,
  setConfirmedChangeSplit
}: SplitEvenBottomSheetProps) => {
  const { captureMessage } = useSentry()
  const { getRestaurantPath } = useRestaurant()
  const { portionsToBePaid = 1, updateSupplementalPartyProperties } = useParty()
  const { partyRefresh, loading: partyRefreshLoading } = useGetPartyRefresh()
  const { evenSplitPaidPortions = 0, evenSplitPortions = 0 } =
    partyRefresh?.splitPaymentData || {}
  const history = useHistory()
  const { removeSplit, loading: removeSplitLoading } = useRemoveSplit()
  const { applySplitType, loading: applySplitTypeLoading } = useApplySplitType()
  const splitAlreadyDeclared =
    partyRefresh?.splitPaymentData?.evenSplitPortions && !applySplitTypeLoading
  const splitPaymentAlreadyMade =
    (partyRefresh?.splitPaymentData?.evenSplitPaidPortions || 0) > 0

  const [userFacingError, setUserFacingError] = useState<string>()
  const [showAlreadySplitDialog, setShowAlreadySplitDialog] = useState(false)
  const [showConfirmEditSplitDialog, setShowConfirmEditSplitDialog] =
    useState(false)
  const [showCannotApplyChangeDialog, setShowCannotApplyChangeDialog] =
    useState(false)
  const [showDisabledAlert, setShowDisabledAlert] = useState(false)
  const clickRef = useOuterClick<HTMLDivElement>(() =>
    setShowDisabledAlert(false)
  )
  const loading =
    partyRefreshLoading || removeSplitLoading || applySplitTypeLoading

  const [totalShares, setTotalShares] = useState(
    evenSplitPortions ||
      Math.max(partyRefresh?.party.members.length || 0, portionsToBePaid, 2)
  )
  const [claimedShares, setClaimedShares] = useState(portionsToBePaid)

  const CLAIMED_SHARES_MAX = totalShares - evenSplitPaidPortions

  const buttonCopy = useMemo(() => {
    if (isEdit) {
      return CTA.APPLY_CHANGE
    }

    if (splitAlreadyDeclared) {
      return CTA.CONTINUE
    }

    return CTA.SPLIT_EVEN
  }, [splitAlreadyDeclared, isEdit])

  const onApplySplitTypeSuccess = () => {
    history.push({
      pathname: getRestaurantPath('tab/close'),
      search: `?paymentMode=${SplitPaymentMode.SPLIT}`
    })
  }

  const onApplySplitTypeError = (error: OptPartyError) => {
    switch (error.code) {
      case OptPartyErrorCode.SplitPaymentOverrideNeededError:
        setShowDrawer(false)

        if (isEdit) {
          setShowConfirmEditSplitDialog(true)
        } else {
          setShowAlreadySplitDialog(true)
        }
        break
      case OptPartyErrorCode.SplitPaymentAssignmentLockedInError:
        setShowDrawer(false)
        setShowCannotApplyChangeDialog(true)
        break
      default:
        setUserFacingError(error.message)
        captureMessage(error.message, 'warning')
    }
  }

  const applySplit = async () => {
    if (claimedShares === CLAIMED_SHARES_MAX) {
      updateSupplementalPartyProperties({
        portionsToBePaid: claimedShares,
        totalPortions: totalShares
      })
      if (isEdit) {
        setShowDrawer(false)
      }
      history.push({
        pathname: getRestaurantPath('tab/close'),
        search: `?paymentMode=${SplitPaymentMode.PARTY}`
      })
    } else {
      if (
        !splitAlreadyDeclared ||
        (isEdit && totalShares !== evenSplitPortions)
      ) {
        await applySplitType({
          onComplete: onApplySplitTypeSuccess,
          onError: onApplySplitTypeError,
          setUserFacingError,
          splitPaymentInitializationData: {
            splitMode: SplitMode.Even,
            evenSplitPortions: totalShares
          },
          overrideSplit: false,
          newPortionsToBePaid: claimedShares,
          newTotalPortions: totalShares
        })
        if (isEdit) {
          track('editSplitPayment', {
            numberOfPortionsInCheck: totalShares,
            numberOfClaimedPortions: claimedShares
          })
        } else {
          track('createSplitPayment', {
            numberOfPortionsInCheck: totalShares,
            numberOfClaimedPortions: claimedShares
          })
        }
      } else {
        updateSupplementalPartyProperties({
          portionsToBePaid: claimedShares,
          totalPortions: totalShares
        })
        // this should not occur, but do not track someone joining if there are
        // no portions to pay for
        if (evenSplitPaidPortions !== evenSplitPortions) {
          track('joinSplitPayment', {
            numberOfPortionsInCheck: totalShares
          })
        }

        if (isEdit) {
          setShowDrawer(false)
        }
        history.push({
          pathname: getRestaurantPath('tab/close'),
          search: `?paymentMode=${SplitPaymentMode.SPLIT}`
        })
      }
    }
  }

  const onRemoveSplitSuccess = () => {
    history.push({
      pathname: getRestaurantPath('tab')
    })
    setShowDrawer(false)
  }

  const onRemoveSplitError = (error: OptPartyError) => {
    switch (error.code) {
      case OptPartyErrorCode.SplitPaymentAssignmentLockedInError:
        setShowDrawer(false)
        setShowCannotApplyChangeDialog(true)
        return
      default:
        setUserFacingError(error.message)
        captureMessage(error.message, 'warning')
    }
  }

  const decrementClaimedShares = useCallback(() => {
    setClaimedShares((cs) => (cs > CLAIMED_SHARES_MIN ? cs - 1 : cs))
  }, [])

  const incrementClaimedShares = useCallback(() => {
    setClaimedShares((cs) => (cs < CLAIMED_SHARES_MAX ? cs + 1 : cs))
  }, [CLAIMED_SHARES_MAX])

  const decrementTotalShares = useCallback(() => {
    setTotalShares((ts) => (ts > TOTAL_SHARES_MIN ? ts - 1 : ts))
    setClaimedShares((cs) => (cs >= CLAIMED_SHARES_MAX ? cs - 1 : cs))
  }, [CLAIMED_SHARES_MAX])

  const incrementTotalShares = useCallback(() => {
    setTotalShares((ts) => (ts < TOTAL_SHARES_MAX ? ts + 1 : ts))
  }, [])

  let splitEvenSubtotal
  const havePaymentsBeenMade = useGetMemberPayments().length > 0
  const canEditTotalSplit = !splitAlreadyDeclared || isEdit
  const editingAlreadySplit = isEdit && splitPaymentAlreadyMade

  // make sure total shares reflects the currently set evenSplitPortions
  useEffect(() => {
    if (
      totalShares !== evenSplitPortions &&
      evenSplitPortions > 0 &&
      showDrawer
    ) {
      setTotalShares(evenSplitPortions)
    }
  }, [showDrawer]) // eslint-disable-line react-hooks/exhaustive-deps

  // if no member payments have been made, the subtotal we see is the maincheck subtotal
  // if payments have been made, we will receive a splitCheck subtotal and have no need to divide.
  if (!havePaymentsBeenMade) {
    splitEvenSubtotal = (subtotal / totalShares) * claimedShares
  } else {
    splitEvenSubtotal =
      (subtotal / (evenSplitPortions - evenSplitPaidPortions)) * claimedShares
  }

  if (partyRefreshLoading) {
    return null
  }

  return (
    <>
      <AlreadySplitDialog
        showDialog={showAlreadySplitDialog}
        setShowDialog={setShowAlreadySplitDialog}
        setShowDrawer={setShowDrawer}
        toSplitMode={SplitMode.Even}
        selectedTotalShares={totalShares}
        selectedClaimedShares={claimedShares}
      />
      <ConfirmEditSplitDialog
        showDialog={showConfirmEditSplitDialog}
        setShowDialog={setShowConfirmEditSplitDialog}
        selectedTotalShares={totalShares}
        selectedClaimedShares={claimedShares}
        handleClose={() => {
          setConfirmedChangeSplit!(true)
        }}
      />
      <CannotApplyChangeDialog
        showDialog={showCannotApplyChangeDialog}
        setShowDialog={setShowCannotApplyChangeDialog}
        onClose={() => {
          history.push(getRestaurantPath('/tab'))
        }}
      />
      <BottomDrawer
        isOpen={showDrawer}
        onClose={() => {
          setShowDrawer(false)

          if (isEdit) {
            track('dismissedEditSplitPayment', {
              numberOfPortionsInCheck: totalShares,
              numberOfClaimedPortions: portionsToBePaid
            })
          } else if (!splitAlreadyDeclared) {
            track('dismissedCreateSplitPayment')
          }
        }}
        data-testid={'split-evenly-bottom-sheet'}
        customCloseIcon={CustomCloseIcon}
        customRootId='split-even-bottom-drawer'
      >
        <div className='items-center px-4 pb-6 space-y-6'>
          <div>
            <h4 className='font-medium text-center whitespace-pre-wrap'>
              {canEditTotalSplit
                ? HeaderText.SPLIT_EVENLY
                : `The check is split evenly between ${totalShares} people.`}
            </h4>
          </div>
          <div className='flex flex-col items-center justify-center w-full space-y-6'>
            {canEditTotalSplit && (
              <TargetedNotification
                className={'relative'}
                isOpen={showDisabledAlert}
                position='above'
                message={
                  <div className='p-2'>
                    The split cannot be changed after a payment has been made.
                  </div>
                }
                alignment='left'
                notificationClass={cx('absolute p-2 mr-4', styles.notification)}
                data-testid='split-even-edit-total-disabled-notification'
              >
                <div
                  ref={clickRef}
                  className={cx('flex flex-col items-center', {
                    'opacity-50': editingAlreadySplit
                  })}
                  onClick={() => {
                    if (editingAlreadySplit) {
                      setShowDisabledAlert(true)
                    }
                  }}
                >
                  {isEdit && (
                    <div className='mb-6 -mt-6'>
                      <Button
                        variant='text-link'
                        onClick={() => {
                          setConfirmedChangeSplit!(true)
                          removeSplit({
                            onComplete: onRemoveSplitSuccess,
                            onError: onRemoveSplitError,
                            setUserFacingError
                          })
                        }}
                        className='text-link'
                        disabled={splitPaymentAlreadyMade || loading}
                        testId='split-even-remove-split'
                      >
                        {removeSplitLoading ? (
                          <Loading variant='link' />
                        ) : (
                          <>Undo split</>
                        )}
                      </Button>
                    </div>
                  )}
                  <div
                    data-testid='split-even-total-shares'
                    className='flex items-center justify-between w-full space-x-4 text-left'
                  >
                    <div data-testid='split-even-total-shares-label'>
                      {FieldLabel.SPLIT_EVEN_TOTAL_SHARES}
                    </div>
                    <QuantityTicker
                      data-testid='split-even-total-shares-ticker'
                      value={totalShares}
                      minimum={TOTAL_SHARES_MIN}
                      maximum={TOTAL_SHARES_MAX}
                      onDecrement={decrementTotalShares}
                      onIncrement={incrementTotalShares}
                      disabled={editingAlreadySplit}
                    />
                  </div>
                </div>
              </TargetedNotification>
            )}
            <div
              data-testid='split-even-total-claimed-shares'
              className={cx(
                'flex items-center',
                splitAlreadyDeclared && !isEdit
                  ? 'justify-center space-x-16'
                  : 'justify-between w-fit max-w-xs space-x-16'
              )}
            >
              <div data-testid='split-even-claimed-shares-label'>
                {FieldLabel.SPLIT_EVEN_CLAIMED_SHARES}
              </div>
              <QuantityTicker
                data-testid='split-even-claimed-shares-ticker'
                value={claimedShares}
                minimum={CLAIMED_SHARES_MIN}
                maximum={CLAIMED_SHARES_MAX}
                onDecrement={decrementClaimedShares}
                onIncrement={incrementClaimedShares}
              />
            </div>
          </div>
          <div className='-mx-4 border-0 border-b border-solid border-gray-50' />
          <div className='flex justify-between font-semibold'>
            <div>Estimated subtotal</div>
            <FormatCurrency
              amount={splitEvenSubtotal}
              data-testid='estimate-amount'
            />
          </div>
          {userFacingError && <Error>{userFacingError}</Error>}
          <div>
            <Button
              onClick={() => {
                applySplit()
              }}
              data-testid='split-even-cta'
              className='w-full'
              disabled={loading}
            >
              {applySplitTypeLoading ? (
                <Loading />
              ) : (
                <span className='flex justify-center w-full'>{buttonCopy}</span>
              )}
            </Button>
          </div>
        </div>
      </BottomDrawer>
    </>
  )
}
