import React, { useCallback } from 'react'
import { PropTypes } from 'prop-types'
import QRCode from 'qrcode.react'

import { CopyIcon, ShareArrowIcon } from '@toasttab/buffet-pui-icons'
import { PoweredByToastModal } from '../PoweredByToastModal/PoweredByToastModal'
import { handleShareRequest } from '../PartyInviteOthers/party-invite-controller'
import { useGenerateInviteLink, InviteLinkType } from '../../utils/urls'
import Button from '../Button/Button'
import { toastNotification } from '../ToastNotification/ToastNotification'

import { HeaderText } from '@/il8n/en'
import styles from './PartyInviteModal.module.css'

export const PartyInviteModal = ({ onClose, baseUrl, whiteLabelName }) => {
  const qrUrl = useGenerateInviteLink(baseUrl, InviteLinkType.QR)
  const copyUrl = useGenerateInviteLink(baseUrl, InviteLinkType.COPY)
  const shareInviteUrl = useGenerateInviteLink(baseUrl, InviteLinkType.SHARE)

  return (
    <PoweredByToastModal onClose={onClose} responsive>
      <div className={styles.container}>
        <h4 className='my-4'>{HeaderText.INVITE_OTHERS_MODAL}</h4>
        <p className='type-default'>
          People can join by scanning this QR code. You can also invite them by
          sharing the link.
        </p>
        <div className='my-10'>
          <QRCode value={qrUrl} size={170} renderAs='svg' />
        </div>
        <div className={styles.buttonContainer}>
          <CopyButton url={copyUrl} />
          <ShareButton url={shareInviteUrl} whiteLabelName={whiteLabelName} />
        </div>
      </div>
    </PoweredByToastModal>
  )
}

const CopyButton = ({ url }) => {
  const onCopiedCallback = React.useCallback(() => {
    toastNotification('Link copied!', false)
  }, [])
  const [copyElement, initiateCopy] = useCopyText({
    text: url,
    onCopiedCallback
  })

  return (
    <>
      {copyElement}
      <Button
        onClick={initiateCopy}
        variant={'secondary'}
        data-testid='copy-share-url-button'
      >
        <div className={styles.buttonText}>
          <CopyIcon />
          Copy link
        </div>
      </Button>
    </>
  )
}

const ShareButton = ({ url, whiteLabelName }) => {
  const initiateShare = useCallback(async () => {
    handleShareRequest(url, whiteLabelName)
  }, [url, whiteLabelName])

  return (
    <>
      <Button
        onClick={initiateShare}
        variant={'secondary'}
        data-testid='native-share-url-button'
      >
        <div className={styles.buttonText}>
          <ShareArrowIcon />
          Share link
        </div>
      </Button>
    </>
  )
}

const noop = () => {}
const useCopyText = ({ text, onCopiedCallback = noop }) => {
  const copyURlDomRef = React.useRef(null)
  const [copyVisible, setCopyVisible] = React.useState(false)

  const initiateCopy = React.useCallback(() => {
    setCopyVisible(true)
  }, [])

  React.useLayoutEffect(() => {
    if (copyVisible && copyURlDomRef.current) {
      copyURlDomRef.current.select()
      document.execCommand('copy')
      copyURlDomRef.current.blur()
      setCopyVisible(false)
      onCopiedCallback()
    }
  }, [copyVisible, onCopiedCallback])

  const copyElement = copyVisible && (
    <input
      type='text'
      className={styles.urlInput}
      defaultValue={text}
      ref={copyURlDomRef}
    />
  )

  return [copyElement, initiateCopy]
}

PartyInviteModal.propTypes = {
  onClose: PropTypes.func,
  baseUrl: PropTypes.string,
  whiteLabelName: PropTypes.string
}

CopyButton.propTypes = {
  url: PropTypes.string
}
