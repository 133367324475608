import React from 'react'
import PropTypes from 'prop-types'
import { connect, getIn } from 'formik'
import cx from 'classnames'

import Checkmark from '../../../assets/checkmark-large.svg'

import styles from './Required.module.css'

const Required = ({ name, formik }) => {
  const err = getIn(formik.errors, name)
  let value = getIn(formik.values, name)
  if (Array.isArray(value)) {
    value = value.find((a) => a.selected === true)
  }
  const satisfied = value && !err
  return (
    <div
      data-testid='modifier-required-container'
      className={cx(styles.required, { [styles.satisfied]: satisfied })}
    >
      <div className={styles.pill}>
        {satisfied && <Checkmark className={styles.check} />}
        <span
          data-testid='modifier-required-text'
          className={cx('type-subhead', styles.text)}
        >
          Required
        </span>
      </div>
    </div>
  )
}

Required.propTypes = {
  name: PropTypes.string.isRequired,
  formik: PropTypes.object
}

export default connect(Required)
