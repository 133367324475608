import { FormatCurrency } from '../Currency/FormatCurrency'
import React from 'react'

export interface SurchargeDisclaimerProps {
  surchargeRatePercentage: number
  surchargeAmount?: number | undefined
}

export const SurchargeDisclaimer = ({
  surchargeRatePercentage,
  surchargeAmount
}: SurchargeDisclaimerProps) => (
  <p
    className='mt-1 mb-2 font-normal type-subhead text-gray-75'
    data-testid='surcharge-disclaimer'
  >
    If you use a credit card, this establishment will charge an additional{' '}
    {surchargeRatePercentage.toFixed(2)}%{' '}
    {surchargeAmount && (
      <>
        (<FormatCurrency amount={surchargeAmount} />){' '}
      </>
    )}
    to help offset processing costs. This amount is not more than what we pay in
    fees.
  </p>
)
