import React from 'react'
import PropTypes from 'prop-types'

import { SearchBanner } from './SearchBanner'
import { CTA } from '@/il8n/en'

export const NoSearchResults = ({ prompt }) => (
  <SearchBanner>
    <span>{CTA.NO_SEARCH_RESULTS_1}</span>
    <span>{CTA.NO_SEARCH_RESULTS_2}</span>
  </SearchBanner>
)

NoSearchResults.propTypes = {
  prompt: PropTypes.string
}
