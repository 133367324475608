import gql from 'graphql-tag'

export const GET_ACTIVE_MENU = gql`
  query GetActiveMenu {
    activeMenu @client
  }
`

export const menuResolvers = {
  Mutation: {
    setActiveMenu: (_, { id, shortUrl, dateTime }, { cache }) => {
      cache.writeQuery({ query: GET_ACTIVE_MENU, data: { activeMenu: id } })
      return { id, __typename: 'ActiveMenu' }
    }
  }
}
