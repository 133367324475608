import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
  useContext
} from 'react'
import PropTypes from 'prop-types'

const nop = () => {}
const emptyContextValues = {
  handleClose: nop,
  setModal: nop
}
const ErrorModalContext = createContext(emptyContextValues)
export const ErrorModalProvider = ({ children }) => {
  const [modalContent, setModal] = useState(null)
  const handleClose = useCallback(() => {
    setModal(null)
  }, [])

  const context = useMemo(
    () => ({
      handleClose,
      setModal
    }),
    [handleClose]
  )

  return (
    <ErrorModalContext.Provider value={context}>
      {modalContent}
      {children}
    </ErrorModalContext.Provider>
  )
}

ErrorModalProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useErrorModal = () => useContext(ErrorModalContext)
