import { LDFlags } from '../../../../launchdarkly/flags'
import { formatTel } from '../../../../utils/form-utils'
import {
  PaymentFormKeys,
  GetPaymentFormInitialValuesParams
} from '../../types/types'

/**
 * Customer Info slice of payment forms
 */
export interface CustomerInfoCheckoutFormValues {
  [PaymentFormKeys.CUSTOMER_FIRST_NAME]?: string
  [PaymentFormKeys.CUSTOMER_LAST_NAME]?: string
  [PaymentFormKeys.CUSTOMER_EMAIL]?: string
  [PaymentFormKeys.CUSTOMER_TEL]?: string
  [PaymentFormKeys.CUSTOMER_MARKETING_CONSENT]?: boolean
}

export const getInitialValues = ({
  user,
  featureFlags,
  country
}: GetPaymentFormInitialValuesParams) => {
  const marketingOptInAvailable =
    featureFlags[LDFlags.EMAIL_MARKETING_PREFERENCES]
  return {
    [PaymentFormKeys.CUSTOMER_FIRST_NAME]: user.firstName || '',
    [PaymentFormKeys.CUSTOMER_LAST_NAME]: user.lastName || '',
    [PaymentFormKeys.CUSTOMER_EMAIL]: user.email || '',
    [PaymentFormKeys.CUSTOMER_TEL]: formatTel(user.phone || ''),
    [PaymentFormKeys.COUNTRY]: country,
    [PaymentFormKeys.CUSTOMER_MARKETING_CONSENT]: marketingOptInAvailable
      ? true
      : null
  }
}
