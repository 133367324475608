import React from 'react'
import { SplitMode } from '../../apollo/generated/OptWebGraphQLOperations'
import { CheckStatus, combineChecks } from '../../utils/check-helpers'
import { useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { FormatCurrency } from '../Currency/FormatCurrency'
import { isTaxInclusive } from '../../utils/tax-inclusive'

export interface SplitEvenSubtotalProps {
  remainingSubtotal: number
  evenSplitPaidPortions: number
  evenSplitPortions: number
  paidSubtotal: number
}

/**
 * Gets props that are necessary to feed to SplitEvenSubtotal
 * component. Returns null if the conditions to render are invalid.
 * Optimized for usage so consuming component can more refinedly determine
 * whether to render a split even subtotal breakdown or not.
 */
export const useSplitEvenSubtotalProps = (): SplitEvenSubtotalProps | null => {
  const { partyRefresh } = useGetPartyRefresh()
  const { data: restaurantInfo } = useRestaurantInfo()

  const { evenSplitPaidPortions, evenSplitPortions, splitMode } =
    partyRefresh?.splitPaymentData || {}

  const allChecks = partyRefresh?.order?.checks
  const openChecks = combineChecks(allChecks, CheckStatus.OPEN)
  const closedChecks = combineChecks(allChecks, CheckStatus.CLOSED)

  let remainingSubtotal = openChecks?.preDiscountedSubtotal
  let paidSubtotal = closedChecks?.preDiscountedSubtotal

  if (
    splitMode !== SplitMode.Even ||
    evenSplitPortions === undefined ||
    evenSplitPaidPortions === undefined ||
    evenSplitPaidPortions < 1 ||
    remainingSubtotal === undefined ||
    paidSubtotal === undefined
  ) {
    return null
  }

  if (isTaxInclusive(restaurantInfo)) {
    remainingSubtotal += openChecks?.tax || 0.0
    paidSubtotal += closedChecks?.tax || 0.0
  }

  return {
    remainingSubtotal,
    paidSubtotal,
    evenSplitPaidPortions,
    evenSplitPortions
  }
}

export const SplitEvenSubtotal = ({
  remainingSubtotal,
  evenSplitPaidPortions,
  evenSplitPortions,
  paidSubtotal
}: SplitEvenSubtotalProps) => {
  return (
    <div className='type-default space-y-2'>
      <div className='flex justify-between'>
        <div>
          Paid subtotal ({evenSplitPaidPortions} of {evenSplitPortions} people)
        </div>
        <div
          className='text-success font-bold'
          data-testid='split-even-paid-subtotal'
        >
          <FormatCurrency amount={paidSubtotal} />
        </div>
      </div>
      <div className='flex justify-between font-bold'>
        <div>Remaining subtotal</div>
        <div data-testid='split-even-remaining-subtotal'>
          <FormatCurrency amount={remainingSubtotal} />
        </div>
      </div>
    </div>
  )
}
