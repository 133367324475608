/* eslint-disable accessor-pairs */
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

// local variable to help debug state changes
const _LOCAL_DEBUG_MODE = false

export const PARTY_INIT_STATE_ENUM = {
  UNINITIALIZED: 'uninitialized',
  CREATE_UNIQUE_IDENTITY: 'create_unique_identity',
  PROMPT_START_A_PARTY: 'prompt_start_a_party',
  PROMPT_IS_THIS_YOUR_PARTY: 'prompt_is_this_your_party',
  INITIALIZED: 'initialized', // show menu
  PROMPT_TAB_IS_CLOSED: 'prompt_tab_is_closed', // after party is closed by another user, and has to prompt
  TAB_IS_CLOSED: 'tab_is_closed', // if party is closed by me, no need to prompt
  LOADING_PARTY_STUB: 'loading_party_stub',
  SELECT_PARTY_MODAL: 'select_party_modal',
  PROMPT_JOIN_PARTY: 'prompt_join_party',
  PROMPT_REQUIRE_INVITE: 'require_invite',
  PROMPT_NOT_MY_PARTY_CONFIRM: 'prompt_not_my_party_confirm',
  DISPLAY_PARTY_STUB_ERROR: 'display_party_stub_error',
  DISPLAY_PARTY_INVITE_STUB_ERROR: 'display_party_invite_stub_error',
  RETRY_LOAD_STUB: 'retry_load_stub',
  PROMPT_SSAT_JOIN_OR_CREATE: 'prompt_ssat_join_or_create'
}

export const PARTY_INIT_ACTIONS = {
  SET_INVITE_CODE: 'set_invite_code',
  SET_UNIQUE_USER_ID: 'set_unique_user_id',
  SET_PARTY_STUB: 'set_party_stub',
  SET_GUEST_NAME: 'set_guest_name',
  SET_GUEST_PHONE: 'set_guest_phone',
  SET_GUEST_EMAIL: 'set_guest_email',
  SET_PARTY_KEYS: 'set_party_keys',
  USER_ACTION: 'user_action',
  SET_PARTY_STUB_ERROR: 'set_party_stub_error',
  SET_PARTY_STUB_LOADING: 'set_party_stub_loading',
  SET_PARTY_INVITE_STUB_ERROR: 'set_party_invite_stub_error',
  RESET_PARTY_STATE: 'reset_party_state',
  SET_PAYG_GUID: 'set_payg_guid',
  SET_PARTY_CLOSED: 'set_party_closed'
}

export const USER_ACTIONS = {
  RETRY: 'RETRY',
  CLEAR_INVITE_CODE: 'CLEAR_INVITE_CODE',
  CONFIRM_JOIN_PARTY: 'CONFIRM_JOIN_PARTY',
  DENY_JOIN_PARTY: 'DENY_JOIN_PARTY'
}

// after a party is this old, require invite
const REQUIRE_INVITE_AGE_SECONDS = 30 * 60

/**
 * @typedef ReducerAction
 * @type {object}
 * @property {string} type - an action type enum
 * @property {*} value - value to be passed
 * @property {string} forState - state that action intends to transition from
 *
 * @param {OPTPartyInitializationModel} initPartyModel
 * @param {ReducerAction} action
 */
export const partyModelReducer = (initPartyModel, action) => {
  if (_LOCAL_DEBUG_MODE) {
    console.log('ACTION', action.type, action.value)
  }
  switch (action.type) {
    case undefined:
      throw Error('Trying to dispatch an undefined action.')
    case PARTY_INIT_ACTIONS.SET_INVITE_CODE:
      initPartyModel.inviteCode = action.value
      break
    case PARTY_INIT_ACTIONS.SET_UNIQUE_USER_ID:
      initPartyModel.uniqueUserId = action.value
      break
    case PARTY_INIT_ACTIONS.SET_PARTY_STUB:
      initPartyModel.partyStub = action.value
      break
    case PARTY_INIT_ACTIONS.SET_GUEST_NAME:
      initPartyModel.guestName = action.value
      break
    case PARTY_INIT_ACTIONS.SET_GUEST_PHONE:
      initPartyModel.guestPhone = action.value
      break
    case PARTY_INIT_ACTIONS.SET_GUEST_EMAIL:
      initPartyModel.guestEmail = action.value
      break
    case PARTY_INIT_ACTIONS.SET_PARTY_KEYS:
      initPartyModel.partyKeys = action.value
      break
    case PARTY_INIT_ACTIONS.SET_PARTY_INVITE_STUB_ERROR:
    case PARTY_INIT_ACTIONS.SET_PARTY_STUB_ERROR:
      initPartyModel.partyStubError = action.value
      break
    case PARTY_INIT_ACTIONS.SET_PARTY_STUB_LOADING:
      initPartyModel.loadingPartyStub = action.value
      break
    case PARTY_INIT_ACTIONS.USER_ACTION:
      return initPartyModel.userAction(action)
    case PARTY_INIT_ACTIONS.RESET_PARTY_STATE:
      initPartyModel.clear()
      break
    case PARTY_INIT_ACTIONS.SET_PARTY_CLOSED:
      initPartyModel.partyClosed = action.value
      break
    case PARTY_INIT_ACTIONS.SET_PAYG_GUID:
      initPartyModel.paygOrderGuid = action.value
      break
    default:
      throw new Error(`undefined action '${action.type}'`)
  }

  return initPartyModel.run()
}

export class OPTPartyInitializationModel {
  constructor({ joinablePartyEnabled, ssatEnabled, pinRequired }) {
    this.previousState = null
    this.joinablePartyEnabled = joinablePartyEnabled
    this.ssatEnabled = ssatEnabled
    this.pinRequired = pinRequired
    this.clear()
  }

  set inviteCode(code) {
    this._state.inviteCode = code
  }

  set partyStubError(errorObject) {
    this._state.partyStubError = errorObject
  }

  get hasPartyStubError() {
    return Boolean(this._state.partyStubError)
  }

  set loadingPartyStub(bool) {
    this._state.loadingPartyStub = bool
  }

  get loadingPartyStub() {
    return this._state.loadingPartyStub
  }

  get hasInvite() {
    return Boolean(this._state.inviteCode)
  }

  get uniqueUserId() {
    return this._state.uniqueUserId
  }

  set uniqueUserId(id) {
    this._state.uniqueUserId = id
  }

  get identityCreated() {
    return Boolean(this._state.uniqueUserId)
  }

  set partyStub(stub) {
    this._state.partyStub = stub
  }

  get partyStub() {
    return this._state.partyStub
  }

  get partyActiveAtTable() {
    // TODO: return false if party is closed
    return this._state.partyStub === null
      ? null
      : Boolean(this._state.partyStub)
  }

  set guestName(name) {
    this._state.guestName = name
  }

  get nameRegistered() {
    return Boolean(this._state.guestName)
  }

  set partyKeys(keys) {
    this._state.partyKeys = keys
  }

  get hasPartyKeys() {
    return this._state.partyKeys === null
      ? null
      : Boolean(this._state.partyKeys.guid || this._state.partyKeys.partyGuid)
  }

  get partyKeysMatch() {
    return (
      this.hasPartyKeys &&
      this.partyActiveAtTable &&
      this._state.partyStub.guid === this._state.partyKeys.partyGuid
    )
  }

  get partyClosed() {
    return this._state.partyClosed
  }

  set partyClosed(bool) {
    this._state.partyClosed = bool
  }

  set paygOrderGuid(value) {
    this._state.paygOrderGuid = value
  }

  get paygOrderGuid() {
    return this._state.paygOrderGuid
  }

  userAction(action) {
    if (!action.forState) {
      throw Error('User actions must set the forState property')
    }

    return this.run(action)
  }

  clear() {
    this.identityMatchesStoredValue = null

    const prevState = this._state ? this._state.previousState : null

    // actual state values
    this._state = {
      partyInitState: {
        current: PARTY_INIT_STATE_ENUM.UNINITIALIZED,
        previous: prevState
      },
      inviteCode: null,
      uniqueUserId: null,
      guestName: null,
      partyStub: null,
      partyKeys: null,
      partyRefresh: null,
      partyStubError: null,
      loadingPartyStub: false,
      partyClosed: null,
      paygOrderGuid: null
    }
  }

  /**
   * Runs the current state transition from current state to next state.
   * @param transientAction An action that is processed once and then forgotten about such as a user button press.
   */
  run(transientAction) {
    const partyInitState = this._state.partyInitState
    const previousState = partyInitState.current

    switch (partyInitState.current) {
      case undefined:
        throw new Error('Trying to set an undefined state.')
      case PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR:
        if (
          isEqual(transientAction, {
            type: PARTY_INIT_ACTIONS.USER_ACTION,
            value: USER_ACTIONS.CLEAR_INVITE_CODE,
            forState: partyInitState.current
          })
        ) {
          this.inviteCode = null
          this.partyStubError = null
          partyInitState.current = PARTY_INIT_STATE_ENUM.RETRY_LOAD_STUB
        }
        break
      case PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_STUB_ERROR:
        if (
          isEqual(transientAction, {
            type: PARTY_INIT_ACTIONS.USER_ACTION,
            forState: partyInitState.current,
            value: USER_ACTIONS.RETRY
          })
        ) {
          this.partyStubError = null
          this.partyStub = null
          partyInitState.current = PARTY_INIT_STATE_ENUM.RETRY_LOAD_STUB
        } else if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        }
        break
      case PARTY_INIT_STATE_ENUM.UNINITIALIZED:
        if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        } else if (this.hasPartyStubError) {
          if (this.hasInvite) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR
          } else {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_STUB_ERROR
          }
        } else if (this.identityCreated === false) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.CREATE_UNIQUE_IDENTITY
        } else if (this.partyKeysMatch) {
          this.inviteCode = null
          partyInitState.current = PARTY_INIT_STATE_ENUM.INITIALIZED
        } else if (this.hasInvite && this.joinablePartyEnabled) {
          if (this.partyActiveAtTable === true) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY
          } else if (this.partyActiveAtTable === false) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR
          }
        } else if (this.hasPartyKeys === true) {
          // Should go to LOADING_PARTY but we let the app handle that for now
          partyInitState.current = PARTY_INIT_STATE_ENUM.INITIALIZED
        } else if (this.hasPartyKeys === false) {
          if (
            this.identityCreated === true &&
            this.ssatEnabled &&
            !this.pinRequired
          ) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_SSAT_JOIN_OR_CREATE
          } else if (
            this.identityCreated === true &&
            (this.partyActiveAtTable === false || !this.joinablePartyEnabled)
          ) {
            partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY
          } else if (
            this.identityCreated === true &&
            this.partyActiveAtTable === true
          ) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY
          }
        }
        break
      case PARTY_INIT_STATE_ENUM.CREATE_UNIQUE_IDENTITY:
        if (this.identityCreated === true) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.UNINITIALIZED
        }
        break
      case PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY:
      case PARTY_INIT_STATE_ENUM.PROMPT_JOIN_PARTY:
      case PARTY_INIT_STATE_ENUM.PROMPT_SSAT_JOIN_OR_CREATE:
        if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        } else if (this.nameRegistered === true) {
          // Should go to LOADING_PARTY but we let the app handle that for now
          partyInitState.current = PARTY_INIT_STATE_ENUM.INITIALIZED
        }
        break
      case PARTY_INIT_STATE_ENUM.INITIALIZED:
        if (this.partyClosed === true || this.paygOrderGuid) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_TAB_IS_CLOSED
        } else if (!this.hasPartyKeys) {
          // when detects party keys are lost (for whatever reason), assumes that tab is closed and resets state
          partyInitState.current = PARTY_INIT_STATE_ENUM.TAB_IS_CLOSED
        }
        break
      case PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY:
        // if partyStub gets re-loaded and is empty, move to prompt start party instead
        if (this.partyStub === false) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY
        } else if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        } else if (
          transientAction &&
          transientAction.type === PARTY_INIT_ACTIONS.USER_ACTION &&
          transientAction.forState === partyInitState.current
        ) {
          if (transientAction.value === USER_ACTIONS.CONFIRM_JOIN_PARTY) {
            if (
              getPartyAgeSeconds(this.partyStub) >=
                REQUIRE_INVITE_AGE_SECONDS &&
              !this.hasInvite
            ) {
              partyInitState.current =
                PARTY_INIT_STATE_ENUM.PROMPT_REQUIRE_INVITE
            } else {
              partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_JOIN_PARTY
            }
          } else if (transientAction.value === USER_ACTIONS.DENY_JOIN_PARTY) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM
          }
        }
        break
      case PARTY_INIT_STATE_ENUM.PROMPT_REQUIRE_INVITE:
        // if partyStub gets re-loaded and is empty, move to prompt start party instead
        if (this.partyStub === false) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY
        } else if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        } else if (
          this.hasInvite ||
          getPartyAgeSeconds(this.partyStub) < REQUIRE_INVITE_AGE_SECONDS
        ) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_JOIN_PARTY
        } else if (
          transientAction &&
          transientAction.type === PARTY_INIT_ACTIONS.USER_ACTION &&
          transientAction.forState === partyInitState.current
        ) {
          if (transientAction.value === USER_ACTIONS.DENY_JOIN_PARTY) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM
          }
        }
        break
      case PARTY_INIT_STATE_ENUM.PROMPT_NOT_MY_PARTY_CONFIRM:
        if (
          transientAction &&
          transientAction.type === PARTY_INIT_ACTIONS.USER_ACTION &&
          transientAction.forState === partyInitState.current
        ) {
          if (transientAction.value === USER_ACTIONS.CONFIRM_JOIN_PARTY) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.PROMPT_IS_THIS_YOUR_PARTY
          } else if (transientAction.value === USER_ACTIONS.DENY_JOIN_PARTY) {
            partyInitState.current = PARTY_INIT_STATE_ENUM.PROMPT_START_A_PARTY
          }
        }
        break
      case PARTY_INIT_STATE_ENUM.RETRY_LOAD_STUB:
        if (this.loadingPartyStub) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB
        }
        break
      case PARTY_INIT_STATE_ENUM.LOADING_PARTY_STUB:
        if (this.hasPartyStubError) {
          if (this.hasInvite) {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_INVITE_STUB_ERROR
          } else {
            partyInitState.current =
              PARTY_INIT_STATE_ENUM.DISPLAY_PARTY_STUB_ERROR
          }
        } else if (!this.loadingPartyStub) {
          // when loading is complete, revert to uninitialized state so that response can be handled
          partyInitState.current = PARTY_INIT_STATE_ENUM.UNINITIALIZED
        }
        break
      case PARTY_INIT_STATE_ENUM.TAB_IS_CLOSED:
        // calling this.clear will handle state transition automatically
        break
      case PARTY_INIT_STATE_ENUM.PROMPT_TAB_IS_CLOSED:
        if (!this.hasPartyKeys) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.TAB_IS_CLOSED
        } else if (!(this.partyClosed === true || this.paygOrderGuid)) {
          partyInitState.current = PARTY_INIT_STATE_ENUM.INITIALIZED
        }
        break
      default:
        throw new Error(`Unknown state transition '${partyInitState.current}'`)
    }

    if (partyInitState.current !== previousState) {
      // only set if states have changed
      partyInitState.previous = previousState
    }

    const returnState = cloneDeep(this._state)
    if (_LOCAL_DEBUG_MODE) {
      console.log('STATE', returnState.partyInitState.current, returnState)
    }
    return returnState
  }
}

const getPartyAgeSeconds = (partyStub) => {
  if (!partyStub || !partyStub.createdTime) {
    return 0
  }
  return Date.now() / 1000 - partyStub.createdTime
}

export class MockOPTPartyInitializationModel extends OPTPartyInitializationModel {
  constructor(mockState = {}) {
    super()
    this._state = { ...this._state, ...mockState }
  }
}
