import namespacedStorage, {
  useStateWithNamespacedStorage,
  nameSpaces
} from './namespaced-storage'
import { useRestaurant } from '../components/RestaurantProvider/RestaurantProvider'
import { useMemo } from 'react'

export const EXPIRATION_DURATION = 43200000

export const restaurantStorageKeys = {
  CART_GUID: 'cartGuid',
  CART_MODE: 'mode',
  HAS_SPLASHED: 'hasSplashed',
  TAB_CHECK_GUID: 'tabCheckGuid',
  TAB_ORDER_GUID: 'tabOrderGuid',
  PARTY_PROPERTIES: 'partyProperties',
  PAYG_ORDER_GUID: 'paygOrderGuid',
  HAS_JUST_OPENED_TAB: 'hasJustOpenedTab',
  HAS_SEEN_TAB_INSTRUCTIONS: 'hasSeenTabInstructions',
  TAB_EMAIL: 'tabEmail',
  TAB_FIRST_NAME: 'tabFirstName',
  TAB_LAST_NAME: 'tabLastName',
  TABLE_NAME: 'tableName'
}

const noopLocalStorage = { get: () => {}, set: () => {} }

export const useRestaurantStorage = (rxGuidProp) => {
  const { restaurantGuid } = useRestaurant()
  const restaurantStorage = useMemo(() => {
    const namespace = nameSpaces.TOAST_OPT_RESTAURANT
    const storage = namespacedStorage(namespace)

    const rxGuid = rxGuidProp || restaurantGuid

    return {
      get: (key) => storage.get([rxGuid, key]),
      set: (key, value) => storage.set([rxGuid, key], value),
      remove: (key) => storage.remove([rxGuid, key])
    }
  }, [restaurantGuid, rxGuidProp])

  if (!restaurantGuid && !rxGuidProp) {
    return noopLocalStorage
  }
  return restaurantStorage
}

export const useStateWithRestaurantStorage = (key, initialValue) => {
  const restaurantStorage = useRestaurantStorage()
  return useStateWithNamespacedStorage(key, initialValue, restaurantStorage)
}
