import React, { useState } from 'react'

import EyeOpen from '../../../assets/eye-open.svg'
import EyeClosed from '../../../assets/eye-closed.svg'
import Input from '../Input/Input'

import styles from './Password.module.css'

export const Password = (props) => {
  const [pwVisible, setPwVisibility] = useState(false)
  const togglePwVisibility = () => setPwVisibility(!pwVisible)
  const type = pwVisible ? 'text' : 'password'
  return (
    <Input
      data-testid={Password.Input}
      {...props}
      type={type}
      endAdornment={
        <button
          tabIndex='-1'
          type='button'
          className={styles.eye}
          onClick={togglePwVisibility}
        >
          {pwVisible ? (
            <EyeOpen data-testid='password-field-eye-open' />
          ) : (
            <EyeClosed data-testid='password-field-eye-closed' />
          )}
        </button>
      }
    />
  )
}
