import * as React from 'react'
import PropTypes from 'prop-types'
import { ApolloProvider } from '@apollo/client'
import 'isomorphic-fetch'

import '../../utils/lifecycle'
import { DDIGlobalsProvider } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { getApolloClientByMode } from './helpers/get-apollo-client-by-mode'
import { AppSelector } from './AppSelector'

const AppComponent = () => {
  const ddiGlobals = window.OO_GLOBALS
  return (
    <ApolloProvider client={getApolloClientByMode(ddiGlobals.mode)}>
      <DDIGlobalsProvider ddiGlobals={ddiGlobals}>
        <AppSelector
          mode={ddiGlobals.mode}
          uniqueIdentifier={ddiGlobals.uniqueIdentifier}
        />
      </DDIGlobalsProvider>
    </ApolloProvider>
  )
}

AppComponent.propTypes = {
  // Intended to be window.location
  location: PropTypes.shape({
    pathname: PropTypes.any.isRequired
  }),
  ddiGlobals: PropTypes.object
}

export const BanquetApp = AppComponent
