import * as React from 'react'

export const usePaymentOptionsHandler = (defaultValue = false) => {
  const [paymentOptionsOpen, setPaymentOptionsOpen] =
    React.useState(defaultValue)

  const openPaymentOptions = () => setPaymentOptionsOpen(true)
  const closePaymentOptions = () => setPaymentOptionsOpen(false)

  return { paymentOptionsOpen, openPaymentOptions, closePaymentOptions }
}
