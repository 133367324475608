import { GuestsWithoutOrdersProps } from './GuestsWithoutOrders'

/**
 * @returns guests who have not added to the order
 */
export const getGuestsWithoutSelections = ({
  carts, // current carts
  me,
  partyMembers
}: GuestsWithoutOrdersProps) => {
  return partyMembers.filter((guest) =>
    carts.some((cart) => {
      const { selections } = cart.order.checks[0]!
      return (
        selections.length === 0 &&
        guest.cartGuid === cart.guid &&
        me?.partyMemberGuid !== guest.partyMemberGuid
      )
    })
  )
}
