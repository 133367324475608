/* eslint-disable */
/* Generated by GraphQL Code Generator against pre-production environment */


      export default {
  "possibleTypes": {
    "ApplyCodeToGuestCurrencyAccountResult": [
      "ApplyCodeToGuestCurrencyAccountError",
      "ApplyCodeToGuestCurrencyAccountSuccess"
    ],
    "ApplyPromoCodeResponseOrError": [
      "ApplyPromoCodeError",
      "ApplyPromoCodeResponse"
    ],
    "ApplyPromoCodeResponseOrErrorV2": [
      "ApplyPromoCodeError",
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse"
    ],
    "AuthorizePaymentResponseOrError": [
      "AuthorizePaymentError",
      "AuthorizePaymentResponse"
    ],
    "CON_EnrollInIndustryPassResult": [
      "CON_EnrollInIndustryPassError",
      "CON_EnrollInIndustryPassSuccess"
    ],
    "CON_IndustryPassStatus": [
      "CON_IndustryPassEligible",
      "CON_IndustryPassEnrolled",
      "CON_IndustryPassIneligible"
    ],
    "CON_ToastCashbackRestaurantsResult": [
      "CON_ToastCashbackInvalidInputError",
      "CON_ToastCashbackProgramNotFoundError",
      "CON_ToastCashbackRestaurants"
    ],
    "CON_ToastCashbackRewardResult": [
      "CON_ToastCashbackAlreadyIssuedReward",
      "CON_ToastCashbackInvalidOrder",
      "CON_ToastCashbackReward",
      "CON_ToastCashbackRewardFailed"
    ],
    "CartModificationResponseOrError": [
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse"
    ],
    "CartResponseOrError": [
      "CartError",
      "CartResponse"
    ],
    "CompleteIdentityProfileResponseOrError": [
      "CompleteIdentityProfileError",
      "CompleteIdentityProfileResponse"
    ],
    "CompleteProfileCreationResponseOrError": [
      "CompleteProfileCreationError",
      "CompleteProfileCreationResponse"
    ],
    "ConfirmGuestResult": [
      "CompleteGuestProfileInputRequiredError",
      "ConfirmGuestSuccess",
      "InvalidEmailError",
      "InvalidNameError",
      "ProfileLockedForDeletionError",
      "UnexpectedAuthError"
    ],
    "CreateAccountResponseOrError": [
      "CreateAccountError",
      "CreateAccountResponse"
    ],
    "CreateConfirmedAccountResponseOrError": [
      "AuthenticationResponse",
      "CreateAccountError"
    ],
    "CreatePaymentIntentResponse": [
      "CreatePaymentIntentError",
      "CreatePaymentIntentSuccessResponse"
    ],
    "CustomerLoyaltyInfoResponseOrError": [
      "CustomerLoyaltyError",
      "LoyaltyInquiryResponse"
    ],
    "DeleteAccountResponseOrError": [
      "DeleteAccountError",
      "DeleteAccountResponse"
    ],
    "DeleteAddressResponseOrError": [
      "DeleteAddressError",
      "DeleteAddressResponse"
    ],
    "DeleteCreditCardResponseOrError": [
      "DeleteCreditCardError",
      "DeleteCreditCardResponse"
    ],
    "DeleteGuestAddressResult": [
      "DeleteGuestAddressSuccess",
      "UnexpectedError"
    ],
    "DeleteGuestPaymentCardResult": [
      "DeleteGuestPaymentCardSuccess",
      "UnexpectedError"
    ],
    "DoMenus_FindMenuItemResult": [
      "DoMenus_FindMenuItemError",
      "DoMenus_FindMenuItemResponse"
    ],
    "DoMenus_FindMenuItemsResult": [
      "DoMenus_FindMenuItemsError",
      "DoMenus_FindMenuItemsResponse"
    ],
    "DoMenus_PopularItemsResult": [
      "DoMenus_PopularItemsError",
      "DoMenus_PopularItemsResponse"
    ],
    "Error": [
      "AlternativePaymentError",
      "ApplyCodeToGuestCurrencyAccountError",
      "ApplyPromoCodeError",
      "AuthorizePaymentError",
      "CON_ToastCashbackAlreadyIssuedReward",
      "CON_ToastCashbackInvalidInputError",
      "CON_ToastCashbackInvalidOrder",
      "CON_ToastCashbackProgramNotFoundError",
      "CON_ToastCashbackRewardFailed",
      "CartError",
      "CartModificationError",
      "CartOutOfStockError",
      "CompleteGuestProfileInputRequiredError",
      "CompleteIdentityProfileError",
      "CompleteProfileCreationError",
      "CreateAccountError",
      "CreatePaymentIntentError",
      "CustomerLoyaltyError",
      "DeleteAccountError",
      "DeleteAddressError",
      "DeleteCreditCardError",
      "GeneralError",
      "GiftCardBalanceInquiryError",
      "GiftCardLookupError",
      "GiftCardNotFoundError",
      "GuestAddressNotFoundError",
      "GuestFeedbackV2Error",
      "GuestPaymentCardNotFoundError",
      "ImportAccountError",
      "InvalidEmailError",
      "InvalidNameError",
      "InvalidPhoneFormatError",
      "LoginError",
      "LoyaltySignupError",
      "MakeCreditCardPrimaryError",
      "OPTOrderError",
      "OPTPartyError",
      "OPTTabError",
      "OnlineOrderingPaymentIntentError",
      "OrderAtTheTableGiftCardBalanceInquiryError",
      "PasswordlessAuthenticationError",
      "PayForCheckError",
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PopularItemsError",
      "ProfileLockedForDeletionError",
      "RefreshError",
      "ReorderError",
      "ResetPasswordError",
      "SignUpToLoyaltyError",
      "SingleIdentityInputRequiredError",
      "StartIdentityProfileError",
      "StartProfileCreationError",
      "UnexpectedAuthError",
      "UnexpectedError",
      "UpdateAddressError",
      "UpdateBasicInfoError",
      "UpdatePasswordError",
      "ValidateApplePayMerchantError",
      "VerifyEmailError"
    ],
    "FutureFulfillmentTimeSlot": [
      "FutureFulfillmentServiceGap",
      "FutureFulfillmentTime"
    ],
    "GiftCardBalanceInquiryResponseOrError": [
      "GiftCardBalanceInquiryError",
      "GiftCardBalanceInquiryResponse"
    ],
    "GiftCardDataOrError": [
      "GiftCardData",
      "GiftCardLookupError",
      "GiftCardNotFoundError"
    ],
    "GuestCurrencyUnit": [
      "GuestCurrencyExpirableUnit"
    ],
    "ImportAccountResponseOrError": [
      "ImportAccountError",
      "ImportAccountResponse"
    ],
    "Info": [
      "CartInfo"
    ],
    "ItemDetailOfferBadgeResponseOrError": [
      "GeneralError",
      "ItemDetailOfferBadgeResponse"
    ],
    "ItemFeedbackConfigResponse": [
      "ItemFeedbackConfig",
      "ItemFeedbackConfigError"
    ],
    "ItemOrderHistoryResponseOrError": [
      "InvalidOffsetError",
      "ItemOrderHistoryResponse",
      "MissingCustomerOrGuestGuidError"
    ],
    "LoginResponse": [
      "AuthenticationResponse",
      "LoginError",
      "MfaChallengeGeneratedResponse"
    ],
    "LoyaltyInquiryResponseOrError": [
      "CartOutOfStockError",
      "CustomerLoyaltyError",
      "LoyaltyInquiryResponse"
    ],
    "LoyaltySignupResponseOrError": [
      "LoyaltySignupError",
      "LoyaltySignupResponse"
    ],
    "MakeCreditCardPrimaryResponseOrError": [
      "MakeCreditCardPrimaryError",
      "MakeCreditCardPrimaryResponse"
    ],
    "MenuOfferBadgesResponseOrError": [
      "GeneralError",
      "MenuOfferBadgesResponse"
    ],
    "MenusResponseOrError": [
      "GeneralError",
      "MenusResponse"
    ],
    "OPTAddToTabResponseOrError": [
      "AddToOPTTabResponse",
      "OPTTabError"
    ],
    "OPTPartyCartResponse": [
      "OPTPartyError",
      "OrderAtTableCart"
    ],
    "OPTPartyRefreshResponse": [
      "OPTPartyError",
      "OPTPartyRefresh"
    ],
    "OPTPartyResponse": [
      "OPTParty",
      "OPTPartyError"
    ],
    "OPTPartyStubResponse": [
      "OPTPartyError",
      "OPTPartyStub"
    ],
    "OPTSplitPaymentChargesResponseOrError": [
      "OPTPartyError",
      "OPTSplitPaymentChargesResponse"
    ],
    "OPTTabHistoryResponse": [
      "OPTTabError",
      "OPTTabHistoryOrder"
    ],
    "OPT_CheckSelection": [
      "OPT_CheckSelectionGuid",
      "OPT_CheckSelectionNoGuid"
    ],
    "OPT_CompleteProfileCreationResponseOrError": [
      "OPT_CompleteProfileCreationError",
      "OPT_CompleteProfileCreationResponse"
    ],
    "OPT_CreateAccountResponseOrError": [
      "OPT_CreateAccountError",
      "OPT_CreateAccountResponse"
    ],
    "OPT_CreateConfirmedAccountResponseOrError": [
      "OPT_AuthenticationResponse",
      "OPT_CreateAccountError"
    ],
    "OPT_CreatePaymentIntentResponse": [
      "OPT_PaymentIntent",
      "OPT_PaymentIntentError"
    ],
    "OPT_DeleteAddressResponseOrError": [
      "OPT_DeleteAddressError",
      "OPT_DeleteAddressResponse"
    ],
    "OPT_DeleteCreditCardResponseOrError": [
      "OPT_DeleteCreditCardError",
      "OPT_DeleteCreditCardResponse"
    ],
    "OPT_Error": [
      "OPT_CompleteProfileCreationError",
      "OPT_CreateAccountError",
      "OPT_DeleteAddressError",
      "OPT_DeleteCreditCardError",
      "OPT_GeneralError",
      "OPT_GetMenusError",
      "OPT_GetPopularItemsError",
      "OPT_GetSpotlightMessageError",
      "OPT_GetTableRelationshipsError",
      "OPT_ImportAccountError",
      "OPT_ItemFeedbackSurveyError",
      "OPT_LoginError",
      "OPT_LoyaltySignupError",
      "OPT_MakeCreditCardPrimaryError",
      "OPT_OPTCartError",
      "OPT_OPTOrderError",
      "OPT_OPTPartiesLookupError",
      "OPT_OPTPartyError",
      "OPT_OPTPartyPaymentError",
      "OPT_OPTPartyPaymentErrorV2",
      "OPT_OPTSplitCheckPaymentError",
      "OPT_PayForCheckError",
      "OPT_PlaceOrderCartUpdatedError",
      "OPT_PlaceOrderError",
      "OPT_PlaceOrderOutOfStockError",
      "OPT_RefreshError",
      "OPT_ResetPasswordError",
      "OPT_SignUpToLoyaltyError",
      "OPT_StartProfileCreationError",
      "OPT_UpdateAddressError",
      "OPT_UpdateBasicInfoError",
      "OPT_UpdatePasswordError",
      "OPT_ValidateApplePayMerchantError",
      "OPT_VerifyEmailError"
    ],
    "OPT_FeatureFlagResult": [
      "OPT_BooleanFeatureFlagResult",
      "OPT_NumericFeatureFlagResult",
      "OPT_StringFeatureFlagResult"
    ],
    "OPT_GetMenusResponseOrError": [
      "OPT_GetMenusError",
      "OPT_GetMenusResponse"
    ],
    "OPT_GetPopularItemsResponse": [
      "OPT_GetPopularItemsError",
      "OPT_PopularItemsV3"
    ],
    "OPT_GetSpotlightMessageResponseOrError": [
      "OPT_GetSpotlightMessageError",
      "OPT_GetSpotlightMessageResponse"
    ],
    "OPT_GetTableRelationshipsResponse": [
      "OPT_GetTableRelationshipsError",
      "OPT_TableRelationships"
    ],
    "OPT_ImportAccountResponseOrError": [
      "OPT_ImportAccountError",
      "OPT_ImportAccountResponse"
    ],
    "OPT_Info": [],
    "OPT_ItemFeedbackSurveyResponse": [
      "OPT_ItemFeedbackSurvey",
      "OPT_ItemFeedbackSurveyError"
    ],
    "OPT_ItemLevelFeedbackSubmissionsStatus": [
      "OPT_ItemLevelFeedbackSubmissionFailure",
      "OPT_ItemLevelFeedbackSubmissionSuccess"
    ],
    "OPT_LoginResponse": [
      "OPT_AuthenticationResponse",
      "OPT_LoginError",
      "OPT_MfaChallengeGeneratedResponse"
    ],
    "OPT_LoyaltySignupResponseOrError": [
      "OPT_LoyaltySignupError",
      "OPT_LoyaltySignupResponse"
    ],
    "OPT_MDSCustomServerNotificationResponse": [
      "OPT_MDSServerNotificationResponse",
      "OPT_OPTPartyError"
    ],
    "OPT_MakeCreditCardPrimaryResponseOrError": [
      "OPT_MakeCreditCardPrimaryError",
      "OPT_MakeCreditCardPrimaryResponse"
    ],
    "OPT_OPTCartResponse": [
      "OPT_OPTCart",
      "OPT_OPTCartError"
    ],
    "OPT_OPTCheckV2": [
      "OPT_OPTCheckV2Guid",
      "OPT_OPTCheckV2NoGuid",
      "OPT_OPTSplitCheckPreview"
    ],
    "OPT_OPTCloseoutResponse": [
      "OPT_OPTPartyPaymentErrorV2",
      "OPT_OPTPartyPaymentResponse",
      "OPT_OPTSplitCheckPaymentError"
    ],
    "OPT_OPTOrder": [
      "OPT_OPTOrderGuid",
      "OPT_OPTOrderNoGuid"
    ],
    "OPT_OPTPartiesLookupResponse": [
      "OPT_OPTParties",
      "OPT_OPTPartiesLookupError"
    ],
    "OPT_OPTPartyCartResponse": [
      "OPT_OPTCart",
      "OPT_OPTPartyError"
    ],
    "OPT_OPTPartyRefreshResponseV2": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyRefreshV2"
    ],
    "OPT_OPTPartyResponseV2": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyV2"
    ],
    "OPT_OPTPartyStubResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyStub"
    ],
    "OPT_OPTSplitCheckResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTSplitCheckPreview"
    ],
    "OPT_PartyMemberResponse": [
      "OPT_OPTPartyError",
      "OPT_OPTPartyMemberV2"
    ],
    "OPT_PayForCheckResponseOrError": [
      "OPT_PayForCheckError",
      "OPT_PayForCheckResponse"
    ],
    "OPT_PlaceApplePayOrderResponse": [
      "OPT_PlaceOrderCartUpdatedError",
      "OPT_PlaceOrderError",
      "OPT_PlaceOrderOutOfStockError",
      "OPT_PlaceOrderResponse"
    ],
    "OPT_PlaceOPTOrderResponse": [
      "OPT_OPTOrderError",
      "OPT_OPTPayForCheckResponse"
    ],
    "OPT_RefreshResponse": [
      "OPT_AuthenticationResponse",
      "OPT_RefreshError"
    ],
    "OPT_ResetPasswordResponse": [
      "OPT_AuthenticationResponse",
      "OPT_ResetPasswordError"
    ],
    "OPT_RestaurantOrError": [
      "OPT_GeneralError",
      "OPT_Restaurant"
    ],
    "OPT_SignUpToLoyaltyResponseOrError": [
      "OPT_SignUpToLoyaltyError",
      "OPT_SignUpToLoyaltyResponse"
    ],
    "OPT_StartProfileCreationResponseOrError": [
      "OPT_StartProfileCreationError",
      "OPT_StartProfileCreationResponse"
    ],
    "OPT_UpdateAddressResponseOrError": [
      "OPT_UpdateAddressError",
      "OPT_UpdateAddressResponse"
    ],
    "OPT_UpdateBasicInfoResponseOrError": [
      "OPT_UpdateBasicInfoError",
      "OPT_UpdateBasicInfoResponse"
    ],
    "OPT_UpdatePasswordResponseOrError": [
      "OPT_UpdatePasswordError",
      "OPT_UpdatePasswordResponse"
    ],
    "OPT_UpdatePaymentIntentResponse": [
      "OPT_PaymentIntentError",
      "OPT_UpdatePaymentIntentSuccess"
    ],
    "OPT_ValidateApplePayMerchantResponse": [
      "OPT_ValidateApplePayMerchantError",
      "OPT_ValidateApplePayMerchantSuccessResponse"
    ],
    "OPT_VerifyEmailResponse": [
      "OPT_AuthenticationResponse",
      "OPT_VerifyEmailError"
    ],
    "OPT_Warning": [
      "OPT_PlaceOrderWarning"
    ],
    "OfferBadge": [
      "MenuGroupOfferBadge",
      "MenuItemOfferBadge",
      "MenuOfferBadge"
    ],
    "OnlineOrderingSpiCreatePaymentIntentResponse": [
      "OnlineOrderingPaymentIntentError",
      "OnlineOrderingSpiCreatePaymentIntentSuccessResponse"
    ],
    "OrderAtTheTableGiftCardBalanceInquiryResponseOrError": [
      "OrderAtTheTableGiftCardBalanceInquiryError",
      "OrderAtTheTableGiftCardBalanceInquiryResponse"
    ],
    "Page": [
      "GuestCurrencyExpirableUnitPage"
    ],
    "PageItem": [
      "GuestCurrencyExpirableUnit"
    ],
    "PasswordlessLoginResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginResponse"
    ],
    "PasswordlessLoginUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLoginUnifiedResponse"
    ],
    "PasswordlessLogoutResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessLogoutResponse"
    ],
    "PasswordlessSignupResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessSignupResponse"
    ],
    "PasswordlessTokenResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenResponse"
    ],
    "PasswordlessTokenUnifiedResponseOrError": [
      "PasswordlessAuthenticationError",
      "PasswordlessTokenUnifiedResponse"
    ],
    "PayForCheckResponseOrError": [
      "PayForCheckError",
      "PayForCheckResponse"
    ],
    "PaymentIntentResponseOrError": [
      "AlternativePaymentError",
      "PaymentIntentResponse"
    ],
    "PlaceApmOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceApplePayOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceCashOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceCcOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceGiftCardOrderResponse": [
      "PlaceOrderCartUpdatedError",
      "PlaceOrderError",
      "PlaceOrderOutOfStockError",
      "PlaceOrderResponse"
    ],
    "PlaceOPTOrderResponse": [
      "OPTOrderError",
      "OPTPayForCheckResponse"
    ],
    "PopularItemsResponseOrError": [
      "PopularItemsError",
      "PopularItemsResponse"
    ],
    "ProfileLockedError": [
      "ProfileLockedForDeletionError"
    ],
    "PromoBannerBase": [
      "DeliveryBanner",
      "LoyaltyBanner",
      "PromoCodeBanner"
    ],
    "PromoBannerResponseOrError": [
      "GeneralError",
      "PromoBannerResponse"
    ],
    "RefreshResponse": [
      "AuthenticationResponse",
      "RefreshError"
    ],
    "RemovePromoCodeResponseOrError": [
      "CartModificationError",
      "CartOutOfStockError",
      "CartResponse"
    ],
    "ReorderResponseOrError": [
      "ReorderError",
      "ReorderResponse"
    ],
    "ResetPasswordResponse": [
      "AuthenticationResponse",
      "ResetPasswordError"
    ],
    "RestaurantOfferBadgeResponseOrError": [
      "GeneralError",
      "RestaurantOfferBadgeResponse"
    ],
    "RestaurantOfferBadgeV2ResponseOrError": [
      "GeneralError",
      "RestaurantOfferBadgeV2Response"
    ],
    "RestaurantOrError": [
      "GeneralError",
      "Restaurant"
    ],
    "RestaurantSearchResultsOrError": [
      "GeneralError",
      "RestaurantSearchResults"
    ],
    "SetupGuestResult": [
      "InvalidPhoneFormatError",
      "ProfileLockedForDeletionError",
      "SetupGuestSuccess",
      "SingleIdentityInputRequiredError",
      "UnexpectedAuthError"
    ],
    "SignUpToLoyaltyResponseOrError": [
      "SignUpToLoyaltyError",
      "SignUpToLoyaltyResponse"
    ],
    "StartProfileCreationResponseOrError": [
      "StartProfileCreationError",
      "StartProfileCreationResponse"
    ],
    "SubmitGuestFeedbackV2Response": [
      "GuestFeedbackV2Error",
      "GuestFeedbackV2SuccessResponse"
    ],
    "UpdateAddressResponseOrError": [
      "UpdateAddressError",
      "UpdateAddressResponse"
    ],
    "UpdateBasicInfoResponseOrError": [
      "UpdateBasicInfoError",
      "UpdateBasicInfoResponse"
    ],
    "UpdateGuestAddressResult": [
      "GuestAddressNotFoundError",
      "UnexpectedError",
      "UpdateGuestAddressSuccess"
    ],
    "UpdateGuestContactResult": [
      "InvalidNameError",
      "UnexpectedError",
      "UpdateGuestContactSuccess"
    ],
    "UpdatePasswordResponseOrError": [
      "UpdatePasswordError",
      "UpdatePasswordResponse"
    ],
    "UpdatePrimaryGuestPaymentCardResult": [
      "GuestPaymentCardNotFoundError",
      "UnexpectedError",
      "UpdatePrimaryGuestPaymentCardSuccess"
    ],
    "ValidateApplePayMerchantResponse": [
      "ValidateApplePayMerchantError",
      "ValidateApplePayMerchantSuccessResponse"
    ],
    "ValidationError": [
      "InvalidEmailError",
      "InvalidNameError",
      "InvalidPhoneFormatError",
      "SingleIdentityInputRequiredError"
    ],
    "VerifyEmailResponse": [
      "AuthenticationResponse",
      "VerifyEmailError"
    ],
    "Walkout_WalkoutResponse": [
      "Walkout_WalkoutError",
      "Walkout_WalkoutSuccess"
    ],
    "Warning": [
      "CartWarning",
      "DeliveryUnavailableReason",
      "PlaceOrderWarning",
      "ReorderWarning"
    ]
  }
}
    
// Generated on 2024-02-27T12:55:12-05:00
