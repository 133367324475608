import { PaymentMethod } from '../../../../hooks/place-order/use-available-payment-methods'
import {
  GetPaymentFormInitialValuesParams,
  PaymentFormKeys
} from '../../types/types'
import { CheckoutMode } from '../../checkout-modes'
import { PaymentType, SelectedPayment } from '../constants'
import { getIsApplePayPreauthAvailable } from '../../../../utils/apple-pay/apple-pay-utils'

// start preauthed card, then apple pay, then go to the primary card, then nothing
export function getDefaultPaymentMethodGuid(
  paymentMethods: PaymentMethod[]
): string {
  const preauthedGuid = paymentMethods.find(
    (pm) =>
      pm.type === 'SavedCreditCard' &&
      pm.paymentMethodDetails.isPreauthed === true
  )?.guid
  const isApplePayPreauthAvailable =
    getIsApplePayPreauthAvailable(paymentMethods)

  if (preauthedGuid) {
    return isApplePayPreauthAvailable ? 'ApplePay' : preauthedGuid
  }

  const applePayGuid = paymentMethods.find((pm) => pm.type === 'ApplePay')?.guid

  if (applePayGuid) {
    return applePayGuid
  }

  return (
    paymentMethods.find((pm) => pm.isPrimary)?.guid ??
    paymentMethods.find((pm) => pm.type === 'SavedCreditCard')?.guid ??
    'NewCreditCard'
  )
}

function getDefaultPaymentMethodType(paymentMethods: PaymentMethod[]) {
  const isApplePay = getDefaultPaymentMethodGuid(paymentMethods) === 'ApplePay'
  if (isApplePay) {
    return PaymentType.APPLE_PAY
  }
  return PaymentType.CREDIT_CARD
}

function getDefaultSelectedPayment(
  paymentMethods: PaymentMethod[]
): SelectedPayment {
  const type = paymentMethods.find((pm) => pm.isPrimary)?.type

  if (type === 'SavedCreditCard') {
    return SelectedPayment.SAVED_CARD
  }

  if (type === 'ApplePay') {
    return SelectedPayment.APPLE_PAY
  }

  return SelectedPayment.NEW_CARD
}

/**
 * Payment info (i.e credit card input) slice of the payment form
 */
export type PaymentInfoPaymentFormValues = {
  [PaymentFormKeys.ENCRYPTED_CARD]:
    | string
    | {
        encryptionKeyId: string
        cardFirst6: string
        cardLast4: string
        zipCode: string
        expMonth: string
        expYear: string
      }
  [PaymentFormKeys.PAYMENT_TYPE]: keyof typeof PaymentType | ''
  [PaymentFormKeys.PASSWORD]?: string
  [PaymentFormKeys.SAVE_CARD]?: boolean
  [PaymentFormKeys.SAVED_CARD_GUID]?: string
  [PaymentFormKeys.SELECTED_PAYMENT]?: SelectedPayment
  [PaymentFormKeys.SPI_PAYMENT_TYPE]?: string
}

export interface PaymentInfoInitialValueParams {}

export const getInitialValues = ({
  paymentMethods,
  mode,
  applePayEnabled = false
}: GetPaymentFormInitialValuesParams): PaymentInfoPaymentFormValues => {
  const isApplePayPreauthAvailable =
    getIsApplePayPreauthAvailable(paymentMethods)
  const defaultCardGuid = getDefaultPaymentMethodGuid(paymentMethods)
  const defaultPaymentType = getDefaultPaymentMethodType(paymentMethods)
  const defaultSelectedPayment = getDefaultSelectedPayment(paymentMethods)

  switch (mode) {
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE:
      return applePayEnabled
        ? {
            savedCardGuid: 'ApplePay',
            encryptedCard: '',
            selectedPayment: SelectedPayment.APPLE_PAY,
            paymentType: PaymentType.APPLE_PAY
          }
        : {
            encryptedCard: '',
            selectedPayment: SelectedPayment.NEW_CARD,
            paymentType: PaymentType.CREDIT_CARD
          }
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
      return {
        password: '',
        saveCard: false,
        savedCardGuid: defaultCardGuid,
        encryptedCard: '',
        paymentType: PaymentType.CREDIT_CARD,
        selectedPayment: isApplePayPreauthAvailable
          ? SelectedPayment.APPLE_PAY
          : defaultSelectedPayment
      }
    case CheckoutMode.CREATE_TAB_MODE:
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    default:
      return {
        selectedPayment: isApplePayPreauthAvailable
          ? SelectedPayment.APPLE_PAY
          : defaultSelectedPayment,
        password: '',
        saveCard: false,
        savedCardGuid: defaultCardGuid,
        encryptedCard: '',
        paymentType: defaultPaymentType
      }
  }
}
