import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

export const PageHeader = ({
  header,
  className,
  headerStyle = 'm-0 text-xxl'
}) => (
  <div
    data-testid='restaurant-info-container'
    className={cx('mx-6 mt-2', className)}
  >
    <h4 className={headerStyle}>{header}</h4>
  </div>
)

PageHeader.propTypes = {
  header: PropTypes.string.isRequired,
  className: PropTypes.string
}
