import { HttpLink } from '@apollo/client'
import isNil from 'lodash/isNil'

declare global {
  interface Window {
    __SESSION__: {
      id: string
    }
  }
}

function filterEmptyHeaderValues(
  headers: Record<string, any>
): Record<string, any> {
  return Object.fromEntries(
    Object.entries(headers).filter(([, v]) => !isNil(v))
  )
}

type HttpConfigRequirements = {
  BFF_BASE_URI: string
  RESTAURANT_GUID?: string
}

const getHttpLink = (config: HttpConfigRequirements) =>
  new HttpLink({
    uri: `${config.BFF_BASE_URI}/graphql`,
    credentials: 'same-origin',
    headers: filterEmptyHeaderValues({
      'Toast-Restaurant-External-ID': config.RESTAURANT_GUID,
      'Toast-Session-ID':
        typeof window !== 'undefined' ? window.__SESSION__?.id : null
    })
  })

export default getHttpLink
