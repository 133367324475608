import PropTypes from 'prop-types'

const LocationShape = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  phone: PropTypes.string
})

const ImageSetShape = PropTypes.shape({
  raw: PropTypes.string
})

const SocialMediaLinksShape = PropTypes.shape({
  twitterLink: PropTypes.string,
  facebookLink: PropTypes.string
})

export const RestaurantShape = PropTypes.shape({
  location: LocationShape,
  guid: PropTypes.string,
  imageUrl: PropTypes.string,
  bannerUrls: ImageSetShape,
  name: PropTypes.string,
  socialMediaLinks: SocialMediaLinksShape
})
