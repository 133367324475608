import * as React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { useMenuQuery } from '../../components/UseMenuQuery/UseMenuQuery'
import { MenuV2 } from '../../apollo/generated/OptWebGraphQLOperations'
import { dataByTypename } from '../../utils/apollo-helpers'

export const useMenus = () => {
  const {
    loading,
    error,
    data: response,
    refetch,
    networkStatus
  } = useMenuQuery()

  const menus = React.useMemo(() => {
    if (!response) {
      return null
    }

    const menus = response.getMenus?.menus

    const menuDataClone = cloneDeep(menus)

    // Do not display menus or menu groups with no items
    return menuDataClone?.filter((menu: MenuV2) => {
      menu.groups = menu.groups?.filter((group) => {
        return group.items && group.items.length > 0
      })
      return menu.groups && menu.groups.length > 0
    })
  }, [response])

  const { GetMenusError } = dataByTypename(response?.getMenus)

  // return entire response as well as filtered menus
  return {
    loading,
    refetch,
    error: error || GetMenusError,
    networkStatus,
    menus,
    data: response
  }
}

// filter out groups with no items and menus with no groups
export const filterEmptyMenus = (menus: MenuV2[]) =>
  menus.reduce((acc: MenuV2[], menu: MenuV2) => {
    const groups = menu.groups?.filter((group) => group.items.length > 0)
    if (groups.length > 0) {
      const filteredMenu = { ...menu, groups }
      acc.push(filteredMenu)
    }
    return acc
  }, [])
