import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { TipFormValues } from '../getInitialValues'
import { PreComputedTip } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { PaymentFormKeys } from '../../types/types'
import isEqual from 'lodash/isEqual'
import { FieldLabel } from '@/il8n/en'
import { Field, FormikHelpers as FormikActions, FormikTouched } from 'formik'
import Radio from '../../../Form/Radio/Radio'
import Input from '../../../Form/Input/Input'
import { TipConfigStates, useEvaluateTipConfig } from './useEvaluateTipConfig'
import cx from 'classnames'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { getRawTipValue } from './getRawTipValue'

import styles from './Tip.module.css'
import { FormatCurrency } from '../../../Currency/FormatCurrency'
import { useRestaurantInfo } from '../../../../hooks/restaurant-info/use-restaurant-info'
import {
  Currency,
  getCurrencySymbol
} from '@toasttab/buffet-pui-number-utilities'
import { Locale } from '@toasttab/buffet-pui-locale-utilities'
import { SELECTED_TIP, getSelectedPrecomputedTip } from './helpers'

interface TipComponentProps {
  preComputedTips?: Omit<PreComputedTip, 'percent'>[] | null
  values: TipFormValues
  setFieldValue: FormikActions<TipFormValues>['setFieldValue']
  touched: FormikTouched<TipFormValues>
  checkHasGratuityServiceCharges?: boolean
  shouldPrependZeroTip?: boolean
}

function usePrevious<T>(value: T | null): T | null {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

interface GetPrecomputedTipsViewProps {
  preComputedTips?: Omit<PreComputedTip, 'percent'>[] | null
  returnOnlyZero?: boolean
  reverseOrder?: boolean
}

// The goal of this component is to easily modify the arrangement of tips in the component.
// returnOnlyZero: boolean value that if true, returns only the 0% tip option, should it exist in preComputedTips
// reverseOrder: reverses the order from smallest -> greatest to greatest -> least. Also reverses the 'no tip' and 'custom' placement.
const GetPrecomputedTipsView: React.FC<GetPrecomputedTipsViewProps> = ({
  preComputedTips,
  returnOnlyZero = false,
  reverseOrder = false
}) => {
  const newPrecomputedTips = useMemo(() => {
    return reverseOrder ? preComputedTips?.slice().reverse() : preComputedTips
  }, [reverseOrder, preComputedTips])

  return (
    <>
      {newPrecomputedTips
        ?.filter((tip) => {
          return returnOnlyZero ? tip.value === 0 : tip.value !== 0
        })
        ?.map((tip, index) => (
          <Field
            component={Radio}
            variant='chunky'
            label={
              <>
                <span className={styles.percent}>
                  {tip.value === 0 ? 'No tip ' : `${tip.percentLabel}%`}
                </span>
                {Boolean(tip.value) ? (
                  <span className={styles.dollars}>
                    <FormatCurrency amount={tip.value} />
                  </span>
                ) : (
                  <span className={styles.dollars}></span>
                )}
              </>
            }
            labelClassName={tip.value === 0 ? 'h-8' : ''}
            data-testid={'percent-tip-' + tip.percentLabel}
            name={PaymentFormKeys.CREDIT_CARD_TIP}
            id={`tip_${returnOnlyZero ? newPrecomputedTips.length : index}`}
            key={`tip_${returnOnlyZero ? newPrecomputedTips.length : index}`}
            value={tip.value.toFixed(2)}
          />
        ))}
    </>
  )
}

export const TipComponent: React.FC<TipComponentProps> = ({
  preComputedTips,
  values,
  touched,
  setFieldValue,
  checkHasGratuityServiceCharges = false,
  shouldPrependZeroTip = false
}) => {
  const [focusOnInputMount, setFocusOnInputMount] = useState(false)

  const previousPreComputedTips = usePrevious(preComputedTips)

  const { data } = useRestaurantInfo()
  const currency = data?.i18n?.currency as Currency
  const locale = data?.i18n?.locale as Locale
  const currencySymbol = getCurrencySymbol(Currency[currency], locale)
  const creditCardConfig = data?.creditCardConfig

  // Currency mask using createNumberMask from 'text-mask-addons'
  const currencyMaskOptions = {
    prefix: currencySymbol,
    suffix: '',
    includeThousandsSeparator: false,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 6, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false
  }

  const currencyMask = createNumberMask({
    ...currencyMaskOptions
  })

  useEffect(() => {
    try {
      const { creditCardTip } = values
      const selectedTip = getSelectedPrecomputedTip(
        creditCardTip,
        preComputedTips
      )
      window.sessionStorage.setItem(
        SELECTED_TIP,
        JSON.stringify({
          value: creditCardTip,
          percent: selectedTip ? selectedTip.percentV2 : 'custom'
        })
      )
    } catch (e) {}
  }, [values, preComputedTips])

  // synchronize preComputedTip selections when values changes (e.g split payment selection changes)
  // but we want to remain on a specific preComputedTip selection
  useEffect(() => {
    if (!isEqual(preComputedTips, previousPreComputedTips)) {
      const oldTip = previousPreComputedTips?.find(
        (tip) =>
          values[PaymentFormKeys.CREDIT_CARD_TIP] &&
          tip.value.toFixed(2) ===
            getRawTipValue(values[PaymentFormKeys.CREDIT_CARD_TIP]).toFixed(2)
      )
      if (oldTip) {
        const newTip = preComputedTips?.find(
          (tip) => tip.percentV2 === oldTip.percentV2
        )
        if (newTip) {
          setFieldValue(
            PaymentFormKeys.CREDIT_CARD_TIP,
            newTip.value.toFixed(2)
          )
        }
      }
    }
  }, [preComputedTips, previousPreComputedTips, setFieldValue, values])

  const finalPrecomputedTips = useMemo(() => {
    if (!preComputedTips) {
      return preComputedTips
    }
    // Ensure uniqueness based on value
    const finalPrecomputedTipsImpl = preComputedTips.reduce((acc, el) => {
      const hasMatchingValue = Boolean(acc.find((pc) => el.value === pc.value))
      if (hasMatchingValue) {
        return acc
      }
      return acc.concat(el)
    }, [] as typeof preComputedTips)
    const hasZeroTip = finalPrecomputedTipsImpl?.some((t) => t.value === 0)
    if (!hasZeroTip && shouldPrependZeroTip) {
      finalPrecomputedTipsImpl.unshift({
        __typename: 'PreComputedTip',
        percentV2: 0.0,
        percentLabel: '0',
        value: 0.0
      })
    }
    return finalPrecomputedTipsImpl.slice(0, 4)
  }, [preComputedTips, shouldPrependZeroTip])

  const { creditCardTip } = values

  const precomputedTipIsSelected =
    finalPrecomputedTips?.some((tip) => {
      return tip.value.toFixed(2) === creditCardTip
    }) ?? false

  const tipState = useEvaluateTipConfig(creditCardConfig)

  const tipIsTouched = touched[PaymentFormKeys.CREDIT_CARD_TIP]
  const customTipRadioValue = useMemo(() => {
    if (!tipIsTouched && !creditCardTip) {
      return ' '
    }
    return precomputedTipIsSelected ? '' : creditCardTip
  }, [tipIsTouched, precomputedTipIsSelected, creditCardTip])

  // Handle special case of ' ' as Custom being pressed for the first time
  // and manually override as ''
  useEffect(() => {
    if (creditCardTip === ' ') {
      setFieldValue(PaymentFormKeys.CREDIT_CARD_TIP, '')
    }
  }, [setFieldValue, creditCardTip])

  const showCustomTipInput = useMemo(() => {
    if (!preComputedTips?.length) {
      return true
    }
    if (creditCardTip && !precomputedTipIsSelected) {
      return true
    }
    return Boolean(tipIsTouched && !precomputedTipIsSelected)
  }, [
    preComputedTips?.length,
    tipIsTouched,
    precomputedTipIsSelected,
    creditCardTip
  ])

  // When I have selected one of the pre-computed tips,
  // reset the focusOnInputMount tracker
  useEffect(() => {
    if (
      preComputedTips
        ?.map((pt) => pt.value)
        .includes(getRawTipValue(values.creditCardTip))
    ) {
      setFocusOnInputMount(false)
    }
  }, [preComputedTips, values.creditCardTip])

  if (tipState === TipConfigStates.HIDDEN) {
    return null
  }

  return (
    <div className='pb-6 pl-4 pr-4'>
      {checkHasGratuityServiceCharges ? (
        <div>
          <p className='font-bold text-default'>Add an additional tip</p>
          <p className='type-default text-gray-100 type-subhead'>
            Service charges are included below.
          </p>
        </div>
      ) : (
        <div>
          <p className='font-bold text-default'>Add a tip</p>
          <p className='type-default text-gray-100 type-subhead'>
            100% of the tip goes to the staff.
          </p>
        </div>
      )}
      <div data-testid='label-tip' className={cx(styles.tips, 'pt-4')}>
        <GetPrecomputedTipsView
          preComputedTips={finalPrecomputedTips}
          reverseOrder={false}
        />
      </div>
      {Boolean(preComputedTips?.length) && (
        <div className={cx(styles.tips)}>
          <GetPrecomputedTipsView
            preComputedTips={finalPrecomputedTips}
            returnOnlyZero={true}
          />
          <Field
            onClick={() => {
              setFocusOnInputMount(true)
            }}
            className='w-full xs:w-auto'
            component={Radio}
            variant='chunky'
            labelClassName='h-8'
            label={
              <>
                <span className={cx(styles.percent, '-mx-1.5')}>Custom</span>
              </>
            }
            data-testid={'custom_tip_radio'}
            name={PaymentFormKeys.CREDIT_CARD_TIP}
            id={`custom_tip_radio`}
            key={`custom_tip_radio`}
            value={customTipRadioValue}
          />
        </div>
      )}
      {showCustomTipInput && (
        <div className='pt-4'>
          <Field
            // only auto-focus if there are other preComputedTips to choose
            focusOnInputMount={focusOnInputMount}
            data-testid='custom-tip'
            component={Input}
            label={FieldLabel.CHECKOUT_TIP_CUSTOM_AMOUNT}
            type='text'
            name={PaymentFormKeys.CREDIT_CARD_TIP}
            inputMode='decimal'
            id='credit_card_tip'
            key='credit_card_tip'
            fsUnmask
            mask={currencyMask}
          />
        </div>
      )}
    </div>
  )
}

TipComponent.propTypes = {
  values: PropTypes.shape({
    [PaymentFormKeys.CREDIT_CARD_TIP]: PropTypes.string.isRequired
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  preComputedTips: PropTypes.arrayOf(
    PropTypes.shape({
      percentV2: PropTypes.number.isRequired,
      percentLabel: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    }).isRequired
  )
}
