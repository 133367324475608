import * as React from 'react'
import { useUpdatePartyMember } from '../../../../client/hooks/party-member/use-update-party-member'
import { updatePartyMemberRequest$ } from 'cornucopia-apis'
import { ResponseTypes } from '../cornucopia.types'
import {
  ContactInfoType,
  OptPartyError
} from '../../../../client/apollo/generated/OptWebGraphQLOperations'
import { useSentry } from 'banquet-runtime-modules'

export const PartyMemberListener = () => {
  const { captureMessage, captureException } = useSentry()
  const [updatePartyMemberRequest, setUpdatePartyMemberRequest] =
    React.useState<any>()
  const updatePartyMember = useUpdatePartyMember()

  React.useEffect(() => {
    const subscription = updatePartyMemberRequest$.subscribe((request: any) => {
      setUpdatePartyMemberRequest(request)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [setUpdatePartyMemberRequest])

  React.useEffect(() => {
    if (updatePartyMemberRequest) {
      try {
        const {
          partyMember: { name, phoneNumber },
          header: { response$ }
        } = updatePartyMemberRequest
        setUpdatePartyMemberRequest(undefined)

        const updatableProperties = {
          name,
          contactInfo: {
            contactType: ContactInfoType.Phone,
            contact: phoneNumber
          }
        }

        updatePartyMember({
          updatableProperties,
          onSuccess: () => {
            response$.next({
              kind: ResponseTypes.OK,
              info: [
                {
                  code: '200',
                  message: `Update party member success`
                }
              ],
              warnings: []
            })
          },
          onError: (OPTPartyError: OptPartyError) => {
            captureMessage(OPTPartyError.message)
          }
        })
      } catch (e: any) {
        captureException(e)
      }
    }
  }, [
    setUpdatePartyMemberRequest,
    updatePartyMember,
    updatePartyMemberRequest,
    captureException,
    captureMessage
  ])

  return <></>
}
