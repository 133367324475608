export enum ErrorText {
  GENERIC_VIEW_MENUS = 'Some of our services are temporarily offline. You can view the menu here and place an order through your server or cashier.',
  WELCOME_VIEW_MENUS = 'You can view the menu here and place an order through your server or cashier.',
  FAILED_TO_FETCH = 'Sorry, we encountered a problem connecting to our services.',
  RESTAURANT_INFO_UNAVAILABLE = 'Sorry, we encountered a problem retrieving some information from the restaurant',
  TRY_REFRESH = 'You can also try refreshing this page.',
  SERVICES_OFFLINE = 'Our services are temporarily offline.',
  SERVER_HELP = 'Your server or cashier would be happy to help you.',
  MISSING_PARTY_DATA = 'Sorry, we encountered a problem retrieving some information about your party. Please try again.',
  LOYALTY_UNAVAILABLE_AFTER_SPLIT = 'The reward cannot be applied after a check is split. Talk to your server to apply it.'
}
