// Inhouse ad-server
export const PREPROD_INHOUSE_DECISION_URL =
  'https://ws-preprod-api.eng.toasttab.com/brands/v1/as-core/public/decision'

export const PROD_INHOUSE_DECISION_URL =
  'https://ws-api.toasttab.com/brands/v1/as-core/public/decision'

export const ADS_SERVER_INFO = {
  OPT_CHECKOUT: {
    site: 'OPT',
    zone: 'CHECKOUT'
  },
  STP_CHECKOUT: {
    site: 'STP_WEB',
    zone: 'CHECKOUT'
  }
}

export const DEV_DECISION_URL =
  'https://nvbrandspreproduction.herokuapp.com/v1/decision/'
export const DEV_CREDIT_REWARDS_URL =
  'https://nvbrandspreproduction.herokuapp.com/v1/creditRewards/'
export const DEV_KEVEL_INFO = {
  STP_CHECKOUT: {
    divName: 'div0',
    siteId: 1170055,
    zoneIds: [257388],
    adTypes: [4264]
  },
  OPT_CHECKOUT: {
    divName: 'div0',
    siteId: 1235452,
    zoneIds: [281124],
    adTypes: [4754]
  }
}

export const PROD_DECISION_URL =
  'https://nvbrandsproduction.herokuapp.com/v1/decision/'
export const PROD_CREDIT_REWARDS_URL =
  'https://nvbrandsproduction.herokuapp.com/v1/creditRewards/'
export const PROD_KEVEL_INFO = {
  STP_CHECKOUT: {
    divName: 'div1',
    siteId: 1173591,
    zoneIds: [257392],
    adTypes: [4264]
  },
  OPT_CHECKOUT: {
    divName: 'div1',
    siteId: 1236160,
    zoneIds: [281207],
    adTypes: [4754]
  }
}

export const PERCENTAGE_THRESHOLD = 0.8
