import React from 'react'
import PropTypes from 'prop-types'

import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { CreditCardIframe } from './CreditCardIframe'

export const getIframeSrc = ({
  amexAccepted,
  guestExpressMicrospasEnabled,
  guestExpressBaseUri,
  toastwebBaseUri,
  restaurantGuid,
  shortUrl
}) => {
  const color = '%23183da3'
  const iframeSrc =
    process.env.CREDIT_CARD_IFRAME_URI || guestExpressMicrospasEnabled
      ? `${guestExpressBaseUri}/cc-form/${restaurantGuid}`
      : `${toastwebBaseUri}/${shortUrl}/v3/ccform`
  const iframeSrcColored = color ? iframeSrc + `?color=${color}` : iframeSrc
  const iframeUrl = new URL(iframeSrcColored)

  if (amexAccepted) {
    iframeUrl.searchParams.set('amexAccepted', true)
  }

  return iframeUrl.toString()
}

export const CreditCard = ({
  amexAccepted,
  guestExpressMicrospasEnabled,
  field,
  form
}) => {
  const { guestExpressBaseUri, toastwebBaseUri, restaurantGuid, shortUrl } =
    useDDIGlobals()
  const onChange = (data) => {
    form.setFieldValue(field.name, data)
    form.setFieldTouched(field.name, true)
  }
  const iframeSrc = getIframeSrc({
    amexAccepted,
    guestExpressMicrospasEnabled,
    guestExpressBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    shortUrl
  })

  return (
    <CreditCardIframe src={iframeSrc} onChange={(data) => onChange(data)} />
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired
})

const FormShape = PropTypes.shape({
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired
})

CreditCard.defaultValues = {
  amexAccepted: false,
  guestExpressMicrospasEnabled: false
}

CreditCard.propTypes = {
  amexAccepted: PropTypes.bool,
  guestExpressMicrospasEnabled: PropTypes.bool,
  field: FieldShape.isRequired,
  form: FormShape.isRequired
}
