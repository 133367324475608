import React from 'react'
import { ErrorText } from '@/il8n/en'
import { Notification } from '../Notification/Notification'
import { OPTPartySplashHeader } from '../OPTPartySplashProvider/OPTPartySplash'
import { LinkButton } from '@toasttab/buffet-pui-buttons'
import { WarningOutlineIcon } from '@toasttab/buffet-pui-icons'
import { Restaurant } from '../../apollo/generated/OptWebGraphQLOperations'

import Modal from '../Modal/Modal'

export interface FullScreenErrorProps {
  children: React.ReactNode
  title?: string
  retry?(): void
  hasRefreshSuggestion?: boolean
  restaurant?: Restaurant
}

export const FullScreenError = ({
  children,
  title,
  retry,
  restaurant,
  hasRefreshSuggestion = false
}: FullScreenErrorProps) => {
  const { name: restaurantName } = restaurant || {}

  return (
    <Modal focus={false} zIndex={9995}>
      {restaurant && restaurant.name && restaurant.logoUrls ? (
        <div className='flex flex-col pb-6 align-center'>
          <OPTPartySplashHeader restaurant={restaurant} />
          <div className='px-6'>
            <div className='mt-3 mb-6 text-center'>
              <h3 className='font-medium' data-testid='propful-greeting'>
                {title ||
                  `Welcome${restaurantName ? ` to ${restaurantName}` : ''}!`}
              </h3>
            </div>

            <Notification
              variant={retry ? 'error' : 'warning'}
              marginBottom={false}
              testId={'full-screen-error-alert'}
            >
              {children}
              {retry && (
                <button
                  className='block px-0 font-semibold underline bg-transparent border-none cursor-pointer text-default'
                  onClick={retry}
                  data-testid='full-screen-error-retry'
                >
                  Click here to retry
                </button>
              )}
              {hasRefreshSuggestion && (
                <span
                  className='font-semibold underline cursor-pointer text-default'
                  onClick={() => {
                    window.location.reload()
                  }}
                >
                  {' '}
                  {ErrorText.TRY_REFRESH}
                </span>
              )}
            </Notification>
          </div>
        </div>
      ) : (
        <div className='flex flex-col items-center justify-center px-6 pb-6 text-center mt-60'>
          <div className='mb-6 text-disabled'>
            <WarningOutlineIcon size='lg' />
          </div>
          <h5 className='font-semibold type-headline-5'>
            {title || 'Unable to load this item'}
          </h5>
          <div className='px-6'>{children}</div>
          {retry && (
            <LinkButton
              onClick={retry}
              className='underline'
              testId='full-screen-error-retry'
            >
              Click here to retry
            </LinkButton>
          )}
          {hasRefreshSuggestion && (
            <p
              className='font-semibold underline cursor-pointer text-default'
              onClick={() => {
                window.location.reload()
              }}
            >
              {ErrorText.TRY_REFRESH}
            </p>
          )}
        </div>
      )}
    </Modal>
  )
}
