import { useMemo } from 'react'
import { OptCheckV2GuidFragment } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { useGetPartyRefresh, useGetPartyMembers } from '../PartyQuery'

/**
 * Gets a payment made by a member of a group. Should no payment be found with the claimedCheckGuid, 0 is returned.
 */
const getMemberPayment = (
  claimedCheckGuid: string,
  checks: OptCheckV2GuidFragment[]
) => {
  const amount = checks
    .filter((c) => c.payments.length > 0 && c.guid === claimedCheckGuid)
    .reduce((acc, c) => acc + c.total, 0)
  return Number(amount.toFixed(2))
}

export type MemberPayment = { name: string; memberGuid: string; amount: number }
/**
 * Creates a key/value pairing of members with their payments
 */
export const useGetMemberPayments = (): MemberPayment[] => {
  const { partyRefresh } = useGetPartyRefresh()
  const { members } = useGetPartyMembers()
  const checks = partyRefresh?.order?.checks

  return useMemo(() => {
    const paidMembers = members.filter(
      (member) => member.claimedCheckGuids.length > 0
    )
    let memberPayments: MemberPayment[] = []
    paidMembers.forEach((member) => {
      let memberPayment = {
        name: member.name,
        memberGuid: member.partyMemberGuid,
        amount: 0.0
      }

      member.claimedCheckGuids.forEach((guid) => {
        let amount = checks ? getMemberPayment(guid, checks) : 0.0
        if (amount) {
          memberPayment.amount = memberPayment.amount + amount
        }
      })

      if (memberPayment.amount > 0.0) {
        memberPayments.push(memberPayment)
      }
    })

    return memberPayments
  }, [members, checks])
}
