import { TypePolicies } from '@apollo/client'
import { isExpirationDateValid } from '../../utils/credit-card-date-validation'

export const creditCardsPolicy: TypePolicies = {
  SavedCreditCard: {
    fields: {
      expired: {
        read(_, { readField }) {
          const now = new Date(Date.now())
          return !isExpirationDateValid(
            readField('expirationMonth'),
            readField('expirationYear'),
            now
          )
        }
      }
    }
  }
}
