import { CustomerInfo } from '../apollo/generated/OptWebGraphQLOperations'
import React, { useCallback, useMemo } from 'react'
import { useExpiringStateWithNamespacedStorage } from '../utils/namespaced-storage'
import {
  getAuthStorage,
  authStorageKeys
} from '../components/AuthProvider/auth-storage'

/**
 * Representation of locally stored guest information over the course of all party flows.
 * E.g. Saving first name after starting party, email after preauthing, and re-populating
 * guest info among PAYG transactions.
 */
export interface GuestInfo {
  name?: string
  lastName?: string
  phone?: string
  email?: string
}

const EXPIRATION_DURATION = 1000 * 60 * 60 * 24 * 7
const authStorage = getAuthStorage()
const initialGuestInfo: GuestInfo = {
  name: undefined,
  lastName: undefined,
  email: undefined,
  phone: undefined
}

export interface GuestInfoContextValues {
  guestInfo?: GuestInfo
  guestName?: string
  guestLastName?: string
  guestEmail?: string
  guestPhone?: string
  updateGuestName: (name: string) => void
  updateGuestLastName: (lastName: string) => void
  updateGuestEmail: (email: string) => void
  updateGuestPhone: (phone: string) => void
  clearGuestInfo: () => void
  updateGuestInfoFromCustomer: (customer?: Partial<CustomerInfo>) => void
}

const initialGuestInfoContextValues: GuestInfoContextValues = {
  updateGuestName: () => {},
  updateGuestLastName: () => {},
  updateGuestEmail: () => {},
  updateGuestPhone: () => {},
  clearGuestInfo: () => {},
  updateGuestInfoFromCustomer: () => {}
}

const GuestInfoContext = React.createContext<GuestInfoContextValues>(
  initialGuestInfoContextValues
)

// In the long run, it would be cleaner to remove firstName, lastName, and email from TabProvider
// and make useGuestInfo the sole source of truth for those details.
export const GuestInfoProvider = ({
  children
}: React.PropsWithChildren<{}>) => {
  const [guestInfo, _updateGuestInfo] = useExpiringStateWithNamespacedStorage(
    authStorageKeys.GUEST_INFO,
    initialGuestInfo,
    authStorage,
    EXPIRATION_DURATION
  ) as [GuestInfo, React.Dispatch<React.SetStateAction<GuestInfo>>]

  const updateGuestInfoFromCustomer = useCallback(
    (customer?: Partial<CustomerInfo>) => {
      _updateGuestInfo((prevGuestInfo: GuestInfo) => ({
        name: customer?.firstName || prevGuestInfo.name,
        lastName: customer?.lastName || prevGuestInfo.lastName,
        email: customer?.email || prevGuestInfo.email,
        phone: customer?.phone || prevGuestInfo.phone
      }))
    },
    [_updateGuestInfo]
  )

  const updateGuestName = useCallback(
    (name) => {
      _updateGuestInfo((prevGuestInfo: GuestInfo) => ({
        ...prevGuestInfo,
        name
      }))
    },
    [_updateGuestInfo]
  )
  const updateGuestLastName = useCallback(
    (lastName) => {
      _updateGuestInfo((prevGuestInfo: GuestInfo) => ({
        ...prevGuestInfo,
        lastName
      }))
    },
    [_updateGuestInfo]
  )
  const updateGuestEmail = useCallback(
    (email) => {
      _updateGuestInfo((prevGuestInfo: GuestInfo) => ({
        ...prevGuestInfo,
        email
      }))
    },
    [_updateGuestInfo]
  )
  const updateGuestPhone = useCallback(
    (phone) => {
      _updateGuestInfo((prevGuestInfo: GuestInfo) => ({
        ...prevGuestInfo,
        phone
      }))
    },
    [_updateGuestInfo]
  )

  const clearGuestInfo = useCallback(() => {
    _updateGuestInfo(initialGuestInfo)
  }, [_updateGuestInfo])

  const guestInfoContextValues: GuestInfoContextValues = useMemo(
    () => ({
      guestInfo,
      guestName: guestInfo.name,
      guestLastName: guestInfo.lastName,
      guestEmail: guestInfo.email,
      guestPhone: guestInfo.phone,
      updateGuestInfoFromCustomer,
      updateGuestName,
      updateGuestLastName,
      updateGuestEmail,
      updateGuestPhone,
      clearGuestInfo
    }),
    [
      guestInfo,
      updateGuestInfoFromCustomer,
      updateGuestName,
      updateGuestLastName,
      updateGuestEmail,
      updateGuestPhone,
      clearGuestInfo
    ]
  )

  return (
    <GuestInfoContext.Provider value={guestInfoContextValues}>
      {children}
    </GuestInfoContext.Provider>
  )
}

export const useGuestInfo = () => React.useContext(GuestInfoContext)
