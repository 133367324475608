import * as React from 'react'
import { useLocation, Redirect } from 'react-router'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'

export const MenuPageRedirect = () => {
  const location = useLocation()
  const { getRestaurantPath } = useRestaurant()
  return (
    <Redirect
      to={{
        pathname: getRestaurantPath('/'),
        search: location?.search
      }}
    />
  )
}
