import { useEffect, useState } from 'react'
import { useBrandsQuery } from '../apollo/generated/OptWebGraphQLOperations'
import { useLocation } from 'react-router'
import {
  PROD_DECISION_URL,
  DEV_DECISION_URL,
  PROD_KEVEL_INFO,
  DEV_KEVEL_INFO,
  PERCENTAGE_THRESHOLD,
  ADS_SERVER_INFO,
  PREPROD_INHOUSE_DECISION_URL,
  PROD_INHOUSE_DECISION_URL
} from '../components/Advertisement/const'
import { AdParams, AdPlacement } from '../components/Advertisement/types'
import { DDIMode } from '../types/DDIGlobals'
import { useRestaurant } from '../components/RestaurantProvider/RestaurantProvider'
import {
  PaymentType,
  SupportedPaymentMethods
} from '../components/CheckoutForm/PaymentInfo/constants'

import { envFromOrderHostname } from '@toasttab/do-secundo-env-from-orders-hostname'
import { useIsIntlRestaurant } from '../hooks/use-is-intl-restaurant'
import { useRestaurantInfo } from '../hooks/restaurant-info/use-restaurant-info'

export const PlacementTypes: {
  [name: string]: AdPlacement
} = {
  STP_CHECKOUT: 'STP_CHECKOUT',
  OPT_CHECKOUT: 'OPT_CHECKOUT'
}

export const useBranding = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  return queryParams.get('brand')
}

// store url parameter in session storage to retrieve later since url params fall off easily
export const useBrandingStore = () => {
  const brand = useBranding()
  return {
    set: () => {
      if (brand) {
        sessionStorage.setItem('brand', brand)
      }
    },
    get: () => {
      return sessionStorage.getItem('brand')
    }
  }
}

export const isEligibleForBranding = (placement?: string) => {
  return Boolean(
    placement === PlacementTypes.STP_CHECKOUT ||
      placement === PlacementTypes.OPT_CHECKOUT
  )
}

export const useIsBrandingEnabled = ({
  restaurantGuid,
  mode
}: {
  restaurantGuid?: string
  mode?: DDIMode | 'STP' | 'OPT' | 'MNP' | 'TTS'
}): {
  isBrandingEnabled: boolean
  adPlacement?: AdPlacement
  isPending: boolean
} => {
  const rx = useRestaurant().restaurantGuid
  const storedBrand = useBrandingStore().get()
  const urlBrand = useBranding()
  const brand = urlBrand || storedBrand
  const isIntlRx = useIsIntlRestaurant()

  // this will need to be more sophisticated once we add more zones to each inventory but it works for now
  let adPlacement: AdPlacement | undefined
  switch (mode) {
    case 'STP':
      adPlacement = PlacementTypes.STP_CHECKOUT
      break
    case 'OPT':
      adPlacement = PlacementTypes.OPT_CHECKOUT
      break
    case 'TTS':
      adPlacement = PlacementTypes.OPT_CHECKOUT
      break
    default:
      adPlacement = undefined
      break
  }

  if (!adPlacement || isIntlRx) {
    return { isBrandingEnabled: false, isPending: false }
  } else if (!brand && adPlacement === PlacementTypes.STP_CHECKOUT) {
    return {
      isBrandingEnabled: false,
      adPlacement: undefined,
      isPending: false
    }
  }

  const query = useBrandsQuery
  const { data } = query({
    variables: {
      input: {
        restaurantGuid: restaurantGuid || rx || '',
        adPlacement
      }
    }
  })

  return {
    isPending: data?.nvBrandingConfig?.isNvBrandingEnabled === undefined,
    isBrandingEnabled: Boolean(
      data?.nvBrandingConfig?.isNvBrandingEnabled && !isIntlRx
    ),
    adPlacement: adPlacement
  }
}

interface ApiInfoResponse {
  url: string
  params:
    | {
        placements: {
          divName: string
          siteId: number
          zoneIds: number[]
          adTypes: number[]
        }[]
        keywords: (string | null)[]
      }
    | {
        site: string
        zone: string
        keywords?: (string | null)[]
        restaurantId?: string
        city: string | undefined
        state: string | undefined
      }
}

export const getApiDetail = (
  adPlacement: AdPlacement,
  brand: string | null,
  isKevel: boolean,
  city?: string,
  state?: string,
  keyword?: string | null,
  restaurantGuid?: string
): ApiInfoResponse | null => {
  const env = envFromOrderHostname(window.location.hostname)
  const isProd = env === 'prod'
  let url
  let params
  const keywords = adPlacement === PlacementTypes.STP_CHECKOUT ? [brand] : []
  if (keyword) {
    keywords.push(keyword)
  }

  if (isKevel) {
    url = isProd ? PROD_DECISION_URL : DEV_DECISION_URL
    const placements = isProd
      ? PROD_KEVEL_INFO[adPlacement]
      : DEV_KEVEL_INFO[adPlacement]

    params = {
      placements: [placements],
      keywords: keywords
    }
  } else {
    url = isProd ? PROD_INHOUSE_DECISION_URL : PREPROD_INHOUSE_DECISION_URL
    params = {
      ...ADS_SERVER_INFO[adPlacement],
      keywords: keywords,
      restaurantId: restaurantGuid,
      city: city,
      state: state
    }
  }

  return { url, params }
}

export const getBrandingDecision = async ({
  adPlacement,
  brand,
  orderTotal,
  keyword,
  isKevel,
  restaurantGuid,
  city,
  state
}: {
  adPlacement: AdPlacement
  brand: string | null
  orderTotal: number
  keyword?: string | null
  isKevel: boolean
  restaurantGuid?: string
  city: string | undefined
  state: string | undefined
}): Promise<AdParams | null> => {
  if (!isEligibleForBranding(adPlacement)) {
    return null
  }

  const apiInfo = getApiDetail(
    adPlacement,
    brand,
    isKevel,
    city,
    state,
    keyword,
    restaurantGuid
  )
  if (!apiInfo) {
    return null
  }
  const { url, params } = apiInfo

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  }).catch(() => {
    return null
  })

  if (response && response.status === 200) {
    let responseData: any
    let metaData: any
    if (isKevel) {
      const { results } = await response.json()
      responseData = results[0]
      metaData = results?.[0]?.metaData
    } else {
      responseData = await response.json()
      metaData = responseData.metadata
    }

    const isSTP = adPlacement === PlacementTypes.STP_CHECKOUT
    const stpOrderMeetsThreshold =
      isSTP && orderTotal >= metaData?.minimumSpendAmount * PERCENTAGE_THRESHOLD

    // hide ad if brand does not match the url param on STP orders
    // hide ad if order does not meet the threshold minimum amount for order total for STP order
    // show ad for all OPT orders
    if (
      !isSTP ||
      (stpOrderMeetsThreshold &&
        metaData.brand.toUpperCase().includes(brand?.toUpperCase()))
    ) {
      return {
        // TODO: clean up field names mapping when we sunset kevel
        clickUrl: responseData.clickUrl,
        contentUrl: responseData.contentUrl || responseData.creativeUrl,
        impressionUrl: responseData.impressionUrl,
        metaData: metaData
      }
    }
  }

  return null
}

export const useQueryBrandedAd = ({
  partyMode,
  paymentAmount,
  restaurantGuid
}: {
  partyMode: DDIMode | 'STP' | 'OPT' | 'MNP' | 'TTS' | undefined
  paymentAmount?: number
  restaurantGuid?: string
}): {
  adData: AdParams | null
  isBrandingEnabled: boolean
} => {
  const [adData, setAdData] = useState<AdParams | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const storedBrand = useBrandingStore().get()
  const urlBrand = useBranding()

  // Query to fetch city and state for location targetting in ads-server
  const { data: restaurantInfo, loading: isRestaurantInfoLoading } =
    useRestaurantInfo()
  const { city, state } = restaurantInfo?.location || {}

  const Restaurant = useRestaurant()
  restaurantGuid = restaurantGuid || Restaurant.restaurantGuid
  const brand = urlBrand || storedBrand
  const {
    isBrandingEnabled: isKevel,
    adPlacement,
    isPending
  } = useIsBrandingEnabled({
    restaurantGuid,
    mode: partyMode
  })

  useEffect(() => {
    if (
      !isPending &&
      (paymentAmount || paymentAmount === 0) &&
      adPlacement &&
      !adData &&
      !isLoading &&
      !isRestaurantInfoLoading
    ) {
      setIsLoading(true)
      getBrandingDecision({
        adPlacement,
        brand,
        orderTotal: paymentAmount,
        isKevel,
        restaurantGuid,
        city,
        state
      })
        .then((decision) => {
          if (decision) {
            setAdData(decision)
          }
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paymentAmount,
    isKevel,
    adPlacement,
    adData,
    brand,
    isPending,
    isRestaurantInfoLoading
  ])
  return { adData, isBrandingEnabled: adData === null ? false : true }
}

/**
 * Converts a brands paymentMethod to support payment methods
 * for good inter-op with DigitalPaymentMethodsUI
 */
export function toSupportedPaymentMethod(
  paymentMethod?: PaymentType
): SupportedPaymentMethods | undefined {
  if (!paymentMethod) {
    return undefined
  } else if (paymentMethod.toUpperCase() === PaymentType.CREDIT_CARD) {
    return SupportedPaymentMethods.ToastEncryptedNewCard
  } else if (paymentMethod === PaymentType.CLICK_TO_PAY) {
    return SupportedPaymentMethods.Click2Pay
  }
  function snakeToUpperCamel(str: string): string {
    const lowerCamelCased = str
      .toLowerCase()
      .replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace('-', '').replace('_', '')
      )
    return lowerCamelCased.toUpperCase()[0] + lowerCamelCased.slice(1)
  }

  return snakeToUpperCamel(paymentMethod) as SupportedPaymentMethods
}
