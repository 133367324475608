import * as React from 'react'
import { useLocation } from 'react-router'
import { useParty } from '../components/PartyProvider/PartyProvider'

export type State = {
  partyProperties?: {
    partyGuid: string
    partyMemberGuid: string
    memberAuthToken: string
    pin?: string | null
  }
}

export interface LocationWithState {
  state: State
  search: Location['search']
}

/**
 * populates party data when landing on the main page via SMS rejoin link
 * This fixes the issue where starting on and then rejoining via incognito/private browser tab
 * caused a new session for private tab guests instead of taking them to their existing order
 */
export const useHandleRejoin = () => {
  const location: LocationWithState = useLocation()
  const { updatePartyProperties } = useParty()
  const redirectProperties = location.state?.partyProperties

  React.useEffect(() => {
    if (
      redirectProperties?.partyGuid &&
      redirectProperties?.partyMemberGuid &&
      redirectProperties?.memberAuthToken
    ) {
      if (!redirectProperties.pin) {
        redirectProperties.pin = null
      }
      updatePartyProperties(redirectProperties)
      delete location.state.partyProperties
    }
  }, [location.state, redirectProperties, updatePartyProperties])
}
