
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AppliedServiceCharge"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AppliedServiceCharge"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"externalId"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chargeType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"chargeAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"taxAmount"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"gratuity"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"serviceChargeDetails"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"guid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"percent"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"gratuity"},"arguments":[],"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"serviceChargeCategory"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":223}};
    doc.loc.source = {"body":"fragment AppliedServiceCharge on AppliedServiceCharge {\n  guid\n  name\n  externalId\n  chargeType\n  chargeAmount\n  taxAmount\n  gratuity\n  serviceChargeDetails {\n    guid\n    percent\n    gratuity\n  }\n  serviceChargeCategory\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
