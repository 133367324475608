import {
  OptCartFragment,
  OptPartyMemberV2Fragment
} from '../../apollo/generated/OptWebGraphQLOperations'
import { getPrimaryCheckFromOrder } from '../check-helpers/check-helpers'
import { Bucket } from '../order-helpers'

// Determine if there are selections in the cart.
export const doCartsHaveSelections = (carts: OptCartFragment[]) => {
  // get number of selections on all checks
  const numberOfTotalSelections =
    // traverse thru each order
    carts?.reduce(
      (orderSelectionCount, cart) =>
        // traverse thru each check
        cart.order.checks.reduce(
          (checkSelectionCount, check) =>
            //count selections
            check.numberOfSelections + checkSelectionCount,
          0
        ) + orderSelectionCount,
      0
    ) ?? 0
  return numberOfTotalSelections > 0
}

// Determine if there are selections in any buckets.
export const doBucketsHaveSelections = (buckets: Bucket[]) => {
  // get number of selections on all checks
  const numberOfTotalSelections =
    buckets?.reduce(
      (orderSelectionCount, buckets) =>
        buckets.check.numberOfSelections + orderSelectionCount,
      0
    ) ?? 0
  return numberOfTotalSelections > 0
}

// Sort so me is the top of the list and all empty carts
// are at the bottom.
export const sortMembersFromCarts = (
  cartsMap: Map<string, OptCartFragment>,
  members: OptPartyMemberV2Fragment[],
  me: OptPartyMemberV2Fragment
): OptPartyMemberV2Fragment[] => {
  return members.slice().sort((a, b) => {
    if (!a.cartGuid || !b.cartGuid) {
      return 0
    }

    const cartA = cartsMap.get(a.cartGuid)
    const cartB = cartsMap.get(b.cartGuid)

    if (a.partyMemberGuid === me.partyMemberGuid) {
      return -1
    } else if (b.partyMemberGuid === me.partyMemberGuid) {
      return 1
    } else if (
      getPrimaryCheckFromOrder(cartA?.order)?.numberOfSelections === 0
    ) {
      return 0
    } else if (
      getPrimaryCheckFromOrder(cartB?.order)?.numberOfSelections === 0
    ) {
      return -1
    }

    return 0
  })
}

// Function to rename members of a group. If the memberGuid is the same as
// the current party member's guid, rename to 'YOU'. Otherwise return the name in uppercase
// if there is only one person in a party, return no name.
export const getFilteredMemberName = (
  members: Bucket[],
  member: Pick<OptPartyMemberV2Fragment, 'name' | 'partyMemberGuid'>,
  self: OptPartyMemberV2Fragment
) => {
  // if there is only one party ordering, there is no need to display their name above items
  if (members.length === 1) {
    return ''
  }

  // make labeling more personal for the viewer in a group
  if (member.partyMemberGuid === self.partyMemberGuid) {
    return 'YOU'
  }

  return member.name.toUpperCase()
}
