import * as React from 'react'
import { CheckCircleSelectedDisplayIcon } from '@toasttab/buffet-pui-icons'

interface ConfirmationProps {
  headerCopy: string
  bodyCopy: string
}

export const Confirmation = ({ headerCopy, bodyCopy }: ConfirmationProps) => {
  return (
    <div className='flex flex-col items-center justify-center'>
      <CheckCircleSelectedDisplayIcon className='mb-4' size='2xl' />
      <div className='flex flex-col items-center justify-center w-full text-center'>
        <h4
          data-testid='confirmation-header-title'
          className='type-header-4 font-normal whitespace-pre-wrap'
        >
          {headerCopy}
        </h4>
        <p
          data-testid='confirmation-header-body'
          className='mt-4 text-center w-fit type-default'
        >
          {bodyCopy}
        </p>
      </div>
    </div>
  )
}
