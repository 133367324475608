import { useQuery } from '@apollo/client'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import { dataByTypename } from '../../utils/apollo-helpers'
import {
  OPT_LOOKUP_PARTY_BY_ORDER,
  OPT_LOOKUP_PARTY_BY_TABLE,
  OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN
} from '../../apollo/party/party.graphql'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { DDIMode } from '../../types/DDIGlobals'
import { useGetPartyMode } from '../../components/PartyQuery/PartyQuery'

export const usePartyByUniqueIdentifiers = () => {
  const { uniqueIdentifier } = useDDIGlobals()
  const { mode } = useGetPartyMode()
  const { restaurantGuid } = useRestaurant()

  let mutation = undefined
  const variables = {
    restaurantGuid
  }
  const shouldSkipPartyLookup = mode !== DDIMode.OPT && mode !== DDIMode.TTS

  switch (mode) {
    case DDIMode.STP:
      // TODO: should eventually be stp mutations, but we can pass in a dummy value for now, because it will be skipped
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      variables.checkGuid = uniqueIdentifier
      break
    case DDIMode.MNP:
      // TODO: should eventually be mnp mutations, but we can pass in a dummy value for now, because it will be skipped
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      variables.tableName = uniqueIdentifier
      break
    case DDIMode.TTS:
      mutation = OPT_LOOKUP_PARTY_BY_ORDER
      variables.orderGuid = uniqueIdentifier
      break
    default:
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      variables.tableName = uniqueIdentifier
      break
  }

  const results = useQuery(mutation, {
    variables,
    fetchPolicy: 'no-cache',
    skip: shouldSkipPartyLookup
  })
  const { OPTPartyStub } = dataByTypename(
    results.data?.optLookupPartyByTable || results.data?.optLookupPartyByOrder
  )
  return {
    ...results,
    party: OPTPartyStub
  }
}

export const usePartyByUniqueIdentifiersOrInvite = (
  { inviteCode },
  options = {}
) => {
  const { uniqueIdentifier } = useDDIGlobals()
  const { mode } = useGetPartyMode()
  const { restaurantGuid } = useRestaurant()

  let mutation = undefined
  let inviteMutation = undefined
  const variables = {
    restaurantGuid,
    pin: inviteCode
  }
  const shouldSkipPartyLookup = mode !== DDIMode.OPT && mode !== DDIMode.TTS
  const isTts = mode === DDIMode.TTS

  switch (mode) {
    case DDIMode.STP:
      // TODO: should eventually be stp mutations, but we can pass in a dummy value for now, because it will be skipped
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      inviteMutation = OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN
      variables.checkGuid = uniqueIdentifier
      break
    case DDIMode.MNP:
      // TODO: should eventually be mnp mutations, but we can pass in a dummy value for now, because it will be skipped
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      inviteMutation = OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN
      variables.tableName = uniqueIdentifier
      break
    case DDIMode.TTS:
      inviteMutation = OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN
      mutation = OPT_LOOKUP_PARTY_BY_ORDER
      variables.orderGuid = uniqueIdentifier
      break
    default:
      inviteMutation = OPT_LOOKUP_PARTY_BY_TABLE_AND_PIN
      mutation = OPT_LOOKUP_PARTY_BY_TABLE
      variables.tableName = uniqueIdentifier
      break
  }

  const prioritizeInviteResults = Boolean(inviteCode)

  const tableResults = useQuery(mutation, {
    variables: {
      restaurantGuid,
      tableName: isTts ? null : uniqueIdentifier,
      orderGuid: isTts ? uniqueIdentifier : null
    },
    fetchPolicy: 'no-cache',
    skip: prioritizeInviteResults || shouldSkipPartyLookup,
    notifyOnNetworkStatusChange: true
  })

  const inviteResults = useQuery(inviteMutation, {
    variables,
    skip: !prioritizeInviteResults || shouldSkipPartyLookup,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  })

  const results =
    inviteResults?.data?.optLookupPartyByTableAndPin ||
    tableResults?.data?.optLookupPartyByTable ||
    tableResults?.data?.optLookupPartyByOrder
  const { OPTPartyStub, OPTPartyError } = dataByTypename(results)

  return {
    ...(prioritizeInviteResults ? inviteResults : tableResults),
    party: OPTPartyStub,
    error:
      OPTPartyError ||
      (prioritizeInviteResults ? inviteResults.error : tableResults.error)
  }
}
