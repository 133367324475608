import * as React from 'react'
import { BottomDrawer } from '@toasttab/do-secundo-bottom-drawer'
import { Shelf } from 'cornucopia-apis'
import styles from './PaymentOptions.module.css'

export interface PaymentOptionsProps {
  isOpen: boolean
  onClose: () => void
}

export const PaymentOptions = ({ isOpen, onClose }: PaymentOptionsProps) => {
  return (
    <BottomDrawer
      isOpen={isOpen}
      onClose={onClose}
      data-corn-tabs={true}
      data-testid='opt-payment-options-drawer'
      customHeader={
        <div className='flex items-center justify-center h-16 mb-8 text-gray-100 border-t-0 border-b border-l-0 border-r-0 border-solid type-subhead'>
          GROUP TAB
        </div>
      }
      customRootId='opt-payment-options-drawer'
      className={styles.drawer}
    >
      <Shelf name='payment-options-shelf' params={{ onComplete: onClose }} />
    </BottomDrawer>
  )
}
