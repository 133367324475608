import namespacedStorage, { nameSpaces } from '../../utils/namespaced-storage'

export const authStorageKeys = {
  UNVERIFIED_CUSTOMER: 'unverifiedCustomer',
  RECOGNIZED_CUSTOMER: 'recognizedCustomer',
  UNIQUE_USER_ID: 'UNIQUE_USER_ID',
  GUEST_INFO: 'guestInfo'
}

export const getAuthStorage = () => {
  return namespacedStorage(nameSpaces.TOAST_OPT_AUTH)
}
