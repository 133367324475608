import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { onEnterOrSpace } from '../../../utils/key-handler'

import styles from './Radio.module.css'

const selectorStyles = Object.freeze({
  DEFAULT: 'default',
  TAB: 'tab',
  CHUNKY: 'chunky',
  NOBUTTON: 'defaultNoButton',
  GRAY: 'defaultGray',
  CHUNKYLEFT: 'chunkyleft'
})

const getStyle = (style) =>
  Object.values(selectorStyles).indexOf(style) !== -1
    ? style
    : selectorStyles.DEFAULT

const Radio = ({
  field,
  id,
  value,
  label,
  form,
  disabled,
  variant = selectorStyles.DEFAULT,
  onSelect,
  className,
  labelClassName,
  innerRef,
  ...props
}) => (
  <div
    data-testid='radio-input-container'
    className={cx(
      styles[`${getStyle(variant)}InputGroup`],
      styles[getStyle(variant)],
      className
    )}
  >
    <input
      data-testid='radio-input-control'
      ref={innerRef}
      className={styles[`${getStyle(variant)}Radio`]}
      name={field.name}
      id={id}
      type='radio'
      value={value}
      checked={field.value === value}
      onClick={onSelect}
      onChange={(...a) => {
        if (onSelect) {
          onSelect()
          return
        }
        form.setFieldTouched(field.name, true)
        field.onChange(...a)
      }}
      onBlur={field.onBlur}
      disabled={disabled}
      {...props}
    />
    <label
      data-testid='radio-label'
      className={cx(styles[`${getStyle(variant)}Label`], labelClassName)}
      htmlFor={id}
      tabIndex='0'
      onKeyDown={onEnterOrSpace(() => {
        if (!disabled) {
          if (onSelect) {
            onSelect()
            return
          }
          form.setFieldValue(field.name, value)
        }
      })}
    >
      {label}
    </label>
  </div>
)

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

Radio.propTypes = {
  field: FieldShape.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  style: PropTypes.string,
  onSelect: PropTypes.func,
  form: PropTypes.object,
  disabled: PropTypes.bool
}

export default Radio
export { selectorStyles }
