/**
 * Distinct flows through which the checkout/payment screen is handled,
 * which controls different behaviors for form initial values and submissions to API
 */
export enum CheckoutMode {
  PLACE_PAYG_ORDER_MODE = 'PLACE_PAYG_ORDER_MODE',
  CREATE_TAB_MODE = 'CREATE_TAB_MODE',
  CREATE_PREAUTH_TAB_MODE = 'CREATE_PREAUTH_TAB_MODE',
  CLOSE_PARTY_TAB_MODE = 'CLOSE_PARTY_TAB_MODE',
  CLOSE_PREAUTH_TAB_MODE = 'CLOSE_PREAUTH_TAB_MODE'
}
