import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@toasttab/buffet-pui-buttons'
import Modal from '../Modal/Modal'
import { useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import {
  OptPartyError,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { SplitPaymentMode } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import { useApplySplitType } from '../../hooks/split-payments/use-apply-split-type'
import { useSentry } from 'banquet-runtime-modules'

interface AlreadySplitDialogProps {
  showDialog: boolean
  setShowDialog: (newState: boolean) => void
  setShowDrawer: (newState: boolean) => void
  toSplitMode: SplitMode
  selectedTotalShares?: number
  selectedClaimedShares?: number
}

export const AlreadySplitDialog = ({
  showDialog,
  setShowDialog,
  setShowDrawer,
  toSplitMode,
  selectedTotalShares,
  selectedClaimedShares
}: AlreadySplitDialogProps) => {
  const { captureMessage } = useSentry()
  let { partyRefresh, loading: partyRefreshLoading } = useGetPartyRefresh()
  const actualSplitMethod = partyRefresh?.splitPaymentData?.splitMode
  const actualTotalShares = partyRefresh?.splitPaymentData?.evenSplitPortions
  const { applySplitType, loading: applySplitTypeLoading } = useApplySplitType()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()
  const [userFacingError, setUserFacingError] = useState<string>()

  const keepSplit = () => {
    setShowDialog(false)
    setShowDrawer(true)
  }

  const updateSplit = () => {
    applySplitType({
      onComplete: () => {
        history.push({
          pathname: getRestaurantPath('tab/close'),
          search: `?paymentMode=${SplitPaymentMode.SPLIT}`
        })
      },
      onError: (error: OptPartyError) => {
        setUserFacingError(error.message)
        captureMessage(error.message, 'warning')
      },
      setUserFacingError,
      splitPaymentInitializationData: {
        splitMode: toSplitMode,
        evenSplitPortions: selectedTotalShares ?? 0
      },
      overrideSplit: true,
      newPortionsToBePaid: selectedClaimedShares,
      newTotalPortions: selectedTotalShares
    })
  }

  if (!showDialog || partyRefreshLoading) {
    return null
  }

  const splitCopy =
    actualSplitMethod === SplitMode.MyItems
      ? 'pay for their items'
      : `split the bill between ${actualTotalShares} people`

  return (
    <Modal
      header={
        <h4 className='font-medium whitespace-pre-wrap text-center'>
          Oops! Someone else has split the bill already
        </h4>
      }
      responsive={false}
      compactHeader={true}
      onClose={() => {
        setShowDialog(false)
      }}
    >
      <div className='flex flex-col px-4 space-y-6 items-center mb-6'>
        <div className='type-default' data-testid='splitTypeCopy'>
          A member of your party chose to {splitCopy}. We recommend talking to
          them and agreeing on how to split the bill.
        </div>
        {userFacingError && <Error>{userFacingError}</Error>}
        <Button
          onClick={keepSplit}
          variant={applySplitTypeLoading ? 'primary' : 'secondary'}
          className='w-full'
          testId='already-split-keep-button'
          disabled={applySplitTypeLoading}
        >
          Keep as is
        </Button>
        <Button
          onClick={updateSplit}
          variant={applySplitTypeLoading ? 'primary' : 'secondary'}
          className='w-full'
          testId='already-split-override-button'
          disabled={applySplitTypeLoading}
        >
          {applySplitTypeLoading ? (
            <Loading />
          ) : (
            <span>
              Change to split{' '}
              {toSplitMode === SplitMode.MyItems
                ? 'by item'
                : `between ${selectedTotalShares} people`}
            </span>
          )}
        </Button>
      </div>
    </Modal>
  )
}
