import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal/Modal'

import { OrderLayout } from '../OrderLayout/OrderLayout'
import Button from '../Button/Button'
import { CTA, HeaderText } from '@/il8n/en'
import {
  useGetTotalsRemaining,
  useGetPartyMode
} from '../PartyQuery/PartyQuery'
import { useJoinablePartyEnabled } from '../../hooks/party/use-party-enabled'

import { ConfirmPageContents } from '../ConfirmPage/ConfirmPage'
import { DDIMode } from '../../types/DDIGlobals'
import { ShowForUS } from '../ShowForUS/ShowForUS'
import GuestFeedback from '../GuestFeedback/GuestFeedback'

export const TabClosed = ({ onClose }) => {
  const joinablePartiesEnabled = useJoinablePartyEnabled()
  const { mode } = useGetPartyMode()
  const { remainingTotal, loading: totalsRemainingLoading } =
    useGetTotalsRemaining()
  let headerText = joinablePartiesEnabled
    ? HeaderText.THANK_YOU
    : HeaderText.YOUR_CHECK_IS_CLOSED_INTERRUPT

  if (
    joinablePartiesEnabled &&
    !totalsRemainingLoading &&
    remainingTotal === 0
  ) {
    headerText = HeaderText.YOUR_CHECK_IS_CLOSED_INTERRUPT
  }

  // on TTS, guests cannot start a new order from the app, they would need a new receipt printed out
  const hideNewOrderCtaForTts = onClose && mode === DDIMode.TTS

  const shouldShowPlaceOrder = onClose && !hideNewOrderCtaForTts

  return (
    <Modal responsive header={headerText} focus={false}>
      <OrderLayout body={<ConfirmPageContents />} />
      {shouldShowPlaceOrder && (
        <div className='flex justify-center'>
          <Button type='button' onClick={onClose} variant='link'>
            {CTA.CONFIRM_NEW_ORDER_CREATED}
          </Button>
        </div>
      )}
      <ShowForUS>
        <GuestFeedback />
      </ShowForUS>
    </Modal>
  )
}

TabClosed.propTypes = {
  onClose: PropTypes.func
}
