import { useMemo } from 'react'
import { LDFlags } from '../../launchdarkly/flags'
import { useFlag } from '../FeatureFlag/use-flag'
import {
  OptCheckV2Fragment,
  SurchargePaymentType,
  SurchargeSummaryForPaymentTypes
} from '../../apollo/generated/OptWebGraphQLOperations'

export interface CheckoutFormFeatureFlags {
  [LDFlags.EMAIL_MARKETING_PREFERENCES]: boolean
}

export const useFeatureFlagsForCheckoutModules =
  (): CheckoutFormFeatureFlags => {
    const marketingOptInAvailable = useFlag(LDFlags.EMAIL_MARKETING_PREFERENCES)

    return useMemo(
      () => ({
        [LDFlags.EMAIL_MARKETING_PREFERENCES]: marketingOptInAvailable
      }),
      [marketingOptInAvailable]
    )
  }

export const getCreditSurchargeFromCheck = (
  check: OptCheckV2Fragment | undefined
): SurchargeSummaryForPaymentTypes | undefined =>
  check && 'surcharges' in check
    ? check.surcharges?.find((s) =>
        s.paymentTypes.includes(SurchargePaymentType.Credit)
      )
    : undefined
