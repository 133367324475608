import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import {
  usePartyProperties,
  PartyPropertiesInterface
} from './usePartyProperties'
import { createUseInitializeParty } from './useInitializeParty'
import { HandlePartySideEffects } from './HandlePartySideEffects'
import { useGetPaidItems } from '../PartyQuery/PartyQuery'

const noop = () => {}

const initialContext = {
  updateSupplementalPartyProperties: noop,
  updatePartyProperties: noop,
  deletePartyProperties: noop,
  getRevalidatedPartyProperties() {
    return {}
  }
}

export const PartyContext =
  React.createContext<PartyPropertiesInterface>(initialContext)
export const useParty = () => useContext(PartyContext)

const HandlePartyValueValidation: React.FC = () => {
  const { excludeSelectionsHint, updateSupplementalPartyProperties } =
    useParty()

  // verifies integrity of excludeSelectionsHint list if someone else has paid
  const { paidItems } = useGetPaidItems()
  React.useEffect(() => {
    paidItems.forEach((paidItemGuid) => {
      if (
        typeof paidItemGuid === 'string' &&
        excludeSelectionsHint?.includes(paidItemGuid)
      ) {
        updateSupplementalPartyProperties((currentProps) => {
          return {
            excludeSelectionsHint: (
              currentProps.excludeSelectionsHint ?? []
            ).filter((i) => i !== paidItemGuid)
          }
        })
      }
    })
  }, [paidItems, updateSupplementalPartyProperties, excludeSelectionsHint])

  return null
}

const EXPIRATION_DURATION = 1000 * 60 * 60 * 8
export const PartyProvider: React.FC = ({ children }) => {
  const partyProperties = usePartyProperties({
    expirationDuration: EXPIRATION_DURATION
  })

  return (
    <PartyContext.Provider value={partyProperties}>
      <HandlePartySideEffects />
      <HandlePartyValueValidation />
      {children}
    </PartyContext.Provider>
  )
}

PartyProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useInitializeParty = createUseInitializeParty({
  expirationDuration: EXPIRATION_DURATION
})

export { generateTableKey } from './usePartyProperties'
