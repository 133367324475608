export enum HeaderText {
  FIRE_TAB_ROUND = 'Place order',
  CLOSE_TAB = 'Pay check',
  VIEW_TAB = 'Open check',
  CREATE_TAB = 'Place order',
  REVIEW_ORDER = 'Your order',
  PREVIOUS_ORDERS = 'Previous orders',
  YOUR_CHECK = 'Your check',
  GROUP_CHECK = 'Group check',
  COMBINED_TAB_CART = 'Your tab',
  COMBINED_TAB_CART_V2 = 'Your order',
  COMBINED_TAB_CART_GROUP = 'Group order',
  YOUR_CHECK_IS_CLOSED_INTERRUPT = 'Your check is closed',
  NEW_ORDER_CREATED_INTERRUPT = 'Item added to new cart',
  SAVE_PAYMENT_INFORMATION = 'Save payment information',
  RESET_PASSWORD = 'Reset password',
  FORGOT_PASSWORD = 'Forgot password',
  VERIFY_YOUR_ACCT = 'Verify your account',
  LOG_IN = 'Log in',
  CREATE_ACCOUNT = 'Create account',
  CREATE_ACCOUNT_LOYALTY = 'Create account to earn rewards',
  DELETE_CREDIT_CARD = 'Delete credit card',
  OPEN_CHECK_PAY_LATER = 'Open check and pay later',
  START_TAB_PAY_LATER = 'Start a tab & pay later',
  OR_CLOSE_CHECK_NOW = 'Or close check now',
  SEARCH_MENU = 'Search the menu',
  INVITE_OTHERS_MODAL = 'Invite your group',
  INVALID_INVITE_LINK = 'Invalid invite link',
  REMEMBER_THIS_CARD_TERMS = 'Remember this card',
  PAGE_SERVER = 'Need help?',
  TABLE_LOOKUP_FAIL_NOTIFICATION = 'Table not found',
  PAYMENT_ERROR_OUT_OF_SYNC = 'The total has been updated',
  SAVE_PAYMENT = 'Start a check',
  PARTY_ALREADY_PAID = `You're all set!`,
  PARTY_MEMBER_ALREADY_PAID = `You're all set!`,
  PAYMENT_METHOD_DISALLOWED = `Payment failed`,
  THANK_YOU = 'Thank you',
  SOMETHING_WENT_WRONG = 'Oops! Something went wrong',
  TRY_AGAIN_OR_ASK_YOUR_SERVER = 'Try reloading the page or ask your server for help',
  PROGRAM_REWARDS = 'Program Rewards',
  SPLIT_EVENLY = 'Split the bill evenly',
  SPLIT_EVEN_CLAIM_SHARES = 'How many people would you like to pay for?',
  SPLIT_CHANGED = 'Someone in your party changed the way the bill is split',
  SPLIT_REMOVED = 'Someone in your party chose to undo the split',
  ORDER_PLACED = 'Your order is in!',
  ORDER_MORE_TAB = 'Order more items at any time. Pay before you leave.',
  GROUP_ORDER_PLACED = 'Group order is in!',
  DONT_FORGET_TO_PAY = "Don't forget to close out your check at the end of your meal.",
  KITCHEN_ITEMS_RECEIVED = 'The kitchen has received your items!'
}
