import React from 'react'
import { Link } from 'react-router-dom'

import Close from '../../assets/close.svg'

import styles from './CloseButton.module.css'

interface ToShape {
  pathname: string
  search: string
  hash: string
  state: any
}
interface TagProps {
  to?: string | ToShape
  onClick?: () => void
  children: React.ReactNode
  className?: string
}

interface CloseButtonProp {
  onClick: () => void
  to?: ToShape
  'data-testid'?: string
  children?: React.ReactNode
}

const Tag = ({ to, onClick, ...props }: TagProps) =>
  to ? (
    <Link to={to} {...props} />
  ) : (
    <button type='button' onClick={onClick} {...props} />
  )

const CloseButton = ({
  onClick,
  to,
  'data-testid': dataTestId,
  children = <Close />,
  ...props
}: CloseButtonProp) => (
  <Tag
    className={styles.closeButton}
    onClick={onClick}
    to={to}
    data-testid={dataTestId}
    {...props}
  >
    {children}
  </Tag>
)

export default CloseButton
