import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { useRestaurant } from '../../RestaurantProvider/RestaurantProvider'
import { MenuItemsShape } from './MenuItemsShape'
import { MenuItemPrice } from './MenuItemPrice/MenuItemPrice'
import { MenuItemImage } from './MenuItemImage/MenuItemImage'
import { imgInPreprod, lowResImage } from '../../../utils/img-url'
import { ReorderQuickAdd } from '../../ReorderQuickAdd/ReorderQuickAdd'
import styles from './MenuItems.module.css'
import { StateLink } from '../../StateLink/StateLink'
import { useAvailability } from '../../../utils/availability'
import { useGetPartyMemberCart } from '../../PartyQuery/PartyQuery'
import { QuickAddItem } from './InstaAddItem/QuickAddItem'
import { buildSelectionsMap } from '../../../utils/cart-helpers'
import { calculateRank } from './helpers'

const MenuItems = ({ items, nonResponsive = false, isExternal }) => {
  const { getRestaurantPath } = useRestaurant()
  const { orderingAvailable } = useAvailability()
  const { error, loading, cart } = useGetPartyMemberCart()

  const selections = Boolean(error || loading || !cart)
    ? []
    : cart.order.checks[0].selections

  const selectionsMap = buildSelectionsMap(selections)

  const menuItemsToRender = React.useMemo(
    () =>
      items
        .slice()
        .map((item) => {
          item.rank = calculateRank(item)
          return item
        })
        .sort((a, b) => b.rank - a.rank),
    [items]
  )

  return (
    <ul data-testid='menu-items' className={styles.menuItems}>
      {menuItemsToRender.map(
        (
          {
            name,
            guid,
            price,
            outOfStock,
            description,
            imageUrl,
            calories,
            itemGroupGuid,
            usesFractionalQuantity,
            unitOfMeasure,
            itemTags,
            // for reorder from tab
            isReorder,
            selectionGuid,
            modifierGroups,
            hasModifiers
          },
          idx,
          itemsArray
        ) => {
          imageUrl = imgInPreprod(imageUrl)

          const addItemPath =
            isReorder && selectionGuid
              ? `add/${guid}/${itemGroupGuid}/${selectionGuid}`
              : `add/${guid}/${itemGroupGuid}`

          const showQuickAdd = !isReorder && orderingAvailable

          const isSelected =
            selectionsMap[guid] &&
            selectionsMap[guid].itemGroupGuid === itemGroupGuid

          const dataTestId =
            isReorder && selectionGuid ? 'reorder-item-link' : 'menu-item-link'
          const isLast = idx === itemsArray.length - 1

          const stateLinkStyles = imageUrl
            ? 'absolute right-1 -bottom-1 z-[1]'
            : 'relative mr-1'
          const quickAddStyles = imageUrl
            ? 'absolute right-1 bottom-[20px] z-[1]'
            : 'relative mr-1'
          const quickAddText = isSelected ? selectionsMap[guid].quantity : '+'

          return (
            <li
              data-testid={`menu-item-${guid}`}
              key={`${guid}-${itemGroupGuid || ''}-${idx}`}
              className={cx(styles.menuItem, {
                [styles.outOfStock]: outOfStock,
                [styles.responsive]: !nonResponsive,
                'border-t-0 border-b border-l-0 border-r-0 border-solid':
                  !isLast
              })}
            >
              <StateLink
                data-testid={dataTestId}
                className={styles.menuItemLink}
                to={getRestaurantPath(addItemPath)}
                isExternal={isExternal}
              >
                <div data-testid='menu-item-content' className={styles.content}>
                  <span
                    data-testid='menu-item-name'
                    className={cx(
                      'font-semibold type-default text-default mr-4 line-clamp-1',
                      { 'text-gray-100': outOfStock }
                    )}
                  >
                    {name}
                  </span>
                  {description && (
                    <p
                      data-testid='menu-item-description'
                      className={cx(
                        'font-normal type-subhead text-secondary mt-1 mb-2 line-clamp-2',
                        { 'text-gray-100': outOfStock }
                      )}
                    >
                      {description}
                    </p>
                  )}
                  {outOfStock && (
                    <span
                      data-testid='menu-item-out-of-stock-label'
                      className={styles.outOfStockLabel}
                    >
                      –Out of stock–
                    </span>
                  )}
                  {price > 0 && !outOfStock && (
                    <MenuItemPrice
                      price={price}
                      usesFractionalQuantity={usesFractionalQuantity}
                      unitOfMeasure={unitOfMeasure}
                    />
                  )}
                  {calories > 0 && (
                    <span
                      data-testid='menu-item-calories'
                      className={styles.calories}
                    >
                      {calories} Cal
                    </span>
                  )}
                </div>
                {isReorder && orderingAvailable ? (
                  <ReorderQuickAdd selectionGuid={selectionGuid} />
                ) : (
                  <div className='relative'>
                    <MenuItemImage
                      imageUrl={lowResImage(imageUrl)}
                      outOfStock={outOfStock}
                    />
                    {showQuickAdd && (
                      <>
                        {hasModifiers ? (
                          <div className={stateLinkStyles}>
                            <StateLink
                              data-testid='quick-add-link'
                              className={styles.menuItemLink}
                              to={getRestaurantPath(addItemPath)}
                              isExternal={isExternal}
                            >
                              <div className={styles.quickButtonWrapper}>
                                <button
                                  disabled={outOfStock}
                                  className={styles.quickAddButton}
                                >
                                  <p className={styles.quickAddText}>
                                    {quickAddText}
                                  </p>
                                </button>
                              </div>
                            </StateLink>
                          </div>
                        ) : (
                          <div
                            className={quickAddStyles}
                            onClick={(e) => e.preventDefault()} // prevents menu item details page from opening when clicking quick add
                          >
                            <QuickAddItem
                              data-testid='quick-add-button'
                              menuItem={isSelected ? selectionsMap[guid] : null}
                              guid={guid}
                              itemGroupGuid={itemGroupGuid}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </StateLink>
            </li>
          )
        }
      )}
    </ul>
  )
}

MenuItems.propTypes = {
  items: PropTypes.arrayOf(MenuItemsShape),
  nonResponsive: PropTypes.bool,
  isExternal: PropTypes.bool
}

export default MenuItems
export { MenuItemsShape }
