import React, {
  useEffect,
  useReducer,
  useCallback,
  useMemo,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { useFlag } from '../FeatureFlag/use-flag'
import {
  addEventProperties,
  removeEventProperty,
  track as heapTrack
} from '@toasttab/do-secundo-analytics'

const emptyExperimentsContextValues = { experiments: {} }
const fnNoOP = () => {}
const ExperimentsContext = React.createContext(emptyExperimentsContextValues)
export const ExperimentsProvider = ({ children }) => {
  // eslint-disable-next-line no-unused-vars
  const [experiments, updateExperiments] = useReducer(
    (state, experiment) => ({ ...state, ...experiment }),
    {}
  )

  // SETUP EXPERIMENTS
  // Each must be passed the `updateExperiments` callback to update the context state
  // useCreateExperiment(XP_EXAMPLE, updateExperiments)

  const context = useMemo(
    () => ({
      experiments
    }),
    [experiments]
  )
  return (
    <ExperimentsContext.Provider value={context}>
      {children}
    </ExperimentsContext.Provider>
  )
}

ExperimentsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const defaultEventOnTrack = 'Experimental treatment applied'
const defaultEventOnUntrack = 'Experimental treatment unapplied'

// eslint-disable-next-line no-unused-vars
const useCreateExperiment = (
  {
    experimentName,
    flagName,
    useInitVariant,
    getTreatment = fnNoOP,
    customEventOnTrack = defaultEventOnTrack,
    customEventOnUntrack = defaultEventOnUntrack
  },
  updateExperiments
) => {
  const variant = useInitVariant()

  const { track, untrack } = useExperimentTrackers({
    experimentName,
    variant,
    eventOnTrack: customEventOnTrack,
    eventOnUntrack: customEventOnUntrack
  })

  const treatment = useMemo(
    () => getTreatment(variant),
    [variant, getTreatment]
  )

  const getVariantAndTrack = useCallback(() => {
    track()
    return variant
  }, [variant, track])

  const getTreatmentAndTrack = useCallback(() => {
    track()
    return treatment
  }, [treatment, track])

  useEnableFlaggedExperiment({
    flagName,
    updateExperiments,
    experimentName,
    untrack,
    getVariantAndTrack,
    getTreatmentAndTrack,
    variant
  })
}

const getVariantKey = (experimentName) => experimentName.concat('-variant')
const getTreatmentKey = (experimentName) => experimentName.concat('-treatment')

const useExperimentTrackers = ({
  experimentName,
  variant,
  eventOnTrack,
  eventOnUntrack
}) => {
  const hasUntrackedRef = useRef(true)
  const hasTrackedRef = useRef(false)

  const track = useCallback(() => {
    if (!hasTrackedRef.current) {
      hasUntrackedRef.current = false
      heapTrack(eventOnTrack, {
        [getVariantKey(experimentName)]: variant,
        [getTreatmentKey(experimentName)]: true
      })
      addEventProperties({
        [getTreatmentKey(experimentName)]: true
      })
      hasTrackedRef.current = true
    }
  }, [eventOnTrack, variant, experimentName])

  const untrack = useCallback(() => {
    if (!hasUntrackedRef.current) {
      hasTrackedRef.current = false
      heapTrack(eventOnUntrack, {
        [getVariantKey(experimentName)]: variant,
        [getTreatmentKey(experimentName)]: false
      })
      removeEventProperty(getTreatmentKey(experimentName))
      hasUntrackedRef.current = true
    }
  }, [eventOnUntrack, variant, experimentName])

  return {
    track,
    untrack
  }
}

const useEnableFlaggedExperiment = ({
  flagName,
  updateExperiments,
  experimentName,
  untrack,
  getVariantAndTrack,
  getTreatmentAndTrack,
  variant
}) => {
  const flagOn = useFlag(flagName)
  useEffect(() => {
    if (flagOn) {
      updateExperiments({
        [experimentName]: {
          untrack,
          getVariantAndTrack,
          getTreatmentAndTrack,
          variant
        }
      })
      addEventProperties({
        [getVariantKey(experimentName)]: variant
      })
    }
  }, [
    flagOn,
    updateExperiments,
    experimentName,
    variant,
    getTreatmentAndTrack,
    getVariantAndTrack,
    untrack
  ])
}

const emptyExperiment = {
  untrack: fnNoOP,
  getTreatmentAndTrack: fnNoOP,
  getVariantAndTrack: fnNoOP,
  variant: undefined
}

export const useExperiments = (experimentKey) => {
  const ctx = React.useContext(ExperimentsContext)
  if (experimentKey === undefined) {
    return ctx
  }
  return ctx.experiments[experimentKey] || emptyExperiment
}
