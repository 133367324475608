import { useCallback, useState } from 'react'
import { useTab } from '../../components/TabProvider/TabProvider'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { trackOrder } from '../../utils/track-ecommerce'
import { useHistory } from 'react-router'
import { useGuestInfo } from '../use-guest-info'
import { getPrimaryCheckFromOrder } from '../../utils/check-helpers/check-helpers'
import {
  useOpt_Add_Preauth_And_FireMutation,
  OptPartyError
} from '../../apollo/generated/OptWebGraphQLOperations'
import { updateVars } from './use-add-preauth-and-fire.helper'

export const useHandleAddPreauthAndFire = () => {
  const history = useHistory()
  const { getRestaurantPath, restaurantGuid } = useRestaurant()
  const { partyGuid, partyMemberGuid, memberAuthToken, updatePartyProperties } =
    useParty()

  // should deprecate TabProvider
  const {
    updateTabOrderGuid,
    updateTabCheckGuid,
    setEmail,
    setFirstName,
    setLastName
  } = useTab() as {
    updateTabOrderGuid: (str: string) => void
    updateTabCheckGuid: (str: string) => void
    setEmail: (str: string) => void
    setFirstName: (str: string) => void
    setLastName: (str: string) => void
  }

  const { updateGuestEmail, updateGuestName, updateGuestLastName } =
    useGuestInfo()
  const [errorMessage, setErrorMessage] = useState<OptPartyError | null>(null)
  const confirmationPath = 'tab-management/confirm'
  const confirmPath = getRestaurantPath(confirmationPath)
  const redirect = useCallback(() => {
    history.replace(confirmPath)
  }, [history, confirmPath])

  const [optAddPreauthAndFire, { error: mutationError, ...mutationResponse }] =
    useOpt_Add_Preauth_And_FireMutation({
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch()
      },
      onCompleted(data) {
        const OPTPartyRefreshV2 =
          data.optAddPreauthAndFire.__typename === 'OPTPartyRefreshV2'
            ? data.optAddPreauthAndFire
            : undefined

        const OPTPartyError =
          data.optAddPreauthAndFire.__typename === 'OPTPartyError'
            ? data.optAddPreauthAndFire
            : undefined

        if (OPTPartyError) {
          setErrorMessage({
            code: OPTPartyError.code,
            message: OPTPartyError.message
          })
        }
        if (OPTPartyRefreshV2 && OPTPartyRefreshV2.order) {
          OPTPartyRefreshV2.party.orderGuid &&
            updateTabOrderGuid(OPTPartyRefreshV2.party.orderGuid)
          updateTabCheckGuid(OPTPartyRefreshV2.order.checkGuid)
          updatePartyProperties({
            partyGuid: OPTPartyRefreshV2.party.guid,
            partyMemberGuid,
            memberAuthToken,
            pin: OPTPartyRefreshV2.party.pin
          })

          const { guid } = OPTPartyRefreshV2.order
          const primaryCheck = getPrimaryCheckFromOrder(OPTPartyRefreshV2.order)

          if (primaryCheck) {
            const { selections, total, tax } = primaryCheck

            const adjustedSelections = selections.map((s) => ({
              id: s.itemGuid,
              name: s.name,
              price: s.price,
              quantity: s.quantity
            }))

            trackOrder(adjustedSelections, {
              id: guid,
              revenue: total,
              tax: tax,
              option: 'pre-auth',
              shipping: undefined
            })
          }

          redirect()
        }
      }
    })

  const mutation = useCallback(
    async (values) => {
      if (
        !restaurantGuid ||
        !partyGuid ||
        !partyMemberGuid ||
        !memberAuthToken
      ) {
        throw new Error('Missing required params')
      }
      const vars = {
        variables: {
          addPreauthAndFireInput: {
            customer: values.paymentInfo.customer,
            restaurantGuid,
            partyGuid,
            partyMemberGuid,
            memberAuthToken,
            pkPaymentToken: undefined,
            newCardInput: undefined,
            savedCardInput: undefined
          }
        }
      }

      const updatedVars = updateVars({
        vars,
        values
      })

      const res = await optAddPreauthAndFire(updatedVars)

      if (res.data?.optAddPreauthAndFire.__typename === 'OPTPartyRefreshV2') {
        setEmail(values.paymentInfo.customer.email)
        setFirstName(values.paymentInfo.customer.firstName)
        setLastName(values.paymentInfo.customer.lastName)
        updateGuestEmail(values.paymentInfo.customer.email)
        updateGuestName(values.paymentInfo.customer.firstName)
        updateGuestLastName(values.paymentInfo.customer.lastName)
      }
      return res
    },
    [
      optAddPreauthAndFire,
      restaurantGuid,
      partyGuid,
      partyMemberGuid,
      memberAuthToken,
      setEmail,
      setFirstName,
      setLastName,
      updateGuestEmail,
      updateGuestName,
      updateGuestLastName
    ]
  )

  return [
    mutation,
    { error: errorMessage || mutationError, ...mutationResponse }
  ]
}
