import React from 'react'
import PropTypes from 'prop-types'

import ErrorComponent from '../../../Error/Error'
import { LegalLinks } from '../../../CheckoutForm/LegalLinks/LegalLinks'

import { CTA } from '@/il8n/en'
import { useFlag } from '../../../FeatureFlag/use-flag'
import { LDFlags } from '../../../../launchdarkly/flags'
import { useGetPartyMembers } from '../../../PartyQuery/PartyQuery'
import { usePreauthEnabled } from '../../../../hooks/preauth/use-preauth-enabled'
import Button from '../../../Button/Button'

export const StartTabButton = ({
  error,
  loading,
  onClick,
  disabled,
  type = 'submit'
}) => {
  const { members } = useGetPartyMembers()
  const preauthEnabled = usePreauthEnabled()
  const isGroupOrder = members.length > 1
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)
  const fireRoundText = () => {
    if (isGroupOrder) {
      return preauthEnabled
        ? CTA.FIRE_GROUP_PREAUTH_ROUND
        : CTA.FIRE_GROUP_ROUND
    }
    return preauthEnabled ? CTA.FIRE_TAB_PREAUTH_ROUND : CTA.FIRE_TAB_ROUND
  }

  return (
    <>
      <section>
        <LegalLinks />
        <div>
          {error && (
            <ErrorComponent
              error={error}
              withoutPleaseTryAgain={error.message.includes(
                'not available to order'
              )}
            />
          )}
          {tabsRedesignOn ? (
            <div
              className='fixed bottom-0 left-0 z-10 w-full bg-white border-t border-b-0 border-l-0 border-r-0 border-solid shadow'
              data-testid='button-container'
            >
              <div className='w-11/12 mb-4 ml-4 mr-4'>
                <Button
                  data-testid='open-tab-submit-button'
                  variant='primary'
                  onClick={onClick}
                  loading={loading}
                  disabled={disabled}
                  type={type}
                  responsive
                >
                  {fireRoundText()}
                </Button>
              </div>
            </div>
          ) : (
            <div className='my-4'>
              <Button
                data-testid='open-tab-submit-button'
                variant='primary'
                onClick={onClick}
                loading={loading}
                disabled={disabled}
                type={type}
                responsive
              >
                {CTA.FIRE_TAB_ROUND}
              </Button>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

StartTabButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string
}
