import { v4 as uuid } from 'uuid'

import {
  Maybe,
  ServiceChargeType,
  OptCheckV2Fragment,
  OptPartyMemberV2Fragment,
  OptCheckV2GuidFragment
} from './../../apollo/generated/OptWebGraphQLOperations'

/**
 * Basic data encapsulation of a party member
 * and their corresponding items they are responsible for
 */
export interface Bucket {
  member: Pick<OptPartyMemberV2Fragment, 'name' | 'partyMemberGuid'>
  check: Pick<OptCheckV2GuidFragment, 'numberOfSelections' | 'selections'>
}

export type MemberBucketLookup = Map<string, string[]>
interface GetMemberBucketsInterface {
  partyMembers: Maybe<OptPartyMemberV2Fragment>[]
  check: Maybe<OptCheckV2Fragment>
}

interface GetServerBucketsInterface {
  check: OptCheckV2Fragment
  partyMembers: Array<OptPartyMemberV2Fragment>
}

interface GetSortedMemberBucketsInterface extends GetMemberBucketsInterface {
  me: OptPartyMemberV2Fragment
}

const getPartyMemberBucket = (
  check: Maybe<OptCheckV2Fragment>,
  member: OptPartyMemberV2Fragment
): Bucket => {
  const allSelections = check?.selections ?? []
  const mySelections =
    allSelections.filter((selection) =>
      member.orderSelectionIds.includes(selection.externalId ?? '')
    ) || []
  return {
    member,
    check: {
      numberOfSelections: mySelections.length,
      selections: mySelections
    }
  }
}

/**
 * Returns an array of buckets with a party member and its corresponding selections from an order
 */
export const getMemberBuckets = ({
  partyMembers,
  check
}: GetMemberBucketsInterface): Bucket[] => {
  return partyMembers.flatMap((member) => {
    if (!member) {
      return []
    }

    const resultBucket = getPartyMemberBucket(check, member)
    if (!resultBucket.member) {
      return []
    }
    return [
      {
        member: resultBucket.member,
        check: resultBucket.check
      }
    ]
  })
}

// TODO:  Handle items from secondary checks, for now limit to primary check
export const getServerBuckets = ({
  check,
  partyMembers
}: GetServerBucketsInterface): Bucket[] => {
  const allClaimedItemIds = partyMembers.flatMap(
    (member) => member.orderSelectionIds
  )
  const allUnclaimedSelections = check.selections.filter(
    (selection) => !allClaimedItemIds.includes(selection?.externalId ?? '')
  )
  return [
    {
      // creates fake party member that corresponds to bucket's true owner
      member: {
        name: 'Items added by your server',
        partyMemberGuid: uuid()
      },
      check: {
        numberOfSelections: allUnclaimedSelections.length,
        selections: allUnclaimedSelections
      }
    }
  ]
}

// sorts so me at the top and all empty buckets
// are at the bottom
export const sortMemberBuckets = (
  buckets: Bucket[],
  me: OptPartyMemberV2Fragment
): Bucket[] => {
  return buckets.slice().sort((a, b) => {
    if (a.member.partyMemberGuid === me.partyMemberGuid) {
      return -1
    } else if (b.member.partyMemberGuid === me.partyMemberGuid) {
      return 1
    } else if (!a.check.numberOfSelections) {
      return 0
    } else if (!b.check.numberOfSelections) {
      return -1
    }

    return 0
  })
}

export const getSortedMemberBuckets = ({
  partyMembers,
  check,
  me
}: GetSortedMemberBucketsInterface): Bucket[] => {
  const unsortedBuckets = getMemberBuckets({
    partyMembers,
    check
  }).filter((b) => b.member)
  return sortMemberBuckets(unsortedBuckets, me)
}

/**
 * Determines whether an order has a service charge
 * higher than a percent threshold. Returns false if
 * a non-percent service charge applied.
 */

const SERVICE_CHARGE_THRESHHOLD = 10

export const getHasHighServiceCharge = (
  check: OptCheckV2Fragment | undefined,
  /** percent threshold as a float. Valiue between 0 and 100 */
  thresholdPercent: number = SERVICE_CHARGE_THRESHHOLD
): boolean => {
  if (!check) {
    return false
  }
  const appliedServiceCharges = check.appliedServiceCharges
  if (!appliedServiceCharges) {
    return false
  }
  const estimatedAppliedServiceCharges = appliedServiceCharges.flatMap((sc) => {
    if (sc.chargeType !== ServiceChargeType.Percent) {
      return []
    }
    const percent = sc.serviceChargeDetails?.percent
    return percent ? [percent] : []
  })

  return estimatedAppliedServiceCharges.some(
    (percent) => percent >= thresholdPercent
  )
}
