import { OptCheckV2Fragment } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { CheckoutFormValues } from '../../../CheckoutForm/types/types'
import { CheckLineItem } from '../../../../utils/check-helpers'
import { getRawTipValue } from '../../../CheckoutForm/Tip/TipComponent/getRawTipValue'
import {
  InitPaymentFlow,
  SelectedPaymentState,
  createInitToastEncryptedNewCardFlow,
  createInitGPayFlow,
  createInitToastSavedCardFlow,
  createInitApplePayFlow
} from '@toasttab/digital-payment-methods-poc'
import { adaptCheckDisplayDetails } from './'
import { track } from '@toasttab/do-secundo-analytics'
import { SupportedPaymentMethods } from '../../../CheckoutForm/PaymentInfo/constants'
import { getRawPhoneNumber } from '../../../../utils/phone-number'
import { FormikHelpers } from 'formik'

type GetLineItemsFn = (params: {
  check: OptCheckV2Fragment
  tip: number
  totalMode: 'DUE'
}) => CheckLineItem[]

export const handleDigitalPaymentStatePaymentFlow = async ({
  selectedPaymentState,
  setError: setDigitalPaymentsError,
  checkToPay,
  restaurantData: { restaurantGuid, restaurantName },
  handleCloseCheckSubmit,
  getLineItems,
  valuesToSubmit,
  noPaymentRequired,
  formikBag,
  captureMessage
}: {
  selectedPaymentState: SelectedPaymentState
  setError(errorState: { message: string } | undefined): void
  restaurantData: {
    restaurantGuid: string
    restaurantName: string
  }
  checkToPay?: OptCheckV2Fragment | null
  valuesToSubmit: CheckoutFormValues
  handleCloseCheckSubmit: (v: CheckoutFormValues) => Promise<any> | void
  getLineItems: GetLineItemsFn
  noPaymentRequired: boolean
  formikBag: FormikHelpers<CheckoutFormValues>

  captureMessage: (error: any, severity: string, context: any) => void
}) => {
  // reset errors
  setDigitalPaymentsError(undefined)

  // bypass error-checking guards
  // if no payment data is being handled
  if (noPaymentRequired) {
    try {
      return await handleCloseCheckSubmit(valuesToSubmit)
    } catch (e) {
      setDigitalPaymentsError(e as any)
    }
  }

  if (selectedPaymentState.state !== 'valid') {
    throw new Error('Not valid')
  }

  if (!checkToPay) {
    throw new Error('Cannot find check to pay for')
  }

  const checkLineItems = getLineItems({
    check: checkToPay,
    tip: getRawTipValue(valuesToSubmit.creditCardTip),
    totalMode: 'DUE'
  })

  const { displayDetails, orderTotal } = adaptCheckDisplayDetails(
    checkLineItems,
    restaurantName
  )

  const { selectedPaymentMethod } = selectedPaymentState
  type InitPaymentFlowParams = Parameters<InitPaymentFlow>[0]
  const params: InitPaymentFlowParams = {
    paymentRequest: {
      displayDetails,
      merchantDetails: {
        merchantIdentifier: restaurantGuid,
        merchantName: restaurantName
      },
      total: orderTotal
    },
    async completePayment({ paymentResponse }) {
      const buildPaymentMethodValues = () => {
        const { paymentMethod, data } = paymentResponse
        switch (paymentMethod) {
          case SupportedPaymentMethods.ToastEncryptedNewCard:
            return {
              encryptedCard: data.encryptedCard,
              saveCard: data.saveCard
            }
          case SupportedPaymentMethods.GooglePay: {
            const extractedGuestPhoneNumber = getRawPhoneNumber(
              data.billingAddress.phoneNumber ?? ''
            ) // pulls phone number from GPay data (this comes in the format: +1 555-555-555) if the "billingPhoneRequired" configuration property has been passed through
            return {
              customerTel: extractedGuestPhoneNumber ?? undefined,
              customerEmail: data.email, // pulls email from google pay data
              googlePayParams: {
                token: data.googlePayToken,
                billingAddress: {
                  name: data.billingAddress.name!, // should this be nullable in bff?
                  ...data.billingAddress
                }
              },
              // REQUIRED for DOCT-940
              customerMarketingConsent: false
            }
          }
          case SupportedPaymentMethods.ToastSavedCard:
            return {
              savedCardGuid: data.savedCardGuid
            }
          case SupportedPaymentMethods.ApplePay:
            return {
              pkPaymentToken: JSON.stringify(
                paymentResponse.data.pkPaymentToken
              ),
              customerEmail: paymentResponse.data.email,
              customerTel:
                paymentResponse.data.pkPaymentToken?.shippingContact
                  ?.phoneNumber
            }
          default:
            return {}
        }
      }

      return handleCloseCheckSubmit({
        ...valuesToSubmit,
        ...buildPaymentMethodValues()
      })
    },
    onCompletePaymentFailed(error) {
      track('Payment completion failed', {
        paymentMethod: selectedPaymentMethod
      })
      formikBag.setSubmitting(false)
      if (error?.message) {
        captureMessage(error.message, 'error', {
          captureContext: (scope: any) => {
            return scope.setContext(
              'component',
              'digital-payment-methods-selector-ui'
            )
          }
        })
        setDigitalPaymentsError({ message: error.message })
      }
    },
    onPrepaymentCanceled(error) {
      formikBag.setSubmitting(false)

      track('Payment attempt cancelled', {
        paymentMethod: selectedPaymentMethod
      })
      if (error?.reason) {
        setDigitalPaymentsError({ message: error.reason })
      }
    },
    onPrepaymentFailed(error) {
      formikBag.setSubmitting(false)

      track('Payment attempt failed', {
        paymentMethod: selectedPaymentMethod
      })
      captureMessage(error?.message as any, 'error', {
        captureContext: (scope: any) => {
          return scope.setContext(
            'component',
            'digital-payment-methods-selector-ui'
          )
        }
      })
      if (error?.message) {
        setDigitalPaymentsError({ message: error.message })
      }
    }
  }

  if (selectedPaymentMethod === SupportedPaymentMethods.ToastEncryptedNewCard) {
    return createInitToastEncryptedNewCardFlow(selectedPaymentState.data)(
      params
    )
  } else if (selectedPaymentMethod === SupportedPaymentMethods.GooglePay) {
    return createInitGPayFlow(selectedPaymentState.data)(params)
  } else if (selectedPaymentMethod === SupportedPaymentMethods.ToastSavedCard) {
    return createInitToastSavedCardFlow(selectedPaymentState.data)(params)
  } else if (selectedPaymentMethod === SupportedPaymentMethods.ApplePay) {
    return createInitApplePayFlow(selectedPaymentState.data)(params)
  } else if (selectedPaymentMethod === SupportedPaymentMethods.Click2Pay) {
    await handleCloseCheckSubmit({ ...valuesToSubmit })
  }
}
