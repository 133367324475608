import { useEffect } from 'react'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useUpdatePartyMember } from '../../hooks/party-member/use-update-party-member'
import { useSentry } from 'banquet-runtime-modules'

export const PartyMemberAuthListener = () => {
  const { captureException } = useSentry()
  const { authenticated } = useAuth()
  const updatePartyMember = useUpdatePartyMember()

  useEffect(() => {
    const updatePartyMemberUpdateOnChange = async () => {
      try {
        await updatePartyMember({})
      } catch (error) {
        captureException(error as Error)
      }
    }

    updatePartyMemberUpdateOnChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated])

  return null
}
