import { track } from '@toasttab/do-secundo-analytics'

interface PartyHeapTrack {
  partyGuid: string
  partyMemberGuid: string
}

export const trackPartySuccess = ({
  partyGuid,
  partyMemberGuid
}: PartyHeapTrack): Promise<void> => {
  return track('Joined Party', {
    partyGuid,
    partyMemberGuid
  })
}

export const trackMenuLoadSuccess = ({
  partyGuid,
  partyMemberGuid
}: PartyHeapTrack): Promise<void> => {
  return track('Loaded Menu', {
    partyGuid,
    partyMemberGuid
  })
}

export const trackPermalinkUsed = ({
  partyGuid,
  partyMemberGuid
}: PartyHeapTrack): Promise<void> => {
  return track('Used Permalink', {
    partyGuid,
    partyMemberGuid
  })
}

export const trackBadPermalinkUsed = ({
  partyGuid,
  partyMemberGuid
}: PartyHeapTrack): Promise<void> => {
  return track('Used Invalid Permalink', {
    partyGuid,
    partyMemberGuid
  })
}
