import isEqual from 'lodash/isEqual'
import { useRef } from 'react'
import {
  OptCloseoutInput,
  SplitMode
} from '../apollo/generated/OptWebGraphQLOperations'
import { useParty } from '../components/PartyProvider/PartyProvider'
import {
  useGetMainCheck,
  useGetSortedMemberAndServerBuckets,
  useGetPartyRefresh
} from '../components/PartyQuery/PartyQuery'

interface SplitItem {
  guid: string
  quantity: number
}

/**
 * Returns selections to split argument for usage in
 * post split check and for split check payment/closeout.
 * Returns null if I simply am selecting to pay for/check preview
 * my selections.
 */
export const useGetSelectionsToSplit =
  (): OptCloseoutInput['selectionsToSplit'] => {
    const { excludeSelectionsHint, portionsToBePaid } = useParty()
    const { sortedMemberBuckets, me, serverBuckets } =
      useGetSortedMemberAndServerBuckets()
    const { partyRefresh } = useGetPartyRefresh()
    const { mainCheck } = useGetMainCheck()
    const splitMode = partyRefresh?.splitPaymentData?.splitMode
    const splitPaymentData = partyRefresh?.splitPaymentData

    /* if I'm splitting evenly, use the main check. The reason the main check is used is:
     * When we split off selections for a split evenly (or eventually a 'chaos mode') scenario, we adjust the selection.proportion based on the split.
     * For instance:
     *
     * Say a 4 party members order 4 drinks. They choose to split the check evenly into 4 portions. User A decides to pay for one portion.
     * The selection.proportion for each item will be reduced by 25% because that's how much User A paid for that selection.
     * The source of truth for selection.proportion data is on the mainCheck.
     */
    const myBucket =
      splitMode === SplitMode.Even && mainCheck
        ? { check: mainCheck, member: me }
        : sortedMemberBuckets.find(
            (bucket) => bucket.member.partyMemberGuid === me?.partyMemberGuid
          )

    const selectionsSet = useRef<
      OptCloseoutInput['selectionsToSplit'] | undefined
    >(undefined)

    const mySelectionsToSplit: SplitItem[] =
      myBucket?.check.selections.flatMap((s) => {
        if (s.__typename === 'CheckSelectionGuid') {
          const quantity = s.usesFractionalQuantity
            ? s.fractionalQuantity.quantity
            : s.quantity
          return [{ guid: s.guid, quantity }]
        }
        return []
      }) ?? []

    // This should never occur, but here's a safety check for split evenly
    // ensure that portionsToBePaid has been set for split evenly.
    // RE: https://github.com/toasttab/opt-web/pull/998#discussion_r986261825
    if (splitMode === SplitMode.Even && !portionsToBePaid) {
      return null
    }

    const serverSelectionsToSplit: SplitItem[] =
      serverBuckets.flatMap((b) =>
        b.check.selections.flatMap((s) => {
          if (s.__typename === 'CheckSelectionGuid') {
            const quantity = s.usesFractionalQuantity
              ? s.fractionalQuantity.quantity
              : s.quantity
            return [{ guid: s.guid, quantity }]
          }
          return []
        })
      ) ?? []

    const newSelections = [...mySelectionsToSplit, ...serverSelectionsToSplit]
      .filter((sel) => !excludeSelectionsHint?.some((g) => g === sel.guid))
      .map((sel) => {
        const proportion = (() => {
          if (splitMode === SplitMode.Even) {
            return (
              sel.quantity *
              (portionsToBePaid! /
                (splitPaymentData?.evenSplitPortions! -
                  splitPaymentData?.evenSplitPaidPortions!))
            )
          }

          return sel.quantity
        })()
        return {
          proportion: proportion,
          selectionID: sel.guid
        }
      })

    if (!isEqual(newSelections, selectionsSet.current)) {
      selectionsSet.current = newSelections
    }

    return selectionsSet.current
  }
