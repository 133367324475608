import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { DO_MENUS } from './Menus.graphql'
import { lifecycle } from '../../utils/lifecycle'
import { useParty } from '../PartyProvider/PartyProvider'
import { trackMenuLoadSuccess } from '../../utils/track-ecommerce'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useSentry } from 'banquet-runtime-modules'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'

export const useMenuQuery = () => {
  const { captureMessage } = useSentry()
  const { restaurantGuid } = useRestaurant()
  const { mobileDiningSolutionsChannelGuid } = useDDIGlobals()

  const party = useParty()

  const variables = {
    restaurantGuid,
    channelGuid: mobileDiningSolutionsChannelGuid
  }

  const skip = !restaurantGuid

  const queryResult = useQuery(DO_MENUS, {
    variables,
    notifyOnNetworkStatusChange: true,
    skip: skip,
    onCompleted(data) {
      if (!data) {
        captureMessage('Data is null for Menus Query', 'warning', {
          captureContext: (scope) =>
            scope.setExtra('queryVars', { restaurantGuid })
        })

        return
      }
      lifecycle.trigger('menuloaded', { restaurantGuid })
    }
  })

  useEffect(() => {
    if (queryResult.data && party.partyGuid) {
      trackMenuLoadSuccess({
        partyGuid: party.partyGuid,
        partyMemberGuid: party.partyMemberGuid
      })
    }
  }, [party.partyGuid, party.partyMemberGuid, queryResult.data])

  return queryResult
}
