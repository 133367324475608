import * as React from 'react'
import cx from 'classnames'
import { CheckSelectionFragment } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { FractionalQuantity } from '../../../Cart/FractionalQuantity/FractionalQuantity'
import { EditComponentProps } from '../../CheckTable'

import styles from './SelectionCard.module.css'
import { formatProportion } from '../../../../utils/checkout-helpers'
import { FormatCurrency } from '../../../Currency/FormatCurrency'

const getSelectionGuid = (selection: CheckSelectionFragment) => {
  const persistedSelectionGuid =
    selection.__typename === 'CheckSelectionGuid' ? selection.guid : undefined

  const nonPersistedSelectionExternalId =
    selection.__typename === 'CheckSelectionNoGuid'
      ? selection.externalId
      : undefined

  return persistedSelectionGuid || nonPersistedSelectionExternalId || 'NO_OP'
}

export interface SelectionCardProps {
  selection: CheckSelectionFragment
  isPaid?: boolean
  EditComponent?: React.ComponentType<EditComponentProps>
  'data-testid'?: string
  displayUnpricedModifiers?: boolean
  hasBorderPadding?: boolean
}

export const SelectionCard = ({
  selection,
  isPaid,
  EditComponent,
  displayUnpricedModifiers,
  'data-testid': dataTestId,
  hasBorderPadding = true
}: SelectionCardProps) => {
  const {
    name,
    quantity,
    price,
    preDiscountPrice,
    modifiers,
    usesFractionalQuantity,
    fractionalQuantity,
    itemGuid,
    itemGroupGuid
  } = selection
  const voided =
    selection.__typename === 'CheckSelectionGuid' ? selection.voided : false

  const lineItemDiscount = selection.appliedDiscounts?.reduce(
    (acc, discount) => {
      return acc - (discount?.discountAmount || 0.0)
    },
    0.0
  )

  return (
    <div data-testid={dataTestId}>
      <div
        className={cx('flex justify-between', {
          'pb-2': !!EditComponent,
          'pb-4': !EditComponent,
          'px-4 pt-4': hasBorderPadding
        })}
      >
        <div className='flex gap-2'>
          <span data-testid='cart-item-quantity'>
            {formatProportion(quantity)}
          </span>
          <div>
            <span
              className={cx({ 'line-through': voided }, styles.name)}
              data-testid='cart-item-name'
            >
              {name}
            </span>
            <ul
              className='-ml-4 text-secondary'
              data-testid='cart-item-modlist'
            >
              {modifiers?.map((modifier, index) => {
                if (!modifier.name) {
                  return null
                }
                const modHasPrice = modifier.price > 0
                const displayModifier = displayUnpricedModifiers || modHasPrice
                return (
                  displayModifier && (
                    <li className='pt-1' key={`${modifier.name}-${index}`}>
                      {modifier.name}{' '}
                      {!modifier.price || modifier.price === 0 ? null : (
                        <span>
                          (
                          <FormatCurrency
                            amount={modifier.price}
                            showLeadingSign
                          />
                          )
                        </span>
                      )}
                    </li>
                  )
                )
              })}
            </ul>
          </div>
          {usesFractionalQuantity && (
            <div
              data-testid='cart-item-frac-qnty'
              className={cx({ 'line-through': voided })}
            >
              <FractionalQuantity
                quantity={fractionalQuantity.quantity}
                unitOfMeasure={fractionalQuantity.unitOfMeasure}
              />
            </div>
          )}
          {voided && <span data-testid='cart-item-voided'>VOIDED</span>}
        </div>
        <div className='text-right font-bold'>
          <div
            data-testid='cart-item-price'
            className={cx('pl-2', { 'text-color-success': isPaid })}
          >
            <FormatCurrency
              amount={voided ? 0 : preDiscountPrice || price || undefined}
            />
          </div>
          {lineItemDiscount < 0 && (
            <div className='font-bold'>
              <FormatCurrency amount={lineItemDiscount} />
            </div>
          )}
        </div>
      </div>
      {!voided && EditComponent && (
        <div className='mb-4 ml-8'>
          <EditComponent
            selectionGuid={getSelectionGuid(selection)}
            itemGuid={itemGuid}
            itemGroupGuid={itemGroupGuid}
          />
        </div>
      )}
    </div>
  )
}
