import React from 'react'
import { usePermalinkEnabled } from '../party/use-permalink-enabled'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import {
  useRestaurantStorage,
  restaurantStorageKeys
} from '../../utils/restaurant-storage'
import { Maybe } from 'yup'
import { useTabEnabled } from '../tabs/useTabEnabled'

type GUEST_FEEDBACK_TAG = {
  name: string
  label: string
}

type useGuestFeedbackItem = {
  restaurantGuid?: string
  orderGuid?: Maybe<string>
  feedbackTagsFinal: Array<GUEST_FEEDBACK_TAG>
}

export const GUEST_FEEDBACK_TAGS = [
  {
    name: 'finding-items',
    label: 'Finding Items'
  },
  {
    name: 'customizing-items',
    label: 'Customizing Items'
  },
  {
    name: 'payment-process',
    label: 'Payment Process'
  },
  {
    name: 'other',
    label: 'Other'
  }
]

export const useGuestFeedback = (): useGuestFeedbackItem => {
  const permalinkEnabled = usePermalinkEnabled()
  const tabEnabled = useTabEnabled()
  const restaurantStorage = useRestaurantStorage()
  const { restaurantGuid } = useRestaurant()
  const orderGuid: Maybe<string> = tabEnabled
    ? restaurantStorage.get(restaurantStorageKeys.TAB_ORDER_GUID)?.value
    : restaurantStorage.get(restaurantStorageKeys.PAYG_ORDER_GUID)?.value
  const feedbackTagsFinal = React.useMemo(() => {
    if (permalinkEnabled) {
      const GUEST_FEEDBACK_TAGS_COPY = GUEST_FEEDBACK_TAGS.slice(
        0,
        GUEST_FEEDBACK_TAGS.length
      )
      GUEST_FEEDBACK_TAGS_COPY.splice(GUEST_FEEDBACK_TAGS.length - 2, 0, {
        name: 'rejoin-link',
        label: 'Text Message Link'
      })
      return GUEST_FEEDBACK_TAGS_COPY
    }
    return GUEST_FEEDBACK_TAGS
  }, [permalinkEnabled])

  return {
    restaurantGuid,
    orderGuid,
    feedbackTagsFinal
  }
}
