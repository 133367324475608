import { v4 as uuid } from 'uuid'
import {
  authStorageKeys,
  getAuthStorage
} from '../components/AuthProvider/auth-storage'
import { useStateWithNamespacedStorage } from './namespaced-storage'
/**
 * Loads a relatively unique user ID if available. Otherwise creates one
 * and puts it into local storage.  Allows reassociating a user
 * to a party in some edge cases.
 *
 * TODO (J5): Right now we just use local storage
 * but this should be loaded from user info if they are logged in.
 */

const generatedUUID = Object.freeze(uuid())
const authStorage = getAuthStorage()
export const useUniqueUserId = () => {
  const [uniqueId] = useStateWithNamespacedStorage(
    authStorageKeys.UNIQUE_USER_ID,
    generatedUUID,
    authStorage
  )
  return uniqueId
}
