import * as React from 'react'
import { joinOrCreatePartyRequest$ } from 'cornucopia-apis'
import { useSentry } from 'banquet-runtime-modules'
import {
  JoinOrCreatePartyRequest,
  PartyVisibility
} from './PartyListener.types'
import { useInitializeParty } from '../../../../client/components/PartyProvider/PartyProvider'
import { toastError } from '../ListenerError'
import { dataByTypename } from '../../../../client/utils/apollo-helpers'
import { ResponseTypes } from '../cornucopia.types'
import { OptPartyError } from '../../../../client/apollo/generated/OptWebGraphQLOperations'
import { toastSuccessNotification } from './helpers'
import { OptPartyRefreshV2 } from '../../../../client/apollo/generated/OptWebGraphQLOperations'
import { ApolloError } from '@apollo/client'
interface JoinOrCreateResult {
  errors: ApolloError[]
  data: {
    optPartyRefresh: OptPartyRefreshV2
    OptPartyError: OptPartyError
  }
}

export const PartyListener = () => {
  const { captureMessage } = useSentry()
  const [joinOrCreatePartyRequest, setJoinOrCreatePartyRequest] =
    React.useState<JoinOrCreatePartyRequest>()

  const [initialize] = useInitializeParty(
    joinOrCreatePartyRequest?.joinOrCreatePartyInfo.partyGuid,
    false,
    null,
    { onCompleted: () => {} }
  )

  React.useEffect(() => {
    const subscription = joinOrCreatePartyRequest$.subscribe(
      (request: JoinOrCreatePartyRequest) => {
        setJoinOrCreatePartyRequest(request)
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  })

  React.useEffect(() => {
    if (joinOrCreatePartyRequest && initialize) {
      const {
        joinOrCreatePartyInfo: { user, mode, visibility, checkGuid },
        header: { response$ }
      } = joinOrCreatePartyRequest
      setJoinOrCreatePartyRequest(undefined)

      initialize({ name: user?.name, phone: user?.phoneNumber, visibility })
        .then((result: JoinOrCreateResult) => {
          // if there is an error object, the rest of the shape is all messed up
          if (result.errors) {
            const errorMessage = `Failed to ${mode.toLowerCase()} party`
            toastError(errorMessage)
            captureMessage(errorMessage, 'warning')

            return response$.next({
              kind: ResponseTypes.ERROR,
              message: errorMessage,
              code: ''
            })
          }

          const { OPTPartyError } = dataByTypename(
            result.data.optPartyRefresh
          ) as {
            OPTPartyError?: OptPartyError
          }

          if (OPTPartyError) {
            toastError(`Failed to ${mode.toLowerCase()} party`)
            if (OPTPartyError.code) {
              captureMessage(OPTPartyError.code, 'warning')
            }

            response$.next({
              kind: ResponseTypes.ERROR,
              message: `Failed to ${mode.toLowerCase()} party`,
              code: ''
            })
          } else {
            response$.next({
              kind: ResponseTypes.OK,
              info: [
                {
                  code: '200',
                  message: `${mode} party success`
                }
              ],
              warnings: []
            })
            // If TTS we don't need this popup
            if (!checkGuid) {
              toastSuccessNotification(
                visibility || PartyVisibility.PUBLIC,
                mode
              )
            }
          }
        })
        .catch((err: any) => {
          toastError(`Failed to initialize ${mode.toLowerCase()} party`, err)
          captureMessage(err.message, 'warning')
          response$.next({
            kind: ResponseTypes.ERROR,
            message: `Failed to ${mode.toLowerCase()} party`,
            code: ''
          })
        })
    }
  }, [joinOrCreatePartyRequest, initialize, captureMessage])

  return <></>
}
