import { Alert } from '@toasttab/buffet-pui-alerts'
import { track } from '@toasttab/do-secundo-analytics'
import * as React from 'react'

export const PaymentInfoError = () => {
  React.useEffect(() => {
    track('SPI initialization error rendered.')
  }, [])

  return (
    <div className='mt-4 mb-2'>
      <Alert variant='error'>
        Something went wrong. Please, refresh the page or contact restaurant
        staff.
      </Alert>
    </div>
  )
}
