import { JoinOrCreatePartyMode, PartyVisibility } from './PartyListener.types'

import { toastNotification } from '../../../../client/components/ToastNotification/ToastNotification'

export const toastSuccessNotification = (
  visibility: PartyVisibility,
  mode: JoinOrCreatePartyMode
) => {
  let toastCopy = ''
  if (visibility === PartyVisibility.PRIVATE) {
    toastCopy = 'Order started'
  } else {
    toastCopy =
      mode === JoinOrCreatePartyMode.CREATE ? 'Group created' : 'Joined group'
  }
  return toastNotification(toastCopy)
}
