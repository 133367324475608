import React from 'react'

import { Button } from '@toasttab/buffet-pui-buttons'
import Loading from '../Loading/Loading'
import { RouterLinkButton } from '../Button/RouterLinkButton'

import styles from './CartButton.module.css'

export interface CartButtonProps {
  /** One of "type", "to", or "disabled" is required */
  type?: 'submit' | 'reset' | 'button'
  /** One of "type", "to", or "disabled" is required */
  to?: string
  left: React.ReactNode
  right?: React.ReactNode
  /** One of "type", "to", or "disabled" is required */
  disabled?: boolean
  loading?: boolean
  testId?: string
}

export const CartButton = ({
  to,
  left,
  right,
  type,
  disabled = false,
  loading = false,
  ...props
}: CartButtonProps) => {
  if (loading) {
    return (
      <div className={styles.sticky}>
        <Button className='w-full' disabled type={type} {...props}>
          <Loading />
        </Button>
      </div>
    )
  }

  const buttonContent = (
    <>
      <span>{left}</span>
      <span>{right}</span>
    </>
  )

  if (type || disabled) {
    return (
      <div className={styles.sticky}>
        <Button
          className={styles.cartButton}
          type={type}
          disabled={disabled}
          {...props}
        >
          {buttonContent}
        </Button>
      </div>
    )
  }

  if (!to) {
    console.warn(
      'CartButton requires one of a "type", "to", or "disabled" prop but none was provided.'
    )
    return null
  }

  return (
    <div className={styles.sticky}>
      <RouterLinkButton to={to} {...props}>
        {buttonContent}
      </RouterLinkButton>
    </div>
  )
}

export default CartButton
