import * as React from 'react'
import isEqual from 'lodash/isEqual'

/**
 * Persists an object if deep equality between renders of a functional component.
 * Useful for hooks' dependency arrays that rely on shallow equality checking
 * @param {Object} object - An object to check between renders
 */
export const usePersistEqualObject = (object) => {
  const objectRef = React.useRef(object)

  React.useEffect(() => {
    if (!isEqual(object, objectRef.current)) {
      objectRef.current = object
    }
  }, [object])

  return objectRef.current
}
