import { ApolloError } from '@apollo/client'

const UNKNOWN_MESSAGE = 'An unknown error occured'

type ErrorToLog =
  | Error
  | ApolloError
  | { message: string }
  | string
  | null
  | undefined

/**
 * Gets a message for display from the provided error object.
 */
export const getMessage = (
  /**
   * Can be any object or `Error` with a `message` property.
   * `ApolloError`s will be handled specially to check for graphql or network errors.
   */
  err: ErrorToLog
): string => {
  if (!err) {
    return UNKNOWN_MESSAGE
  }

  if (typeof err == 'string') {
    return err
  }

  // Handle ApolloError
  if ('graphQLErrors' in err) {
    if (err.graphQLErrors && err.graphQLErrors[0]?.message) {
      return err.graphQLErrors[0].message
    }

    if (err.networkError && err.networkError.message) {
      return err.networkError.message
    }
  }

  return err.message || UNKNOWN_MESSAGE
}
