import React from 'react'

import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'
import { BaseCard } from '@toasttab/buffet-pui-card'
const MenuItemCard: React.ComponentType = () => {
  return (
    <div className='mb-4'>
      <BaseCard className='w-full space-y-2 lg:w-2/3'>
        <Skeleton className='w-2/3 h-6' />
        <div>
          <Skeleton className='w-full h-4 mb-1' />
          <Skeleton className='w-full h-4 mb-1' />
        </div>
      </BaseCard>
    </div>
  )
}

export interface MenuLoadingProps {
  showTableIndicatorLoading?: Boolean
  showSearchBarLoading?: Boolean
}

export const MenuLoading: React.ComponentType<MenuLoadingProps> = ({
  showTableIndicatorLoading,
  showSearchBarLoading
}) => {
  return (
    <>
      {Boolean(showTableIndicatorLoading) && (
        <div className='flex justify-center items-center py-2 px-5 mb-4 border-t-2 border-b-2 border-r-0 border-l-0  border-solid border-darken-12'>
          <Skeleton className='w-16 h-6' />
        </div>
      )}
      <div className='my-4 mb-7 mx-6'>
        <div className='flex flex-col'>
          {Boolean(showSearchBarLoading) && (
            <Skeleton className='w-full h-6 mb-4' />
          )}
          <MenuItemCard />
          <MenuItemCard />
          <MenuItemCard />
          <MenuItemCard />
          <MenuItemCard />
        </div>
      </div>
    </>
  )
}
