import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { SaveNewCardCheckbox } from '../../SaveNewCardCheckbox/SaveNewCardCheckbox'
import { useFormik } from '../../FormikProvider/FormikProvider'
import { Field } from 'formik'
import InputGroup from '../../Form/InputGroup/InputGroup'
import { Password } from '../../Form/Password/Password'
import { CheckoutMode } from '../checkout-modes'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'

import styles from './SaveNewCardAndAccount.module.css'
import { FieldLabel } from '@/il8n/en'

const inputsPlaceOrderV2 = [
  {
    autoComplete: 'section-customer tel-national',
    id: 'customer_tel',
    label: `${FieldLabel.CHECKOUT_CUSTOMER_PHONE}*`,
    name: 'customerTel',
    type: 'tel',
    mask: [
      '(',
      /[1-9]/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/
    ],
    guide: false
  }
]

const getCustomerInputs = ({ mode }) => {
  switch (mode) {
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE:
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    default:
      return inputsPlaceOrderV2
  }
}

export const SaveNewCardAndAccount = ({ mode }) => {
  const { handleChange, setFieldValue, values } = useFormik()

  const handleFieldChange = useCallback(
    (event) => {
      setFieldValue('password', '')
      handleChange(event)
    },
    [handleChange, setFieldValue]
  )

  if (
    mode === CheckoutMode.CREATE_TAB_MODE ||
    mode === CheckoutMode.CREATE_PREAUTH_TAB_MODE
  ) {
    return null
  }

  const inputs = getCustomerInputs({ mode })

  return (
    <div
      className={cx(
        styles.makeItEasyRedesign,
        'p-4 mt-4 bg-gray-0 border border-solid rounded-lg'
      )}
    >
      <div className={'flex space-x-4 font-semibold'}>
        {
          <div className='h-10 w-10'>
            <ToastLogo size='sm' className='rounded-full' />
          </div>
        }
        <div>Make it easy to order more with a Toast account</div>
      </div>
      <div className={styles.checkboxFieldRedesign}>
        <Field
          component={SaveNewCardCheckbox}
          name='saveCard'
          onChange={handleFieldChange}
        />
      </div>
      {values.saveCard && (
        <div className={styles.passwordFieldRedesign}>
          <InputGroup inputConfigs={inputs} />
          <Field
            component={Password}
            label={FieldLabel.CHECKOUT_CUSTOMER_PASSWORD}
            name='password'
            id='checkout_password'
            value={values.password}
            autoComplete='off'
          />
          {inputs && inputs.find((i) => i.name === 'customerTel') && (
            <p className={'type-default text-gray-100'}>
              By providing a mobile number, you give Toast permission to contact
              you using automated text messages to provide transactional
              messages such as order status updates.
            </p>
          )}
        </div>
      )}
    </div>
  )
}

SaveNewCardAndAccount.propTypes = {
  mode: PropTypes.string.isRequired
}
