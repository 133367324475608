import { useFlag } from '../components/FeatureFlag/use-flag'
import { LDFlags } from '../launchdarkly/flags'
import { useRestaurantInfo } from './restaurant-info/use-restaurant-info'

export const useIsIntlRestaurant = () => {
  const isVsmbRestaurants = useFlag(LDFlags.VSMB_RESTAURANTS)
  const { data } = useRestaurantInfo()
  const country = data?.i18n?.country
  const allowedIntlCountries = ['IE', 'CA', 'GB']
  return (country && allowedIntlCountries.includes(country)) ||
    isVsmbRestaurants
    ? true
    : false
}
