import * as React from 'react'
import cx from 'classnames'
import styles from './BasicHeader.module.css'
import { RestaurantLogoV2 } from '../RestaurantLogoV2/RestaurantLogoV2'

export interface BasicHeaderProps {
  logoUrl?: string
  restaurantName: string
  tableName: string
}

export const BasicHeader = ({
  logoUrl,
  restaurantName,
  tableName
}: BasicHeaderProps) => (
  <div
    data-testid='basic-header'
    className={cx(
      'flex flex-col items-center pb-16 pt-[72px]',
      styles.basicInfoContainer
    )}
  >
    <div className='mb-4'>
      <RestaurantLogoV2 logoUrl={logoUrl} restaurantName={restaurantName} />
    </div>
    <p className='mb-3 text-center type-default'>{`Welcome to ${restaurantName}`}</p>
    <p className='text-center type-headline-2'>{`Table ${tableName}`} </p>
  </div>
)
