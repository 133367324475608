import { HeaderText } from '@/il8n/en'

export const getHeader = (
  isGroupCart: boolean,
  groupCartsHaveItems: boolean
) => {
  if (isGroupCart) {
    return groupCartsHaveItems
      ? HeaderText.COMBINED_TAB_CART_GROUP
      : HeaderText.GROUP_CHECK
  } else {
    return groupCartsHaveItems
      ? HeaderText.COMBINED_TAB_CART_V2
      : HeaderText.YOUR_CHECK
  }
}
