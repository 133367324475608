import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import styles from './ButtonSpacer.module.css'

const ButtonSpacer = ({ vertical }) => (
  <div className={cx({ [styles.vertical]: vertical })} />
)

ButtonSpacer.propTypes = {
  vertical: PropTypes.bool
}

export default ButtonSpacer
