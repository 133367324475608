import React, { useState } from 'react'
import { Field } from 'formik'
import PropTypes from 'prop-types'

import {
  IfAuthenticated,
  UnlessAuthenticated
} from '../IfAuthenticated/IfAuthenticated'
import Checkbox from '../Form/Checkbox/Checkbox'
import Error from '../Error/Error'
import Progress from '../Progress/Progress'

import { useCreditCard } from '../CreditCardProvider/CreditCardProvider'

import { TermsModal, SAVE_CARD } from '../TermsModal/TermsModal'

import InfoImg from '../../assets/info.svg'
import styles from './SaveNewCardCheckbox.module.css'
import { CTA } from '@/il8n/en'
import { selectorStyles } from '../Form/Radio/Radio'

export const SaveNewCardCheckbox = ({
  disabled,
  field,
  onChange = field.onChange
}) => {
  const [shouldShowTerms, editShowTerms] = useState(false)
  const { loading, error } = useCreditCard()

  // eslint-disable-next-line react/prop-types
  const Label = ({ text }) => (
    <span>
      <span>
        {text}
        <button
          data-testid='save-new-card-terms-button'
          type='button'
          className={styles.infoButton}
          onClick={() => editShowTerms(!shouldShowTerms)}
        >
          <InfoImg className={styles.infoImage} />
        </button>
      </span>
    </span>
  )

  return (
    <>
      {shouldShowTerms && (
        <TermsModal onClose={() => editShowTerms(false)} terms={SAVE_CARD} />
      )}
      <IfAuthenticated>
        {() => {
          if (loading) {
            return (
              <Progress eventData={{ category: 'Save New Card Checkbox' }} />
            )
          }

          if (error) {
            return <Error error={error} />
          }

          return (
            <Field
              component={Checkbox}
              name={field.name}
              value={field.value}
              id={`${field.name}_authenticated_checkbox`}
              disabled={disabled}
              onChange={onChange}
              label={<Label text='Remember this card' />}
            />
          )
        }}
      </IfAuthenticated>
      <UnlessAuthenticated>
        <Field
          component={Checkbox}
          variant={selectorStyles.GRAY}
          name={field.name}
          value={field.value}
          id={`${field.name}_unauthenticated_checkbox`}
          disabled={disabled}
          onChange={onChange}
          label={<Label text={CTA.CREATE_ACCOUNT_AND_SAVE_CARD} />}
        />
      </UnlessAuthenticated>
    </>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
})

SaveNewCardCheckbox.propTypes = {
  disabled: PropTypes.bool,
  field: FieldShape.isRequired,
  onChange: PropTypes.func
}
