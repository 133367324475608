import React from 'react'

import { EditComponentProps } from '../OrderTable/CheckTable'
import { CTA } from '@/il8n/en'

interface ThisIsntMineComponentProps extends EditComponentProps {
  handleDisclaim: (selectionGuid: string) => void
  handleClaim: (selectionGuid: string) => void
  isMine: boolean
}

export const ClaimItemComponent: React.FC<ThisIsntMineComponentProps> = ({
  selectionGuid,
  handleDisclaim,
  handleClaim,
  isMine
}) => {
  if (!isMine) {
    return (
      <div>
        <span
          onClick={() => handleClaim(selectionGuid)}
          className={'text-color-link'}
          tabIndex={0}
        >
          {CTA.CLAIM_ITEM}
        </span>
      </div>
    )
  }

  return (
    <div>
      <span
        onClick={() => handleDisclaim(selectionGuid)}
        className={'text-color-link'}
        tabIndex={0}
      >
        {CTA.THIS_ISNT_MINE}
      </span>
    </div>
  )
}
