import { useQuery, QueryHookOptions } from '@apollo/client'

import { LOOKUP_PARTIES_BY_TABLE } from '../../apollo/party/party.graphql'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'

export const useLookupPartiesByTable = (options: QueryHookOptions) => {
  const { uniqueIdentifier: tableName } = useDDIGlobals()
  const { restaurantGuid } = useRestaurant()

  return useQuery(LOOKUP_PARTIES_BY_TABLE, {
    variables: {
      input: {
        activeOnly: true,
        restaurantGuid,
        tableName
      }
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    ...options
  })
}
