import * as Yup from 'yup'
import { getRawPhoneNumber } from './form-utils'

export const passwordSchema = Yup.string()
  .min(8, 'Must be at least 8 characters')
  .required('Required')

export const emailSchema = Yup.string()
  .trim()
  .email('Must be valid email')
  .required('Required')

export const deliveryInfoAddress2Schema = Yup.string()
  .label('Apt./Suite no.')
  .trim()
  .max(255)
  .default('')

export const deliveryInfoNotesSchema = Yup.string()
  .label('Delivery Instructions')
  .trim()
  .max(300)
  .default('')

export const phoneSchema = Yup.string()
  .transform(getRawPhoneNumber)
  .trim()
  .matches(/^\d{10}$/, 'Must be a 10 digit number')
  .required('Required')

export const savedAddressSchema = Yup.object().shape({
  address2: deliveryInfoAddress2Schema,
  notes: deliveryInfoNotesSchema
})

export const createAccountValidationSchema = Yup.object().shape({
  phone: phoneSchema,
  email: emailSchema
})

export const loginValidationSchema = Yup.object().shape({
  phone: phoneSchema
})
