import React from 'react'
import {
  restaurantStorageKeys,
  useRestaurantStorage
} from '../../utils/restaurant-storage'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { useExpiringStateWithNamespacedStorage } from '../../utils/namespaced-storage'
import { CompletedPaymentType } from './constants'

export const generateTableKey = (tableName: string, mode: string) => {
  const tableKeyNameRaw = `${mode}-${tableName}`
  let postfix = '/'
  try {
    postfix += window.btoa(tableKeyNameRaw)
  } catch (e) {
    postfix += window.btoa(encodeURIComponent(tableKeyNameRaw))
  }
  return `${restaurantStorageKeys.PARTY_PROPERTIES}${postfix}`
}

interface RequiredPartyProperties {
  readonly partyGuid: string
  readonly partyMemberGuid: string
  readonly memberAuthToken: string
  readonly pin?: string | null
}

interface SupplementalPartyProperties {
  readonly paygOrderGuid?: string
  readonly itemsPaid?: number
  readonly excludeSelectionsHint?: string[]
  readonly preauthInvalidated?: boolean
  readonly completedPaymentType?: CompletedPaymentType
  readonly portionsToBePaid?: number
  readonly totalPortions?: number
}

interface PartyProperties
  extends Partial<RequiredPartyProperties>,
    SupplementalPartyProperties {}

const initialRequiredPartyProperties: Readonly<
  Partial<RequiredPartyProperties>
> = {
  partyGuid: undefined,
  partyMemberGuid: undefined,
  memberAuthToken: undefined,
  pin: undefined
}

const requiredPartyProperties = Object.keys(
  initialRequiredPartyProperties
) as ReadonlyArray<keyof RequiredPartyProperties>

const initialSupplementalPartyProperties: Readonly<SupplementalPartyProperties> =
  {
    paygOrderGuid: undefined,
    itemsPaid: 0,
    excludeSelectionsHint: [],
    preauthInvalidated: false,
    completedPaymentType: undefined,
    portionsToBePaid: undefined,
    totalPortions: undefined
  }

const supplementalPartyProperties = Object.keys(
  initialSupplementalPartyProperties
) as ReadonlyArray<keyof SupplementalPartyProperties>

const initialPartyProperties: Readonly<PartyProperties> = {
  ...initialRequiredPartyProperties,
  ...initialSupplementalPartyProperties
}

type SupplementalPropertiesUpdaterCallback = (
  oldProps: SupplementalPartyProperties
) => SupplementalPartyProperties
export interface PartyPropertiesInterface extends Readonly<PartyProperties> {
  updateSupplementalPartyProperties(
    newProps:
      | SupplementalPartyProperties
      | SupplementalPropertiesUpdaterCallback
  ): void
  updatePartyProperties(newProps: RequiredPartyProperties): void
  deletePartyProperties(): void
  /**
   * Gets the most up-to-date party properties
   * Useful when it is extremely important to not have stale values.
   */
  getRevalidatedPartyProperties(): PartyProperties
}

const updateRestrictedProperties = <Type extends { [key: string]: any }>(
  newProps: Type,
  currentState: Partial<Type>,
  restrictedPropertiesList: ReadonlyArray<keyof typeof newProps>
): Partial<Type> => {
  return restrictedPropertiesList.reduce(
    (acc, p) => ({
      ...acc,
      [p]: p in newProps ? newProps[p] : currentState[p]
    }),
    currentState
  )
}

export const usePartyProperties = ({
  expirationDuration
}: {
  expirationDuration: number
}): PartyPropertiesInterface => {
  const { uniqueIdentifier, mode } = useDDIGlobals()
  const finalIdentifier =
    typeof uniqueIdentifier === 'string' ? uniqueIdentifier : 'undefinedTable'
  const modeString = mode ? String(mode) : 'undefinedMode'
  const restaurantStorage = useRestaurantStorage()
  const stateInterface = useExpiringStateWithNamespacedStorage(
    generateTableKey(finalIdentifier, modeString),
    initialPartyProperties,
    restaurantStorage,
    expirationDuration,
    {
      resetInceptionTimeOnUpdate: false
    }
  )

  const partyProperties = stateInterface[0] as PartyProperties
  const _updatePartyProperties = stateInterface[1] as React.Dispatch<
    React.SetStateAction<Partial<PartyProperties>>
  >
  const getRevalidatedPartyProperties = stateInterface[2]
    .getRevalidatedStorageValues as () => PartyProperties

  /**
   * Updates essential party properties.
   * Expects all party properties on each update,
   * and uses previous values as a fallback.
   */
  const updatePartyProperties = React.useCallback(
    (newProps: RequiredPartyProperties) => {
      // TODO: Remove warnings when all consumers are in TypeScript
      requiredPartyProperties.forEach((p) => {
        if (!(p in newProps)) {
          console.error(
            `updatePartyProperties expected to be called with missing property: ${p}`
          )
        }
      })
      _updatePartyProperties((currentState) => {
        return updateRestrictedProperties(
          newProps,
          currentState,
          requiredPartyProperties
        )
      })
    },
    [_updatePartyProperties]
  )

  const updateSupplementalPartyProperties = React.useCallback(
    (
      newPropsOrUpdaterCb:
        | SupplementalPartyProperties
        | SupplementalPropertiesUpdaterCallback
    ) => {
      if (typeof newPropsOrUpdaterCb === 'function') {
        _updatePartyProperties((currentState) => {
          const newSupplementalProps = newPropsOrUpdaterCb({
            excludeSelectionsHint: currentState.excludeSelectionsHint,
            itemsPaid: currentState.itemsPaid,
            paygOrderGuid: currentState.paygOrderGuid,
            preauthInvalidated: currentState.preauthInvalidated,
            completedPaymentType: currentState.completedPaymentType,
            portionsToBePaid: currentState.portionsToBePaid,
            totalPortions: currentState.totalPortions
          })

          return updateRestrictedProperties(
            newSupplementalProps,
            currentState,
            supplementalPartyProperties
          )
        })
      } else {
        _updatePartyProperties((currentState) =>
          updateRestrictedProperties(
            newPropsOrUpdaterCb,
            currentState,
            supplementalPartyProperties
          )
        )
      }
    },
    [_updatePartyProperties]
  )

  const deletePartyProperties = React.useCallback(() => {
    _updatePartyProperties(initialPartyProperties)
  }, [_updatePartyProperties])

  return {
    ...partyProperties,
    updateSupplementalPartyProperties,
    updatePartyProperties,
    deletePartyProperties,
    getRevalidatedPartyProperties
  }
}
