import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import KeyHandler from 'react-key-handler'
import FocusTrap from 'focus-trap-react'
import { CSSTransition } from 'react-transition-group'

import Error from '../Error/Error'
import CloseButton from '../CloseButton/CloseButton'

import styles from './Drawer.module.css'
import { track } from '@toasttab/do-secundo-analytics'

let drawerCount = 0
let drawerRoot = document.getElementById('drawer-root')
if (!drawerRoot) {
  drawerRoot = document.createElement('div')
  drawerRoot.id = 'drawer-root'
  drawerRoot.classList.add('fs-unmask')
  document.body.appendChild(drawerRoot)
}

const stopEventPropagation = (event) => {
  event.stopPropagation()
}

class Drawer extends React.Component {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
    this.scrollWidth = window.innerWidth - document.body.clientWidth
    this.state = {
      mounted: false
    }
  }

  componentDidMount() {
    drawerCount++
    if (drawerCount === 1) {
      document.body.classList.add('drawer-open')
      document.body.style.margin = `0 ${this.scrollWidth}px 0 0`
    }
    this.setState({
      mounted: true
    })
    this.props.sendOpenEvent()
    drawerRoot.appendChild(this.el)
  }

  componentWillUnmount() {
    drawerCount--
    if (drawerCount === 0) {
      document.body.classList.remove('drawer-open')
      document.body.style.margin = ''
    }
    drawerRoot.removeChild(this.el)
  }

  render() {
    const {
      wrapper,
      onClose,
      children,
      header,
      error,
      footer,
      sendCloseEvent
    } = this.props
    const closeHandler = (event) => {
      sendCloseEvent()
      onClose(event)
    }
    const contents = wrapper(
      <FocusTrap>
        <div className={styles.background} onClick={closeHandler}>
          <CSSTransition in classNames={{ ...styles }} timeout={1000} appear>
            <div
              role='dialog'
              className={styles.drawer}
              onClick={stopEventPropagation}
            >
              <div className={styles.header}>
                {onClose && (
                  <div className={styles.closeButton}>
                    <CloseButton
                      data-testid='drawer-close-button'
                      onClick={closeHandler}
                    />
                    <KeyHandler
                      keyEventName='keydown'
                      keyValue='Escape'
                      onKeyHandle={closeHandler}
                    />
                  </div>
                )}
                <div
                  data-testid='drawer-header-content'
                  className={styles.headerContent}
                >
                  {header}
                </div>
              </div>

              {error && <Error error={error} />}
              <div className={styles.body}>{children}</div>
              {footer && <div className={styles.footer}>{footer}</div>}
            </div>
          </CSSTransition>
        </div>
      </FocusTrap>
    )
    return ReactDOM.createPortal(contents, this.el)
  }
}

Drawer.propTypes = {
  wrapper: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  error: PropTypes.object,
  footer: PropTypes.node,
  sendCloseEvent: PropTypes.func.isRequired,
  sendOpenEvent: PropTypes.func.isRequired
}

Drawer.defaultProps = {
  wrapper: (x) => x,
  sendCloseEvent: () => track('Drawer Open'),
  sendOpenEvent: () => track('Drawer Close')
}

export const TestDrawer = Drawer

export default Drawer
