import { useQuery, QueryHookOptions } from '@apollo/client'
import {
  ItemTag,
  PopularItemV3
} from '../../apollo/generated/OptWebGraphQLOperations'
import { GET_POPULAR_ITEMS } from '../../apollo/menu/menus.graphql'
import { FinalMenuItemV2 } from '../../components/MenuItem/VirtualMenu.helpers'

import uniqWith from 'lodash/uniqWith'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'

export const mapToMenuItemV2 = (popItems?: PopularItemV3[] | null) =>
  popItems
    ? (popItems.map(({ itemGuid, groupGuid, doMenuItem }) => ({
        name: doMenuItem.name,
        guid: itemGuid,
        itemGroupGuid: groupGuid,
        description: doMenuItem.description,
        price: doMenuItem.price,
        outOfStock: doMenuItem.outOfStock,
        imageUrl: doMenuItem.images?.item?.fullPath,
        imageUrls: doMenuItem.imageUrls,
        hasModifiers: doMenuItem.modifierGroups.length > 0,
        itemTags: (doMenuItem.itemTags || []) as ItemTag[],
        dietaryItemTags: doMenuItem.dietaryItemTags as ItemTag[],
        unitOfMeasure: doMenuItem.unitOfMeasure || 'NONE',
        usesFractionalQuantity: doMenuItem.usesFractionalQuantity
      })) as unknown as FinalMenuItemV2[])
    : undefined

export const useGetPopularItems = (
  popItemsLimit: number = 10,
  options?: QueryHookOptions
) => {
  const { mobileDiningSolutionsChannelGuid, restaurantGuid } =
    useDDIGlobals() as any

  const skip = !restaurantGuid

  const input = {
    restaurantGuid,
    popItemsLimit: popItemsLimit,
    channelGuid: mobileDiningSolutionsChannelGuid
  }
  const { data, loading, error, ...rest } = useQuery(GET_POPULAR_ITEMS, {
    variables: {
      input
    },
    skip,
    ...options
  })

  const popularMenuItems = mapToMenuItemV2(data?.getPopularItems?.popularItems)

  const uniquePopularItems = popularMenuItems
    ? uniqWith(popularMenuItems, (a, b) => {
        return a.guid === b.guid && a.itemGroupGuid === b.itemGroupGuid
      })
    : undefined

  return {
    data,
    popularMenuItems: uniquePopularItems,
    loading,
    error,
    ...rest
  }
}
