import { string, boolean, mixed, object } from 'yup'
import { passwordSchema } from '../../../../utils/form-schemas'
import { CheckoutMode } from '../../checkout-modes'
import { PaymentType, SelectedPayment } from '../constants'
import { SpiInputState } from '../../../../types/SPI'

const whenNewCredit = ({ then, otherwise = () => string().default('') }) =>
  string().when(['$giftCardHasSufficientFunds', 'selectedPayment'], {
    is: (giftCardHasSufficientFunds, selectedPayment) => {
      if (selectedPayment !== SelectedPayment.NEW_CARD) {
        return false
      }
      return !giftCardHasSufficientFunds
    },
    then,
    otherwise
  })

const whenSavedCredit = ({ then, otherwise = () => string().default('') }) =>
  string().when(['$giftCardHasSufficientFunds', 'selectedPayment'], {
    is: (giftCardHasSufficientFunds, selectedPayment) => {
      if (selectedPayment !== SelectedPayment.SAVED_CARD) {
        return false
      }
      return !giftCardHasSufficientFunds
    },
    then,
    otherwise
  })

export const getValidationSchema = ({ authenticated, mode }) => {
  switch (mode) {
    case CheckoutMode.CREATE_TAB_MODE:
      return {}
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE:
      return {
        paymentType: string()
          .trim()
          .required('required')
          .oneOf(
            [
              PaymentType.CREDIT_CARD,
              PaymentType.APPLE_PAY,
              PaymentType.CLICK_TO_PAY
            ],
            'must be a valid payment type'
          ),
        savedCardGuid: whenSavedCredit({
          then: () =>
            string()
              .required('required')
              // guid will be prefixed with '!' if card isn't accepted.
              .matches(/^[^!]/, 'must be an accepted card type')
        }),
        encryptedCard: whenNewCredit({
          // The validation only seems to work with string() even though this value is
          // actually an object. Note that the value itself should be opaque since it
          // came from the iframe so we really only care whether it is present or not.
          then: () => string().required('required')
        })
      }
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE:
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    default:
      return {
        saveCard: boolean().default(false),
        password: string().when(
          ['paymentType', 'saveCard', 'selectedPayment'],
          {
            is: (paymentType, saveCard, selectedPayment) =>
              !authenticated &&
              paymentType === PaymentType.CREDIT_CARD &&
              saveCard &&
              selectedPayment === SelectedPayment.NEW_CARD,
            then: () => passwordSchema,
            otherwise: () => string().default('')
          }
        ),
        paymentType: string()
          .trim()
          .required('required')
          .oneOf(
            [
              PaymentType.APPLE_PAY,
              PaymentType.CREDIT_CARD,
              PaymentType.CLICK_TO_PAY
            ],
            'must be a valid payment type'
          ),
        savedCardGuid: whenSavedCredit({
          then: () =>
            string()
              .required('required')
              // guid will be prefixed with '!' if card isn't accepted.
              .matches(/^[^!]/, 'must be an accepted card type')
        }),
        selectedPayment: string()
          .trim()
          .required('required')
          .oneOf(
            [
              SelectedPayment.APPLE_PAY,
              SelectedPayment.NEW_CARD,
              SelectedPayment.SAVED_CARD,
              SelectedPayment.CLICK_TO_PAY
            ],
            'must be a valid selected payment'
          ),
        encryptedCard: whenNewCredit({
          // The validation only seems to work with string() even though this value is
          // actually an object. Note that the value itself should be opaque since it
          // came from the iframe so we really only care whether it is present or not.
          then: () =>
            mixed().when(['spiActive'], {
              is: (spiActive) => spiActive,
              then: () =>
                string().required('required').oneOf([SpiInputState.VALID]),
              otherwise: () => object().required('required')
            })
        })
      }
  }
}
