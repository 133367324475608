import { PaymentFormKeys } from '../../../CheckoutForm/types/types'
import * as yup from 'yup'
import { SelectedPayment } from '../../../CheckoutForm/PaymentInfo/constants'
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js'
import { getRawPhoneNumber } from '../../../../utils/form-utils'
import { SpiInputState, SpiPaymentType } from '../../../../types/SPI'

export const getValidationSchema = ({
  authenticated,
  requireTel,
  adyenActive
}: {
  authenticated: boolean
  requireTel?: boolean
  adyenActive?: boolean
}) => {
  return yup.object().shape({
    creditCardTip: yup
      .string()
      .trim()
      // Transform here given that tip has a currency mask that adds $ prefix to it
      .transform((raw: string) => {
        raw = raw.replace(/[^0-9.]/g, '')
        return raw
      })
      .default('')
      .matches(/^([1-9][0-9]*|0)?(\.\d{2})?$/, 'must be a dollar amount'),
    encryptedCard: yup.string().when(['spiActive', 'spiPayment'], {
      is: (spiActive: boolean, spiPayment: boolean) => {
        return spiActive && spiPayment
      },
      then: (s: yup.StringSchema) =>
        s.required('required').oneOf([SpiInputState.VALID]),
      otherwise: (s: yup.StringSchema) =>
        s.when(
          ['$giftCardHasSufficientFunds', PaymentFormKeys.SELECTED_PAYMENT],
          {
            is(
              giftCardHasSufficientFunds: boolean,
              selectedPayment: SelectedPayment
            ) {
              if (adyenActive) {
                return false
              }

              if (selectedPayment === SelectedPayment.NEW_CARD) {
                return !giftCardHasSufficientFunds
              }
              return false
            },
            then: (s: yup.StringSchema) => s.required(),
            otherwise: (s: yup.StringSchema) => s.default('')
          }
        )
    }),
    selectedPayment: yup.string().notRequired(),
    saveCard: yup.boolean().default(false),
    savedCardGuid: yup.string().default(''),
    customerTel: yup
      .string()
      .when(['selectedPayment', 'saveCard', 'spiActive', 'spiPaymentType'], {
        is: (
          selectedPayment: string,
          saveCard: boolean,
          spiActive: boolean,
          spiPaymentType: string
        ) => {
          if (authenticated || !requireTel) {
            return false
          }

          if (spiActive) {
            return spiPaymentType === SpiPaymentType.NEW_CARD
          }

          return selectedPayment === SelectedPayment.NEW_CARD
        },
        then: () => {
          return yup
            .string()
            .required('required')
            .trim()
            .transform(getRawPhoneNumber)
            .test({
              name: 'validate',
              exclusive: false,
              params: {},
              message: 'must be a valid phone number',
              test: (value, context) => {
                const { country } = context.parent
                if (!value) {
                  return true
                }
                return isValidPhoneNumber(value, country as CountryCode)
              }
            })
        },
        otherwise: () => yup.string().default('')
      }),
    customerEmail: yup
      .string()
      .trim()
      .when(['selectedPayment', 'spiPaymentType', 'spiPayment'], {
        is: (
          selectedPayment: SelectedPayment,
          spiPaymentType: string,
          spiPayment: boolean
        ) => {
          if (
            spiPayment &&
            (spiPaymentType === SpiPaymentType.APPLE_PAY ||
              spiPaymentType === SpiPaymentType.GOOGLE_PAY)
          ) {
            return false
          }

          return [
            SelectedPayment.NEW_CARD,
            SelectedPayment.SAVED_CARD,
            SelectedPayment.CLICK_TO_PAY
          ].includes(selectedPayment)
        },
        then: (s: yup.StringSchema) => {
          return s.email('must be valid email').required('required')
        },
        otherwise: (s: yup.StringSchema) => {
          return s.default('')
        }
      })
  })
}
