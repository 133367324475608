import { dataByTypename } from '../../utils/apollo-helpers'
import {
  CUSTOMER_SERVER,
  LOGIN_SERVER,
  MFA_LOGIN_SERVER
} from './authentication.graphql'
import {
  storage,
  setTokens,
  deleteTokens,
  accountLinkingStorage
} from './authentication-helpers'
import { ooV2Auth } from './oov2-auth'
import { cache, config, defaultData } from '../apollo-client'
import { COMPLETE_PROFILE_CREATION } from '@local/do-secundo-passwordless-authentication'
import { CTA } from '@/il8n/en'

export const handleAuthResponse = (response, forAccountLinking = false) => {
  const {
    AuthenticationResponse: data,
    MfaChallengeGeneratedResponse: mfaData,
    LoginError: error
  } = dataByTypename(response)

  if (error) {
    if (forAccountLinking) {
      deleteTokens(accountLinkingStorage)
    } else {
      deleteTokens()
    }
    return error
  }

  if (mfaData) {
    return mfaData
  }

  if (forAccountLinking) {
    setTokens(data, accountLinkingStorage)
  } else {
    setTokens(data)
  }
  return data
}

export const authenticationResolvers = {
  Query: {
    customer: async (obj, args, { client }) => {
      let token = storage.get('accessToken')
      const isPasswordlessAuthenticated = JSON.parse(
        localStorage.getItem('hasActiveSession')
      )

      if (!token && !isPasswordlessAuthenticated) {
        // Fall back to OO V2 if possible
        const ooV2Tokens = await ooV2Auth.getTokens()
        if (ooV2Tokens) {
          setTokens(ooV2Tokens)
          token = storage.get('accessToken')
        }
      }

      if (token || isPasswordlessAuthenticated) {
        try {
          const result = await client.query({
            query: CUSTOMER_SERVER,
            fetchPolicy: 'network-only'
          })
          return result.data.customer
        } catch (err) {
          return undefined
        }
      }
      return undefined
    }
  },
  Mutation: {
    login: async (
      _,
      { input: { email, password, forAccountLinking = false } },
      { client }
    ) => {
      try {
        const resp = await client.mutate({
          mutation: LOGIN_SERVER,
          variables: {
            input: { email, password }
          }
        })
        return handleAuthResponse(resp.data.login, forAccountLinking)
      } catch ({ message }) {
        deleteTokens()
        return { message, __typename: 'LoginError' }
      }
    },
    mfaLogin: async (
      _,
      { input: { code, challengeToken, email, forAccountLinking = false } },
      { client }
    ) => {
      try {
        const resp = await client.mutate({
          mutation: MFA_LOGIN_SERVER,
          variables: {
            input: { code, challengeToken, email }
          }
        })
        return handleAuthResponse(resp.data.mfaLogin, forAccountLinking)
      } catch ({ message }) {
        deleteTokens()
        return { message, __typename: 'LoginError' }
      }
    },
    logout: async (_obj, { input: { cart } }, { client }) => {
      deleteTokens()
      client.resetStore()
      return {
        message: 'success!',
        __typename: 'LogoutResponse'
      }
    },
    passwordlessLogin: async (_, { input: { code, source } }, { client }) => {
      try {
        await config.authClient.loginWithCode(code)
        const resp = await client.mutate({
          mutation: COMPLETE_PROFILE_CREATION,
          variables: {
            input: { source }
          }
        })

        const {
          CompleteProfileCreationResponse,
          CompleteProfileCreationError
        } = dataByTypename(resp.data.completeProfileCreation)

        if (CompleteProfileCreationError) {
          throw Error(CompleteProfileCreationError.message)
        }

        return CompleteProfileCreationResponse
      } catch ({ message }) {
        const getMessage = (message) => {
          if (message.includes('403')) {
            return CTA.WRONG_OR_EXPIRED_CODE
          }

          if (message.includes('429')) {
            return CTA.MAXIMUM_NUMBER_OF_FAILED_SMS_ATTEMPTS
          }

          return message
        }
        localStorage.setItem('hasActiveSession', false)

        return {
          message: getMessage(message),
          __typename: 'CompleteProfileCreationError'
        }
      }
    },
    passwordlessLogout: async (_obj, { input: { cart } }, { client }) => {
      try {
        await config.authClient.logout()
        // @todo
        // confirm that we dont need this bc loyalty points cannot be redeemed
        // await cleanUpCartData(client, { cart })

        // client.resetStore() doesnt clear out user data
        // and client.clearStore() by itself, clears out the default restaurant data
        // so we need to write the default data back in
        await client.clearStore()
        cache.writeQuery(defaultData)

        //this is done so when a user logs out, the history state is always cleared.
        // we need this because the SmsConfirmationBenefits shows based on the from property on the confirm page
        if (window.history.state?.state?.from) {
          window.history.replaceState(
            { ...window.history.state, state: { from: 'logout ' } },
            ''
          )
        }

        return {
          message: 'success!',
          __typename: 'LogoutResponse'
        }
      } catch ({ message }) {
        return { message, __typeName: 'PasswordlessLogoutError' }
      }
    },
    authenticate: (
      _,
      {
        input: {
          accessToken,
          refreshToken,
          customerGuid,
          forAccountLinking = false
        }
      }
    ) => {
      if (forAccountLinking) {
        setTokens({ accessToken, refreshToken }, accountLinkingStorage)
      } else {
        setTokens({ accessToken, refreshToken })
      }
      return {
        accessToken,
        refreshToken,
        customerGuid,
        __typename: 'AuthenticationResponse'
      }
    }
  }
}
