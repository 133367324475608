import React from 'react'
import { Currency, formatCurrency } from '@toasttab/buffet-pui-number-utilities'
import { Locale, checkLocale } from '@toasttab/buffet-pui-locale-utilities'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'

type FormatCurrencyProps = {
  amount: number
  showLeadingSign?: boolean
}

type I18nType = {
  currency: Currency
  locale: Locale
}

export const FormatCurrency = ({
  amount = 0,
  showLeadingSign = false
}: FormatCurrencyProps) => {
  const { data } = useRestaurantInfo()
  let rxI18n: I18nType = data?.i18n
  const currency = rxI18n?.currency
  const locale = checkLocale(rxI18n?.locale)
  const leadingSign = showLeadingSign && amount >= 0 ? '+' : ''
  const formattedCurrency = formatCurrency(
    {
      amount,
      currency
    },
    locale,
    {
      trailingZeros: true
    }
  )

  return (
    <>
      {leadingSign}
      {formattedCurrency}
    </>
  )
}
