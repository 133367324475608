import { setContext } from '@apollo/client/link/context'

import { dataByTypename } from '../../utils/apollo-helpers'
import namespacedStorage from '../../utils/namespaced-storage'
import { REFRESH } from './authentication.graphql'
import { ooV2Auth } from './oov2-auth'

export const storage = namespacedStorage('auth')

// for passwordless auth and account linking functionality
export const accountLinkingStorage = namespacedStorage('accountLinkingAuth')

export const deleteTokens = (storage = namespacedStorage('auth')) => {
  storage.remove('accessToken')
  storage.remove('refreshToken')
  ooV2Auth.deleteTokens()
}

export const setTokens = (
  { accessToken, refreshToken },
  storage = namespacedStorage('auth')
) => {
  storage.set('accessToken', accessToken)
  storage.set('refreshToken', refreshToken)
}

export const getAuthLink = () =>
  setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token =
      storage.get('accessToken') ||
      accountLinkingStorage.get('accessToken') ||
      ''
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        'toast-customer-access': token
      }
    }
  })

export const refreshToken = async (config) => {
  const refreshToken = storage.get('refreshToken')
  if (!refreshToken) {
    deleteTokens()
    return null
  }
  try {
    const resp = await config.client.mutate({
      mutation: REFRESH,
      variables: {
        input: { refreshToken }
      }
    })
    const { AuthenticationResponse: data, RefreshError: error } =
      dataByTypename(resp.data.refresh)

    if (error) {
      deleteTokens()
      return null
    }

    setTokens(data)
    return data
  } catch ({ message }) {
    return null
  }
}
