export enum FieldLabel {
  YOUR_ORDER_TABLE_HEADER = 'Your order',
  GROUP_ORDER_TABLE_HEADER = 'Group order',
  YOUR_ORDER_ACCORDION_LABEL = 'View your receipt',
  GROUP_ORDER_ACCORDION_LABEL = 'View full bill',
  YOUR_ORDER_COMPLETED_TABLE_HEADER = 'Your order',
  PAYMENT_OPTIONS = 'Payment options',
  PERSONAL_INFORMATION = 'Personal information',
  DELIVERY_INFORMATION = 'Delivery information',
  CUSTOMER_INFO_CHANGE_PASSWORD = 'Change password',
  INITIALIZE_PARTY_FIRST_NAME = 'Name',
  INITIALIZE_PARTY_NAME_INSTRUCTIONS = 'Your name helps us deliver your order to you.',
  INITIALIZE_PARTY_NAME_MISSING = 'Please enter your name to start ordering.',
  INITIALIZE_PARTY_NAME_IN_USE = 'This name is in use. Please use a unique name.',
  INITIALIZE_PARTY_PIN_MISSING = 'Ask another member of your group for the PIN.',
  INITIALIZE_PARTY_PHONE = 'Number',
  INITIALIZE_PARTY_EMAIL = 'Email',
  INITIALIZE_PARTY_PHONE_INSTRUCTIONS = 'We’ll text you a link to your tab for easy access.',
  INITIALIZE_PARTY_EMAIL_INSTRUCTIONS = 'We’ll send you a link to your order for easy access.',
  INITIALIZE_PARTY_PHONE_INSTRUCTIONS_INTL = 'Your phone number will only be used to text you a link to your order. Messaging and data fees may apply.',
  INITIALIZE_PARTY_PHONE_DISCLAIMER_FEES = 'Messaging and data fees may apply.',
  INITIALIZE_PARTY_PHONE_DISCLAIMER_RATE = 'Message frequency varies.',
  INITIALIZE_PARTY_PHONE_DISCLAIMER_STOP = 'Reply STOP to opt out.',
  INITIALIZE_PARTY_PHONE_MISSING = 'Your phone number is required to start ordering.',
  INITIALIZE_PARTY_PIN = 'PIN',
  INITIALIZE_PARTY_TOGGLE = 'Send to my',
  CHECKOUT_SEND_RECEIPT_TO = 'Send receipt to',
  CHECKOUT_TIP = 'Tip',
  CHECKOUT_TIP_CUSTOM_AMOUNT = 'Custom amount',
  CHECKOUT_PAYMENT_METHOD = 'Secure payment method',
  CHECKOUT_PROMO_CODE = 'Add promo code',
  CHECKOUT_GIFT_CARD_AND_TOAST_CASH_CHECKBOX = 'Add gift card or Toast Cash',
  CHECKOUT_GIFT_CARD_CHECKBOX = 'Add a gift card',
  CHECKOUT_TOAST_CASH_CHECKBOX = 'Add Toast Cash',
  CHECKOUT_GIFT_CARD_AND_TOAST_CASH_INPUT = 'Add gift card or Toast Cash',
  CHECKOUT_GIFT_CARD_INPUT = 'Add gift card number',
  CHECKOUT_TOAST_CASH_INPUT = 'Add Toast Cash code',
  CHECKOUT_CUSTOMER_FIRST_NAME = 'First name',
  CHECKOUT_CUSTOMER_LAST_NAME = 'Last name',
  CHECKOUT_CUSTOMER_EMAIL = 'Email',
  CHECKOUT_CUSTOMER_PHONE = 'Phone',
  CHECKOUT_CUSTOMER_PASSWORD = 'Password*',
  CHECKOUT_SURCHARGE_LABEL = 'Credit Card Surcharge',
  MODIFIER_SPECIAL_INSTRUCTIONS = 'Special instructions',
  MODIFIER_QUANTITY = 'Quantity',
  SAVED_ADDRESS_ADDRESS_LABEL = 'Address label',
  SAVED_ADDRESS_APT_NO = 'Apt./Suite no.',
  SAVED_ADDRESS_DELIVERY_INSTRUCTIONS = 'Delivery instructions',
  CREATE_ACCOUNT_EMAIL = 'Email',
  CREATE_ACCOUNT_PASSWORD = 'Password',
  ORDER_EXPLANATION = 'Place your order below. You can order more items at any time, and pay before you leave.',
  GROUP_ORDER_EXPLANATION = 'Place your group order below when everybody is ready. You can order more items at any time, and pay before you leave.',
  SPLIT_EVEN_TOTAL_SHARES = 'Split between',
  SPLIT_EVEN_CLAIMED_SHARES = 'Pay for',
  SPLIT_REMOVED = 'Any member of your party can pay the bill in full or split it again',
  TOTAL_DUE = 'Total Payment Due',
  TOTAL_REMAINING = 'Total Remaining',
  TOTAL_PAID = 'Total Amount Paid',
  SOLO_TOTAL = 'Your total',
  GROUP_TOTAL = 'Group total',
  SUBTOTAL = 'Subtotal',
  TAX = 'Tax',
  SURCHARGE_TAX = 'Surcharge Tax',
  PROMO_APPLIED = 'Promo Applied',
  LOYALTY_DISCOUNT_APPLIED = 'Reward Applied',
  GIFT_CARD_APPLIED = 'Gift Card Applied',
  TOAST_CASH_APPLIED = 'Toast Cash Applied',
  EMAIL_DISCLAIMER = 'We’ll use your email to provide digital receipts and account communications. You may also receive marketing messages from Toast and restaurants you visit, unless you unsubscribe',
  INTERNATIONAL_EMAIL_DISCLAIMER = 'Your email will be used to provide you with digital receipts for your purchases. The information you provide will be disclosed to the merchant and also processed pursuant to Toast’s ',
  GOOGLE_PAY_DISCLAIMER = 'We will use your Google Pay information to provide digital receipts and order status updates. You may also receive marketing messages from Toast and restaurants you visit, unless you unsubscribe.',
  APPLE_PAY_DISCLAIMER = 'We will use your Apple Pay information to provide digital receipts and order status updates. You may also receive marketing messages from Toast and restaurants you visit, unless you unsubscribe.'
}
