import React from 'react'
import { useDDIGlobals } from '../../../DDIGlobalsProvider/DDIGlobalsProvider'
import {
  PaymentType,
  SelectedPayment,
  SupportedPaymentMethods
} from '../constants'
import {
  DigitalPaymentSelectorUI,
  GooglePayEnvironments,
  SelectedPaymentState
} from '@toasttab/digital-payment-methods-poc'
import {
  RestaurantCreditCardConfig,
  SavedCreditCard,
  useValidate_Apple_Pay_MerchantMutation
} from '../../../../apollo/generated/OptWebGraphQLOperations'
import { useFlag } from '../../../FeatureFlag/use-flag'
import { LDFlags } from '../../../../launchdarkly/flags'
import { getIframeSrc } from '../../../CreditCard/CreditCard'
import { useClick2PayEnabled } from '../../../../hooks/use-click-2-pay-enabled'
import { useCreditCardsWithPreauth } from '../../../../hooks/place-order/use-available-payment-methods'
import { useGetPartyMode } from '../../../PartyQuery/PartyQuery'
import { useSentry } from 'banquet-runtime-modules'

export type MFEPaymentInfoComponentProps = {
  restaurantInfo: {
    restaurantName: string
    restaurantGuid: string
  }
  googlePayEnvironment: GooglePayEnvironments
  creditCardConfig: RestaurantCreditCardConfig
  giftCardHasSufficientFunds: boolean
  setFieldValue: (field: string, value: any) => void
  authenticated: boolean
}

export const isApplePayPreauth = (savedCard: SavedCreditCard) => {
  return !savedCard.expirationMonth && !savedCard.expirationYear
}

export const MFEPaymentInfoComponent = ({
  setFieldValue,
  giftCardHasSufficientFunds,
  creditCardConfig,
  googlePayEnvironment,
  restaurantInfo,
  authenticated
}: MFEPaymentInfoComponentProps) => {
  const { captureMessage, captureException } = useSentry()
  const partyMode = useGetPartyMode()
  const { restaurantGuid, shortUrl, toastwebBaseUri, guestExpressBaseUri } =
    useDDIGlobals() as Required<ReturnType<typeof useDDIGlobals>>
  const guestExpressMicrospasEnabled = useFlag(LDFlags.GUEST_EXPRESS_MICROSPAS)
  const { creditCards } = useCreditCardsWithPreauth()

  const hasApplePayPreauth = creditCards?.find((savedCard) =>
    isApplePayPreauth(savedCard)
  )

  const c2pEnabled = useClick2PayEnabled()

  const [_validateApplePayMerchantMutation] =
    useValidate_Apple_Pay_MerchantMutation()
  const getMerchantSession = async (validationURL: string) => {
    const { data, errors } = await _validateApplePayMerchantMutation({
      variables: {
        input: {
          merchantDomain: window.location.hostname,
          restaurantGuid: restaurantInfo.restaurantGuid,
          validationURL
        }
      }
    })

    if (
      data?.validateApplePayMerchant.__typename ===
      'ValidateApplePayMerchantSuccessResponse'
    ) {
      return JSON.parse(data.validateApplePayMerchant.merchantSession)
    } else {
      const errorResponse =
        data?.validateApplePayMerchant.__typename ===
          'ValidateApplePayMerchantError' && data.validateApplePayMerchant
      if (errorResponse) {
        captureException((errorResponse as any) || errors?.[0])
      }
      throw new Error('Unable to verify merchant URL.')
    }
  }

  const savedCCPaymentMethods = React.useMemo(() => {
    if (!creditCards) {
      return []
    }
    return creditCards.map((card) => {
      if (isApplePayPreauth(card)) {
        return { ...card, cardType: 'APPLE_PAY_PREAUTH' }
      }
      return card
    })
  }, [creditCards])

  const iframeUrl = getIframeSrc({
    amexAccepted: creditCardConfig.amexAccepted,
    guestExpressMicrospasEnabled,
    guestExpressBaseUri,
    toastwebBaseUri,
    restaurantGuid,
    shortUrl
  })

  const shouldEnableApplePay = !hasApplePayPreauth

  const enabledMethods: Set<SupportedPaymentMethods> = (() => {
    const methods = new Set([SupportedPaymentMethods.ToastEncryptedNewCard])

    // ensure only ToastEncryptedNewCard only if gift card covers entire total
    // This is because checkout button rendering currently depends
    // upon this expectation
    if (giftCardHasSufficientFunds) {
      return methods
    } else {
      methods.add(SupportedPaymentMethods.GooglePay)
      methods.add(SupportedPaymentMethods.ToastSavedCard)
    }

    if (shouldEnableApplePay) {
      methods.add(SupportedPaymentMethods.ApplePay)
    }

    if (c2pEnabled) {
      methods.add(SupportedPaymentMethods.Click2Pay)
    }

    return methods
  })()

  const onSelectedPaymentMethodChanged = async (
    paymentMethodValue: SelectedPaymentState
  ) => {
    // work to synchronize internal state with external form
    if (
      paymentMethodValue.selectedPaymentMethod ===
      SupportedPaymentMethods.ToastEncryptedNewCard
    ) {
      await setFieldValue('selectedPayment', SelectedPayment.NEW_CARD)
      await setFieldValue('paymentType', PaymentType.CREDIT_CARD)
      if (paymentMethodValue.state === 'valid') {
        // any present string will bring this to a valid state
        // but we will defer to the initSelectedPaymentFlow for actually
        // getting the card data instead
        await setFieldValue('encryptedCard', 'valid')
      } else {
        await setFieldValue('encryptedCard', '')
      }
    } else if (
      paymentMethodValue.selectedPaymentMethod ===
      SupportedPaymentMethods.GooglePay
    ) {
      // running multiple setFieldValues causes validation to run on stale values
      // https://github.com/jaredpalmer/formik/issues/2266
      await setFieldValue('selectedPayment', SelectedPayment.GOOGLE_PAY)
      await setFieldValue('paymentType', PaymentType.GOOGLE_PAY)
    } else if (
      paymentMethodValue.selectedPaymentMethod ===
      SupportedPaymentMethods.ApplePay
    ) {
      await setFieldValue('selectedPayment', SelectedPayment.APPLE_PAY)
      await setFieldValue('paymentType', PaymentType.APPLE_PAY)
      await setFieldValue('savedCardGuid', 'ApplePay')
    } else if (
      paymentMethodValue.selectedPaymentMethod ===
      SupportedPaymentMethods.ToastSavedCard
    ) {
      await setFieldValue('selectedPayment', SelectedPayment.SAVED_CARD)
      await setFieldValue('paymentType', PaymentType.CREDIT_CARD)
    } else if (
      paymentMethodValue.selectedPaymentMethod ===
      SupportedPaymentMethods.Click2Pay
    ) {
      await setFieldValue('selectedPayment', SelectedPayment.CLICK_TO_PAY)
      await setFieldValue('paymentType', PaymentType.CLICK_TO_PAY)
    }
  }

  return (
    <DigitalPaymentSelectorUI
      authenticated={authenticated}
      googlePayParams={{
        environment: googlePayEnvironment,
        merchantConfig: {
          merchantId: restaurantInfo.restaurantGuid,
          merchantName: restaurantInfo.restaurantName
        },
        billingAddressOptions:
          partyMode.mode === 'OPT'
            ? {
                billingPhoneRequired: true
              }
            : undefined
      }}
      applePayParams={{
        getMerchantSession
      }}
      enabledMethods={enabledMethods}
      creditCardConfig={{
        amexAccepted: creditCardConfig.amexAccepted
      }}
      logError={(err) =>
        captureMessage(err, 'error', {
          captureContext: (scope) => {
            return scope.setTags({
              component: 'digital-payment-methods-selector-ui'
            })
          }
        })
      }
      toastConfig={{
        shortUrl,
        toastwebBaseUri,
        iframeUrl
      }}
      onSelectedPaymentMethodChanged={onSelectedPaymentMethodChanged}
      savedCCPaymentMethods={savedCCPaymentMethods}
    />
  )
}
