import isEmpty from 'lodash/isEmpty'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'

export const useGiftCardLinks = () => {
  const response = useRestaurantInfo()
  const { data } = response
  if (!data) {
    return response
  }
  if (isEmpty(data)) {
    return response
  }
  const {
    giftCardLinks: { purchaseLink, checkValueLink, addValueEnabled }
  } = data

  return {
    ...response,
    purchaseLink: purchaseLink && `${purchaseLink}?utmCampaign=onlineOrdering`,
    checkValueLink,
    addValueEnabled
  }
}
