import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'

import { useParty } from '../PartyProvider/PartyProvider'
import OPTPartySplash from './OPTPartySplash'
import {
  OPTPartyInitializationModel,
  partyModelReducer,
  PARTY_INIT_ACTIONS
} from './model/opt_party_initialization_model'
import { useJoinablePartyEnabled } from '../../hooks/party/use-party-enabled'
import { useLocation } from 'react-router'
import { useAvailability } from '../../utils/availability'
import { useAccountRoutes } from '../ModeRouter/ModeRouter'

import { useServerStartsATabEnabled } from '../../hooks/party/use-server-starts-a-tab-enabled'
import { LDFlags } from '../../launchdarkly/flags'
import { useFlag } from '../FeatureFlag/FeatureFlag'
import { useHandleRejoin } from '../../hooks/use-handle-rejoin'

const noop = () => {
  console.error(
    'Party initialization state is being changed outside of PartySplash context'
  )
}
const PartySplashContext = createContext({
  setModal: noop,
  dispatch: noop,
  partyInitState: {
    current: null,
    previous: null
  },
  inviteCode: null
})

const PartySplashContextProvider = ({ children }) => {
  const partyKeys = useParty()
  const joinablePartyEnabled = useJoinablePartyEnabled()
  const ssatEnabled = useServerStartsATabEnabled()
  const pinRequired = useFlag(LDFlags.OPT_PARTY_PINS)

  // Ref instead of memo because useMemo may recreate the object
  // at any time.
  const initPartyModelRef = useRef(null)
  // only create once
  if (initPartyModelRef.current === null) {
    initPartyModelRef.current = new OPTPartyInitializationModel({
      joinablePartyEnabled,
      ssatEnabled,
      pinRequired
    })
  }

  const [partySplashState, dispatch] = useReducer((state, action) => {
    const initPartyModel = initPartyModelRef.current
    return partyModelReducer(initPartyModel, action)
  }, initPartyModelRef.current.run())

  const statePartyGuid =
    partySplashState &&
    partySplashState.partyKeys &&
    partySplashState.partyKeys.partyGuid
  const storedPartyGuid = partyKeys && partyKeys.partyGuid
  useEffect(() => {
    if (storedPartyGuid !== statePartyGuid) {
      dispatch({
        type: PARTY_INIT_ACTIONS.SET_PARTY_KEYS,
        value: {
          partyGuid: partyKeys.partyGuid,
          partyMemberGuid: partyKeys.partyMemberGuid,
          memberAuthToken: partyKeys.memberAuthToken,
          pin: partyKeys.pin
        }
      })
    }
  }, [dispatch, storedPartyGuid, statePartyGuid, partyKeys])

  const currentContext = {
    dispatch,
    ...partySplashState,
    // deep copy to ensure internal state can't be modified by accident
    partyInitState: { ...partySplashState.partyInitState }
  }

  return (
    <PartySplashContext.Provider value={currentContext}>
      {children}
    </PartySplashContext.Provider>
  )
}

const OPTPartySplashContainerInner = () => {
  const location = useLocation()
  const inviteCode = location && location.state && location.state.inviteCode

  const { mode } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const accountRoutes = useAccountRoutes()

  useHandleRejoin()

  // hide splash container if on scan page or if on another route being handled by mode router
  if (location.pathname === '/scan' || accountRoutes.includes(mode)) {
    return null
  }

  return (
    <PartySplashContextProvider>
      <OPTPartySplash inviteCode={inviteCode} />
    </PartySplashContextProvider>
  )
}

PartySplashContextProvider.propTypes = {
  children: PropTypes.node
}

OPTPartySplashContainerInner.propTypes = {}

export const usePartySplashState = () => {
  return useContext(PartySplashContext)
}

export const OPTPartySplashContainer = () => {
  const { shouldCreateParty } = useAvailability()
  if (!shouldCreateParty) {
    return null
  }
  return <OPTPartySplashContainerInner />
}

OPTPartySplashContainer.propTypes = {}
