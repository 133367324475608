import React, { useCallback } from 'react'

import { CheckTable } from '../OrderTable/CheckTable'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { useParty } from '../PartyProvider/PartyProvider'
import { useIsMyPreauthCard } from '../PartyQuery/PartyQuery'
import { ClaimItemComponent } from './ClaimItemComponent'
import { getNumberOfValidSelections, PartialBucketCheck } from './helpers'

interface ServerCheckTableProps {
  myCheck?: PartialBucketCheck
  serverCheck: PartialBucketCheck
  paymentMethod?: SplitPaymentMethod
}

export const ServerCheckTable = ({
  myCheck,
  serverCheck,
  paymentMethod
}: ServerCheckTableProps) => {
  const { updateSupplementalPartyProperties, excludeSelectionsHint } =
    useParty()

  const handleDisclaim = useCallback(
    (selectionGuid) => {
      updateSupplementalPartyProperties((currentProps) => {
        return {
          excludeSelectionsHint: (
            currentProps.excludeSelectionsHint ?? []
          ).concat(selectionGuid)
        }
      })
    },
    [updateSupplementalPartyProperties]
  )
  const handleClaim = useCallback(
    (selectionGuid) => {
      updateSupplementalPartyProperties((currentProps) => {
        return {
          excludeSelectionsHint: (
            currentProps.excludeSelectionsHint ?? []
          ).filter((i) => i !== selectionGuid)
        }
      })
    },
    [updateSupplementalPartyProperties]
  )

  const isMyPreauth = useIsMyPreauthCard()

  const doesShowServerItemsAction =
    !isMyPreauth && paymentMethod === SplitPaymentMethod.PAY_FOR_SELF

  const doesShowCrossThrough = Boolean(
    !isMyPreauth && paymentMethod === SplitPaymentMethod.PAY_FOR_SELF
  )

  const isLastItemToDisclaim = (selectionGuid: string) => {
    if (getNumberOfValidSelections(myCheck) > 0) {
      return false
    }
    return (
      getNumberOfValidSelections(serverCheck) -
        (excludeSelectionsHint?.length ?? 0) <
        2 && !excludeSelectionsHint?.includes(selectionGuid)
    )
  }

  return (
    <>
      <CheckTable
        memberName={'Items added by your server'}
        check={serverCheck}
        editComponent={
          doesShowServerItemsAction
            ? ({ selectionGuid }) => {
                if (isLastItemToDisclaim(selectionGuid)) {
                  return null
                }
                return (
                  <ClaimItemComponent
                    handleDisclaim={handleDisclaim}
                    handleClaim={handleClaim}
                    selectionGuid={selectionGuid}
                    isMine={
                      !excludeSelectionsHint?.includes(selectionGuid) ?? true
                    }
                  />
                )
              }
            : undefined
        }
        crossThroughSelectionGuids={
          doesShowCrossThrough ? excludeSelectionsHint : undefined
        }
      />
    </>
  )
}
