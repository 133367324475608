import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import {
  useGetPartyRefresh,
  useIsMyPreauthCard
} from '../../components/PartyQuery/PartyQuery'
import {
  getMainCheck,
  getPartyMode
} from '../../components/PartyQuery/PartyQuery/helpers'
import { DDIMode } from '../../types/DDIGlobals'
import { useRestaurantInfo } from '../restaurant-info/use-restaurant-info'
import { useIsIntlRestaurant } from '../use-is-intl-restaurant'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

export const useSplitPaymentsEnabled = (): boolean => {
  const partyRefreshResult = useGetPartyRefresh()
  const ddiGlobals = useDDIGlobals()
  const mode = getPartyMode(partyRefreshResult, ddiGlobals)
  const isIntl = useIsIntlRestaurant()
  const splitEvenEnabled = Boolean(
    useRestaurantInfo().data?.toastPayConfig?.splitPaymentsEnabled
  )
  const intlStpSplitCheckEnabled = useFlag(LDFlags.INTL_STP_SPLIT_CHECK)
  const isMyPreauth = useIsMyPreauthCard()
  if (isIntl && !intlStpSplitCheckEnabled) {
    return false
  }

  if (mode === DDIMode.STP) {
    const hasAnyLoyaltyDiscounts =
      (getMainCheck(partyRefreshResult)?.discounts.loyaltyDiscounts.length ??
        0) > 0

    // 4/17/2024 -- Preauth for STP case is primarily for the "Tables X Pay experiment"; this use-case will not likely scale up further within opt-web
    if (isMyPreauth) {
      return false
    }
    return splitEvenEnabled && !hasAnyLoyaltyDiscounts
  }

  // OPT split by item enabled if > 1 party member has ordered their own item
  if (mode === DDIMode.OPT) {
    if (!partyRefreshResult.partyRefresh) {
      return false
    }
    const membersWithOrderSelectionsCount =
      partyRefreshResult.partyRefresh.party.members.reduce((acc, member) => {
        if (member.orderSelectionIds.length > 0) {
          return acc + 1
        }
        return acc
      }, 0)
    return membersWithOrderSelectionsCount > 1
  }

  return false
}
