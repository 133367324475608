import React, { useMemo } from 'react'

import * as SplitPaymentSwitch from '../SplitPaymentSwitch/SplitPaymentSwitch'
import { CheckTable } from '../OrderTable/CheckTable'
import { SplitPaymentMethod } from '../SplitPaymentSwitch/SplitPaymentMethod.enum'
import { Bucket } from '../../utils/order-helpers'
import { OptPartyMemberV2Fragment } from '../../apollo/generated/OptWebGraphQLOperations'
import { getFilteredMemberName } from '../../utils/cart-helpers'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { CheckTableV2 } from '../OrderTable/CheckTableV2/CheckTableV2'
import { ServerCheckTable } from './ServerCheckTable'
import { StaticServerCheckTable } from './StaticServerCheckTable'

interface CheckBucketsViewProps {
  splitPaymentMethod: SplitPaymentMethod
  claimServerItems?: boolean
  buckets: Bucket[]
  enableReorder?: boolean
  serverBuckets?: Bucket[]
  me: OptPartyMemberV2Fragment
  isOrderClosed?: boolean
  checksCollapsible: boolean
  showAddMore?: boolean
  showSubtotals?: boolean
  displayUnpricedMods: boolean
}

export const CheckBucketsView = ({
  splitPaymentMethod,
  buckets,
  serverBuckets,
  claimServerItems = false,
  enableReorder,
  me,
  isOrderClosed = false,
  checksCollapsible,
  showAddMore = true,
  showSubtotals = false,
  displayUnpricedMods = false
}: CheckBucketsViewProps) => {
  const myBucket = useMemo(
    () =>
      buckets.find((db) => {
        return db.member.partyMemberGuid === me.partyMemberGuid
      }) ?? buckets[0],
    [me.partyMemberGuid, buckets]
  )

  const displayBuckets = useMemo(() => {
    if (
      splitPaymentMethod ===
        SplitPaymentSwitch.SplitPaymentMethod.PAY_FOR_PARTY ||
      !splitPaymentMethod
    ) {
      return buckets
    }
    return myBucket ? [myBucket] : []
  }, [splitPaymentMethod, buckets, myBucket])

  const tabsRedesignEnabled = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)

  // assumes first bucket is mine
  const myCheck = myBucket?.check

  return (
    <>
      {serverBuckets?.map((b) => {
        if (b.check.numberOfSelections < 1) {
          return null
        }
        if (!claimServerItems) {
          return (
            <StaticServerCheckTable
              key={b.member.partyMemberGuid}
              serverCheck={b.check}
            />
          )
        }

        return (
          <ServerCheckTable
            key={b.member.partyMemberGuid}
            myCheck={myCheck}
            serverCheck={b.check}
            paymentMethod={splitPaymentMethod}
          />
        )
      })}
      {displayBuckets.map(({ member, check }) => {
        return (
          <React.Fragment key={member.partyMemberGuid}>
            {tabsRedesignEnabled ? (
              <div className='mb-4'>
                <CheckTableV2
                  check={check}
                  memberName={getFilteredMemberName(displayBuckets, member, me)}
                  partyMemberGuid={member?.partyMemberGuid}
                  isInParty={displayBuckets.length > 1}
                  checksCollapsible={checksCollapsible}
                  showAddMore={showAddMore}
                  showSubtotals={showSubtotals}
                  displayUnpricedMods={displayUnpricedMods}
                />
              </div>
            ) : (
              <CheckTable
                check={check}
                memberName={getFilteredMemberName(displayBuckets, member, me)}
                partyMemberGuid={member?.partyMemberGuid}
                enableReorder={Boolean(enableReorder)}
                hasDivider={displayBuckets.length > 1}
                isOrderClosed={isOrderClosed}
              />
            )}
          </React.Fragment>
        )
      })}
    </>
  )
}
