import * as React from 'react'
import { NoSearchResults } from './NoSearchResults'
import Progress from '../Progress/Progress'
import { SearchBanner } from './SearchBanner'

export const SearchBannerContainer = ({
  loading,
  results,
  searchTerm,
  debouncedSearchTerm
}) => {
  const inputIsEmpty = !searchTerm && !loading
  const noSearchResults = !results?.length && !!searchTerm && !loading

  if (loading || debouncedSearchTerm !== searchTerm) {
    return <Progress eventData={{ category: 'Client-side search' }} />
  }

  if (inputIsEmpty) {
    return <SearchBanner>Begin typing to search</SearchBanner>
  }

  if (noSearchResults) {
    return <NoSearchResults />
  }

  return null
}
