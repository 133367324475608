import { useMemo } from 'react'

import { DdiMode } from '../../../apollo/generated/OptWebGraphQLOperations'
import { addEventProperties } from '@toasttab/do-secundo-analytics'
import { useTabEnabled } from '../../../hooks/tabs/useTabEnabled'
import { usePreauthEnabled } from '../../../hooks/preauth/use-preauth-enabled'
import { useJoinablePartyEnabled } from '../../../hooks/party/use-party-enabled'
import { usePreviewMode } from '../../../hooks/use-preview-mode'
import { useAvailability } from '../../../utils/availability'
import { useRestaurantInfo } from '../../../hooks/restaurant-info/use-restaurant-info'
import { useIsIntlRestaurant } from '../../../hooks/use-is-intl-restaurant'

import {
  useGetPartyMembers,
  useGetPartyMode,
  useGetPartyRefresh
} from '../../PartyQuery/PartyQuery'

export const useHeapParams = () => {
  const tabEnabled = useTabEnabled()
  const preauthTabEnabled = usePreauthEnabled()
  const joinablePartyEnabled = useJoinablePartyEnabled()
  const previewMode = usePreviewMode()
  const { orderingAvailable } = useAvailability()
  const { mode } = useGetPartyMode()
  const { members, loading } = useGetPartyMembers()
  const { partyRefresh } = useGetPartyRefresh()
  const { data: restaurant } = useRestaurantInfo()
  const isIntl = useIsIntlRestaurant()

  const country = restaurant?.i18n?.country || null

  const heapParams = useMemo(() => {
    if (!loading) {
      let orderFlow
      if (mode === DdiMode.Opt) {
        if (preauthTabEnabled) {
          orderFlow = 'Pre-auth Tabs'
        } else if (tabEnabled) {
          orderFlow = 'No-auth Tabs'
        } else {
          orderFlow = 'Pay-as-you-go'
        }
      } else {
        orderFlow = 'None'
      }

      const heapParams = {
        previewMode,
        viewOnlyMenu: !orderingAvailable,
        orderFlow,
        tabEnabled,
        preauthTabEnabled,
        joinablePartiesEnabled: joinablePartyEnabled,
        splitPaymentsEnabled: joinablePartyEnabled,
        orderGuid: partyRefresh?.order?.guid,
        mode,
        multipersonGroup: Boolean(members?.length > 1),
        country,
        isIntl
      }

      addEventProperties(heapParams)
      return heapParams
    }
    return null
  }, [
    orderingAvailable,
    previewMode,
    tabEnabled,
    preauthTabEnabled,
    joinablePartyEnabled,
    partyRefresh?.order?.guid,
    mode,
    loading,
    members?.length,
    country,
    isIntl
  ])

  return heapParams
}
