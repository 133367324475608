import { MenuV2 } from '../../../apollo/generated/OptWebGraphQLOperations'
import { FinalMenuItemV2 } from '../../MenuItem/VirtualMenu.helpers'

const properties = ['imageUrl', 'description', 'outOfStock'] as const

const propertyWeightsMap = {
  imageUrl: 2,
  description: 1,
  outOfStock: -4
}

export const calculateRank = (item: FinalMenuItemV2) => {
  let rank = 0
  properties.forEach((property) => {
    const itemHasProperty = Boolean(item[property])
    if (itemHasProperty) {
      rank += propertyWeightsMap[property]
    }
  })

  return rank
}

export const rankMenuItems = (menus?: MenuV2[]) => {
  if (!menus) {
    return menus
  }
  menus.forEach(({ groups }) => {
    groups.forEach((group) => {
      const finalizedItems = group.items
        .map((item: FinalMenuItemV2) => {
          item.rank = calculateRank(item)
          return item
        })
        .sort((a, b) => b.rank! - a.rank!)

      group.items = finalizedItems
    })
  })
  return menus
}
