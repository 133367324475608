import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Progress from '../Progress/Progress'

import { useMessage } from '../../utils/use-message'

const getOrigin = (src) => {
  const url = new URL(src)
  return url.origin
}

export const CreditCardIframe = ({ src, onChange, window }) => {
  const [height, setHeight] = useState(0)

  useMessage({
    origin: getOrigin(src),
    messageHandlers: {
      CC_VALID: (data) => onChange(data),
      CC_INVALID: () => onChange(''),
      IFRAME_HEIGHT: (data) => setHeight(data)
    },
    window
  })

  return (
    <>
      {height === 0 && (
        <Progress eventData={{ category: 'Credit Card Iframe' }} />
      )}
      <iframe
        title='Credit Card Form'
        id='credit_card_iframe'
        data-testid='credit-card-iframe'
        frameBorder='0'
        scrolling='no'
        height={`${height}px`}
        width='100%'
        src={src}
      />
    </>
  )
}

CreditCardIframe.propTypes = {
  src: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  window: PropTypes.object
}

CreditCardIframe.defaultValues = {
  window: window // i.e. the global window
}
