import React from 'react'
import cx from 'classnames'
import { PoweredByToast } from '../PoweredByToast/PoweredByToast'
import { useAvailability } from '../../utils/availability'

import CartImg from '../../assets/order.svg'

import styles from './EmptyCart.module.css'

const EmptyCart = () => {
  const { orderingAvailable } = useAvailability()

  return (
    <div className={styles.emptyCartWrapper}>
      <div className={styles.emptyCart}>
        <div
          className={cx(styles.cartCircle, {
            [styles.unavailable]: !orderingAvailable
          })}
        >
          <CartImg className={styles.cartImage} />
        </div>
        {orderingAvailable ? (
          <p>
            Ready to go! <br />
            Add items to get started
          </p>
        ) : (
          <p>Items cannot be added to order</p>
        )}
      </div>
      <PoweredByToast className={styles.poweredBy} />
    </div>
  )
}

export default React.memo(EmptyCart)
