import { useEffect, useRef, useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import { useParty } from './../../../PartyProvider/PartyProvider'
import { useGetSelectionsToSplit } from '../../../../hooks/use-get-selections-to-split'
import { useGetMainCheck, useGetPartyRefresh } from './../PartyQuery'
import {
  SplitItemInput,
  usePrice_Split_CheckQuery
} from '../../../../apollo/generated/OptWebGraphQLOperations'
import { useGiftCard } from '../../../GiftCardProvider/GiftCardProvider'

type usePrice_Split_CheckQueryHookOptions = Parameters<
  typeof usePrice_Split_CheckQuery
>[0]

/**
 *
 * Executes a query based on split items input.
 * Automatically refetch when selectionsToSplit argument
 * changes.
 *
 */
export const usePriceSplitCheck = (
  options: usePrice_Split_CheckQueryHookOptions = {}
) => {
  const { mainCheck } = useGetMainCheck()
  const selectionsToSplit = useGetSelectionsToSplit()
  const { memberAuthToken, partyGuid, partyMemberGuid } = useParty()
  const { partyRefresh } = useGetPartyRefresh()
  const fullyPaid = mainCheck?.isClosed ?? true

  const { rxGiftCard, globalGiftCard } = useGiftCard()
  const totalGiftCardBalance = useMemo(
    () =>
      (rxGiftCard?.expectedAvailableBalance ?? 0) +
      (globalGiftCard?.expectedAvailableBalance ?? 0),
    [
      rxGiftCard?.expectedAvailableBalance,
      globalGiftCard?.expectedAvailableBalance
    ]
  )

  const variables = (() => {
    if (partyGuid && partyMemberGuid && memberAuthToken && selectionsToSplit) {
      return {
        splitCheckInput: {
          partyGuid,
          partyMemberGuid,
          memberAuthToken,
          selectionsToSplit
        },
        totalGiftCardBalance
      }
    }
    return undefined
  })()
  const priceSplitCheckResult = usePrice_Split_CheckQuery({
    variables,
    ...options,
    skip:
      !variables ||
      !partyRefresh?.order ||
      !partyRefresh?.splitPaymentData ||
      fullyPaid,
    notifyOnNetworkStatusChange: options.notifyOnNetworkStatusChange ?? true
  })

  useEffect(() => {
    const toMap = (
      selections?: SplitItemInput[] | null
    ): Record<string, number> => {
      const map: Record<string, number> = {}
      return (selections ?? [])?.reduce((acc, el) => {
        return {
          ...acc,
          [el.selectionID]: el.proportion
        }
      }, map)
    }
    if (
      variables?.splitCheckInput &&
      !isEqual(
        toMap(variables?.splitCheckInput.selectionsToSplit),
        toMap(selectionsToSplit)
      )
    ) {
      priceSplitCheckResult.refetch(variables)
    }
  }, [priceSplitCheckResult, selectionsToSplit, variables])

  // refetch on change in main check total (e.g. another payment applied, discount applied, etc.)
  const refetchQuery = priceSplitCheckResult.refetch
  const prevTotal = useRef<number | undefined>(mainCheck?.total)
  const mainCheckTotal = mainCheck?.total
  useEffect(() => {
    if (prevTotal.current !== mainCheckTotal) {
      prevTotal.current = mainCheckTotal
      if (variables) {
        refetchQuery(variables)
      }
    }
  }, [mainCheckTotal, variables, refetchQuery])

  return useMemo(() => {
    const resolvedData = priceSplitCheckResult.data?.optPriceSplitCheck
    const customPricedSplitCheck =
      resolvedData?.__typename === 'OPTSplitCheckPreview'
        ? resolvedData
        : undefined
    const optPartyError =
      resolvedData?.__typename === 'OPTPartyError' ? resolvedData : undefined

    const splitCheckPreview = customPricedSplitCheck

    return {
      ...priceSplitCheckResult,
      splitCheckPreview,
      error: priceSplitCheckResult.error ?? optPartyError
    }
  }, [priceSplitCheckResult])
}
