import * as React from 'react'
import { useMenus } from '../../hooks/use-menus/use-menus'
import { useGetPartyMemberCart } from '../PartyQuery/PartyQuery'
import { usePersistEqualObject } from '../../hooks/use-persist-equal-object/use-persist-equal-object'
import { getItemsFlat } from '../../utils/menu-search'

const UpsellsContext = React.createContext({ upsells: [] })
const noopFilterRules = [() => true]
const noopSortingRules = [() => 0]

export const UpsellsProvider = ({
  filterRules = noopFilterRules,
  sortRules = noopSortingRules,
  children
}) => {
  const { menus } = useMenus()
  const { cartCheck } = useGetPartyMemberCart()

  const currentFilterRules = usePersistEqualObject(filterRules)
  const currentSortRules = usePersistEqualObject(sortRules)
  const upsellItems = React.useMemo(() => {
    const items = getItemsFlat(menus || [])

    const filteredItems = currentFilterRules.reduce((acc, rule) => {
      return acc.filter((item) =>
        rule({ selections: cartCheck?.selections }, item)
      )
    }, items)

    const sortedItems = currentSortRules.reduce((acc, rule) => {
      return acc.sort((itemA, itemB) => {
        return rule({ selections: cartCheck?.selections }, itemA, itemB)
      })
    }, filteredItems.slice())

    return sortedItems
  }, [currentFilterRules, currentSortRules, cartCheck, menus])

  const context = {
    upsells: upsellItems
  }

  return (
    <UpsellsContext.Provider value={context}>
      {children}
    </UpsellsContext.Provider>
  )
}

export const useUpsells = () => React.useContext(UpsellsContext)
