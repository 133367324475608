import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'

import styles from './Loading.module.css'

const Loading = ({ variant }) => {
  return (
    <div className={cx(styles.loading, styles[variant])}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  )
}

Loading.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'link', 'horizontal'])
}

export default Loading
