import styles from './Button.module.css'

import PropTypes from 'prop-types'
import React from 'react'
import cx from 'classnames'

import Loading from '../Loading/Loading'

const Button = ({
  children,
  disabled,
  loading,
  onClick,
  responsive = false,
  variant = 'link',
  type = 'button',
  additionalClasses = '',
  ...props
}) => {
  const finalStyle =
    variant === 'linkLargeText'
      ? [styles.link, styles[variant]]
      : styles[variant]

  return (
    <button
      type={type}
      className={cx(
        finalStyle,
        {
          [styles.loading]: loading,
          [styles.responsive]: responsive
        },
        additionalClasses
      )}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Loading variant={variant} /> : children}
    </button>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  responsive: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'link',
    'primaryWide',
    'linkWide',
    'linkLargeText',
    'secondary',
    'secondaryNoBorder',
    'secondaryWide',
    'none'
  ]),
  type: PropTypes.oneOf(['submit', 'button', 'reset'])
}

export default Button
