import * as React from 'react'
import { OPTPartySplashContainer } from '../OPTPartySplashProvider/OPTPartySplashContainer'
import { GroupTabManagementContainer } from '../GroupTabManagementContainer/GroupTabManagementContainer'
import { PartyInitialization } from '../PartyInitialization'
import { DDIMode, OptConfig } from '../../types/DDIGlobals'

export interface SplashProps {
  optConfig: Pick<OptConfig, 'orderingConfig' | 'paymentConfig'>
  mode: DDIMode
  noSplashNoAuthEnabled: boolean
}
export const getSplashScreen = ({
  optConfig,
  mode,
  noSplashNoAuthEnabled
}: SplashProps) => {
  const {
    orderingConfig: { allowJoinableParty, allowTabs, orderingEnabled },
    paymentConfig: { allowPreauth }
  } = optConfig

  if (mode === DDIMode.TTS) {
    return null
  }

  if (orderingEnabled && allowJoinableParty) {
    return <GroupTabManagementContainer />
  }

  if (orderingEnabled && allowTabs && allowPreauth) {
    return <PartyInitialization /> // silent init
  }

  if (orderingEnabled && allowTabs && noSplashNoAuthEnabled) {
    return <PartyInitialization /> // silent init
  }

  // when unified onboarding flag is on and MNP is redirected to OPT route
  // +
  // stp/payg + tabs-no-preauth(if flag is off) splash screen/auto party init
  return <OPTPartySplashContainer />
}
