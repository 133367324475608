import React from 'react'
import { NetworkStatus } from '@apollo/client'
import PropTypes from 'prop-types'

import EmptyCart from '../EmptyCart/EmptyCart'
import Error from '../Error/Error'
import { CartModificationError } from './CartModificationError/CartModificationError'
import Loader from './Cart.loader'
import CartTable from './CartTable/CartTable'
import { useCart } from '../CartProvider/CartProvider'
import CartOrTabButton from '../CartOrTabButton/CartOrTabButton'
import { NewTabOrCheckoutButton } from '../NewTabOrCheckoutButton/NewTabOrCheckoutButton'
import { useGetTab } from '../TabQuery/TabQuery'
import { OrderLayout } from '../OrderLayout/OrderLayout'
import { useGetPartyMemberCart } from '../PartyQuery/PartyQuery'
import { CheckFooter } from '../CheckFooter/CheckFooter'
import { CartUpsells } from '../CartUpsells/CartUpsells'

import styles from './Cart.module.css'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

const Cart = ({ hideFooter }) => {
  const { error, loading, refetch, networkStatus, cartCheck } =
    useGetPartyMemberCart()
  const { cartState } = useCart()
  const { order, loading: tabLoading } = useGetTab()
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)

  if (
    loading ||
    cartState.loading ||
    tabLoading ||
    networkStatus === NetworkStatus.refetch
  ) {
    return <Loader />
  }

  return (
    <OrderLayout
      body={
        !cartCheck || cartCheck.numberOfSelections === 0 ? (
          <EmptyCart />
        ) : (
          <>
            <div className='mx-6 mt-6'>
              <CartTable cartCheck={cartCheck} editable showSubtotals />
            </div>
            <div className='mx-4 mt-5 mb-4'>
              <CartUpsells mode='cart' />
            </div>
          </>
        )
      }
      error={
        error ? (
          <Error error={error} retry={refetch} />
        ) : (
          <CartModificationError />
        )
      }
      footer={
        !hideFooter &&
        Boolean(cartCheck && cartCheck.numberOfSelections > 0) && (
          <>
            <div className={styles.line}>
              {!tabsRedesignOn &&
                cartCheck &&
                cartCheck.numberOfSelections > 0 && (
                  <div className='px-4 py-3'>
                    <CheckFooter check={cartCheck} totalMode='NONE' />
                  </div>
                )}
              {order ? <CartOrTabButton /> : <NewTabOrCheckoutButton />}
            </div>
          </>
        )
      }
    />
  )
}

Cart.propTypes = {
  hideFooter: PropTypes.bool
}

export default Cart
