import * as React from 'react'
import ToastIcon from '../../assets/toast.svg'

export interface RestaurantLogoV2Props {
  logoUrl?: string
  restaurantName: string
}

export const RestaurantLogoV2 = ({
  logoUrl,
  restaurantName
}: RestaurantLogoV2Props) => {
  return (
    <>
      {logoUrl ? (
        <img
          data-testid='restaurant-logo'
          width='64px'
          height='64px'
          className='text-center rounded-full drop-shadow-lg'
          src={logoUrl}
          alt={`Logo of ${restaurantName}`}
        />
      ) : (
        <ToastIcon
          data-testid='fallback-logo'
          className='w-16 h-16 mb-0 rounded-full drop-shadow-lg'
        />
      )}
    </>
  )
}
