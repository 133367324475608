import React from 'react'
import { doCartsHaveSelections } from '../../utils/cart-helpers'
import { getHeader } from './helpers'
import { FieldLabel, HeaderText } from '@/il8n/en'
import { PageHeader } from '../PageHeader/PageHeader'
import { useGetPartyCarts, useGetMainCheck } from '../PartyQuery/PartyQuery'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

export const TabCartPageHeader = () => {
  const { carts } = useGetPartyCarts()
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)
  const { mainCheck } = useGetMainCheck()
  const hasTab = Boolean(mainCheck)
  const isGroupCart = carts?.length > 1
  const groupCartsHaveItems = doCartsHaveSelections(carts)
  const explanationText = isGroupCart
    ? FieldLabel.GROUP_ORDER_EXPLANATION
    : FieldLabel.ORDER_EXPLANATION

  if (tabsRedesignOn) {
    return (
      <div className={'mt-6 ml-6 mr-6 type-large'}>
        <div
          data-testid='restaurant-info-container'
          className='text-center type-headline-4'
        >
          {getHeader(isGroupCart, groupCartsHaveItems)}
        </div>
        {hasTab ? null : (
          <div className='mt-2 text-center text-gray-110'>
            {explanationText}
          </div>
        )}
      </div>
    )
  }

  return <PageHeader header={HeaderText.COMBINED_TAB_CART} />
}
