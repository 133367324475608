export const sumByField =
  (fieldKey, init = 0) =>
  (entities) =>
    entities.reduce((acc, { [fieldKey]: val }) => acc + val, init)

const returnItemValues = (fieldKey, items) =>
  items
    .filter(({ [fieldKey]: val }) => Boolean(val))
    .map(({ [fieldKey]: val }) => val)

/**
 * @param {string} fieldKey
 * @param {Array} items
 * @returns a string with items separated by commas with no 'and'
 */
export const joinFieldByCommas =
  (fieldKey) =>
  (items = []) =>
    Array.isArray(items) ? returnItemValues(fieldKey, items).join(', ') : ''

/**
 * @param {string} fieldKey
 * @param {Array} items
 * @returns a string with items separated by commas and the conjunction 'and'
 */
export const joinFieldByCommasWithAnd = (fieldKey, items = []) => {
  if (!Array.isArray(items)) {
    return ''
  }

  const itemValues = returnItemValues(fieldKey, items)
  const formatter = new Intl.ListFormat('en')

  return formatter.format(itemValues)
}
