import React, { useState } from 'react'
import { Button } from '@toasttab/buffet-pui-buttons'
import Modal from '../Modal/Modal'
import {
  OptPartyError,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import { useApplySplitType } from '../../hooks/split-payments/use-apply-split-type'
import { track } from '@toasttab/do-secundo-analytics'
import { useSentry } from 'banquet-runtime-modules'

interface ConfirmEditSplitDialogProps {
  showDialog: boolean
  setShowDialog: (newState: boolean) => void
  selectedTotalShares: number
  selectedClaimedShares: number
  handleClose: () => void
}

export const ConfirmEditSplitDialog = ({
  showDialog,
  setShowDialog,
  selectedTotalShares,
  selectedClaimedShares,
  handleClose
}: ConfirmEditSplitDialogProps) => {
  const { captureMessage } = useSentry()
  const { applySplitType, loading: applySplitTypeLoading } = useApplySplitType()
  const [userFacingError, setUserFacingError] = useState<string>()

  const updateSplit = () => {
    applySplitType({
      onComplete: () => {
        track('changeSplitPaymentApplied', {
          numberOfPortionsInCheck: selectedTotalShares,
          numberOfClaimedPortions: selectedClaimedShares
        })
        setShowDialog(false)
      },
      onError: (error: OptPartyError) => {
        setUserFacingError(error.message)
        captureMessage(error.message, 'warning')
      },
      setUserFacingError,
      splitPaymentInitializationData: {
        splitMode: SplitMode.Even,
        evenSplitPortions: selectedTotalShares
      },
      overrideSplit: true,
      newPortionsToBePaid: selectedClaimedShares,
      newTotalPortions: selectedTotalShares
    })
  }

  if (!showDialog) {
    return null
  }

  return (
    <Modal
      header={
        <h4 className='font-medium whitespace-pre-wrap text-center'>
          Are you sure you want to change how the bill is split?
        </h4>
      }
      responsive={false}
      compactHeader={true}
      onClose={() => {
        setShowDialog(false)
      }}
    >
      <div className='flex flex-col px-4 space-y-6 items-center mb-6'>
        <div className='type-default'>
          Some members of your party might lose the selections they have made.
          Let your party know you're making the change.
        </div>
        {userFacingError && <Error>{userFacingError}</Error>}
        <Button
          onClick={() => {
            setShowDialog(false)
          }}
          variant='primary'
          className='w-full'
          testId='already-split-keep-button'
          disabled={applySplitTypeLoading}
        >
          Continue without changing
        </Button>
        <Button
          onClick={() => {
            updateSplit()
            handleClose()
          }}
          variant={applySplitTypeLoading ? 'primary' : 'secondary'}
          className='w-full'
          testId='already-split-override-button'
          disabled={applySplitTypeLoading}
        >
          {applySplitTypeLoading ? <Loading /> : <span>Apply changes</span>}
        </Button>
      </div>
    </Modal>
  )
}
