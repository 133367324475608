import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../Modal/Modal'

import Button from '../Button/Button'
import ButtonSpacer from '../ButtonSpacer/ButtonSpacer'

import styles from './ConfirmModal.module.css'
import { CTA } from '@/il8n/en'

export const ConfirmModal = ({
  header,
  onConfirm,
  onClose,
  isLoading,
  confirmText,
  children
}) => {
  const footerButtons = (
    <div className={styles.buttons}>
      <Button
        data-testid='confirm-modal-confirm-button'
        id='confirm-button'
        type='submit'
        variant='primaryWide'
        onClick={onConfirm}
        loading={isLoading}
      >
        {confirmText}
      </Button>
      <ButtonSpacer vertical />
      <Button
        data-testid='confirm-modal-cancel-button'
        id='cancel-button'
        type='submit'
        variant='linkWide'
        onClick={onClose}
      >
        {CTA.CONFIRM_MODAL_CANCEL}
      </Button>
    </div>
  )

  return (
    <Modal header={header} onClose={onClose} footer={footerButtons}>
      <div data-testid='confirm-modal-body' className={styles.modal}>
        {children}
      </div>
    </Modal>
  )
}

ConfirmModal.propTypes = {
  header: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmText: PropTypes.string,
  children: PropTypes.array
}

ConfirmModal.defaultProps = {
  confirmText: 'Confirm'
}
