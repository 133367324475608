import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { FormatCurrency } from '../../../Currency/FormatCurrency'

export const MenuItemPrice = ({
  price,
  usesFractionalQuantity,
  unitOfMeasure,
  outOfStock
}) => (
  <span
    data-testid='menu-item-price'
    className={cx(
      outOfStock ? 'text-gray-75 type-subhead' : 'text-gray-125 type-default'
    )}
  >
    <FormatCurrency amount={price} />
    {usesFractionalQuantity && `/${unitOfMeasure.toLowerCase()}`}
  </span>
)

MenuItemPrice.propTypes = {
  price: PropTypes.number.isRequired,
  usesFractionalQuantity: PropTypes.bool,
  unitOfMeasure: PropTypes.string,
  outOfStock: PropTypes.bool
}

MenuItemPrice.defaultProps = {
  unitOfMeasure: '',
  usesFractionalQuantity: false
}
