import React from 'react'
import PropTypes from 'prop-types'
import SearchResults from '../../assets/search-results.svg'

export const SearchBanner = ({ children }) => {
  return (
    <div className={'flex flex-col items-center text-center lg:mt-7'}>
      <SearchResults className='w-28' />
      {children}
    </div>
  )
}

SearchBanner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node
  ])
}
