import { OptCheckV2Fragment } from '../../apollo/generated/OptWebGraphQLOperations'

export type PartialBucketCheck = Pick<
  OptCheckV2Fragment,
  'numberOfSelections' | 'selections'
>

export const getNumberOfValidSelections = (check?: PartialBucketCheck) => {
  return (
    check?.selections
      .map((s) => {
        return {
          voided: s.__typename === 'CheckSelectionGuid' ? s.voided : false
        }
      })
      .reduce((acc, s) => acc + (s.voided ? 0 : 1), 0) ?? 0.0
  )
}
