import React, { useState } from 'react'
import { LoyaltyIcon } from '@toasttab/buffet-pui-icons'
import { Button } from '@toasttab/buffet-pui-buttons'
import { Formik, Form, Field } from 'formik'
import Input from '../Form/Input/Input'
import { FieldLabel } from '@/il8n/en'
import { TermsModal, LOYALTY } from '../TermsModal/TermsModal'
import { emailSchema } from '../../utils/form-schemas'
import { object } from 'yup'
import { SIGN_UP_TO_LOYALTY } from '../../apollo/loyalty/loyalty.graphql'
import { useMutation } from '@apollo/client'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useAuth } from '../AuthProvider/AuthProvider'
import { useGuestInfo } from '../../hooks/use-guest-info'
import { LoyaltyEarnedNotification } from './LoyaltyEarnedNotification'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import Error from '../Error/Error'

const validationSchema = object().shape({
  email: emailSchema
})

export const LoyaltyForm = ({ checkGuid, restaurantGuid }) => {
  const [showTerms, toggleTerms] = useState(false)
  const [loyaltySignUpResult, setLoyaltySignUpResult] = useState(null)
  const [loyaltySignUpError, setLoyaltySignUpError] = useState(null)
  const [signUpToLoyalty] = useMutation(SIGN_UP_TO_LOYALTY)
  const { data: restaurant } = useRestaurantInfo()

  const { guestEmail } = useGuestInfo()
  const { authenticated, user } = useAuth()
  const accountEmail = user?.email
  const allowChangingEmail = !authenticated || !accountEmail
  const email = accountEmail ?? guestEmail ?? ''

  const initialFormValues = {
    values: {
      email
    }
  }

  return (
    <>
      {loyaltySignUpResult ? (
        <LoyaltyEarnedNotification
          isNewAccount={loyaltySignUpResult.newAccount}
          restaurant={restaurant}
        />
      ) : (
        <div>
          {showTerms && (
            <TermsModal onClose={() => toggleTerms(false)} terms={LOYALTY} />
          )}
          <div className='pt-10 pb-4 px-4 max-w-md mx-auto'>
            <div className='text-center'>
              <LoyaltyIcon className='text-brand-50 fill-current' />
              <div className='type-headline-4 mt-2'>
                Don't miss out on rewards
              </div>

              <div
                className='text-primary-75 type-default pt-1 pb-1 mb-3 leading-relaxed cursor-pointer hover:underline'
                onClick={() => toggleTerms(!showTerms)}
                role='button'
                tabIndex='0'
              >
                View reward program terms
              </div>
            </div>
            <div>
              <Formik
                initialValues={initialFormValues.values}
                validationSchema={validationSchema}
                validateOnMount
                onSubmit={async (values, { setSubmitting }) => {
                  const input = {
                    variables: {
                      input: {
                        ...values,
                        restaurantGuid,
                        checkGuid
                      }
                    }
                  }
                  try {
                    setSubmitting(true)
                    const result = await signUpToLoyalty(input)
                    const { SignUpToLoyaltyResponse, SignUpToLoyaltyError } =
                      dataByTypename(result.data.signUpToLoyalty)
                    if (SignUpToLoyaltyError) {
                      setLoyaltySignUpResult(null)
                      setLoyaltySignUpError(SignUpToLoyaltyError)
                    }
                    if (SignUpToLoyaltyResponse) {
                      setLoyaltySignUpResult(SignUpToLoyaltyResponse)
                      setLoyaltySignUpError(null)
                    }
                    setSubmitting(false)
                  } catch (err) {
                    setSubmitting(false)
                    setLoyaltySignUpResult(null)
                    setLoyaltySignUpError(err)
                  }
                }}
              >
                {({ isSubmitting, isValid, handleSubmit }) => {
                  return (
                    <Form data-testid='signup_form'>
                      {allowChangingEmail && (
                        <div className='mt-6'>
                          <Field
                            autoComplete='email'
                            data-testid='customer_email'
                            label={FieldLabel.CHECKOUT_CUSTOMER_EMAIL}
                            name='email'
                            type={'email'}
                            id='signup_form_email'
                            component={Input}
                          />
                        </div>
                      )}

                      <Button
                        id='loyaltyOpenButton'
                        type='submit'
                        onClick={() => handleSubmit()}
                        className='w-full text-large mt-2'
                        disabled={!isValid || isSubmitting}
                      >
                        Join loyalty program
                      </Button>
                      {loyaltySignUpError && (
                        <div className='mt-2'>
                          <Error error={loyaltySignUpError} />
                        </div>
                      )}
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
