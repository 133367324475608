import * as React from 'react'
import { CheckTable } from '../OrderTable/CheckTable'
import { PartialBucketCheck } from './helpers'

interface StaticServiceCheckTableProps {
  serverCheck: PartialBucketCheck
}

export const StaticServerCheckTable = ({
  serverCheck
}: StaticServiceCheckTableProps) => {
  return (
    <CheckTable memberName={'Items added by your server'} check={serverCheck} />
  )
}
