import { useReducer, useEffect, useCallback, useRef } from 'react'
import MenuSearchWorker from '../workers/menu-search.worker'

const SET_LOADING = 'SET_LOADING'
const UPDATE_DATA = 'UPDATE_DATA'
const SET_ERROR = 'SET_ERROR'

const reducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case UPDATE_DATA:
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case SET_ERROR:
      return {
        ...state,
        error: undefined
      }
    default:
      console.error(`Action type "${action.type}" is not defined`)
      return state
  }
}

const initialState = {
  data: undefined,
  error: undefined,
  loading: false
}

const createWorker = () => new MenuSearchWorker()
export const useMenuSearch = (query, menus) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const menuSearchWorkerRef = useRef(null)

  useEffect(() => {
    menuSearchWorkerRef.current = createWorker()
  }, [])

  useEffect(() => {
    const worker = menuSearchWorkerRef.current
    if (worker) {
      worker.onmessage = ({ data }) => {
        dispatch({ type: UPDATE_DATA, data })
      }
    }

    return () => {
      worker.terminate()
    }
  }, [])

  const fetchMenus = useCallback((query, menus) => {
    const worker = menuSearchWorkerRef.current
    dispatch({ type: SET_LOADING })
    worker.postMessage({ action: 'search', params: [query, menus] })
  }, [])

  useEffect(() => {
    fetchMenus(query, menus)
  }, [query, menus, fetchMenus])

  return state
}
