import * as React from 'react'
import { Shelf } from 'cornucopia-apis'
import { MenuPageRedirect } from '../MenuPageRedirect/MenuPageRedirect'
import { useParty } from '../PartyProvider/PartyProvider'
import { useLocation, matchPath } from 'react-router'
import { useGetPartyRefresh } from '../PartyQuery/PartyQuery'
import { useTab } from '../TabProvider/TabProvider'
import { TabClosed } from '../TabClosed/TabClosed'
import { LoadingModal } from '../OPTPartySplashProvider/OPTPartySplash'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { useHandleRejoin } from '../../hooks/use-handle-rejoin'
import { useFlag } from '../FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'

const partyCreationRoutes = [
  '/tab-management/contact-info',
  '/tab-management/join'
]

const isPartyCreationRoute = (location: any) => {
  return partyCreationRoutes.some(
    (route) => matchPath(location.pathname, route)?.isExact
  )
}

const isConfirmPageRoute = (location: any) => {
  const regexp = /[/]confirm[/]./i
  return regexp.test(location.pathname)
}

export const GroupTabManagementContainer = () => {
  const { uniqueIdentifier } = useDDIGlobals()
  const [open, setOpen] = React.useState(true)
  const onClose = () => setOpen(false)

  const quickTabsDataCollectionEnabled = useFlag(
    LDFlags.QUICK_TABS_DATA_COLLECTION,
    false
  )

  const { deleteTab } = useTab()
  const { deletePartyProperties } = useParty()
  const { partyRefresh, loading } = useGetPartyRefresh({
    notifyOnNetworkStatusChange: false
  })
  const location = useLocation()

  const party = partyRefresh?.party
  const isClosed = partyRefresh?.order?.isClosed
  const userLeftParty = !Boolean(party) && !open && !loading

  const onTabClosed = () => {
    deleteTab && deleteTab()
    deletePartyProperties()
    setOpen(true)
  }

  useHandleRejoin()

  // reopens group-party-mode-drawer upon leaving party
  if (userLeftParty) {
    setOpen(true)
  }

  if (open && loading) {
    return <LoadingModal />
  }

  if (isConfirmPageRoute(location) && isClosed) {
    return null
  }

  if (isClosed) {
    return <TabClosed onClose={onTabClosed} />
  }

  const isPreviewMode = uniqueIdentifier === 'welcome'
  const partyStarted = party?.guid && party?.partyMemberGuid

  if (partyStarted || isPartyCreationRoute(location) || isPreviewMode) {
    return null
  }

  return (
    <>
      <MenuPageRedirect />
      {!quickTabsDataCollectionEnabled && (
        <Shelf
          name='group-party-mode-drawer'
          params={{
            open,
            onClose
          }}
        />
      )}
    </>
  )
}
