import { RestaurantCreditCardConfig } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { LDFlags } from '../../../../launchdarkly/flags'
import { useFlag } from '../../../FeatureFlag/use-flag'
import { useGetPartyMode } from '../../../PartyQuery/PartyQuery'

export const useTipEnabled = (
  creditCardConfig: RestaurantCreditCardConfig
): boolean => {
  const tipsDisabledFlag = useFlag(LDFlags.OPT_DISABLE_TIPS)
  const { mode } = useGetPartyMode()
  const configTipEnabled = creditCardConfig.tipEnabled
  const optTipEnabled = configTipEnabled && !tipsDisabledFlag
  return mode === 'OPT' ? optTipEnabled : true
}
