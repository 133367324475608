import { track } from '@toasttab/do-secundo-analytics'
import { CheckoutType } from '../../hooks/place-order/v2/use-handle-party-closeout'

const ensureNumber = (value) => {
  const type = typeof value
  if (type === 'string') {
    return parseFloat(value)
  }
  if (type === 'number') {
    return value
  }
  return undefined
}

const trackProduct = (
  eventName,
  { id, name, category, brand, variant, price, quantity }
) =>
  track(eventName, {
    id,
    name,
    category,
    brand,
    variant,
    price: ensureNumber(price),
    quantity: ensureNumber(quantity)
  })

export const trackAddProduct = (product) => trackProduct('Add Product', product)

export const trackReorderProduct = (product) =>
  trackProduct('Reorder Product', product)

export const trackRemoveProduct = (product) =>
  trackProduct('Remove Product', product)

// tracks the end of an order; in tabs should only fire after close tab
export const trackPurchase = (products, purchaseInfo) => {
  const { id, revenue, tax, shipping, option, checkoutType } = purchaseInfo
  if (checkoutType === CheckoutType.PAYG) {
    // a PAYG purchase always coincides with placing an order
    trackOrder(products, purchaseInfo)
  }

  const data = {
    id,
    revenue: ensureNumber(revenue),
    tax: ensureNumber(tax),
    shipping: ensureNumber(shipping),
    option
  }
  products.forEach((p) => trackProduct('Purchase Product', p))

  track('Completed Order', {
    id: data.id,
    itemCount: products.length,
    revenue: ensureNumber(data.revenue),
    orderTax: ensureNumber(data.tax),
    orderShipping: ensureNumber(data.shipping) || 0,
    option: data.option,
    checkoutType
  })
}

// tracks the placing of an order; in tabs should fire after each round
export const trackOrder = (
  products,
  { id, revenue, tax, shipping, option }
) => {
  const data = {
    id,
    revenue: ensureNumber(revenue),
    tax: ensureNumber(tax),
    shipping: ensureNumber(shipping),
    option
  }

  track('Placed Order', {
    id: data.id,
    itemCount: products.length,
    revenue: ensureNumber(data.revenue),
    orderTax: ensureNumber(data.tax),
    orderShipping: ensureNumber(data.shipping) || 0,
    option: data.option
  })
}
