import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import isEqual from 'lodash/isEqual'
import { useCreditCard } from '../../CreditCardProvider/CreditCardProvider'
import Button from '../../Button/Button'
import { ConfirmModal } from '../../ConfirmModal/ConfirmModal'
import Error from '../../Error/Error'

import AMEX from '../../../assets/credit-cards/amex.svg'
import GENERIC from '../../../assets/credit-cards/credit-card.svg'
import DISCOVER from '../../../assets/credit-cards/discover.svg'
import MASTERCARD from '../../../assets/credit-cards/mastercard.svg'
import VISA from '../../../assets/credit-cards/visa.svg'
import APPLE_PAY from '../../../assets/apple-pay.svg'

import styles from './SavedCreditCardLabelV2.module.css'
import { toast } from 'react-toastify'
import { track } from '@toasttab/do-secundo-analytics'
import { CTA, HeaderText } from '@/il8n/en'

const cardTypeMap = {
  AMEX,
  DISCOVER,
  MASTERCARD,
  VISA
}

export const SavedCreditCardLabelV2 = React.memo(({ card, isDeletable }) => {
  const [showModal, setShowModal] = React.useState(false)
  const { deleteCard, loading } = useCreditCard()
  const { expired, isPrimary, isPreauthed, expirationMonth, expirationYear } =
    card
  const isApplePayPreauth = !(expirationMonth || expirationYear) && isPreauthed
  const CardIcon = isApplePayPreauth
    ? APPLE_PAY
    : cardTypeMap[card.cardType] || GENERIC
  const input = {
    variables: {
      input: {
        cardGuid: card.guid
      }
    }
  }

  const expirationString = expired ? 'Expired' : 'Expires'
  return (
    <span className={cx(styles.card, { [styles.expired]: expired })}>
      <div data-testid='credit-card-info' className={styles.cardInfo}>
        <CardIcon className={styles.cardIcon} />
        {isApplePayPreauth ? (
          <p data-testid='credit-card-number' className={styles.cardNumber}>
            Apple Pay Card
          </p>
        ) : (
          <div>
            <p data-testid='credit-card-number' className={styles.cardNumber}>
              {card.maskedPan.replace(/XXXX-/, '**** ')}
            </p>
            <p data-testid='credit-card-exp' className={styles.cardExp}>
              {expirationString}: {card.expirationMonth}/{card.expirationYear}
            </p>
          </div>
        )}
      </div>
      {Boolean(isPreauthed || isPrimary) && (
        <div data-testid='credit-card-primary' className={styles.primary}>
          {isPreauthed ? 'PREAUTHORIZED' : isPrimary ? 'PRIMARY' : null}
        </div>
      )}

      {isDeletable && (
        <Button
          data-testid='button-delete-cc'
          type='button'
          onClick={(event) => {
            event.stopPropagation()
            setShowModal(true)
          }}
        >
          {CTA.DELETE_SAVED_CARD}
        </Button>
      )}
      {showModal && (
        <ConfirmModal
          header={HeaderText.DELETE_CREDIT_CARD}
          onClose={() => setShowModal(false)}
          onConfirm={async () => {
            await deleteCard.mutate(input)
            toast('Card Deleted Successfully!')
            track('User Account Delete Credit Card')
          }}
          confirmText={CTA.DELETE_CREDIT_CARD}
          isLoading={Boolean(loading)}
        >
          {deleteCard.result.error && <Error error={deleteCard.result.error} />}
          <div>Are you sure you want to delete this card?</div>
          {isPrimary && <div>This is currently your primary credit card!</div>}
        </ConfirmModal>
      )}
    </span>
  )
}, isEqual)

const CardShape = PropTypes.shape({
  cardType: PropTypes.string.isRequired,
  expirationMonth: PropTypes.string.isRequired,
  expirationYear: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool,
  maskedPan: PropTypes.string.isRequired,
  expired: PropTypes.bool.isRequired
})

SavedCreditCardLabelV2.propTypes = {
  card: CardShape.isRequired,
  isDeletable: PropTypes.bool
}
