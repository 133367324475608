import * as React from 'react'
import {
  updateCartRequest$,
  dispatchCartDefaultError,
  dispatchCartResponse
} from 'cornucopia-apis'
import { useSentry } from 'banquet-runtime-modules'
import { toast } from 'react-toastify'
import {
  useAddItemToCart,
  useEditItemInCart,
  useDeleteItemFromCart,
  useReorderItemToCart
} from '../../../../client/components/CartMutation/CartMutation'
export interface CartListenerProps {
  restaurantGuid?: string
  partyGuid?: string
  partyMemberGuid: string
  memberAuthToken: string
}

export const CartListener = ({
  restaurantGuid,
  partyGuid,
  partyMemberGuid,
  memberAuthToken
}: CartListenerProps) => {
  const { captureException } = useSentry()
  const [addItemToCart] = useAddItemToCart()
  const [editItemInCart] = useEditItemInCart()
  const [deleteItemInCart] = useDeleteItemFromCart()
  const [reorderItemToCart] = useReorderItemToCart()

  const getMutation = ({ selection, selectionGuid, isReorder }) => {
    if (isReorder) return reorderItemToCart
    if (!selection) return deleteItemInCart
    if (selectionGuid) return editItemInCart
    return addItemToCart
  }

  React.useEffect(() => {
    const subscription = updateCartRequest$.subscribe((request: any) => {
      const { selection, selectionGuid, isReorder } = request
      const variables = isReorder
        ? {
            input: {
              partyGuid,
              partyMemberGuid,
              memberAuthToken,
              ...(selectionGuid && { selectionGuid })
            }
          }
        : {
            input: {
              partyGuid,
              partyMemberGuid,
              memberAuthToken,
              // remove the itemMasterId to conform to OPT's query schema
              ...(selection && {
                selection: { ...selection, itemMasterId: undefined }
              }),
              ...(selectionGuid && { selectionGuid })
            }
          }

      const cartMutation = getMutation(request)
      cartMutation({ variables })
        .then((responseData: any) => {
          const data = responseData.data
          if (!data || !data.optPartyRefresh) {
            const fields =
              typeof data === 'object'
                ? Object.keys(data).join(', ')
                : 'undefined'
            captureException(
              new Error(`Unknown response for addItemToCart (${fields}`)
            )
            toast('Something went wrong, please try again')
            dispatchCartDefaultError(request)
          } else {
            const responseData = data.optPartyRefresh
            dispatchCartResponse(request, responseData)
          }
        })
        .catch((e: Error) => {
          captureException(e)
          dispatchCartDefaultError(request)
        })
    })

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restaurantGuid,
    addItemToCart,
    partyGuid,
    partyMemberGuid,
    memberAuthToken
  ])

  return <></>
}
