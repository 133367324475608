import { GiftCardContextTypeV2 } from '../../../GiftCardProvider/GiftCardProvider'
import { HandlePlaceOrderValuesV2 } from '../../../../utils/checkout-helpers'

export const getArgsForSubmit = ({
  giftCardContext
}: {
  giftCardContext: GiftCardContextTypeV2
}): Pick<
  HandlePlaceOrderValuesV2['paymentInfo'],
  'giftCard' | 'globalGiftCard'
> => {
  const rxCardNumber = giftCardContext.rxGiftCard?.cardNumber || undefined
  const rxExpectedAvailableBalance =
    giftCardContext.rxGiftCard?.expectedAvailableBalance || 0
  const globalCardIdentifier =
    giftCardContext.globalGiftCard?.cardIdentifier || undefined
  const globalExpectedAvailableBalance =
    giftCardContext.globalGiftCard?.expectedAvailableBalance || 0
  let rxGiftCard,
    globalGiftCard = undefined
  if (rxCardNumber && rxExpectedAvailableBalance > 0) {
    rxGiftCard = {
      cardNumber: rxCardNumber,
      expectedAvailableBalance: rxExpectedAvailableBalance
    }
  }
  if (globalCardIdentifier && globalExpectedAvailableBalance > 0) {
    globalGiftCard = {
      cardIdentifier: globalCardIdentifier,
      expectedAvailableBalance: globalExpectedAvailableBalance
    }
  }
  return {
    giftCard: rxGiftCard,
    globalGiftCard
  }
}
