import React from 'react'

import Progress from '../../Progress/Progress'
import { useGiftCardLinks } from '../../use-gift-card-links/use-gift-card-links'

import styles from '../NavigationDrawer.module.css'

export const GiftCardLinks = () => {
  const { loading, error, purchaseLink, checkValueLink, addValueEnabled } =
    useGiftCardLinks()
  if (error) {
    return null
  }
  if (loading) {
    return <Progress eventData={{ category: 'Gift Cards' }} />
  }

  const checkValueLabel =
    'Check ' + (addValueEnabled === true ? 'or add ' : '') + 'gift card value'
  return (
    <>
      {checkValueLink && (
        <li>
          <a
            data-testid='nav-drawer-check-gift-card-balance'
            className={styles.listItem}
            href={checkValueLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            {checkValueLabel}
          </a>
        </li>
      )}
      {purchaseLink && (
        <li>
          <a
            data-testid='nav-drawer-buy-gift-card'
            className={styles.listItem}
            href={purchaseLink}
            target='_blank'
            rel='noreferrer noopener'
          >
            Purchase gift cards
          </a>
        </li>
      )}
    </>
  )
}
