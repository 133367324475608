import React from 'react'
import { toast } from 'react-toastify'

import { AuthContext as AuthPasswordlessContext } from './AuthProviderPasswordless'

export const AUTH_NOTIFICATION_CONTAINER_ID = 'auth'

export const useAuth = () => React.useContext(AuthPasswordlessContext)

export const importSuccessToast = () => {
  toast('Connection process started')
}
