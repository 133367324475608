import { OptPartyV2Fragment } from '../../../../apollo/generated/OptWebGraphQLOperations'
import { LDFlags } from '../../../../launchdarkly/flags'
import { useFlag } from '../../../FeatureFlag/use-flag'

export const useIsDigitalPaymentStateEnabled = ({
  partyMode
}: {
  partyMode: OptPartyV2Fragment['mode']
}) => {
  const isPayAndGoExperiment = useFlag(LDFlags.OPT_PAY_AND_GO)

  const enabledForCurrentMode =
    partyMode === 'STP' ||
    (partyMode === 'MNP' && isPayAndGoExperiment) || // we probably want this enabled for all MNP, but it is out of scope for now
    partyMode === 'OPT'

  return enabledForCurrentMode
}
