import { PreComputedTip } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'

export const SELECTED_TIP = 'selected_tip'

export const getStoredTip = (): { value: string; percent: number } | null => {
  try {
    const storedTip = window.sessionStorage.getItem(SELECTED_TIP)
    if (storedTip) {
      return JSON.parse(storedTip)
    }
  } catch (e) {
    return null
  }
  return null
}

export const getSelectedPrecomputedTip = (
  tipValue: string,
  preComputedTips?: Omit<PreComputedTip, 'percent'>[] | null
) => {
  const selectedTip = preComputedTips?.find((tip) => {
    return tip.value.toFixed(2) === tipValue
  })

  return selectedTip
}
