import { useRef } from 'react'
import { useParty } from '../../../components/PartyProvider/PartyProvider'
import {
  Opt_Get_Party_RefreshQueryVariables,
  OptCartFragment,
  OptCheckV2GuidFragment,
  useOpt_Get_Party_RefreshQuery,
  OptPartyMemberV2,
  OptCheckV2Fragment,
  SplitMode,
  OptPartyV2Fragment
} from '../../../apollo/generated/OptWebGraphQLOperations'
import { useCallback, useMemo } from 'react'
import {
  getServerBuckets,
  getSortedMemberBuckets
} from '../../../utils/order-helpers'
import {
  CheckStatus,
  combineChecks,
  getPrimaryCheckFromOrder
} from '../../../utils/check-helpers/check-helpers'
import { useDDIGlobals } from '../../DDIGlobalsProvider/DDIGlobalsProvider'
import { PartyPropertiesInterface } from '../../PartyProvider/usePartyProperties'
import { DDIMode } from '../../../types/DDIGlobals'
import { usePriceSplitCheck } from './use-price-split-check'
import { getMainCheck, getPartyMode } from './helpers'
import { useGiftCard } from '../../GiftCardProvider/GiftCardProvider'

type Opt_Get_Party_RefreshQueryHookOptions = Parameters<
  typeof useOpt_Get_Party_RefreshQuery
>[0]

const getPartyRefreshVariables = (
  {
    memberAuthToken,
    partyGuid,
    partyMemberGuid
  }: Pick<
    PartyPropertiesInterface,
    'memberAuthToken' | 'partyGuid' | 'partyMemberGuid'
  >,
  totalGiftCardBalance: number | undefined
): Opt_Get_Party_RefreshQueryVariables | undefined => {
  if (partyGuid && partyMemberGuid && memberAuthToken) {
    return {
      partyGuid,
      partyMemberGuid,
      memberAuthToken,
      totalGiftCardBalance
    }
  }
}

export const useGetPartyRefresh = (
  options: Opt_Get_Party_RefreshQueryHookOptions = {}
) => {
  const {
    getRevalidatedPartyProperties,
    partyGuid,
    partyMemberGuid,
    memberAuthToken
  } = useParty()

  const { rxGiftCard, globalGiftCard } = useGiftCard()
  const totalGiftCardBalance = useMemo(
    () =>
      (rxGiftCard?.expectedAvailableBalance ?? 0) +
      (globalGiftCard?.expectedAvailableBalance ?? 0),
    [
      rxGiftCard?.expectedAvailableBalance,
      globalGiftCard?.expectedAvailableBalance
    ]
  )

  const variables = getPartyRefreshVariables(
    {
      partyGuid,
      partyMemberGuid,
      memberAuthToken
    },
    totalGiftCardBalance
  )
  const skip = options.skip || !variables

  const queryResult = useOpt_Get_Party_RefreshQuery({
    variables,
    ...options,
    skip,
    notifyOnNetworkStatusChange: options.notifyOnNetworkStatusChange ?? true
  })

  // maintains in a ref to avoid continual re-creation of callback
  const getRevalidatedPartyPropertiesRef = useRef(getRevalidatedPartyProperties)
  getRevalidatedPartyPropertiesRef.current = getRevalidatedPartyProperties

  const guardRefetch = useCallback(
    (...args) => {
      const validatedPartyProperties =
        getRevalidatedPartyPropertiesRef.current()
      const validatedSkip =
        skip ||
        !Boolean(
          getPartyRefreshVariables(
            validatedPartyProperties,
            totalGiftCardBalance
          )
        )
      const refetch = queryResult.refetch
      if (!validatedSkip) {
        return refetch(...args)
      }
    },
    [skip, queryResult.refetch, totalGiftCardBalance]
  )

  const data = queryResult?.data?.optPartyRefresh

  const OPTPartyError = data?.__typename === 'OPTPartyError' ? data : undefined
  const OPTPartyRefresh =
    data?.__typename === 'OPTPartyRefreshV2' ? data : undefined

  return useMemo(
    () => ({
      ...queryResult,
      error: queryResult.error || OPTPartyError,
      refetch: guardRefetch,
      partyRefresh: OPTPartyRefresh
    }),
    [queryResult, OPTPartyRefresh, OPTPartyError, guardRefetch]
  )
}

export const useGetPartyMode = (): { mode: OptPartyV2Fragment['mode'] } => {
  const partyRefreshResult = useGetPartyRefresh()
  const ddiGlobals = useDDIGlobals()
  const mode = getPartyMode(partyRefreshResult, ddiGlobals)
  return useMemo(() => ({ mode }), [mode])
}

export const useGetIsServerSplitCheck = () => {
  const partyMode = useGetPartyMode().mode
  const splitMode =
    useGetPartyRefresh().partyRefresh?.splitPaymentData?.splitMode

  return partyMode === DDIMode.STP && splitMode !== SplitMode.Even
}

const emptyMembers: OptPartyMemberV2[] = []
/**
 * Gets all party members
 */
export const useGetPartyMembers = () => {
  const partyRefreshResult = useGetPartyRefresh()
  return useMemo(
    () => ({
      ...partyRefreshResult,
      members: partyRefreshResult?.partyRefresh?.party?.members ?? emptyMembers
    }),
    [partyRefreshResult]
  )
}

/**
 * Get tab data in a response shape matching useGetTab response (i.e. OPTTabHistoryOrder)
 */
export const useGetOrderFromParty = () => {
  const partyRefreshResult = useGetPartyRefresh()
  const { partyRefresh } = partyRefreshResult
  const order = useMemo(() => {
    if (partyRefresh?.__typename === 'OPTPartyRefreshV2') {
      return partyRefresh.order
    }
  }, [partyRefresh])

  return useMemo(() => {
    const checks = order?.checks
    const checkFromOrder = getPrimaryCheckFromOrder(order ?? undefined)
    const combinedCheck = combineChecks(checks)

    return {
      ...partyRefreshResult,
      order,
      primaryCheck:
        checkFromOrder?.__typename === 'OPTCheckV2Guid'
          ? checkFromOrder
          : undefined,
      combinedCheck
    }
  }, [order, partyRefreshResult])
}

/**
 * Get a party member's data.
 * If no argument, get my party member data.
 */
export const useGetPartyMember = (partyMemberGuid?: string) => {
  const { partyMemberGuid: myPartyMemberGuid } = useParty()
  if (partyMemberGuid === undefined) {
    partyMemberGuid = myPartyMemberGuid
  }

  const { members } = useGetPartyMembers()

  return useMemo(
    () => members.find((m) => m.partyMemberGuid === partyMemberGuid),
    [members, partyMemberGuid]
  )
}

export const usePartyMemberHasPreauth = () => {
  const { primaryCheck } = useGetOrderFromParty()
  const { members } = useGetPartyMembers()

  const appliedPreauthInfo = primaryCheck?.appliedPreauthInfo

  if (!appliedPreauthInfo) {
    return null
  }

  return members.find((m) => {
    return m?.preauthCardGuid === appliedPreauthInfo.guid
  })
}

export const useIsMyPreauthCard = () => {
  const memberWithPreauth = usePartyMemberHasPreauth()
  const partyMember = useGetPartyMember()
  if (!memberWithPreauth) {
    return false
  }
  return Boolean(
    memberWithPreauth.partyMemberGuid === partyMember?.partyMemberGuid
  )
}

/**
 * Returns party query with list of paid items as a selections list
 */
export const useGetPaidItems = () => {
  const { partyRefresh, ...queryResult } = useGetPartyRefresh()
  const paidItems = useMemo(() => {
    const checks: OptCheckV2GuidFragment[] = partyRefresh?.order?.checks ?? []
    const paidCheck = combineChecks(checks, CheckStatus.CLOSED)
    return paidCheck?.selections ?? []
  }, [partyRefresh])

  return useMemo(
    () => ({ ...queryResult, paidItems }),
    [queryResult, paidItems]
  )
}

/**
 * Gets all checks on the party order.
 */
export const useGetAllChecks = () => {
  const { partyRefresh, ...partyQueryResult } = useGetPartyRefresh()
  const allChecks = partyRefresh?.order?.checks
  return useMemo(() => {
    return {
      ...partyQueryResult,
      allChecks: allChecks ?? []
    }
  }, [partyQueryResult, allChecks])
}

export const useGetMainCheck = () => {
  const partyQueryResult = useGetPartyRefresh()
  const mainCheck = getMainCheck(partyQueryResult)
  return useMemo(() => {
    return {
      ...partyQueryResult,
      mainCheck
    }
  }, [partyQueryResult, mainCheck])
}

/*
 * Gets preauth card on check, as long as it
 * is mine to use.
 */
export const useGetMyPreauthInfo = () => {
  const isMyPreauthCard = useIsMyPreauthCard()
  const { mainCheck } = useGetMainCheck()

  if (!mainCheck || !isMyPreauthCard) {
    return undefined
  }
  return mainCheck.appliedPreauthInfo
}

/**
 * Get my checks that I own (paid + unpaid) and split check preview that
 * corresponds to my items
 */
export const useGetMyChecks = () => {
  const { partyRefresh, ...partyQueryResult } = useGetPartyRefresh()
  const me = useGetPartyMember()
  const allChecks = partyRefresh?.order?.checks

  const myChecks: OptCheckV2GuidFragment[] = useMemo(() => {
    if (!allChecks || !me) {
      return []
    }
    const myCheckGuidSet = new Set(me.claimedCheckGuids)
    return allChecks.filter((check) => {
      return myCheckGuidSet.has(check.guid)
    })
  }, [allChecks, me])

  const { splitCheckPreview } = usePriceSplitCheck()

  const myPaidChecks: OptCheckV2GuidFragment[] = myChecks.filter(
    (c) => c.payments.length > 0
  )
  const myUnpaidChecks: OptCheckV2GuidFragment[] = myChecks.filter(
    (c) => c.payments.length < 1
  )

  return useMemo(() => {
    return {
      ...partyQueryResult,
      allChecks,
      mySplitCheckPreview: splitCheckPreview,
      myChecks,
      myPaidChecks,
      myUnpaidChecks
    }
  }, [
    partyQueryResult,
    myChecks,
    splitCheckPreview,
    myPaidChecks,
    allChecks,
    myUnpaidChecks
  ])
}

/**
 * Gets an array of all your payment amounts
 */
const useGetMyPaymentsAmounts = () => {
  const { myChecks, ...partyQueryResult } = useGetMyChecks()
  return useMemo(
    () => ({
      ...partyQueryResult,
      // NOTE: Currently assumes that if I own the check,
      // all the payments belong to me.
      payments: myChecks.flatMap((chk) => chk.payments)
    }),
    [partyQueryResult, myChecks]
  )
}

/**
 * Gets the amounts of my most recent payment
 */
export const useGetMyPaymentAmounts = () => {
  const { payments, ...queryResult } = useGetMyPaymentsAmounts()
  return {
    payment: payments[payments.length - 1],
    ...queryResult
  }
}

/**
 * Prioritizes checks to pay for my user based on:
 * 1. Unpaid checks that I have claimed
 * 2. Price split check based on my selections
 * 3. My split check preview (no custom pricing)
 */
export const useGetMyDueCheck = () => {
  const {
    mySplitCheckPreview,
    myUnpaidChecks,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    myPaidChecks,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    myChecks,
    ...queryResult
  } = useGetMyChecks()

  const myDueCheck = myUnpaidChecks[0] ?? mySplitCheckPreview

  return {
    ...queryResult,
    myDueCheck
  }
}

export const useGetSortedMemberAndServerBucketsFromCheck = (
  check?: OptCheckV2Fragment | null
) => {
  const { members: partyMembers } = useGetPartyMembers()
  const me = useGetPartyMember()

  const sortedMemberBuckets = useMemo(() => {
    if (!check || !me || !partyMembers) {
      return []
    }
    return getSortedMemberBuckets({
      partyMembers,
      check,
      me
    })
  }, [me, check, partyMembers])

  const serverBuckets = useMemo(() => {
    if (!check || !partyMembers) {
      return []
    }
    return getServerBuckets({
      check,
      partyMembers
    })
  }, [check, partyMembers])

  return useMemo(
    () => ({
      me,
      sortedMemberBuckets,
      serverBuckets
    }),
    [me, sortedMemberBuckets, serverBuckets]
  )
}

/**
 * Gets sorted member buckets from the primary check
 */
export const useGetSortedMemberAndServerBuckets = () => {
  const { primaryCheck: check, ...partyRefreshResponse } =
    useGetOrderFromParty()
  const { serverBuckets, sortedMemberBuckets, me } =
    useGetSortedMemberAndServerBucketsFromCheck(check)

  return useMemo(
    () => ({
      ...partyRefreshResponse,
      me,
      sortedMemberBuckets,
      serverBuckets
    }),
    [me, partyRefreshResponse, serverBuckets, sortedMemberBuckets]
  )
}

/**
 * Gets the total amounts remaining for the main check, NOT for self
 */
export const useGetTotalsRemaining = () => {
  const { primaryCheck, ...queryResults } = useGetOrderFromParty()
  return useMemo(() => {
    if (primaryCheck?.isClosed) {
      return {
        ...queryResults,
        remainingSubtotal: 0,
        remainingTax: 0,
        remainingServiceCharge: 0,
        remainingDiscount: 0,
        remainingTotal: 0
      }
    }
    return {
      ...queryResults,
      remainingSubtotal: primaryCheck?.subtotal ?? 0,
      remainingTax: primaryCheck?.tax ?? 0,
      remainingServiceCharge: primaryCheck?.serviceChargeTotal ?? 0,
      remainingDiscount:
        primaryCheck?.discounts.restaurantDiscount?.amount ?? 0,
      remainingTotal: primaryCheck?.total ?? 0
    }
  }, [primaryCheck, queryResults])
}

const emptyCarts: OptCartFragment[] = []
/**
 * Gets all carts for all users
 */
export const useGetPartyCarts = () => {
  const { partyRefresh, ...partyRefreshResult } = useGetPartyRefresh()

  const carts = partyRefresh ? partyRefresh.carts : emptyCarts

  return useMemo(
    () => ({
      ...partyRefreshResult,
      carts
    }),
    [carts, partyRefreshResult]
  )
}

/**
 * Get a party member's cart with partyMemberGuid
 * If no argument, return my cart
 */
export const useGetPartyMemberCart = (partyMemberGuid?: string) => {
  const { partyMemberGuid: myMemberGuid } = useParty()
  if (partyMemberGuid === undefined) {
    partyMemberGuid = myMemberGuid
  }
  const cartsData = useGetPartyCarts()
  const { cartGuid } = useGetPartyMember(partyMemberGuid) || {}
  const cart = cartsData.carts.find((c) => c && c.guid === cartGuid)
  const cartCheck = getPrimaryCheckFromOrder(cart?.order)

  return useMemo(
    () => ({
      ...cartsData,
      cart,
      cartCheck
    }),
    [cart, cartCheck, cartsData]
  )
}

export { usePriceSplitCheck } from './use-price-split-check'

export const useGetServerExclusivelySplitCheck = () => {
  const { mode } = useGetPartyMode()
  const splitMode =
    useGetPartyRefresh().partyRefresh?.splitPaymentData?.splitMode
  const { allChecks } = useGetAllChecks()

  return (
    mode === DDIMode.STP && splitMode !== SplitMode.Even && allChecks.length > 1
  )
}

export const useGetRemainingPortions = () => {
  const { partyRefresh } = useGetPartyRefresh()
  const totalPortions = partyRefresh?.splitPaymentData?.evenSplitPortions ?? 1
  const paidPortions =
    partyRefresh?.splitPaymentData?.evenSplitPaidPortions ?? 0
  return totalPortions - paidPortions
}
