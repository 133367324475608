import { useLocation } from 'react-router'
import { useDDIGlobals } from '../components/DDIGlobalsProvider/DDIGlobalsProvider'

interface DynamicQrResponse {
  orderGuid?: string | null
  checkGuid: string | null
}

/**
 * Helper function that will parse url params to see if  check guid have been passed,
 * if so, the origin was a dynamic QR code and the function will parse the details
 *
 * The initial screen allows to join a party via order guid, and that's the only time the check guid is needed
 */
export const useDynamicQrCode = (): DynamicQrResponse => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const orderGuid = useDDIGlobals().uniqueIdentifier
  const checkGuid = queryParams.get('checkGuid')

  return {
    orderGuid: orderGuid,
    checkGuid: checkGuid
  }
}

export default useDynamicQrCode
