import React, { useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import Button from '../../Button/Button'
import {
  useDeleteItemFromCart,
  useDeleteItemSelection
} from '../../CartMutation/CartMutation'
import { useCart } from '../../CartProvider/CartProvider'
import { SET_LOADING } from '../../CartProvider/cart-actions'
import { CheckTable } from '../../OrderTable/CheckTable'
import { CTA } from '@/il8n/en'

import { dispatchEditItemUserRequest } from 'cornucopia-apis'

import styles from './CartTable.module.css'
import { useFlag } from '../../FeatureFlag/use-flag'
import { LDFlags } from '../../../launchdarkly/flags'
import { CheckTableV2 } from '../../OrderTable/CheckTableV2/CheckTableV2'

const EditComponent = ({ selectionGuid, itemGuid, itemGroupGuid }) => {
  const [deleteItemMutation] = useDeleteItemFromCart()
  const { cartGuid, dispatch } = useCart()
  const deleteSelection = useDeleteItemSelection(deleteItemMutation)
  const history = useHistory()
  const handleRemove = useCallback(async () => {
    dispatch({ type: SET_LOADING, loading: true })
    await deleteSelection(selectionGuid)
    dispatch({ type: SET_LOADING, loading: false })
  }, [deleteSelection, selectionGuid, dispatch])

  return (
    <>
      <div className={styles.edit}>
        <Button
          data-testid='cart-item-edit'
          onClick={() => {
            dispatchEditItemUserRequest({
              cartGuid,
              selectionGuid,
              itemGuid,
              itemGroupGuid,
              cartApi: 'OPT',
              visibility: 'KIOSK'
            })
            history.replace('/edit')
          }}
        >
          {CTA.EDIT_FROM_CART}
        </Button>
        <Button data-testid='cart-item-remove' onClick={handleRemove}>
          {CTA.REMOVE_FROM_CART}
        </Button>
      </div>
    </>
  )
}

EditComponent.propTypes = {
  selectionGuid: PropTypes.string.isRequired,
  itemGuid: PropTypes.string,
  itemGroupGuid: PropTypes.string
}

const CartTable = ({
  cartCheck,
  editable = false,
  partyMemberGuid,
  memberName,
  isInParty = false,
  showSubtotals
}) => {
  const tabsRedesignOn = useFlag(LDFlags.OPT_TABS_REDESIGN_V2)

  if (!cartCheck) {
    return null
  }

  return tabsRedesignOn ? (
    <CheckTableV2
      check={cartCheck}
      partyMemberGuid={partyMemberGuid}
      memberName={memberName}
      isInParty={isInParty}
      editComponent={editable ? EditComponent : undefined}
      checksCollapsible
      showSubtotals={showSubtotals}
      displayUnpricedMods={true}
      showMyEmptyCheck
    />
  ) : (
    <CheckTable
      check={cartCheck}
      editComponent={editable ? EditComponent : undefined}
      memberName={memberName}
    />
  )
}

CartTable.propTypes = {
  cartCheck: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  memberName: PropTypes.string,
  partyMemberGuid: PropTypes.string
}

export default CartTable
