import { string } from 'yup'
import { CheckoutMode } from '../checkout-modes'

import { PaymentFormKeys } from '../types/types'

const FIELD_NAME = PaymentFormKeys.CREDIT_CARD_TIP

export const getValidationSchema = ({ mode }) => {
  switch (mode) {
    case CheckoutMode.CREATE_TAB_MODE:
      return {}
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE:
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    default:
      return {
        [FIELD_NAME]: string()
          .trim()
          // Transform here given that tip has a currency mask that adds $ prefix to it
          .transform((raw) => {
            raw = raw.replace(/[^0-9.]/g, '')
            return raw
          })
          .default('')
          .matches(/^([1-9][0-9]*|0)?(\.\d{2})?$/, 'must be a dollar amount')
      }
  }
}

export { getArgsForSubmit } from './getArgsForSubmit'
export { getInitialValues } from './getInitialValues'
export { TipComponent as Component } from './TipComponent'
