import React from 'react'
import PropTypes from 'prop-types'

import { onEnterOrSpace } from '../../../utils/key-handler'
import { selectorStyles } from '../Radio/Radio'

import styles from './Checkbox.module.css'

const getSelectorStyles = (variant) => {
  switch (variant) {
    case selectorStyles.NOBUTTON:
      return selectorStyles.NOBUTTON
    case selectorStyles.GRAY:
      return selectorStyles.GRAY
    case selectorStyles.DEFAULT:
    default:
      return selectorStyles.DEFAULT
  }
}

const Checkbox = ({
  onSelect,
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue, setFieldTouched },
  disabled,
  id,
  label,
  variant,
  ...props
}) => {
  const checkboxStyle = getSelectorStyles(variant)

  return (
    <div
      data-testid='checkbox-full'
      className={styles[`${checkboxStyle}InputGroup`]}
    >
      <input
        data-testid='checkbox-input'
        className={styles[`${checkboxStyle}Input`]}
        name={name}
        id={id}
        type='checkbox'
        value={value}
        checked={value}
        onChange={(...a) => {
          if (onSelect) {
            onSelect(...a)
            return
          }
          setFieldTouched(name, true)
          onChange(...a)
        }}
        onBlur={onBlur}
        disabled={disabled}
        {...props}
      />
      <label
        data-testid='checkbox-data'
        className={styles[`${checkboxStyle}Label`]}
        tabIndex='0'
        onKeyDown={onEnterOrSpace(() => {
          if (!disabled) {
            if (onSelect) {
              onSelect()
              return
            }
            setFieldValue(name, !value)
          }
        })}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  )
}

const FormShape = PropTypes.shape({
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func
})

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

Checkbox.propTypes = {
  field: FieldShape.isRequired,
  form: FormShape.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool
}

export default Checkbox
