import { useCallback, useState } from 'react'
import { dataByTypename } from '../../utils/apollo-helpers'
import { useMutation } from '@apollo/client'
import { CREATE_ACCOUNT } from '../../apollo/create-account.graphql'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import { useAuth } from '../../components/AuthProvider/AuthProvider'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { track } from '@toasttab/do-secundo-analytics'
import { useGuestInfo } from '../../hooks/use-guest-info'

const handleCreateAccount = async ({
  createAccount,
  paymentInfo,
  password,
  redirectUrl,
  restaurantGuid,
  guestName
}) => {
  const createAccountInput = {
    email: paymentInfo.customer.email,
    password,
    redirectUrl,
    registrationClient: 'ONLINE_ORDERING',
    restaurantGuid,
    basicInfo: {
      firstName: paymentInfo.customer.firstName || guestName || null,
      lastName: paymentInfo.customer.lastName || null,
      phone: paymentInfo.customer.phone
    }
  }

  const accountCreationResp = await createAccount({
    variables: { input: createAccountInput }
  })
  const { CreateAccountResponse, CreateAccountError } = dataByTypename(
    accountCreationResp.data.createAccount
  )
  const customerGuid = !CreateAccountError ? CreateAccountResponse : null
  const emailInUse = CreateAccountError?.code === 'EMAIL_IN_USE'

  return { customerGuid, CreateAccountError, emailInUse }
}

export const useHandleCreateAccount = () => {
  const { toastwebBaseUri, routerBasename } = useDDIGlobals()
  const redirectUrl = `${toastwebBaseUri}${routerBasename}?mode=verify`
  const { restaurantGuid } = useRestaurant()
  const { guestName } = useGuestInfo()
  const [errorMessage, setErrorMessage] = useState()
  const {
    clearUnverifiedCustomer,
    setUnverifiedCustomer,
    clearRecognizedCustomer,
    setRecognizedCustomer
  } = useAuth()
  const [createAccount, { error: mutationError }] = useMutation(
    CREATE_ACCOUNT,
    {
      onCompleted(data) {
        const { CreateAccountError } = dataByTypename(data.createAccount)

        if (CreateAccountError) {
          if (CreateAccountError.code !== 'EMAIL_IN_USE') {
            setErrorMessage({ message: CreateAccountError.message })
          }
          return
        }

        track('Create User Account on Checkout')
      }
    }
  )

  const mutation = useCallback(
    async ({ paymentInfo, password }) => {
      const customerEmail = paymentInfo.customer.email
      const {
        customerGuid: customerGuidObj,
        CreateAccountError,
        emailInUse
      } = await handleCreateAccount({
        createAccount,
        paymentInfo,
        password,
        redirectUrl,
        restaurantGuid,
        guestName
      })
      const customerGuid = customerGuidObj?.customerGuid

      if (CreateAccountError?.code === 'EMAIL_IN_USE') {
        clearUnverifiedCustomer()
        setRecognizedCustomer({ email: customerEmail })
      }

      if (customerGuid) {
        clearRecognizedCustomer()
        setUnverifiedCustomer({
          guid: customerGuid,
          signupSource: 'SAVE_CREDIT_CARD',
          email: customerEmail
        })
      }

      return [customerGuid, emailInUse]
    },
    [
      redirectUrl,
      restaurantGuid,
      createAccount,
      clearUnverifiedCustomer,
      setUnverifiedCustomer,
      clearRecognizedCustomer,
      setRecognizedCustomer,
      guestName
    ]
  )

  return [mutation, errorMessage || mutationError]
}
