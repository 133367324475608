import React from 'react'
import PropTypes from 'prop-types'
import InputGroup from '../../Form/InputGroup/InputGroup'
import { string } from 'yup'
import { getRawPhoneNumber } from '../../../utils/form-utils'
import { CheckoutMode } from '../checkout-modes'
import { FieldLabel } from '@/il8n/en'
import { SmsCommunicationConsent } from '../SmsCommunicationConsent/SmsCommunicationConsent'
import { SelectedPayment } from '../PaymentInfo/constants'
import { useIsIntlRestaurant } from '../../../hooks/use-is-intl-restaurant'
import { SpiPaymentType } from '../../../types/SPI'

export const Component = ({ authenticated, requireTel, mode }) => {
  const isIntlRx = useIsIntlRestaurant()
  return (
    <>
      <InputGroup
        inputConfigs={getCustomerInputs({
          authenticated,
          requireTel,
          mode,
          isIntlRx
        })}
      />
      <SmsCommunicationConsent requireTel={requireTel} mode={mode} />
    </>
  )
}

Component.propTypes = {
  authenticated: PropTypes.bool,
  requireTel: PropTypes.bool,
  mode: PropTypes.string
}

const v2SchemaOptions = ({
  isIntlRx = false,
  authenticated = false,
  requireTel = false,
  readOnly = false
}) => {
  const autoCompleteData = []
  if (authenticated) {
    autoCompleteData.push({
      autoComplete: 'section-customer email',
      id: 'customer_email',
      label: `${FieldLabel.CHECKOUT_CUSTOMER_EMAIL}*`,
      name: 'customerEmail',
      type: 'email'
    })
  } else {
    autoCompleteData.push({
      autoComplete: 'section-customer email',
      id: 'customer_email',
      label: `${FieldLabel.CHECKOUT_CUSTOMER_EMAIL}*`,
      name: 'customerEmail',
      type: 'email',
      readOnly
    })
  }
  if (requireTel) {
    autoCompleteData.push({
      autoComplete: 'section-customer tel-national',
      id: 'customer_tel',
      label: `${FieldLabel.CHECKOUT_CUSTOMER_PHONE}*`,
      name: 'customerTel',
      type: 'tel',
      mask: isIntlRx
        ? [/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
        : [
            '(',
            /[1-9]/,
            /\d/,
            /\d/,
            ')',
            ' ',
            /\d/,
            /\d/,
            /\d/,
            '-',
            /\d/,
            /\d/,
            /\d/,
            /\d/
          ],
      guide: false
    })
  }
  return autoCompleteData
}

const getCustomerInputs = ({ authenticated, requireTel, mode, isIntlRx }) => {
  switch (mode) {
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE:
      return v2SchemaOptions({
        authenticated: false,
        requireTel: requireTel,
        readOnly: false,
        isIntlRx
      })
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE:
      return [
        {
          autoComplete: 'section-customer email',
          id: 'customer_email',
          label: `${FieldLabel.CHECKOUT_CUSTOMER_EMAIL}*`,
          name: 'customerEmail',
          type: 'email'
        }
      ]
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    case CheckoutMode.CREATE_TAB_MODE:
    default:
      return v2SchemaOptions({
        authenticated,
        requireTel,
        readOnly: authenticated,
        isIntlRx
      })
  }
}

export const getValidationSchema = ({ authenticated, mode, requireTel }) => {
  switch (mode) {
    case CheckoutMode.CLOSE_PREAUTH_TAB_MODE:
    case CheckoutMode.CLOSE_PARTY_TAB_MODE: {
      const defaultValidationSchema = {
        customerEmail: string()
          .trim()
          .email('must be valid email')
          .required('required'),
        customerTel: string().when(['selectedPayment', 'saveCard'], {
          is: (selectedPayment, saveCard) =>
            !authenticated &&
            selectedPayment === SelectedPayment.NEW_CARD &&
            saveCard,
          then: () =>
            string()
              .transform(getRawPhoneNumber)
              .trim()
              .matches(/^$|^\d{10}$/, 'must be a 10 digit number')
              .required('required'),
          otherwise: () => string().default('')
        })
      }
      // Apple Pay will handle getting the required customer info
      const defaultCustomerEmail = defaultValidationSchema.customerEmail
      defaultValidationSchema.customerEmail = string().when(
        ['$isApplePayPreauthAvailable', 'savedCardGuid', 'spiPaymentType'],
        {
          is: (isApplePayPreauthAvailable, savedCardGuid, spiPaymentType) => {
            return (
              (savedCardGuid === 'ApplePay' && !isApplePayPreauthAvailable) ||
              spiPaymentType === SpiPaymentType.APPLE_PAY ||
              spiPaymentType === SpiPaymentType.GOOGLE_PAY
            )
          },
          then: () => undefined,
          otherwise: () => defaultCustomerEmail
        }
      )
      return defaultValidationSchema
    }
    case CheckoutMode.CREATE_PREAUTH_TAB_MODE: {
      const validationSchema = {
        customerEmail: string().when(['savedCardGuid'], {
          is: (savedCardGuid) => {
            return savedCardGuid === 'ApplePay'
          },
          then: () => undefined,
          otherwise: () =>
            string().trim().email('must be valid email').required('required')
        })
      }
      if (requireTel) {
        validationSchema.customerTel = string()
          .transform(getRawPhoneNumber)
          .trim()
          .matches(/^$|^\d{10}$/, 'must be a 10 digit number')
          .required('required')
      }
      return validationSchema
    }
    case CheckoutMode.CREATE_TAB_MODE:
    case CheckoutMode.PLACE_PAYG_ORDER_MODE:
    default: {
      const defaultValidationSchema = {
        customerEmail: string()
          .trim()
          .email('must be valid email')
          .required('required'),
        customerTel: string().when(['selectedPayment', 'saveCard'], {
          is: (selectedPayment, saveCard) =>
            !authenticated &&
            selectedPayment === SelectedPayment.NEW_CARD &&
            saveCard,
          then: () =>
            string()
              .transform(getRawPhoneNumber)
              .trim()
              .matches(/^$|^\d{10}$/, 'must be a 10 digit number')
              .required('required'),
          otherwise: () => string().default('')
        })
      }
      if (requireTel) {
        defaultValidationSchema.customerTel = string()
          .transform(getRawPhoneNumber)
          .trim()
          .matches(/^$|^\d{10}$/, 'must be a 10 digit number')
          .required('required')
      }
      // Apple Pay will handle getting the required customer info
      const defaultCustomerEmail = defaultValidationSchema.customerEmail
      defaultValidationSchema.customerEmail = string().when(
        ['savedCardGuid', 'spiPaymentType'],
        {
          is: (savedCardGuid, spiPaymentType) => {
            return (
              savedCardGuid === 'ApplePay' ||
              spiPaymentType === SpiPaymentType.APPLE_PAY ||
              spiPaymentType === SpiPaymentType.GOOGLE_PAY
            )
          },
          then: () => undefined,
          otherwise: () => defaultCustomerEmail
        }
      )
      const defaultCustomerTel = defaultValidationSchema.customerTel
      defaultValidationSchema.customerTel = string().when(['savedCardGuid'], {
        is: (savedCardGuid) => {
          return savedCardGuid === 'ApplePay'
        },
        then: () => undefined,
        otherwise: () => defaultCustomerTel
      })
      return defaultValidationSchema
    }
  }
}

export const getValidationSchemaIntl = () => {
  return {
    customerEmail: string()
      .trim()
      .email('must be valid email')
      .required('required')
  }
}

export { getInitialValues } from './getInitialValues'
export { getArgsForSubmit } from './getArgsForSubmit'
