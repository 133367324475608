import React, { useCallback, useMemo, useState } from 'react'
import { PropTypes } from 'prop-types'

import { Button } from '@toasttab/buffet-buttons'

import InviteIcon from '../../assets/icon-24-communication.svg'

import styles from './PartyInviteButton.module.css'
import { PartyInviteModal } from '../PartyInviteOthers/PartyInviteModal'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { generateTableUrl } from '../../utils/urls'
import { CTA } from '@/il8n/en'

const DefaultButton = (
  <Button variant={Button.Variant.NO_STYLE}>
    <span className={styles.partyInviteContent}>
      {CTA.PARTY_INVITE_INVITE_OTHERS}
      <InviteIcon className={styles.partyInviteIcon} role='img' />
    </span>
  </Button>
)

export const PartyInviteButton = ({
  whiteLabelName,
  button = DefaultButton
}) => {
  const globals = useDDIGlobals()
  const baseInviteUrl = useMemo(() => generateTableUrl(globals), [globals])

  const [showInviteModal, setShowInviteModal] = useState(false)

  const handleClick = useCallback(async () => {
    setShowInviteModal(true)
  }, [setShowInviteModal])

  const closeFallback = useCallback(() => {
    setShowInviteModal(false)
  }, [setShowInviteModal])

  return (
    <>
      <div onClick={handleClick} data-testid='party-invite-button'>
        {button}
      </div>
      {showInviteModal && (
        <PartyInviteModal
          onClose={closeFallback}
          baseUrl={baseInviteUrl}
          whiteLabelName={whiteLabelName}
        />
      )}
    </>
  )
}

PartyInviteButton.propTypes = {
  whiteLabelName: PropTypes.string,
  button: PropTypes.node
}
