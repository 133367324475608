import React, { useContext } from 'react'
import { Formik, FormikValues, FormikConfig, FormikProps } from 'formik'
import PropTypes from 'prop-types'

const FormikContext = React.createContext({})

interface FormikProviderProps<T> extends FormikConfig<T> {
  children: React.ReactChild | ((f: FormikProps<T>) => React.ReactNode)
}

export function FormikProvider<T extends FormikValues>({
  children,
  ...props
}: FormikProviderProps<T>) {
  return (
    <Formik<T> {...props}>
      {(formikProps) => (
        <FormikContext.Provider value={formikProps}>
          {typeof children === 'function' ? children(formikProps) : children}
        </FormikContext.Provider>
      )}
    </Formik>
  )
}

export const useFormik = () => useContext(FormikContext)

FormikProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
}
