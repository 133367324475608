import '../client/polyfills'
import '../client/globals'
// keep style import on top
import './index.css'

import * as React from 'react'
import ReactDOM from 'react-dom'
import { BanquetApp } from '../client/components/App/BanquetApp.jsx'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { getSentryConfig } from 'do-cornucopia-root'

const tempWindow: any = window
const sentryConfig = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'do',
  rootComponent: BanquetApp as any,
  singleSpaCssLifecycles,
  sentry: sentryConfig
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
