export const getTabSpaRedirectUrl = (
  primaryCheck,
  preauthEnabled,
  tabsEnabled
) => {
  if (!preauthEnabled && tabsEnabled) {
    return '/tab-management/add'
  }

  const { numberOfSelections, appliedPreauthInfo } = primaryCheck || {}
  const addToTabCondition = numberOfSelections > 0 || appliedPreauthInfo

  return addToTabCondition ? '/tab-management/add' : '/tab-management/start'
}
