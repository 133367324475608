import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  useGiftCardEnabled,
  useGiftCardTipEnabled
} from '../IfGiftCard/use-gift-card-enabled'

import {
  OptRestaurantGiftCard,
  OptGlobalGiftCard,
  useGet_Gift_Card_BalanceLazyQuery
} from '../../apollo/generated/OptWebGraphQLOperations'

const noop = () => {}

export interface GiftCardContextTypeV2 {
  rxGiftCardEnabled: boolean
  rxGiftCardTipEnabled: boolean
  rxGiftCard?: OptRestaurantGiftCard
  globalGiftCard?: OptGlobalGiftCard
  applyGiftCard(): void
  clearRxGiftCard(): void
  clearGlobalGiftCard(): void
  error?: { message: string }
  loading: boolean
}

const emptyGiftCardContextV2: GiftCardContextTypeV2 = {
  rxGiftCardEnabled: false,
  rxGiftCardTipEnabled: false,
  rxGiftCard: undefined,
  globalGiftCard: undefined,
  applyGiftCard: noop,
  clearRxGiftCard: noop,
  clearGlobalGiftCard: noop,
  error: undefined,
  loading: false
}

const GiftCardContextV2 = React.createContext<GiftCardContextTypeV2>(
  emptyGiftCardContextV2
)

export const GiftCardProvider: React.FC = ({ children }) => {
  const rxGiftCardEnabled = useGiftCardEnabled()
  const rxGiftCardTipEnabled = useGiftCardTipEnabled()
  const [rxGiftCard, setRxGiftCard] =
    useState<GiftCardContextTypeV2['rxGiftCard']>(undefined)
  const [globalGiftCard, setGlobalGiftCard] =
    useState<GiftCardContextTypeV2['globalGiftCard']>(undefined)
  const [giftCardError, setGiftCardError] =
    useState<GiftCardContextTypeV2['error']>(undefined)
  const clearRxGiftCard = useCallback(() => {
    setRxGiftCard(undefined)
    setGiftCardError(undefined)
  }, [])
  const clearGlobalGiftCard = useCallback(() => {
    setGlobalGiftCard(undefined)
    setGiftCardError(undefined)
  }, [])

  const [applyGiftCard, giftCardResponse] = useGet_Gift_Card_BalanceLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      if (data?.optGetGiftCardBalance?.globalGiftCard) {
        setGlobalGiftCard(data.optGetGiftCardBalance.globalGiftCard)
      } else if (data?.optGetGiftCardBalance?.restaurantGiftCard) {
        setRxGiftCard(data.optGetGiftCardBalance.restaurantGiftCard)
      } else {
        setGiftCardError({
          message: 'No valid Toast Cash or gift card found. Try again.'
        })
      }
    },
    onError() {
      clearRxGiftCard()
      clearGlobalGiftCard()
    }
  })

  const context: GiftCardContextTypeV2 = {
    rxGiftCardEnabled,
    rxGiftCardTipEnabled,
    rxGiftCard,
    globalGiftCard,
    applyGiftCard,
    clearRxGiftCard,
    clearGlobalGiftCard,
    error: giftCardError,
    loading: giftCardResponse.loading
  }

  return (
    <GiftCardContextV2.Provider value={context}>
      {children}
    </GiftCardContextV2.Provider>
  )
}

GiftCardProvider.propTypes = {
  children: PropTypes.node
}

export const useGiftCard = () => {
  return React.useContext(GiftCardContextV2)
}
