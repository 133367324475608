import React, { useCallback, useRef, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'

import {
  OPT_ADD_ITEM_TO_PARTY_MEMBER_CART,
  OPT_EDIT_ITEM_IN_PARTY_CART,
  OPT_DELETE_ITEM_FROM_PARTY_CART,
  OPT_REORDER_ITEM,
  OPT_GET_PARTY_REFRESH
} from '../../apollo/party/party.graphql'

import { ORDER_AT_TABLE_MENUS } from '../UseMenuQuery/Menus.graphql'

import { useCart } from '../CartProvider/CartProvider'
import {
  CLEAR_MODIFICATION_ERRORS,
  PUSH_MODIFICATION_ERRORS
} from '../CartProvider/cart-actions'
import { toastNotification } from '../ToastNotification/ToastNotification'
import { useErrorModal } from '../ErrorModalProvider/ErrorModalProvider'

import Modal from '../Modal/Modal'
import Button from '../Button/Button'
import cartErrorStyles from './CartError.module.css'
import { useParty } from '../PartyProvider/PartyProvider'
import { useGetPartyMember } from '../PartyQuery/PartyQuery'
import {
  trackAddProduct,
  trackRemoveProduct,
  trackReorderProduct
} from '../../utils/track-ecommerce'
import { CTA, HeaderText } from '@/il8n/en'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'

const nop = () => {}

const usePushCartNotificationsCallback = () => {
  const { dispatch } = useCart()
  return useCallback(
    (messages) =>
      dispatch({
        type: PUSH_MODIFICATION_ERRORS,
        modificationErrors: messages
      }),
    [dispatch]
  )
}

export const useDeleteItemSelection = (deleteItemFromCart) => {
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  const input = useMemo(() => {
    return {
      partyGuid,
      partyMemberGuid,
      memberAuthToken
    }
  }, [memberAuthToken, partyGuid, partyMemberGuid])

  return useCallback(
    (selectionGuid) => {
      return deleteItemFromCart({
        variables: {
          input: {
            ...input,
            selectionGuid
          }
        }
      })
    },
    [input, deleteItemFromCart]
  )
}

const useCartMutation = (
  graphQLQuery,
  {
    onSuccessfulMutation = nop,
    showOOSToast = false,
    reattemptOnCartError = false
  },
  { onCompleted = nop, onError = nop } = {}
) => {
  const { partyMemberGuid, partyGuid, memberAuthToken } = useParty()
  const { cartGuid } = useGetPartyMember(partyMemberGuid) ?? {}
  const [mutationError, setMutatationError] = useState(null)
  const { restaurantGuid } = useRestaurant()
  const { mode } = useDDIGlobals()

  const getCartFromCompletedMutation = useCallback(
    (data) => {
      const partyRefreshData = data?.optPartyRefresh
      const OPTPartyError =
        partyRefreshData?.__typename === 'OPTPartyError'
          ? partyRefreshData
          : undefined
      const OPTPartyRefresh =
        partyRefreshData?.__typename === 'OPTPartyRefreshV2'
          ? partyRefreshData
          : undefined
      const myCart = OPTPartyRefresh?.carts?.find((c) => {
        return c && cartGuid === c.guid
      })

      return {
        data,
        myCart,
        OPTPartyError,
        OPTPartyRefresh
      }
    },
    [cartGuid]
  )

  const cartProps = useCart()
  const { updateCartGuid, updateCartMode, dispatch } = cartProps
  const pushNotifications = usePushCartNotificationsCallback()
  const mutationArg = useRef()
  const [apolloMutate, result] = useMutation(graphQLQuery, {
    onError(error) {
      return handleError(error)
    },
    awaitRefetchQueries: true,
    refetchQueries: (data) => {
      const { myCart } = getCartFromCompletedMutation(data)
      const queries = []
      if (partyGuid && partyMemberGuid && memberAuthToken) {
        queries.push({
          query: OPT_GET_PARTY_REFRESH,
          variables: {
            partyGuid: partyGuid,
            partyMemberGuid: partyMemberGuid,
            memberAuthToken: memberAuthToken
          }
        })
      }
      if (
        myCart?.cartMessages?.some(
          (m) =>
            m.messageType === 'MENU_UNAVAILABLE' ||
            m.messageType === 'OUT_OF_STOCK'
        )
      ) {
        queries.push({
          query: ORDER_AT_TABLE_MENUS,
          variables: { restaurantGuid }
        })
      }
      return queries
    },
    onCompleted: (data) => {
      toast.dismiss()
      const { myCart, OPTPartyError } = getCartFromCompletedMutation(data)
      if (OPTPartyError) {
        setMutatationError(OPTPartyError)
        handleError(OPTPartyError)
        onError(OPTPartyError)
      } else if (myCart) {
        updateCartGuid(myCart.guid)
        updateCartMode(mode)
        dispatch({ type: CLEAR_MODIFICATION_ERRORS })
        removalMessagingHelper(myCart, pushNotifications, showOOSToast)
        onSuccessfulMutation(mutationArg.current)
        onCompleted(data)
      }
    }
  })

  const mutate = useCallback(
    async (arg) => {
      mutationArg.current = arg
      dispatch({ type: CLEAR_MODIFICATION_ERRORS })
      return apolloMutate(arg)
    },
    [apolloMutate, dispatch]
  )

  const reattemptOnEmptyCart = useCallback(() => {
    if (reattemptOnCartError) {
      const mutationWithoutCart = {
        variables: {
          input: {
            ...mutationArg.current.variables.input,
            cartGuid: undefined
          }
        }
      }
      return mutate(mutationWithoutCart)
    }
  }, [mutate, reattemptOnCartError])

  const handleCartError = useHandleCartError(reattemptOnEmptyCart)

  const handleError = useCallback(
    (error) => {
      dispatch({ type: PUSH_MODIFICATION_ERRORS, modificationErrors: [error] })
      handleCartError(error)
      onError(error)
    },
    [handleCartError, onError, dispatch]
  )
  return [
    mutate,
    {
      ...result,
      error: result.error || mutationError
    }
  ]
}

const itemReordered = (mutationInput) => {
  const selectionGuid = mutationInput.variables.input.selectionGuid
  trackReorderProduct({
    id: selectionGuid
  })
  itemAdded(mutationInput)
}

const itemAdded = (mutationInput) => {
  const item = mutationInput.variables.input.selection
  // TODO:  item.name and item.price are undefined; no easy way to get them in here right now
  trackAddProduct({
    id: item?.itemGuid,
    name: item?.name || item?.itemGuid,
    price: item?.price || 0.0,
    quantity: item?.quantity
  })
  toastNotification(
    `${item?.quantity || 1} item${item?.quantity > 1 ? 's' : ''} added`
  )
}
export const useAddItemToCart = (options) => {
  return useCartMutation(
    OPT_ADD_ITEM_TO_PARTY_MEMBER_CART,
    {
      onSuccessfulMutation: itemAdded,
      showOOSToast: true,
      reattemptOnCartError: true
    },
    options
  )
}

export const useReorderItemToCart = (options) => {
  const gqlMutation = OPT_REORDER_ITEM
  return useCartMutation(
    gqlMutation,
    {
      onSuccessfulMutation: itemReordered,
      showOOSToast: true,
      reattemptOnCartError: true
    },
    options
  )
}

const itemEdited = (mutationInput) => {
  const item = mutationInput.variables.input.selection
  toastNotification(
    `${item.quantity} item${item.quantity > 1 ? 's' : ''} updated`
  )
}
export const useEditItemInCart = (options) => {
  return useCartMutation(
    OPT_EDIT_ITEM_IN_PARTY_CART,
    {
      onSuccessfulMutation: itemEdited,
      showOOSToast: false,
      reattemptOnCartError: false
    },
    options
  )
}

const itemRemoved = (mutationInput) => {
  toastNotification('Successfully removed')
  const selectionGuid = mutationInput.variables.input.selectionGuid
  trackRemoveProduct({
    selectionGuid
  })
}
export const useDeleteItemFromCart = (options) => {
  return useCartMutation(
    OPT_DELETE_ITEM_FROM_PARTY_CART,
    {
      onSuccessfulMutation: itemRemoved,
      showOOSToast: false,
      reattemptOnCartError: false
    },
    options
  )
}

const removalMessagingHelper = (dataCart, push, showToast) => {
  const cartMessages = dataCart?.cartMessages ?? []
  cartMessages.forEach((m) => {
    const msg = m.userFacingMessage
    if (showToast) {
      toast(msg)
    }
    push([{ message: msg }])
  })
}

const useHandleCartError = (retryCb) => {
  const { handleClose, setModal } = useErrorModal()
  const { deleteCartGuid } = useCart()
  const retryRef = useRef(retryCb)
  retryRef.current = retryCb

  return useCallback(
    (error) => {
      const graphQLErrors = error.graphQLErrors
      const alreadyPaidError =
        graphQLErrors &&
        graphQLErrors.find((e) => {
          return /Credit card payment \(/.test(
            e?.extensions?.response?.body?.developerMessage ?? ''
          )
        })
      if (alreadyPaidError) {
        deleteCartGuid()
        retryRef.current && retryRef.current()
        setModal(
          <Modal
            responsive={false}
            header={HeaderText.NEW_ORDER_CREATED_INTERRUPT}
          >
            <div className={cartErrorStyles.modalBody}>
              <p>
                It appears that you have already completed an order with this
                cart. Please check your email for an order confirmation.
              </p>
              <p>
                If you have any more questions, please reach out to your server.
              </p>
              <Button type='button' variant='primary' onClick={handleClose}>
                {CTA.CONFIRM_NEW_ORDER_CREATED}
              </Button>
            </div>
          </Modal>
        )
      }
    },
    [deleteCartGuid, handleClose, setModal]
  )
}
