import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { QRScannerPage } from '../QRScannerPage/QRScannerPage'

export const QRScannerApp = () => {
  return (
    <>
      <BrowserRouter basename='/'>
        <Switch>
          <Route
            component={QRScannerPage}
            exact
            path='/order-and-pay/qr-scanner'
          />
          <Redirect to='/order-and-pay/qr-scanner' />
        </Switch>
      </BrowserRouter>
    </>
  )
}
