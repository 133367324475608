export enum PaymentType {
  APPLE_PAY = 'APPLE_PAY',
  CREDIT_CARD = 'CREDIT_CARD',
  CLICK_TO_PAY = 'CLICK_TO_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY'
}

export enum SelectedPayment {
  SAVED_CARD = 'SAVED_CARD',
  NEW_CARD = 'NEW_CARD',
  APPLE_PAY = 'APPLE_PAY',
  CLICK_TO_PAY = 'CLICK_TO_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY'
}

/**
 * Supported payment methods
 * for digital-payment-methods-poc
 */
export { PaymentMethodKey as SupportedPaymentMethods } from '@toasttab/digital-payment-methods-poc'
