import Fuse from 'fuse.js'

/* SOURCE: https://www.peterbe.com/plog/a-darn-good-search-filter-function-in-javascript */
export function filterList(q, list, searchProps) {
  const options = {
    includeScore: true,
    keys: searchProps,
    threshold: 0.24
  }

  const fuse = new Fuse(list, options)

  return fuse.search(q).map((result) => result.item)
}

export const getItemsFlat = (menus) =>
  menus.reduce((acc, menu) => {
    const menuName = menu.name
    const items = menu.groups.reduce((itemsAcc, group) => {
      const groupName = group.name
      return itemsAcc.concat(
        group.items.map((item) => ({ ...item, groupName, menuName }))
      )
    }, [])
    return acc.concat(items)
  }, [])

export const menuSearch = (query, menus) => {
  const queryItems = getItemsFlat(menus)
  if (query) {
    return filterList(query, queryItems, [
      'name',
      'description',
      'menuName',
      'groupName'
    ]).sort((a, b) => (filterList(query, [a], ['name']).length ? -1 : 1))
  }
  return queryItems
}
