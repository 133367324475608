import React, { useEffect, useRef, useState, useMemo } from 'react'
import Formik from 'formik'
import { NetworkStatus } from '@apollo/client'
import PropTypes from 'prop-types'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import { EditIcon } from '@toasttab/buffet-pui-icons'
import {
  useGetMainCheck,
  useGetMyDueCheck,
  useGetPartyMembers,
  useGetPartyRefresh,
  useGetRemainingPortions,
  useIsMyPreauthCard
} from '../PartyQuery/PartyQuery'
import { SplitPaymentMethod } from './SplitPaymentMethod.enum'
import { TargetedNotification } from '@local/do-secundo-target-notification'
import {
  getScrollElement,
  getScrollElementTop
} from '../../hooks/use-fix-safari-scroll'
import { Button } from '@toasttab/buffet-pui-buttons'
import { useHistory } from 'react-router'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { SplitEvenBottomSheet } from '../SplitEvenBottomSheet/SplitEvenBottomSheet'
import { useRemoveSplit } from '../../hooks/split-payments/use-remove-split'

import { HeaderText } from '@/il8n/en'
import styles from './SplitPaymentSwitch.module.css'
import {
  OptPartyError,
  OptPartyErrorCode
} from '../../apollo/generated/OptWebGraphQLOperations'
import Error from '../Error/Error'
import Loading from '../Loading/Loading'
import { CannotApplyChangeDialog } from '../SplitEvenBottomSheet/CannotApplyChangeDialog'
import { getIsPayingRemainingSplitPortions } from '../../utils/checkout-helpers'
import { useOuterClick } from '../../hooks/useOuterClick'
import { PortionsChangedDialog } from '../PortionsChangedDialog/PortionsChangedDialog'
import { usePaymentOptionsHandler } from '../PaymentOptions/usePaymentOptionsHandler'
import { PaymentOptions } from '../PaymentOptions/PaymentOptions'
import { useTabSpaEnabled } from '../../hooks/tabs/use-tab-spa-enabled'
import { useSplitPaymentsEnabled } from '../../hooks/split-payments/use-split-payments-enabled'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'
import { isTaxInclusive } from '../../utils/tax-inclusive'

export { SplitPaymentMethod }

interface PayByItemsHeaderProps {
  splitPaymentMethod: SplitPaymentMethod
  disabled: boolean
  openPaymentOptions: () => void
}

export interface SplitEvenlyHeaderProps {
  setConfirmedChangeSplit: (newState: boolean) => void
  openPaymentOptions: () => void
}

export const SplitEvenlyHeader = ({
  setConfirmedChangeSplit,
  openPaymentOptions
}: SplitEvenlyHeaderProps) => {
  const { mainCheck } = useGetMainCheck()
  const splitEvenlyEnabled = useSplitPaymentsEnabled()
  const moreThanOnePortion = useGetRemainingPortions() > 1
  const tabsSpaEnabled = useTabSpaEnabled()
  const [showDrawer, setShowDrawer] = useState(false)
  const { data } = useRestaurantInfo()
  const handleEditSplit = () =>
    tabsSpaEnabled ? openPaymentOptions() : setShowDrawer(true)

  if (!splitEvenlyEnabled || !mainCheck) {
    return null
  }

  const mainCheckSubtotal =
    mainCheck.preDiscountedSubtotal + (isTaxInclusive(data) ? mainCheck.tax : 0)

  return (
    <>
      <SplitEvenBottomSheet
        subtotal={mainCheckSubtotal}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
        isEdit
        setConfirmedChangeSplit={setConfirmedChangeSplit}
      />
      <div className='flex items-center justify-between'>
        <h5>{HeaderText.YOUR_CHECK}</h5>
        {moreThanOnePortion && (
          <Button
            variant='text-link'
            iconLeft={<EditIcon />}
            onClick={handleEditSplit}
          >
            Edit split
          </Button>
        )}
      </div>
    </>
  )
}

const PayByItemsHeader = ({
  splitPaymentMethod,
  disabled,
  openPaymentOptions
}: PayByItemsHeaderProps) => {
  const history = useHistory()
  const tabSpaEnabled = useTabSpaEnabled()
  const { getRestaurantPath } = useRestaurant()
  const { removeSplit, loading } = useRemoveSplit()
  const [showCannotChangeSplitDialog, setShowCannotApplyChangeDialog] =
    useState(false)
  const [userFacingError, setUserFacingError] = useState<string>()
  const headerCopy = useMemo(() => {
    switch (splitPaymentMethod) {
      case SplitPaymentMethod.PAY_FOR_PARTY:
        return HeaderText.GROUP_CHECK
      case SplitPaymentMethod.PAY_FOR_SELF:
      default:
        return HeaderText.YOUR_CHECK
    }
  }, [splitPaymentMethod])

  const onRemoveSplitSuccess = () => {
    history.push(getRestaurantPath('/tab'))
  }

  const onRemoveSplitError = (error: OptPartyError) => {
    switch (error.code) {
      case OptPartyErrorCode.SplitPaymentAssignmentLockedInError:
        setShowCannotApplyChangeDialog(true)
        break
      default:
        setUserFacingError(error.message)
    }
  }

  const handleEditSplit = () =>
    tabSpaEnabled
      ? openPaymentOptions()
      : removeSplit({
          onComplete: onRemoveSplitSuccess,
          onError: onRemoveSplitError,
          setUserFacingError
        })

  return (
    <>
      {showCannotChangeSplitDialog && (
        <CannotApplyChangeDialog
          showDialog={showCannotChangeSplitDialog}
          setShowDialog={setShowCannotApplyChangeDialog}
          onClose={() => {
            history.push(getRestaurantPath('/tab'))
          }}
        />
      )}
      <div className='flex items-center justify-between'>
        <h5>{headerCopy}</h5>
        <Button
          variant='text-link'
          iconLeft={loading ? null : <EditIcon />}
          disabled={disabled || loading}
          onClick={handleEditSplit}
        >
          {loading ? <Loading variant='link' /> : <>Edit split</>}
        </Button>
        {userFacingError && <Error>{userFacingError}</Error>}
      </div>
    </>
  )
}

export interface SplitPaymentSwitchValues {
  paymentMethod?: SplitPaymentMethod
}

const ScrollOnMount = ({ children }: { children: React.ReactChild }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (containerRef.current) {
      const yOffset = -20
      const y =
        containerRef.current.getBoundingClientRect().top +
        getScrollElementTop() +
        yOffset

      getScrollElement().scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [])

  return <div ref={containerRef}>{children}</div>
}

export const SplitPaymentSwitch = ({
  form,
  field: { name, value }
}: Formik.FieldProps<SplitPaymentMethod>) => {
  const isMyPreauthCard = useIsMyPreauthCard()
  const { mainCheck } = useGetMainCheck()
  const { myDueCheck, networkStatus } = useGetMyDueCheck()
  const isReady = networkStatus === NetworkStatus.ready
  const { members } = useGetPartyMembers()
  const { partyRefresh } = useGetPartyRefresh()
  const { evenSplitPaidPortions = 0, evenSplitPortions = 0 } =
    partyRefresh?.splitPaymentData || {}
  const [showPortionsChanged, setShowPortionsChanged] = useState(false)
  const [previousTotalShares, setPreviousTotalShares] =
    useState(evenSplitPortions)
  // State variable to prevent PortionChangedDialog from displaying when a user changes the split portions on their own
  const [confirmedChangeSplit, setConfirmedChangeSplit] = useState(false)
  // State variable to determine if a user has removed the split themselves
  const [splitRemoved, setSplitRemoved] = useState(false)
  const moreThanOnePortion = useGetRemainingPortions() > 1
  useEffect(() => {
    // Detect when receiving guest has a split changed by another user
    if (!confirmedChangeSplit && previousTotalShares) {
      // Modal displaying that portions have changed
      if (
        previousTotalShares !== evenSplitPortions &&
        evenSplitPortions > 0 &&
        !splitRemoved
      ) {
        setTimeout(() => {
          setShowPortionsChanged(true)
        }, 200)
        // Modal displaying that split has been removed
      } else if (
        previousTotalShares > evenSplitPortions &&
        evenSplitPortions === 0
      ) {
        setSplitRemoved(true)
        setShowPortionsChanged(true)
      } else {
        setSplitRemoved(false)
      }
    } else {
      setConfirmedChangeSplit(false)
    }
    setPreviousTotalShares(evenSplitPortions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evenSplitPortions]) // only want to re-render on evenSplitPortion update

  const isPayingRemainingSplitPortions = getIsPayingRemainingSplitPortions({
    splitPaymentMethod: value,
    evenSplitPaidPortions: evenSplitPaidPortions
  })

  const noItemsPayForSelf = !myDueCheck?.subtotal

  const isLastPartyMemberUnpaid = useMemo(() => {
    const mainCheckSelections = mainCheck
      ? new Set(
          mainCheck.selections.flatMap((s) =>
            s.__typename === 'CheckSelectionGuid' && s?.guid ? [s.guid] : []
          )
        )
      : new Set()
    const myDueCheckSelections = myDueCheck
      ? new Set(
          myDueCheck.selections.flatMap((s) =>
            s.__typename === 'CheckSelectionGuid' && s?.guid ? [s.guid] : []
          )
        )
      : new Set()
    return (
      mainCheckSelections.size > 0 &&
      isEqual(mainCheckSelections, myDueCheckSelections)
    )
  }, [mainCheck, myDueCheck])

  useEffect(() => {
    if (
      (noItemsPayForSelf || isMyPreauthCard) &&
      value === SplitPaymentMethod.PAY_FOR_SELF &&
      isReady
    ) {
      form.setFieldValue(name, SplitPaymentMethod.PAY_FOR_PARTY)
    }
  }, [form, isReady, name, noItemsPayForSelf, value, isMyPreauthCard])

  const [showPreauthAlert, setShowAlert] = useState(false)

  const clickRef = useOuterClick<HTMLDivElement>(() => setShowAlert(false))

  const { paymentOptionsOpen, openPaymentOptions, closePaymentOptions } =
    usePaymentOptionsHandler()
  const tabSpaEnabled = useTabSpaEnabled()
  if (isMyPreauthCard) {
    if (members.length < 2) {
      return null
    }
  } else if (
    isLastPartyMemberUnpaid &&
    isReady &&
    value !== SplitPaymentMethod.SPLIT_EVENLY &&
    (!isPayingRemainingSplitPortions || !moreThanOnePortion)
  ) {
    return (
      <>
        <PortionsChangedDialog
          showDialog={showPortionsChanged}
          amountDue={myDueCheck?.expectedPaymentAmount ?? 0}
          handleClose={() => {
            setShowPortionsChanged(false)
          }}
          splitRemoved={splitRemoved}
        />
        <h5 className='mb-2'>{HeaderText.YOUR_CHECK}</h5>
      </>
    )
  }

  return (
    <div className={'relative'}>
      {isMyPreauthCard && (
        <div
          ref={clickRef}
          data-testid='disable-pay-items-overlay'
          className='absolute z-10 w-full h-full'
          onClick={() => {
            setShowAlert(true)
          }}
        />
      )}
      <PortionsChangedDialog
        showDialog={showPortionsChanged}
        amountDue={myDueCheck?.expectedPaymentAmount ?? 0}
        handleClose={() => {
          setShowPortionsChanged(false)
        }}
        splitRemoved={splitRemoved}
      />
      <TargetedNotification
        className={'relative'}
        isOpen={showPreauthAlert}
        position='above'
        message={
          <ScrollOnMount>
            <div className='p-2'>
              We're sorry, because you opened the check you can only pay the
              remaining check total. If others in your group pay first, the
              check total will be reduced.
            </div>
          </ScrollOnMount>
        }
        alignment='left'
        notificationClass={cx('absolute p-2', styles.notification)}
      >
        <div className='flex flex-col w-full'>
          {value === SplitPaymentMethod.SPLIT_EVENLY ||
          isPayingRemainingSplitPortions ? (
            <SplitEvenlyHeader
              setConfirmedChangeSplit={setConfirmedChangeSplit}
              openPaymentOptions={openPaymentOptions}
            />
          ) : (
            <PayByItemsHeader
              splitPaymentMethod={value}
              disabled={isMyPreauthCard}
              openPaymentOptions={openPaymentOptions}
            />
          )}
        </div>
      </TargetedNotification>
      {tabSpaEnabled && (
        <PaymentOptions
          isOpen={paymentOptionsOpen}
          onClose={closePaymentOptions}
        />
      )}
    </div>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

SplitPaymentSwitch.propTypes = {
  field: FieldShape.isRequired,
  form: PropTypes.object.isRequired
}
