import React, { useEffect } from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { useGet_Full_UrlQuery } from '@toasttab/do-federated-gateway-apollo/generated/DoFederatedGatewayGraphQLOperations'
import { ErrorComponent } from '../components/Error'
import { NetworkStatus } from '@apollo/client'

type ShortenedUrlLookupProps = {
  hash: string
  handleRedirect(url: string): void
}

export const ShortenedUrlLookup = ({
  hash,
  handleRedirect
}: ShortenedUrlLookupProps) => {
  const { loading, error, data, refetch, networkStatus } = useGet_Full_UrlQuery(
    {
      variables: {
        paymentShortKey: hash
      },
      skip: !hash
    }
  )

  const redirectTo = data?.CON_getFullURL

  const noRedirectFound = networkStatus === NetworkStatus.ready && !redirectTo

  useEffect(() => {
    if (redirectTo) {
      handleRedirect(redirectTo)
    }
  }, [redirectTo, handleRedirect])

  if (loading) {
    return (
      <div className='w-full h-screen flex items-center justify-center'>
        <MerryGoRound testId={'shortened-url-loading'} />
      </div>
    )
  } else if (noRedirectFound) {
    return <ErrorComponent error={'No check found for the provided URL'} />
  } else if (error) {
    return <ErrorComponent error={error} retry={() => refetch()} />
  }

  return null
}
