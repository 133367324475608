import React from 'react'
import { Redirect, useParams } from 'react-router-dom'

export const PartyInviteHandler = () => {
  let { code } = useParams()
  return (
    <Redirect
      to={{
        pathName: '/',
        state: {
          inviteCode: code
        }
      }}
    />
  )
}
