import { useTabEnabled } from '../tabs/useTabEnabled'
import { useFlag } from '../../components/FeatureFlag/use-flag'
import { LDFlags } from '../../launchdarkly/flags'
import { useJoinablePartyEnabled } from './use-party-enabled'

export const useServerStartsATabEnabled = (): boolean => {
  const tabEnabled = useTabEnabled()
  const groupOrderingEnabled = useJoinablePartyEnabled()
  const pinRequired = useFlag(LDFlags.OPT_PARTY_PINS)
  return tabEnabled && groupOrderingEnabled && !pinRequired
}
