import React from 'react'
import { PropTypes } from 'prop-types'

import { combineCartChecks } from '../../utils/check-helpers'
import Dollars from '../Dollars/Dollars'
import CartButton from '../CartButton/CartButton'

export const PartyCartButton = ({ carts = [], tip = null, to, title }) => {
  const combinedCartsAsCheck = combineCartChecks(
    carts.flatMap((c) => c.order.checks)
  )
  const subtotal = combinedCartsAsCheck?.preDiscountedSubtotal
  const numOfSelections = combinedCartsAsCheck?.numberOfSelections

  if (!numOfSelections) {
    return null
  }

  return (
    <CartButton
      testId='button-checkout-party-cart'
      to={to}
      left={
        <span>
          {title} ({numOfSelections})
        </span>
      }
      right={<Dollars amount={subtotal} />}
    />
  )
}

PartyCartButton.propTypes = {
  me: PropTypes.object.isRequired,
  carts: PropTypes.arrayOf(PropTypes.object),
  tip: PropTypes.number,
  title: PropTypes.string.isRequired,
  to: PropTypes.string
}
