import React from 'react'

import { Notification } from '../../Notification/Notification'
import { useRestaurant } from '../../RestaurantProvider/RestaurantProvider'
import { useCart } from '../../CartProvider/CartProvider'

import styles from './CartModificationError.module.css'

const actionContent = (
  <div className={styles.cartModificationAction}>Select a new item</div>
)

export const MODIFICATION_ERROR_SOURCE_REORDER = 'REORDER'

const translate = ({ message, source }) => {
  const matchesMessage = message.match.bind(message)

  switch (source) {
    case MODIFICATION_ERROR_SOURCE_REORDER:
      if (
        matchesMessage(
          /Items in your order are unavailable and were not added to your cart/
        )
      ) {
        return 'Items in your reorder are unavailable.'
      } else if (matchesMessage(/Some items were unavailable/)) {
        return 'Some items in your reorder are unavailable.'
      } else if (
        matchesMessage(/is unavailable and was not added to your cart/)
      ) {
        return message.replace(
          /(\w+) and was not added to your cart/,
          '$1 from your reorder.'
        )
      }
      return message
    default:
      return message
  }
}

export const CartModificationError = () => {
  const { getRestaurantPath } = useRestaurant()
  const {
    cartState: { modificationErrors }
  } = useCart()

  const displayErrors = modificationErrors.map((error) => {
    return (
      <div key={error.id}>
        {translate(error)}
        {error.source === MODIFICATION_ERROR_SOURCE_REORDER && (
          <span className={styles.hideOnMobile}> Select another item.</span>
        )}
      </div>
    )
  })

  return (
    modificationErrors.length > 0 && (
      <div className={styles.CartModificationError}>
        <Notification
          variant='warning'
          isActionable
          actionLink={getRestaurantPath('/')}
          actionContent={actionContent}
        >
          {displayErrors}
        </Notification>
      </div>
    )
  )
}
