import React from 'react'
import { Skeleton } from '@toasttab/buffet-pui-loading-indicators'

const TabLoader = () => (
  <div
    role='progressbar'
    aria-busy
    className='flex flex-col items-center mx-6 space-y-4'
  >
    <Skeleton className='h-6 w-36' />
    <div className='border border-solid rounded-lg w-full'>
      <div className='flex justify-between border-solid border-0 border-b p-4'>
        <Skeleton className='h-4 w-32' />
        <Skeleton className='h-4 w-12' />
      </div>
      <div className='flex justify-between p-4'>
        <Skeleton className='h-4 w-32' />
        <Skeleton className='h-4 w-12' />
      </div>
    </div>
  </div>
)

export default TabLoader
