import React, { useEffect } from 'react'
import cx from 'classnames'

import {
  GetCheckLineItemsParams,
  useLineItems
} from '../../utils/check-helpers'
import { useGetTotalsRemaining } from '../PartyQuery/PartyQuery'
import Loading from '../Loading/Loading'
import { useJoinablePartyEnabled } from '../../hooks/party/use-party-enabled'
import { InfoTooltip } from '@toasttab/buffet-pui-tooltip'

import styles from './CheckFooter.module.css'
import { CTA, FieldLabel } from '@/il8n/en'
import { FormatCurrency } from '../Currency/FormatCurrency'
import { SurchargeSummaryForPaymentTypes } from '../../apollo/generated/OptWebGraphQLOperations'
import { getCreditSurchargeFromCheck } from '../CheckoutForm/checkout-utils'
import { useSurchargingEnabled } from '../../hooks/surcharging/useSurchargingEnabled'
import { SurchargeDisclaimer } from '../SurchargeDisclaimer/SurchargeDisclaimer'

interface AmountCellProps {
  loading?: boolean
  amount: number
  hasGreenText?: boolean
  hasBlueText?: boolean
  showLeadingSign?: boolean
  className?: string
}

const AmountCell = ({
  loading = false,
  amount,
  hasGreenText = amount < 0.0,
  hasBlueText = false,
  showLeadingSign = false,
  className = ''
}: AmountCellProps) => (
  <td className={`align-top ${className}`}>
    {loading ? (
      <div className='flex justify-end'>
        <div className='opacity-50'>
          <Loading variant='secondary' />
        </div>
      </div>
    ) : (
      <>
        <div
          className={cx([
            { [styles.greenLineItem]: hasGreenText },
            { [styles.blueLineItem]: hasBlueText }
          ])}
        >
          <FormatCurrency
            amount={Number(amount)}
            showLeadingSign={showLeadingSign}
          />
        </div>
      </>
    )}
  </td>
)

// phrase must be at least two words separated by a space
function splitLastWord(phrase: string): [string, string] {
  const words = phrase
    // trim any trailing spaces
    .trim()
    .split(' ')
  const lastWord = words.splice(-1)[0] ?? ''
  return [words.join(' ').concat(' '), lastWord]
}

interface CheckFooterProps {
  check: GetCheckLineItemsParams['check']
  totalMode: GetCheckLineItemsParams['totalMode']
  tip?: GetCheckLineItemsParams['tip']
  showRemainingBalance?: boolean
  usingSpi?: boolean
}

export const CheckFooter = ({
  check,
  totalMode,
  tip = null,
  showRemainingBalance = false,
  usingSpi = false
}: CheckFooterProps) => {
  const joinablePartiesEnabled = useJoinablePartyEnabled()
  const { remainingTotal, refetch, loading } = useGetTotalsRemaining()
  const surchargingEnabled = useSurchargingEnabled(usingSpi)

  useEffect(() => {
    if (showRemainingBalance && refetch) {
      refetch()
    }
  }, [showRemainingBalance, refetch])

  const lineItems = useLineItems({
    check,
    tip,
    totalMode
  })

  const checkHasGratuityServiceCharges =
    check?.appliedServiceCharges?.some(
      (charge) => charge.serviceChargeDetails?.gratuity
    ) ?? false

  const isDiscount = (label: string, id: string) => {
    return (
      label === FieldLabel.PROMO_APPLIED || id === 'loyalty-discount-amount'
    )
  }

  const surcharge: SurchargeSummaryForPaymentTypes | undefined =
    surchargingEnabled ? getCreditSurchargeFromCheck(check) : undefined
  const surchargeRatePercentage = (surcharge?.surchargeRate ?? 0) * 100

  return (
    <div className={styles.footer} data-testid='total-amounts'>
      <table data-testid='check-table'>
        <tfoot>
          {lineItems.map(({ label, amount, id }) => {
            const showServiceChargeInfoIcon =
              id.includes('ubp-service-charge') && totalMode !== 'NONE'
            const [labelWithoutLastWord, lastWord] = splitLastWord(label)

            return (
              <tr
                data-testid={id}
                key={id}
                className={cx(
                  {
                    [styles.total]:
                      id === 'total-order-remaining-amount' ||
                      id === 'total-order-amount' ||
                      id === 'your-total-order-amount' ||
                      id === 'group-total-order-amount' ||
                      id.startsWith('other-payment') ||
                      id === 'amount-due'
                  },
                  'hover:bg-transparent'
                )}
              >
                {checkHasGratuityServiceCharges && label === 'Tip' ? (
                  <td className='pb-1 align-top'>Additional Tips{'\t'}</td>
                ) : (
                  <td className='pb-1 align-text-top'>
                    <div>
                      {
                        <span
                          className={cx({
                            [styles.blueLineItem]: isDiscount(label, id)
                          })}
                        >
                          {labelWithoutLastWord}
                        </span>
                      }
                      <span className='inline-block'>
                        {
                          <span
                            className={cx({
                              [styles.blueLineItem]: isDiscount(label, id)
                            })}
                          >
                            {lastWord}
                          </span>
                        }
                        {showServiceChargeInfoIcon && (
                          <InfoTooltip
                            className={cx(
                              styles.infoToolTip,
                              'bg-transparent border-none pl-3'
                            )}
                            cropToIcon
                          >
                            {CTA.UBP_SERVICE_CHARGE_TOOLTIP}
                          </InfoTooltip>
                        )}
                      </span>
                    </div>
                  </td>
                )}
                <AmountCell
                  amount={amount}
                  hasBlueText={isDiscount(label, id)}
                />
              </tr>
            )
          })}
        </tfoot>
      </table>
      {showRemainingBalance && remainingTotal > 0 && (
        <>
          <div className='w-full h-4 mt-4 border-t' />
          <table>
            <tr className={cx(styles.total)}>
              <td>Remaining Balance</td>
              <AmountCell
                loading={joinablePartiesEnabled && loading}
                amount={remainingTotal}
              />
            </tr>
          </table>
        </>
      )}
      {surcharge && surcharge.surchargePreTaxAmount > 0 && (
        <>
          <div className='w-full h-6 mt-6 border-x-0 border-b-0 border-t-2 border-solid' />
          <table>
            <tr data-testid='surcharge'>
              <td>
                {FieldLabel.CHECKOUT_SURCHARGE_LABEL} ({surchargeRatePercentage}
                %)
              </td>
              <AmountCell
                loading={loading}
                amount={
                  surcharge.surchargePreTaxAmount + surcharge.surchargeTaxAmount
                }
                showLeadingSign
                className='font-bold'
              />
            </tr>
          </table>
          <SurchargeDisclaimer
            surchargeRatePercentage={surchargeRatePercentage}
            surchargeAmount={
              surcharge.surchargePreTaxAmount + surcharge.surchargeTaxAmount
            }
          />
        </>
      )}
    </div>
  )
}
