import * as React from 'react'
import { useUpdateCart } from 'cornucopia-apis'
import { Stepper } from './Stepper'

interface QuickAddItemProps {
  guid: string
  itemGroupGuid: string
  menuItem?: QuickAddSelection
  outOfStock: boolean
}

export interface QuickAddSelection {
  quantity: number
  itemGroupGuid: string
  selections: {
    selectionGuid: string
    selectionQuantity: number
  }[]
}

export const QuickAddItem = ({
  guid,
  menuItem,
  itemGroupGuid,
  outOfStock
}: QuickAddItemProps) => {
  const updateCart = useUpdateCart()
  const [loading, setLoading] = React.useState(false)

  const firstSelection = menuItem && menuItem.selections[0]

  const handleAdd = () => {
    setLoading(true)
    updateCart({
      itemGuid: guid,
      itemGroupGuid,
      quantity: 1,
      modifierGroups: [],
      specialInstructions: ''
    }).subscribe(() => {
      setLoading(false)
    })
  }

  const handleIncrement = () => {
    if (firstSelection) {
      setLoading(true)
      return updateCart(
        {
          itemGuid: guid,
          itemGroupGuid,
          quantity: firstSelection.selectionQuantity + 1,
          modifierGroups: [],
          specialInstructions: ''
        },
        firstSelection.selectionGuid
      ).subscribe(() => {
        setLoading(false)
      })
    }
  }

  const handleDecrement = () => {
    if (firstSelection && firstSelection.selectionQuantity > 1) {
      setLoading(true)
      return updateCart(
        {
          itemGuid: guid,
          itemGroupGuid,
          quantity: firstSelection.selectionQuantity - 1,
          modifierGroups: [],
          specialInstructions: ''
        },
        firstSelection.selectionGuid
      ).subscribe(() => {
        setLoading(false)
      })
    }
  }

  const handleDelete = () => {
    if (firstSelection) {
      setLoading(true)
      updateCart(null, firstSelection.selectionGuid).subscribe(() => {
        setLoading(false)
      })
    }
  }

  return (
    <Stepper
      loading={loading}
      onAdd={firstSelection ? handleIncrement : handleAdd}
      onRemove={
        firstSelection && firstSelection.selectionQuantity > 1
          ? handleDecrement
          : handleDelete
      }
      quantity={menuItem?.quantity || 0}
      outOfStock={outOfStock}
    />
  )
}
