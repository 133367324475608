import React from 'react'
import { useAuth } from '../components/AuthProvider/AuthProvider'
//@ts-ignore
import { track } from '@toasttab/do-secundo-analytics'
import { toastAuthNotification } from '../components/ToastNotification/ToastNotification'

export const useAuthEventsTracking = () => {
  const auth = useAuth()
  //This represents legacy pw- guests that dont have a first/last name
  const [legacyPasswordlessLogin, setLegacyPasswordlessLogin] =
    React.useState(false)

  const trackAccountVerificationEvent = () => {
    track('Successful Code Submission')
    if (
      auth.authClient?.userInfo &&
      auth.authClient?.userInfo?.isProfileCreated !== false
    ) {
      if (
        auth.authClient.userInfo.guid &&
        auth.apolloClient?.cache?.data?.data?.[auth.authClient.userInfo.guid]
          ?.firstName === null
      ) {
        track('Legacy Pw- Login Attempt')
        setLegacyPasswordlessLogin(true)
      } else {
        track('Successful Log In')
        toastAuthNotification('Logged in')
      }
    }
  }

  const trackCompleteIdentity = () => {
    if (legacyPasswordlessLogin) {
      track('Legacy Pw- Login Success')
      toastAuthNotification('Logged in')
    } else {
      track('New Pw- Account Created')
      toastAuthNotification('Account Created')
    }
  }

  const trackSendCodeEvent = () => {
    track('Send SMS Code')
  }

  const trackFailedCodeEntry = (message: string) => {
    track('Failed Code Entry', { message })
  }

  return {
    trackAccountVerificationEvent,
    trackCompleteIdentity,
    trackFailedCodeEntry,
    trackSendCodeEvent
  }
}
