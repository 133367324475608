import React from 'react'
import PropTypes from 'prop-types'

import styles from './OrderLayout.module.css'

export const OrderLayout = ({ body, footer, error }) => {
  return (
    <>
      {error}
      <>
        {Boolean(body) && <div className={styles.long}>{body}</div>}
        {Boolean(footer) && <div className={styles.sticky}>{footer}</div>}
      </>
    </>
  )
}

OrderLayout.propTypes = {
  body: PropTypes.node,
  footer: PropTypes.node,
  error: PropTypes.node
}
