/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import MaskedInput from 'react-text-mask'

import Warning from '../../../assets/warning.svg'
import ErrorMessage from '../ErrorMessage/ErrorMessage'

import styles from './Input.module.css'
import { useEffect } from 'react'
import { useRef } from 'react'

const CustomInput = ({ mask, focusOnInputMount, ...props }) => {
  const inputElRef = useRef(null)
  const initialFocusOnMountRef = useRef(focusOnInputMount)

  useEffect(() => {
    if (initialFocusOnMountRef.current) {
      inputElRef?.current?.focus()
    }
  }, [])

  return mask ? (
    <MaskedInput mask={mask} {...props} />
  ) : (
    <input {...props} ref={inputElRef} />
  )
}

const Input = ({
  field,
  form,
  label,
  id,
  type,
  inputMode,
  name,
  endAdornment,
  noAdornmentMargin = false,
  className,
  disabled,
  instructions = '',
  readOnly,
  compact = false,
  fsUnmask = false,
  ...extraProps
}) => {
  const [focus, setFocus] = useState(false)
  const error = form.errors[field.name] && form.touched[field.name]
  const endAdornmentNode = endAdornment && (
    <span className={styles.endAdornment}>{endAdornment}</span>
  )

  return (
    <>
      <div
        className={cx(styles.adornmentWrapper, {
          [styles.focus]: focus,
          [styles.filled]: field.value?.length,
          [styles.error]: error,
          [styles.disabled]: disabled,
          [styles.noAdornmentMargin]: noAdornmentMargin,
          [styles.readOnly]: readOnly
        })}
      >
        <div
          data-testid={`${id}-container`}
          className={cx(styles[className], styles.wrapper)}
        >
          <label className={styles.label} htmlFor={id}>
            {label}
          </label>
          <CustomInput
            onFocus={() => setFocus(true)}
            className={cx(styles.input, fsUnmask ? '' : 'fs-mask')}
            type={type}
            inputMode={inputMode}
            id={id}
            disabled={disabled}
            readOnly={readOnly}
            {...field}
            {...extraProps}
            onBlur={(...e) => {
              field.onBlur(...e)
              setFocus(false)
            }}
          />
        </div>
        {endAdornmentNode ||
          (error && (
            <span className={styles.endAdornment}>
              <Warning className={styles.warning} />
            </span>
          ))}
      </div>
      {!focus && error ? (
        <div
          data-testid='error-message'
          className={cx(styles.errorMessage, {
            [styles.hidden]: compact && !error
          })}
        >
          <ErrorMessage name={field.name} />
        </div>
      ) : (
        <div
          data-testid='instructions'
          className={cx('type-subhead pt-0.5 h-6', {
            [styles.hidden]: focus || (compact && !instructions)
          })}
        >
          {instructions}
        </div>
      )}
    </>
  )
}

const FieldShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func
})

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  form: PropTypes.object,
  field: FieldShape.isRequired,
  type: PropTypes.string.isRequired,
  endAdornment: PropTypes.node,
  noAdornmentMargin: PropTypes.bool,
  instructions: PropTypes.node
}

export default Input
