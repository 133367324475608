import { useCallback } from 'react'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { useGetPaidItems } from '../../components/PartyQuery/PartyQuery'

export const useDetectPaymentAlert = (): [boolean, () => void] => {
  const { paidItems } = useGetPaidItems()
  const { updateSupplementalPartyProperties, itemsPaid } = useParty()

  const handleAcknowledgement = useCallback(() => {
    updateSupplementalPartyProperties({
      itemsPaid: paidItems.length
    })
  }, [updateSupplementalPartyProperties, paidItems.length])

  const requiresAcknowledgement =
    itemsPaid !== paidItems.length && paidItems.length > 0

  return [requiresAcknowledgement, handleAcknowledgement]
}
