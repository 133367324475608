import * as React from 'react'
import { splitPaymentRequest$ } from 'cornucopia-apis'
import { useSentry } from 'banquet-runtime-modules'
import { SplitMode } from '../../../../client/apollo/generated/OptWebGraphQLOperations'
import { useGetPartyRefresh } from '../../../../client/components/PartyQuery/PartyQuery'
import { useApplySplitType } from '../../../../client/hooks/split-payments/use-apply-split-type'
import { ResponseTypes } from '../cornucopia.types'
import { toastError } from '../ListenerError'
import { SplitRequest } from './SplitPaymentListener.types'

export const SplitPaymentListener = () => {
  const { captureMessage } = useSentry()
  const { applySplitType } = useApplySplitType()
  const { refetch } = useGetPartyRefresh()

  const handleSplit = React.useCallback(
    async (response: any) => {
      await applySplitType({
        onComplete: async () => {
          await refetch()
          response.next({ kind: ResponseTypes.OK })
        },
        onError: (error: any) => {
          toastError(
            'An error occurred attempting to split the check. Please try again.'
          )
          captureMessage(error.message, 'warning')
          response.next({
            kind: ResponseTypes.ERROR,
            message: 'Unknown error',
            code: '400'
          })
        },
        splitPaymentInitializationData: {
          splitMode: SplitMode.MyItems,
          evenSplitPortions: 0
        },
        overrideSplit: false
      })
    },
    [applySplitType, refetch, captureMessage]
  )

  React.useEffect(() => {
    const subscription = splitPaymentRequest$.subscribe(
      async (request: SplitRequest) => {
        const {
          header: { response$ }
        } = request

        const partyResponse = await refetch()

        const newPartyRefresh =
          partyResponse?.data.optPartyRefresh.__typename === 'OPTPartyRefreshV2'
            ? partyResponse?.data.optPartyRefresh
            : undefined

        if (!newPartyRefresh) {
          return response$.next({
            kind: ResponseTypes.ERROR,
            message: 'Unknown error',
            code: '400'
          })
        }
        const isClosed = newPartyRefresh.order?.isClosed
        const alreadySplit = newPartyRefresh.splitPaymentData

        if (isClosed || alreadySplit) {
          response$.next({
            kind: ResponseTypes.ERROR,
            message: 'Operation already done',
            code: '409'
          })
        } else {
          handleSplit(response$)
        }
      }
    )

    return () => subscription.unsubscribe()
  }, [handleSplit, refetch])
  return <></>
}
