import {
  OptPartyError,
  SplitPaymentInitializationDataInput,
  useOpt_Apply_Split_TypeMutation
} from '../../apollo/generated/OptWebGraphQLOperations'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { ErrorText } from '@/il8n/en'
import {
  useGetPartyRefresh,
  usePriceSplitCheck
} from '../../components/PartyQuery/PartyQuery'
import { NetworkStatus } from '@apollo/client'
import { useSentry } from 'banquet-runtime-modules'

interface ApplySplitTypeInput {
  onComplete(): void
  onError(error: OptPartyError): void
  setUserFacingError?: (value: string) => void
  splitPaymentInitializationData: SplitPaymentInitializationDataInput
  overrideSplit: boolean
  newPortionsToBePaid?: number
  newTotalPortions?: number
}

export const useApplySplitType = () => {
  const [applySplitTypeMutation, { loading: applySplitTypeLoading, error }] =
    useOpt_Apply_Split_TypeMutation()
  const { captureMessage } = useSentry()
  const {
    partyGuid,
    partyMemberGuid,
    memberAuthToken,
    portionsToBePaid: currentPortionsToBePaid,
    updateSupplementalPartyProperties
  } = useParty()
  const {
    refetch: refetchPartyRefresh,
    networkStatus: partyRefreshNetworkStatus
  } = useGetPartyRefresh()
  const {
    refetch: refetchPriceSplitCheck,
    networkStatus: priceSplitCheckNetworkStatus
  } = usePriceSplitCheck({ notifyOnNetworkStatusChange: true })
  const refetching =
    partyRefreshNetworkStatus === NetworkStatus.refetch ||
    partyRefreshNetworkStatus === NetworkStatus.setVariables ||
    priceSplitCheckNetworkStatus === NetworkStatus.refetch ||
    priceSplitCheckNetworkStatus === NetworkStatus.setVariables

  const applySplitType = async ({
    onComplete,
    onError,
    setUserFacingError,
    splitPaymentInitializationData,
    overrideSplit,
    newPortionsToBePaid,
    newTotalPortions
  }: ApplySplitTypeInput) => {
    if (!partyGuid || !partyMemberGuid || !memberAuthToken) {
      captureMessage(
        'Missing one of partyGuid, partyMemberGuid, memberAuthToken',
        'warning'
      )
      setUserFacingError && setUserFacingError(ErrorText.MISSING_PARTY_DATA)
      return
    }

    applySplitTypeMutation({
      variables: {
        input: {
          partyGuid,
          partyMemberGuid,
          memberAuthToken,
          splitPaymentInitializationData,
          overrideSplit
        }
      }
    })
      .then(async (result) => {
        const applySplitTypeError =
          result.data?.optPartyRefresh.__typename === 'OPTPartyError'
            ? result.data.optPartyRefresh
            : undefined

        if (applySplitTypeError) {
          await refetchPartyRefresh()
          onError(applySplitTypeError)
        } else {
          if (overrideSplit) {
            await refetchPartyRefresh()
          }
          if (
            newPortionsToBePaid &&
            newPortionsToBePaid !== currentPortionsToBePaid
          ) {
            updateSupplementalPartyProperties({
              portionsToBePaid: newPortionsToBePaid
            })
          }
          updateSupplementalPartyProperties({
            totalPortions: newTotalPortions
          })
          await refetchPriceSplitCheck()
          onComplete()
        }
      })
      .catch((err: unknown) => {
        onError(err as Error)
      })
  }

  return {
    applySplitType,
    loading: applySplitTypeLoading || refetching,
    error
  }
}
