import { onError } from '@apollo/client/link/error'

import { promiseToObservable } from '../../utils/apollo-helpers'
import { refreshToken } from '../authentication/authentication-helpers'

const getErrorLink = (config) =>
  onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let { message, locations, path, extensions } of graphQLErrors) {
        // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-error#retrying-failed-requests
        if (extensions.code === 'UNAUTHENTICATED') {
          const originalHeaders = operation.getContext().headers
          const promise = refreshToken(config)
          return promiseToObservable(promise).flatMap((newToken) => {
            if (!newToken) {
              return []
            }
            // retry the request, returning the new observable
            const headers = {
              ...originalHeaders,
              'toast-customer-access': newToken
            }
            operation.setContext({ headers })
            return forward(operation)
          })
        }
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      }
    }

    if (networkError) {
      console.log('[Network error]: ', networkError)
    }
  })

export default getErrorLink
