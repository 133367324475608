import { useCallback, useRef } from 'react'
import { useRestaurant } from '../../../components/RestaurantProvider/RestaurantProvider'
import { useHistory } from 'react-router'
import { useCart } from '../../../components/CartProvider/CartProvider'
import { OptOrderGuidFragment } from '../../../apollo/generated/OptWebGraphQLOperations'
import { trackPurchase } from '../../../utils/track-ecommerce'
import { AuthUser } from '../../../components/AuthProvider/authUser'
import { getRawPhoneNumber } from '../../../utils/form-utils'
import { useUpdateBasicInfo } from '../../useUpdateBasicInfo'
import { useGuestInfo } from '../../use-guest-info'
import { useAuth } from '../../../components/AuthProvider/AuthProvider'
import { useTabEnabled } from '../../tabs/useTabEnabled'
import { useParty } from '../../../components/PartyProvider/PartyProvider'
import {
  CheckStatus,
  combineChecks
} from '../../../utils/check-helpers/check-helpers'
import { useGetPartyMode } from '../../../components/PartyQuery/PartyQuery'
import { DDIMode } from '../../../types/DDIGlobals'
import { CheckoutType, LoyaltyMode } from './use-handle-party-closeout'
import { useSentry } from 'banquet-runtime-modules'

function useRedirectConfirm(): (
  orderGuid: string,
  checkGuid: string,
  loyaltyMode: LoyaltyMode
) => void {
  const { getRestaurantPath } = useRestaurant()
  const { replace } = useHistory()

  return useCallback(
    (orderGuid: string, checkGuid: string, loyaltyMode: LoyaltyMode) => {
      replace({
        pathname: getRestaurantPath(`confirm/${orderGuid}/${checkGuid}`),
        search: `?loyaltyMode=${loyaltyMode}`
      })
    },
    [replace, getRestaurantPath]
  )
}

function useCleanupLocalStorageValues(): () => void {
  const { deleteCartGuid } = useCart() as { deleteCartGuid: () => void }
  return useCallback(() => {
    deleteCartGuid()
  }, [deleteCartGuid])
}

type UseUpdateBasicInfoAfterCheckoutResult = (
  completedOrder: OptOrderGuidFragment
) => void
function useUpdateBasicInfoAfterCheckout(): UseUpdateBasicInfoAfterCheckoutResult {
  const [updateBasicInfo] = useUpdateBasicInfo()
  const { captureException } = useSentry()
  const tabEnabled = useTabEnabled()
  const { authenticated, user } = useAuth() as {
    user: AuthUser | null
    authenticated: boolean
  }
  const { updateGuestInfoFromCustomer, guestName, guestLastName, guestPhone } =
    useGuestInfo()

  return useCallback(
    async (completedOrder) => {
      const customer = combineChecks(completedOrder.checks)?.customer

      // when tab is enabled, customer info is updated when rounds are fired, not at checkout.
      // useAfterCheckout should only be used for PAYG, but split payments uses it for now.
      if (!tabEnabled && customer) {
        updateGuestInfoFromCustomer({ ...customer })
      }

      if (
        authenticated &&
        (!user || !user.firstName || !user.lastName || !user.phone)
      ) {
        try {
          await updateBasicInfo({
            firstName: user?.firstName || guestName || customer?.firstName,
            lastName: user?.lastName || guestLastName || customer?.lastName,
            phone: getRawPhoneNumber(
              user?.phone || guestPhone || customer?.phone
            )
          })
        } catch (e: any) {
          captureException(e)
        }
      }
    },
    [
      authenticated,
      guestLastName,
      guestName,
      guestPhone,
      tabEnabled,
      updateBasicInfo,
      updateGuestInfoFromCustomer,
      user,
      captureException
    ]
  )
}

function getEventLabel(
  authenticated: boolean,
  customerGuid?: string
): 'new account' | 'authenticated' | 'unauthenticated' {
  if (customerGuid) {
    return 'new account'
  }
  return authenticated ? 'authenticated' : 'unauthenticated'
}

function trackPurchaseFromCompletedOrder(
  completedOrder: OptOrderGuidFragment,
  authenticated: boolean,
  customerGuid?: string,
  checkoutType?: CheckoutType
): void {
  const { guid, checks } = completedOrder
  const combinedCheck = combineChecks(checks, CheckStatus.CLOSED)

  // no-op if cannot find check
  if (!combinedCheck) {
    return
  }
  const { selections, total, tax } = combinedCheck
  const eventLabel = getEventLabel(authenticated, customerGuid)

  trackPurchase(
    selections.map((s) => ({
      id: s?.itemGuid,
      name: s?.name,
      price: s?.price,
      quantity: s?.quantity
    })),
    {
      id: guid,
      revenue: total,
      tax: tax,
      option: eventLabel,
      checkoutType
    }
  )
}

export type AfterCheckoutCallbackV2 = (
  completedOrder: OptOrderGuidFragment,
  checkoutType: CheckoutType,
  checkGuid: string | undefined,
  loyaltyMode: LoyaltyMode
) => void
export const useAfterCheckout = (): AfterCheckoutCallbackV2 => {
  const redirectToConfirm = useRedirectConfirm()
  const cleanupLocalStorageValues = useCleanupLocalStorageValues()
  const { updateSupplementalPartyProperties } = useParty()
  const { mode } = useGetPartyMode()
  const { authenticated, user } = useAuth() as {
    authenticated: boolean
    user: { guid?: string } | null
  }
  const updateInfoAfterCheckout = useUpdateBasicInfoAfterCheckout()

  const completedOrderGuidRef = useRef<string | undefined>()
  const completedOrderCheckGuidRef = useRef<string | undefined>()
  return useCallback(
    async (completedOrder, checkoutType, checkGuid, loyaltyMode) => {
      // Ensure that after checkout callback is completed only once per completed order guid
      // NOTE: We may later use something like the payment guid instead to track this
      if (checkGuid === undefined) {
        checkGuid = completedOrder.checkGuid
      }
      if (
        completedOrderGuidRef.current === completedOrder.guid &&
        completedOrderCheckGuidRef.current === checkGuid
      ) {
        return
      }
      completedOrderGuidRef.current = completedOrder.guid
      completedOrderCheckGuidRef.current = checkGuid
      trackPurchaseFromCompletedOrder(completedOrder, authenticated, user?.guid)
      updateInfoAfterCheckout(completedOrder)
      // Redirect must be called first to avoid re-rendering
      // the base page in between checkout and confirm pages
      redirectToConfirm(completedOrder.guid, checkGuid, loyaltyMode)
      if (mode === DDIMode.OPT || mode === DDIMode.TTS) {
        cleanupLocalStorageValues()
      }
      updateSupplementalPartyProperties({
        paygOrderGuid: completedOrder.guid
      })
    },
    [
      authenticated,
      cleanupLocalStorageValues,
      redirectToConfirm,
      updateInfoAfterCheckout,
      updateSupplementalPartyProperties,
      user?.guid,
      mode
    ]
  )
}
