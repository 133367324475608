import * as React from 'react'
import {
  OptCartFragment,
  OptPartyMemberV2
} from '../../apollo/generated/OptWebGraphQLOperations'
import { joinFieldByCommasWithAnd } from '../../utils/reducer-utils'
import { useGetMainCheck } from '../PartyQuery/PartyQuery'
import { getGuestsWithoutSelections } from './helpers'

export interface GuestsWithoutOrdersProps {
  carts: Pick<OptCartFragment, 'order' | 'guid'>[]
  me?: Pick<OptPartyMemberV2, 'partyMemberGuid'> | {}
  partyMembers: Pick<
    OptPartyMemberV2,
    'cartGuid' | 'partyMemberGuid' | 'name'
  >[]
}
/**
 * @param carts carts in current order
 * @param me current guest
 * @param partyMembers party members in group order
 * @returns component displaying list of guests who have not yet added to the order
 * only displays prior to opening tab and should not display in cart after tab started
 */
export const GuestsWithoutOrders = ({
  carts,
  me,
  partyMembers
}: GuestsWithoutOrdersProps) => {
  const { mainCheck } = useGetMainCheck()
  const guestsWithoutSelections = getGuestsWithoutSelections({
    carts,
    me,
    partyMembers
  })
  const amountOfEmptySelections = guestsWithoutSelections.length
  // guests with no selections AND there is no existing tab
  const showComponent = amountOfEmptySelections > 0 && !Boolean(mainCheck)
  const guestNames =
    amountOfEmptySelections > 4
      ? `${amountOfEmptySelections} people`
      : joinFieldByCommasWithAnd('name', guestsWithoutSelections)
  const verbForm = amountOfEmptySelections > 1 ? 'have' : 'has'

  return (
    <>
      {showComponent && (
        <div className='leading-loose text-center'>{`${guestNames} ${verbForm} not added items to the order yet.`}</div>
      )}
    </>
  )
}
