import React from 'react'
import cx from 'classnames'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import {
  SubtractCircleFillIcon,
  AddCircleFillIcon
} from '@toasttab/buffet-pui-icons'

export interface QuantityTickerProps {
  value: number
  minimum: number
  maximum: number
  onDecrement: () => void
  onIncrement: () => void
  disabled?: boolean
}

export const QuantityTicker = ({
  value,
  minimum,
  maximum,
  onDecrement,
  onIncrement,
  disabled = false
}: QuantityTickerProps) => {
  const decrementDisabled = value <= minimum
  const incrementDisabled = value >= maximum

  return (
    <div className='flex pl-2 items-center'>
      <IconButton
        icon={<SubtractCircleFillIcon size='md' />}
        onClick={onDecrement}
        disabled={decrementDisabled || disabled}
        cropToIcon
        className={cx('bg-transparent border-none', {
          'text-link': disabled
        })}
        testId='quantity-decrement'
      />
      <div className='font-medium type-large mx-4 w-5 text-center'>{value}</div>
      <IconButton
        icon={<AddCircleFillIcon size='md' />}
        onClick={onIncrement}
        disabled={incrementDisabled || disabled}
        cropToIcon
        className={cx('bg-transparent border-none', {
          'text-link': disabled
        })}
        testId='quantity-increment'
      />
    </div>
  )
}
