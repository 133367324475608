import React, { useCallback, useEffect, useMemo } from 'react'
import { useGiftCard } from '../../GiftCardProvider/GiftCardProvider'
import { useParty } from '../../PartyProvider/PartyProvider'
import { FormikProvider } from '../../FormikProvider/FormikProvider'

import styles from '../CheckoutForm.module.css'
import PropTypes from 'prop-types'
import { GiftCardNotification } from './GiftCardNotification/GiftCardNotification'
import { GiftCardPaymentContainer } from './GiftCardPaymentContainer/GiftCardPaymentContainer'
import { LinkButton } from '@toasttab/buffet-pui-buttons'
import { GiftCardIcon } from '@toasttab/buffet-pui-icons'
import { FieldLabel } from '@/il8n/en'

export const GiftCard = ({ validateForm, hasSufficientFunds }) => {
  const {
    applyGiftCard,
    clearGlobalGiftCard,
    clearRxGiftCard,
    rxGiftCard,
    globalGiftCard,
    rxGiftCardEnabled
  } = useGiftCard()
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()
  let rxGiftCardNumber = rxGiftCard?.cardNumber
  let globalGiftCardIdentifier = globalGiftCard?.cardIdentifier
  let expectedTotalAvailableBalance =
    (rxGiftCard?.expectedAvailableBalance || 0) +
    (globalGiftCard?.expectedAvailableBalance || 0)

  const buttonLabel = useMemo(() => {
    if (rxGiftCardEnabled) {
      if (globalGiftCard) {
        return FieldLabel.CHECKOUT_GIFT_CARD_CHECKBOX
      } else if (rxGiftCard) {
        return FieldLabel.CHECKOUT_TOAST_CASH_CHECKBOX
      } else {
        return FieldLabel.CHECKOUT_GIFT_CARD_AND_TOAST_CASH_CHECKBOX
      }
    } else {
      return FieldLabel.CHECKOUT_TOAST_CASH_CHECKBOX
    }
  }, [rxGiftCardEnabled, globalGiftCard, rxGiftCard])

  useEffect(() => {
    validateForm()
  }, [expectedTotalAvailableBalance, validateForm])

  const handleGiftCardSubmit = useCallback(
    (values) => {
      const cardNumber = /^[\d\s]+$/.test(values.giftCardInput) // Only run replacement on rx gift card format inputs
        ? values.giftCardInput.replace(/\D/g, '')
        : values.giftCardInput
      return applyGiftCard({
        variables: {
          input: {
            partyGuid,
            partyMemberGuid,
            memberAuthToken,
            cardIdentifier: cardNumber
          }
        }
      })
    },
    [applyGiftCard, partyGuid, partyMemberGuid, memberAuthToken]
  )

  return (
    <>
      <FormikProvider
        initialValues={{
          payWithGiftCard:
            Boolean(rxGiftCardNumber || globalGiftCardIdentifier) &&
            expectedTotalAvailableBalance > 0,
          giftCardInput: ''
        }}
        onSubmit={handleGiftCardSubmit}
      >
        {({ setFieldValue, values }) => {
          // note: this is required because we aren't allowed to access any
          // state created using useState or the like above while performing
          // the render of the FormikProvider, so we need this to clear the
          // form after a successful submission
          if (
            [rxGiftCard?.cardNumber, globalGiftCard?.cardIdentifier].includes(
              values.giftCardInput
            )
          ) {
            setFieldValue('giftCardInput', '')
          }
          return (
            <div className={styles.gcInput}>
              {!values.payWithGiftCard ? (
                <LinkButton
                  iconLeft={<GiftCardIcon />}
                  onClick={() => {
                    setFieldValue('payWithGiftCard', true)
                    clearRxGiftCard()
                    clearGlobalGiftCard()
                  }}
                >
                  {buttonLabel}
                </LinkButton>
              ) : null}
              <div className='pl-4 pr-3'>
                <GiftCardPaymentContainer
                  hasSufficientFunds={hasSufficientFunds}
                />
              </div>
            </div>
          )
        }}
      </FormikProvider>
      <GiftCardNotification hasSufficientFunds={hasSufficientFunds} />
    </>
  )
}

GiftCard.propTypes = {
  validateForm: PropTypes.func,
  mode: PropTypes.string,
  hasSufficientFunds: PropTypes.bool
}

export const getInitialValues = () => {
  return {}
}

export const getValidationSchema = () => {}

export { getArgsForSubmit } from './getArgsForSubmit/getArgsForSubmit'
