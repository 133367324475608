export enum CTA {
  JOIN_OR_CREATE_PARTY_CONFIRM_MEMBER_NAME = 'Start ordering',
  PREAUTH_ALREADY_EXISTS_START_ORDER = 'View your tab',
  PREAUTH_SAVE_PAYMENT_INFO = 'Start check & place order',
  ADD_TO_CART_SINGLE = 'Add to order',
  ADD_TO_CART_MANY = 'Add to order',
  EDIT_IN_CART_SINGLE = 'Update item',
  EDIT_IN_CART_MANY = 'Update items',
  NAV_FIRE_TAB_ROUND = 'Review order',
  FIRE_TAB_ROUND = 'Place order',
  FIRE_TAB_PREAUTH_ROUND = 'Continue to place order',
  FIRE_GROUP_ROUND = 'Place group order',
  FIRE_GROUP_PREAUTH_ROUND = 'Continue to place group order',
  FIRE_TAB_CONFIRM = 'Place order',
  FIRE_GROUP_CONFIRM_V2 = 'Place group order',
  FIRE_TAB_CONFIRM_V2 = 'Place your order',
  ORDER_MORE = 'Order more',
  PAY_CHECK = 'Pay check',
  NAV_TAB_NEW = 'Review order',
  NAV_TAB_CLOSED = 'Pay check',
  NAV_TAB_VIEW = 'View open check',
  NAV_TAB_CREATE_ANY_CONFIG = 'Continue to place order',
  NAV_CHECK_SUBTOTAL_VISIBLE = 'View open check',
  NAV_CHECK_SUBTOTAL_HIDDEN = 'View open check',
  CLOSE_CHECK_NO_SPLIT_PAY = 'Pay check',
  CLOSE_CHECK_MY_ITEMS = 'Pay for my items',
  CLOSE_CHECK_GROUP = 'Pay for the group',
  CLOSE_CHECK_SPLIT_EVENLY = 'Split evenly',
  CLOSE_CHECK_SELECT_PORTIONS = 'Select how much to pay',
  CLOSE_CHECK_PAY_MY_PART = 'Pay my part',
  CLOSE_CHECK_TOTAL = 'Pay check total',
  CLOSE_CHECK_NO_PAYMENT_PAYG = 'Place order',
  CLOSE_TAB = 'Close tab',
  CLOSE_CHECK_NO_PAYMENT_DUE = 'Close check',
  CREATE_TAB_CONFIRM = 'Place order & open check',
  CHECKOUT_PAYG_WITH_TAB_OPTION = 'Place order & pay',
  LOG_IN = 'Log in',
  LOGOUT = 'Log out',
  LOG_IN_OR_CREATE_ACCT = 'Log in or create account',
  LOG_IN_TO_TOAST = 'Log in to Toast',
  CREATE_ACCOUNT = 'Create account',
  FORGOT_PASSWORD = 'Forgot password',
  CREATE_ACCOUNT_AND_SAVE_CARD = 'Remember this card and create account',
  START_ORDER_AS_GUEST = 'Start order as guest',
  VIEW_THE_MENU = 'View the menu',
  DINING_AT_TABLE = 'TABLE',
  SEND_INSTRUCTIONS = 'Send instructions',
  SEARCH_MENU_PLACEHOLDER = 'Search for an item...',
  GO_BACK = 'Go back',
  LEARN_MORE = 'Learn more',
  RESET_PASSWORD_CHANGE = 'Change',
  UPDATE_MY_INFORMATION = 'Update my information',
  DELETE_SAVED_ADDRESS = 'Delete',
  DELETE_SAVED_CARD = 'Delete',
  UPDATE_MY_ADDRESS = 'Update my address',
  UPDATE_MY_CARD = 'Update my primary card',
  UPDATE_MY_PASSWORD = 'Update my password',
  BACK_TO_MENU = 'Back to menu',
  EDIT_FROM_CART = 'Edit',
  REMOVE_FROM_CART = 'Remove',
  CONFIRM_NEW_ORDER_CREATED = 'Start a new order',
  APPLY_GIFT_CARD_PAYMENT = 'Apply',
  APPLY_PROMO_CODE = 'Apply',
  CONFIRM_MODAL_CANCEL = 'Cancel',
  DELETE_CREDIT_CARD_CONFIRM = 'Delete credit card',
  GUEST_FEEDBACK_SUBMIT = 'Submit',
  MODIFIERS_CONTINUE = 'Continue',
  MODIFIERS_REMOVE = 'Remove item',
  PARTY_LOOKUP_ERROR_CONTINUE_TABLE = 'Continue to order at table',
  PARTY_INVITE_CONTINUE = 'Continue',
  PARTY_THIS_IS_MY_GROUP = 'Join this group',
  PARTY_CONFIRM_DENY_JOIN_PARTY = `Yes, I'm sure`,
  SPLASH_START_ORDER_AS_GUEST = 'Start order as guest',
  ORDER_AT_TABLE_FAIL_NOTIFICATION_RESCAN = `Scan table`,
  ORDER_AT_TABLE_FAIL_NOTIFICATION_REFRESH = `Refresh`,
  PAGE_SERVER = 'Submit',
  PARTY_INVITE_INVITE_OTHERS = 'Invite others',
  SCAN_QR_CODE = 'Scan QR code',
  SPOTLIGHT_MORE = 'More',
  VIEW_TAB_ICON_CONFIRM = 'Got it!',
  NAV_MY_ACCOUNT = 'My account',
  PAYMENT_ERROR_OUT_OF_SYNC_ACK = 'OK',
  PARTY_ALREADY_PAID_ACK = 'Done',
  PARTY_MEMBER_ALREADY_PAID_ACK = 'Done',
  PAYMENT_METHOD_DISALLOWED_ACK = 'OK',
  REORDER = 'Order again',
  CLAIM_ITEM = 'Claim item',
  THIS_ISNT_MINE = "This isn't mine",
  NO_SEARCH_RESULTS_1 = "We couldn't find anything based on your search.",
  NO_SEARCH_RESULTS_2 = 'Please try something else.',
  TRY_AGAIN = 'Try Again',
  WRONG_OR_EXPIRED_CODE = 'Wrong or expired code. Try again by reentering or resending the code.',
  MAXIMUM_NUMBER_OF_FAILED_SMS_ATTEMPTS = 'You have reached the maximum number of failed attempts. Please verify you have entered a valid sms code and valid phone number.',
  UBP_SERVICE_CHARGE_TOOLTIP = 'This fee helps Toast to cover its operational costs for supporting a digital ordering and payment platform.',
  SPLIT_EVEN = 'Split the bill',
  CONTINUE = 'Continue',
  APPLY_CHANGE = 'Apply change',
  PAY_HERE_LATER = 'Pay check here after you order',
  LOG_IN_SECONDARY_TEXT = 'Log into your Toast account to save order information and payment details across all of your favorite restaurants.',
  CREATE_ACCOUNT_SECONDARY_TEXT = 'Get your food faster. Save order info and payment details across all of your favorite restaurants with a Toast account.',
  CONTINUE_AS_GUEST = 'Continue as a guest',
  FAILED_LOGIN = "We're unable to log you into your account at this time. Please try again or continue as a guest below.",
  PHONE_LOGIN_NOT_SUPPORTED = 'Phone login is not available. Please enter a valid email.'
}
