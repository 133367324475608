import { MutationResult } from '@apollo/client'
import { useCallback } from 'react'

import {
  BasicInfoInput,
  Update_Basic_InfoMutation,
  useUpdate_Basic_InfoMutation
} from '../apollo/generated/OptWebGraphQLOperations'
import { useAuth } from '../components/AuthProvider/AuthProvider'
import { useGuestInfo } from './use-guest-info'

type UseUpdateBasicInfoHookResult = [
  updateBasicInfo: (
    basicInfoInput: BasicInfoInput
  ) => ReturnType<ReturnType<typeof useUpdate_Basic_InfoMutation>[0]>,
  mutationResult: MutationResult<Update_Basic_InfoMutation>
]

export const useUpdateBasicInfo = (): UseUpdateBasicInfoHookResult => {
  //@ts-ignore
  const { apolloClient } = useAuth()
  const [updateBasicInfo, result] = useUpdate_Basic_InfoMutation({
    client: apolloClient
  })
  const { updateGuestInfoFromCustomer } = useGuestInfo()

  const updateInfo = useCallback(
    (basicInfoInput: BasicInfoInput) => {
      const { firstName, lastName, phone } = basicInfoInput
      const filteredLastName = lastName !== 'DINE_IN' ? lastName : null
      const filteredPhone = phone !== '5555555555' ? phone : null

      //@ts-ignore
      updateGuestInfoFromCustomer({ firstName, lastName })

      return updateBasicInfo({
        variables: {
          input: {
            firstName,
            lastName: filteredLastName,
            phone: filteredPhone
          }
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateBasicInfo]
  )

  return [updateInfo, result]
}
