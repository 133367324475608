import { useCallback } from 'react'
import { useHistory } from 'react-router'
import { useParty } from '../PartyProvider/PartyProvider'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'

export const useLeaveParty = () => {
  const { deletePartyProperties } = useParty()
  return deletePartyProperties
}

export const useLeavePartyAndScan = () => {
  const leaveParty = useLeaveParty()
  const { getRestaurantPath } = useRestaurant()
  const history = useHistory()

  const qrScanPath = getRestaurantPath('/scan')

  return useCallback(() => {
    leaveParty()
    history.push(qrScanPath)
  }, [history, qrScanPath, leaveParty])
}
