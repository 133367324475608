import qs from 'qs'
import { Redirect, useLocation } from 'react-router-dom'
import React from 'react'

const getQueryProps = (search: string) => {
  return qs.parse(search, { ignoreQueryPrefix: true })
}

export const RejoinHandler = () => {
  const location = useLocation()
  const {
    pg: partyGuid,
    pmg: partyMemberGuid,
    mat: memberAuthToken,
    e: expiry
  } = getQueryProps(location.search)

  const isExpired =
    !expiry || isNaN(Number(expiry)) || Number(expiry) <= Date.now() / 1000

  if (!partyGuid || !partyMemberGuid || !memberAuthToken) {
    return null
  }

  const partyProperties = isExpired
    ? {}
    : {
        partyGuid,
        partyMemberGuid,
        memberAuthToken
      }
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          partyProperties
        }
      }}
    />
  )
}
