import { useState, useEffect, useCallback, useRef } from 'react'
import lodashGet from 'lodash/get'
import lodashSet from 'lodash/set'
import lodashUnset from 'lodash/unset'
import isNil from 'lodash/isNil'
import { getRawPhoneNumber } from './phone-number'

export const nameSpaces = {
  TOAST_OPT_RESTAURANT: 'toast-opt-restaurant',
  TOAST_OPT_AUTH: 'toast-opt-auth',
  TOAST_OPT_EXPERIMENTS: 'toast-opt-experiments',
  TOAST_OPT_CTA: 'toast-opt-cta'
}

// Namespaced LocalStorage cache wrapper
export const namespacedStorage = (namespace, storage = localStorage) => {
  if (!namespace) {
    throw new TypeError('namespacedStorage: missing namespace')
  }
  const getMap = () => JSON.parse(storage.getItem(namespace)) || {}
  const setMap = (fn) => {
    const map = getMap()
    fn(map)
    storage.setItem(namespace, JSON.stringify(map))
  }
  return {
    get: (key) => lodashGet(getMap(), key),
    set: (key, value) =>
      setMap((map) => {
        lodashSet(map, key, value)
      }),
    remove: (key) =>
      setMap((map) => {
        lodashUnset(map, key)
      })
  }
}

const getInitialValue = (key, initialValue, storage) => {
  const currentValue = storage.get(key)
  if (!isNil(storage.get(key))) {
    return currentValue
  }
  return initialValue
}

export const useStateWithNamespacedStorage = (key, initialValue, storage) => {
  const [stateValue, setState] = useState(() =>
    getInitialValue(key, initialValue, storage)
  )

  useEffect(() => {
    storage.set(key, stateValue)
  }, [key, stateValue, storage])

  return [stateValue, setState]
}

const createInitialExpiringValue = (value) => ({
  inceptionMillis: Date.now(),
  value
})

const getIsValueStorageExpired = (valueStorage, expirationDurationMillis) => {
  // check that valueStorage is formatted properly
  if (
    !valueStorage ||
    typeof valueStorage !== 'object' ||
    !('inceptionMillis' in valueStorage) ||
    typeof valueStorage.inceptionMillis !== 'number'
  ) {
    return true
  }
  const { inceptionMillis } = valueStorage
  const millisFromInception = Date.now() - inceptionMillis
  return millisFromInception > expirationDurationMillis
}

const defaultOptions = {
  /**
   * Controls whether to reset the inception
   * timestamp when the value has been updated
   */
  resetInceptionTimeOnUpdate: true
}
export const useExpiringStateWithNamespacedStorage = (
  key,
  initialValue,
  storage,
  expirationDurationMillis,
  options = defaultOptions
) => {
  const { resetInceptionTimeOnUpdate } = {
    ...defaultOptions,
    ...options
  }

  // avoids issues with dependencies seeing newly created initial values
  const initialExpiringValueRef = useRef(
    createInitialExpiringValue(initialValue)
  )
  initialExpiringValueRef.current = createInitialExpiringValue(initialValue)

  const [valueStorage, _setValue] = useStateWithNamespacedStorage(
    key,
    initialExpiringValueRef.current,
    storage
  )

  const isExpired = getIsValueStorageExpired(
    valueStorage,
    expirationDurationMillis
  )

  useEffect(() => {
    if (isExpired) {
      _setValue(initialExpiringValueRef.current)
    }
  }, [isExpired, _setValue])

  const setValue = useCallback(
    (value) => {
      _setValue((state) => {
        if (state.value === value) {
          return state
        }
        const newValue =
          typeof value === 'function' ? value(state.value) : value
        return {
          inceptionMillis: resetInceptionTimeOnUpdate
            ? Date.now()
            : state.inceptionMillis,
          value: newValue
        }
      })
    },
    [_setValue, resetInceptionTimeOnUpdate]
  )

  const valueAfterExpirationCheck = isExpired
    ? initialExpiringValueRef.current.value
    : valueStorage.value

  return [
    valueAfterExpirationCheck,
    setValue,
    {
      getRevalidatedStorageValues() {
        const isExpired = getIsValueStorageExpired(
          valueStorage,
          expirationDurationMillis
        )

        // trigger a reset for the consumer as well
        if (isExpired) {
          _setValue(initialExpiringValueRef.current)
        }

        return isExpired
          ? initialExpiringValueRef.current.value
          : valueStorage.value
      }
    }
  ]
}

/**
 * @typedef {Object} GetPropertyFromNamespacedStorage
 * @property {any} fallbackValue   -Will be used as the default value if no property is found
 * @property {string} nameSpace
 * @property {string} propertyPath
 * @property {function} onReturn   - Will call this function on return with the final value
 * @property {function} [onError]    - Will call this function if an unexpected error is thrown
 * @property {function} [namespacedStorageFunction]    - Will call this function with the namespace
 * @param {GetPropertyFromNamespacedStorage} GetPropertyFromNamespacedStorage
 * @returns function
 */
export const getPropertyFromNamespacedStorage =
  ({
    fallbackValue,
    nameSpace,
    propertyPath,
    onReturn,
    onError = () => {},
    namespacedStorageFunction = namespacedStorage
  }) =>
  () => {
    let value
    try {
      const storage = namespacedStorageFunction(nameSpace)
      value = storage.get(propertyPath)
    } catch (error) {
      onError(error)
    }
    return onReturn(value || fallbackValue)
  }

export const getUserPhoneFromLocalStorage = getPropertyFromNamespacedStorage({
  fallbackValue: '',
  nameSpace: nameSpaces.TOAST_OPT_AUTH,
  propertyPath: 'guestInfo.value.phone',
  onError: console.warn,
  onReturn: getRawPhoneNumber
})

export default namespacedStorage
