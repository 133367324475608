import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { FractionalQuantity } from '../Cart/FractionalQuantity/FractionalQuantity'
import { FormatCurrency } from '../.../../Currency/FormatCurrency'
import { OrderTableModifiersList } from './OrderTableModifiersList/OrderTableModifiersList'
import {
  useGetPartyMember,
  useGetMemberPayments,
  useGetPartyRefresh
} from '../PartyQuery/PartyQuery'
import { ReorderFromTabButton } from '../ReorderFromTabButton/ReorderFromTabButton'
import {
  OptCheckV2Fragment,
  SplitMode
} from '../../apollo/generated/OptWebGraphQLOperations'

import styles from './OrderTable.module.css'
import { formatProportion } from '../../utils/checkout-helpers'

export interface EditComponentProps {
  selectionGuid: string
  itemGuid?: string | null | undefined
  itemGroupGuid?: string | null | undefined
}
export interface CheckTableProps {
  check: Pick<OptCheckV2Fragment, 'selections' | 'numberOfSelections'>
  memberName?: string
  partyMemberGuid?: string
  editComponent?: React.ComponentType<EditComponentProps>
  crossThroughSelectionGuids?: string[]
  enableReorder?: boolean
  hasDivider?: boolean
  isOrderClosed?: boolean
}

export const CheckTable: React.ComponentType<CheckTableProps> = ({
  check,
  memberName,
  partyMemberGuid,
  editComponent: EditComponent,
  crossThroughSelectionGuids,
  enableReorder,
  hasDivider = true,
  isOrderClosed = false
}) => {
  const editable = Boolean(EditComponent)
  const showCart = Boolean(check?.numberOfSelections)
  const partyMember = useGetPartyMember()
  const memberPayments = useGetMemberPayments()
  const showReorder =
    enableReorder &&
    partyMember &&
    partyMemberGuid === partyMember.partyMemberGuid

  const { partyRefresh } = useGetPartyRefresh()
  const isSplitEven =
    partyRefresh?.splitPaymentData?.splitMode === SplitMode.Even

  // since members cannot have a mix of paid/unpaid items in their bucket, we know that if a member has paid, they've paid for everything.
  // BUT we don't want to display items as paid if the check has been split evenly
  const hasMemberPaid = useMemo(() => {
    return (
      memberPayments.some((member) => member.memberGuid === partyMemberGuid) &&
      !isSplitEven
    )
  }, [isSplitEven, memberPayments, partyMemberGuid])

  const { selections } = check || {}
  if (!selections) {
    return null
  }

  return (
    <>
      {Boolean(memberName) && (
        <>
          <div className=' w-full text-secondary font-semibold flex grid'>
            <span className=' col-span-1'>{memberName}</span>
            {hasMemberPaid && showCart && !isOrderClosed && (
              <span
                className={cx(
                  'col-span-1 col-start-2 text-right',
                  styles.greenText
                )}
              >
                PAID
              </span>
            )}
          </div>
          {hasDivider ? (
            <div
              className={
                'border-slate-500 border rounded-sm border-solid mb-2 mt-1'
              }
            />
          ) : null}
          {!showCart && <div className={styles.noItems}>No items!</div>}
        </>
      )}
      {showCart && (
        <table className={cx(styles.table, { [styles.readonly]: !editable })}>
          <tbody data-testid='cart-items'>
            {selections
              .filter((selection) => !selection.voided)
              .map((selection, index) => {
                if (!selection) {
                  return null
                }

                const persistedSelectionGuid =
                  selection.__typename === 'CheckSelectionGuid'
                    ? selection.guid
                    : undefined

                const nonPersistedSelectionExternalId =
                  selection.__typename === 'CheckSelectionNoGuid'
                    ? selection.externalId
                    : undefined

                const {
                  name,
                  price,
                  preDiscountPrice,
                  modifiers,
                  usesFractionalQuantity,
                  fractionalQuantity
                } = selection
                const key =
                  persistedSelectionGuid ||
                  nonPersistedSelectionExternalId ||
                  index

                const voided =
                  selection.__typename === 'CheckSelectionGuid'
                    ? selection.voided
                    : false

                const itemGuid = selection.itemGuid || null
                const itemGroupGuid = selection.itemGroupGuid || null

                const lineItemDiscount = selection.appliedDiscounts?.reduce(
                  (acc, discount) => {
                    return acc - (discount?.discountAmount || 0.0)
                  },
                  0.0
                )

                const isCrossThroughSelection =
                  crossThroughSelectionGuids?.some(
                    (id) => id === persistedSelectionGuid
                  )

                return (
                  <tr
                    data-testid='cart-item-body'
                    key={key}
                    className={cx({
                      [styles.strikeout]: isCrossThroughSelection
                    })}
                  >
                    <td data-testid='cart-item-quantity' valign='top'>
                      <span className={styles.number}>
                        {formatProportion(selection.quantity)}
                      </span>
                    </td>
                    <td data-testid='cart-item-info' className={styles.info}>
                      <span
                        data-testid='cart-item-name'
                        className={cx(styles.title, {
                          'inline-block': voided,
                          'line-through': voided
                        })}
                      >
                        {name}
                      </span>
                      {voided && (
                        <span className={cx(styles.title, styles.voided)}>
                          VOIDED
                        </span>
                      )}
                      {usesFractionalQuantity && (
                        <FractionalQuantity
                          quantity={fractionalQuantity.quantity}
                          unitOfMeasure={fractionalQuantity.unitOfMeasure}
                        />
                      )}
                      {Boolean(modifiers?.length) &&
                        !isCrossThroughSelection && (
                          <OrderTableModifiersList
                            editable={editable}
                            modifiers={modifiers}
                          />
                        )}
                      {!voided && EditComponent && (
                        <EditComponent
                          selectionGuid={
                            nonPersistedSelectionExternalId ||
                            persistedSelectionGuid ||
                            'NO_OP'
                          }
                          itemGuid={itemGuid}
                          itemGroupGuid={itemGroupGuid}
                        />
                      )}
                      {showReorder &&
                        persistedSelectionGuid &&
                        itemGuid &&
                        itemGroupGuid && (
                          <ReorderFromTabButton
                            selectionGuid={persistedSelectionGuid}
                            itemGuid={itemGuid}
                            itemGroupGuid={itemGroupGuid}
                          />
                        )}
                    </td>
                    <td
                      data-testid='item-price'
                      className={styles.number}
                      valign='top'
                    >
                      <div
                        data-testid='price'
                        className={cx({
                          [styles.greenText]: hasMemberPaid && !isOrderClosed
                        })}
                      >
                        <FormatCurrency
                          amount={
                            voided ? 0 : preDiscountPrice || price || undefined
                          }
                        />
                      </div>
                      {lineItemDiscount < 0 && (
                        <div data-testid='discount' className={styles.discount}>
                          <FormatCurrency amount={lineItemDiscount} />
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      )}
    </>
  )
}

CheckTable.propTypes = {
  editComponent: PropTypes.any,
  memberName: PropTypes.string,
  partyMemberGuid: PropTypes.string,
  enableReorder: PropTypes.bool
}
