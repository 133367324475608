import React from 'react'
import { DDIMode } from '../../types/DDIGlobals'
import { ShortenedUrlLookup } from '../../ShortenedUrlLookup/ShortenedUrlLookup'
import { ErrorComponent } from '../Error'
import { QRScannerApp } from './QRScannerApp'
import { AuthProviderPasswordless } from '../AuthProvider/AuthProviderPasswordless'
import OnlineOrderingApp from './OnlineOrderingApp'

const Application = () => {
  return (
    <AuthProviderPasswordless>
      <OnlineOrderingApp />
    </AuthProviderPasswordless>
  )
}

type AppSelectorProps = {
  mode: DDIMode
  uniqueIdentifier?: string
}

export const AppSelector = ({ mode, uniqueIdentifier }: AppSelectorProps) => {
  switch (mode) {
    case DDIMode.SHORTENED: {
      if (!uniqueIdentifier) {
        return <ErrorComponent error={'Not found'} withoutPleaseTryAgain />
      }
      return (
        <ShortenedUrlLookup
          hash={uniqueIdentifier}
          handleRedirect={(url) => window.location.replace(url)}
        />
      )
    }
    case DDIMode.QR:
      return <QRScannerApp />
    default:
      return <Application />
  }
}
