import { useParty } from '../../components/PartyProvider/PartyProvider'
import { getPrimaryCheckFromOrder } from '../check-helpers/check-helpers'

export const partyCartsHaveItems = (carts) =>
  carts.some((cart) =>
    Boolean(
      cart.order && getPrimaryCheckFromOrder(cart.order)?.numberOfSelections
    )
  )

export const doesCheckHaveItems = (check) =>
  Boolean(check && check.numberOfSelections)

export const countItemsInPartyCarts = (carts) =>
  carts.reduce(
    (acc, cart) =>
      acc +
      ((cart.order &&
        getPrimaryCheckFromOrder(cart.order)?.numberOfSelections) ||
        0),
    0
  )

export const useSubmitUpdateCart = ({
  updateCart,
  selectionGuid,
  itemGuid,
  itemGroupGuid,
  getModifierGroups,
  itemDetails
}) => {
  const { partyGuid, partyMemberGuid, memberAuthToken } = useParty()

  const getSelection = (values) => ({
    itemGuid,
    itemGroupGuid,
    quantity: values.quantity,
    modifierGroups: getModifierGroups(values, values.quantity),
    specialInstructions: values.specialInstructions,
    fractionalQuantity: values.fractionalQuantity
      ? {
          unitOfMeasure: itemDetails.unitOfMeasure,
          quantity: parseFloat(values.fractionalQuantity)
        }
      : undefined
  })

  return (values) =>
    updateCart({
      variables: {
        input: {
          partyGuid,
          partyMemberGuid,
          memberAuthToken,
          selection: getSelection(values),
          selectionGuid
        }
      }
    })
}

export const buildSelectionsMap = (selections) => {
  return selections.reduce((map, selection) => {
    const {
      itemGuid,
      itemGroupGuid,
      guid,
      externalId,
      quantity: selectionQuantity
    } = selection

    const selectionGuid = guid || externalId

    // items are unique by guid and group guid
    const menuItem =
      map[itemGuid]?.itemGroupGuid === itemGroupGuid && map[itemGuid]

    if (!menuItem) {
      return {
        ...map,
        [itemGuid]: {
          itemGroupGuid,
          selections: [{ selectionGuid, selectionQuantity }],
          quantity: selectionQuantity
        }
      }
    }
    menuItem.quantity += selectionQuantity
    menuItem.selections.push({
      selectionGuid,
      selectionQuantity
    })
    return map
  }, {})
}
