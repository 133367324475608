import { useState } from 'react'
import { Order_At_Table_Restaurant_InfoQueryVariables } from './../../apollo/generated/OptWebGraphQLOperations'
import { useDDIGlobals } from '../../components/DDIGlobalsProvider/DDIGlobalsProvider'
import { useOrder_At_Table_Restaurant_InfoQuery } from '../../apollo/generated/OptWebGraphQLOperations'

export const useRestaurantInfo = () => {
  const { restaurantGuid } = useDDIGlobals()
  let variables: Order_At_Table_Restaurant_InfoQueryVariables | undefined =
    undefined
  if (restaurantGuid) {
    variables = {
      restaurantGuid
    }
  }
  const [errorResponse, setErrorResponse] = useState<null | string>(null)
  const queryRes = useOrder_At_Table_Restaurant_InfoQuery({
    variables,
    skip: !variables,
    onCompleted(data) {
      const restaurantError =
        data.restaurant.__typename === 'GeneralError'
          ? data.restaurant
          : undefined
      if (restaurantError) {
        setErrorResponse(restaurantError.message)
      }
    }
  })

  const restaurantData =
    queryRes.data?.restaurant?.__typename === 'Restaurant'
      ? queryRes.data?.restaurant
      : undefined

  return {
    ...queryRes,
    data: restaurantData,
    error: queryRes.error || errorResponse
  }
}
