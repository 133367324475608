import React, { useState } from 'react'
import cx from 'classnames'
import { ToastLogo } from '@toasttab/buffet-pui-toast-logo'
import { useAuth } from '../../AuthProvider/AuthProvider'
import CloseButton from '../../CloseButton/CloseButton'

import styles from './DontForget.module.css'

export const DontForget = () => {
  const { unverifiedCustomer } = useAuth()

  if (!unverifiedCustomer?.guid) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>Don’t forget to verify!</div>
      <p>
        We sent an email to {unverifiedCustomer.email}.
        <br />
        Please verify your email to complete account creation.
      </p>
    </div>
  )
}

export const DontForgetV2 = () => {
  const { unverifiedCustomer } = useAuth()
  const [closed, setClosed] = useState(false)

  if (closed || !unverifiedCustomer?.guid) {
    return null
  }

  return (
    <div
      className={cx(
        styles.toastBorder,
        'flex p-4 bg-gray-0 border border-solid rounded-lg'
      )}
    >
      <div className='h-10 w-10 mr-4'>
        <ToastLogo size='sm' className='rounded-full' />
      </div>
      <span className='font-semibold'>
        Verify your email to finish creating your Toast account
      </span>
      <CloseButton
        data-testid='close-button-dont-forget'
        onClick={() => setClosed(true)}
        to={undefined}
        children={undefined}
      />
    </div>
  )
}
