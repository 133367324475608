export const formatPhoneNumber = (phone) => {
  if (typeof phone === 'number') {
    phone = String(phone)
  }
  if (typeof phone === 'string' && phone.length === 10) {
    return `(${phone.substring(0, 3)}) ${phone.substring(
      3,
      6
    )}-${phone.substring(6)}`
  }
  return null
}

export const getRawPhoneNumber = (val) => {
  return val.replace(/\D/g, '').replace(/1?(\d{10})$/, (_, p1) => p1)
}
