import { useCallback, useState } from 'react'
import { useMutation } from '@apollo/client'

import { dataByTypename } from '../../utils/apollo-helpers'
import { useParty } from '../../components/PartyProvider/PartyProvider'
import { useTab } from '../../components/TabProvider/TabProvider'
import { OPT_FIRE_ROUND } from '../../apollo/party/party.graphql'
import { useRestaurant } from '../../components/RestaurantProvider/RestaurantProvider'
import { useCart } from '../../components/CartProvider/CartProvider'
import { useHistory } from 'react-router'
import { trackOrder } from '../../utils/track-ecommerce'
import { usePreauthEnabled } from '../preauth/use-preauth-enabled'
import { toastNotification } from '../../components/ToastNotification/ToastNotification'
import { useGuestInfo } from '../use-guest-info'
import { getPrimaryCheckFromOrder } from '../../utils/check-helpers/check-helpers'
import { useSentry } from 'banquet-runtime-modules'

const handleFirePartyTabRound = async ({
  optFireTabRound,
  paymentInfo,
  partyGuid,
  partyMemberGuid,
  memberAuthToken
}) => {
  return optFireTabRound({
    variables: {
      input: {
        partyGuid,
        customer: (paymentInfo && paymentInfo.customer) || null,
        partyMemberGuid,
        memberAuthToken
      }
    }
  })
}

export const orderPlaced = () => {
  toastNotification('Order placed')
}

export const useHandleFirePartyTabRound = () => {
  const history = useHistory()
  const { captureException } = useSentry()
  const { deleteCartGuid } = useCart()
  const { getRestaurantPath } = useRestaurant()
  const preauthEnabled = usePreauthEnabled()
  const { partyGuid, partyMemberGuid, memberAuthToken, updatePartyProperties } =
    useParty()
  const {
    updateTabOrderGuid,
    updateTabCheckGuid,
    setFirstName,
    setLastName,
    setEmail
  } = useTab()
  const { updateGuestEmail, updateGuestName, updateGuestLastName } =
    useGuestInfo()
  const redirect = useCallback(() => {
    return history.replace(getRestaurantPath('tab-management/confirm'))
  }, [history, getRestaurantPath])

  const [optFireTabRound, { error: mutationError, ...mutationResponse }] =
    useMutation(OPT_FIRE_ROUND, {
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch()
      },
      onCompleted(data) {
        const { OPTPartyRefreshV2, OPTPartyError } = dataByTypename(
          data.optPartyRefresh
        )
        if (OPTPartyError) {
          setErrorMessage({ message: OPTPartyError.message })
        } else {
          const newParty = OPTPartyRefreshV2
          updateTabOrderGuid(newParty.party.orderGuid)
          updateTabCheckGuid(newParty.order.checkGuid)
          deleteCartGuid()
          updatePartyProperties({
            partyGuid: newParty.party.guid,
            partyMemberGuid,
            memberAuthToken,
            pin: newParty.party.pin
          })

          const { guid } = newParty.order
          const { selections, total, tax } = getPrimaryCheckFromOrder(
            newParty.order
          )

          const adjustedSelections = selections.map((s) => ({
            id: s.itemGuid,
            name: s.name,
            price: s.price,
            quantity: s.quantity
          }))

          trackOrder(adjustedSelections, {
            id: guid,
            revenue: total,
            tax: tax,
            option: preauthEnabled ? 'pre-auth' : 'no-auth'
          })

          redirect()
        }
      },
      onError(error) {
        setErrorMessage({ message: error.message })
        captureException(error)
      }
    })
  const [errorMessage, setErrorMessage] = useState(null)

  const mutation = useCallback(
    async (values) => {
      await handleFirePartyTabRound({
        optFireTabRound,
        paymentInfo: (values && values.paymentInfo) || undefined,
        partyGuid,
        partyMemberGuid,
        memberAuthToken
      })
      if (values?.paymentInfo?.customer) {
        setEmail(values.paymentInfo.customer.email)
        setFirstName(values.paymentInfo.customer.firstName)
        setLastName(values.paymentInfo.customer.lastName)
        updateGuestEmail(values.paymentInfo.customer.email)
        updateGuestName(values.paymentInfo.customer.firstName)
        updateGuestLastName(values.paymentInfo.customer.lastName)
      }
    },
    [
      optFireTabRound,
      partyGuid,
      partyMemberGuid,
      memberAuthToken,
      setFirstName,
      setLastName,
      setEmail,
      updateGuestEmail,
      updateGuestName,
      updateGuestLastName
    ]
  )

  return [
    mutation,
    { ...mutationResponse, error: errorMessage || mutationError }
  ]
}
