import { SavedCreditCardWithPreauth } from './use-available-payment-methods'

export const removeDuplicateOfPreauthedCard = (
  arrayOfCards: SavedCreditCardWithPreauth[]
) => {
  // finds the preauthed card
  const preauthedCard = arrayOfCards.find((card) => card.isPreauthed === true)

  if (!preauthedCard) {
    return arrayOfCards
  }

  // finds the same card in the guest's array of saved credit cards
  // matches expiration, and maskedPan since preauthed version
  // of the saved card is given a different guid
  const duplicateCard = arrayOfCards.find((card) => {
    const { expirationMonth, expirationYear, maskedPan, guid } = card
    return (
      expirationMonth === preauthedCard.expirationMonth &&
      expirationYear === preauthedCard.expirationYear &&
      maskedPan === preauthedCard.maskedPan &&
      guid !== preauthedCard.guid
    )
  })

  if (!duplicateCard) {
    return arrayOfCards
  }

  // returns the cards minus the duplicate
  return arrayOfCards.filter((card) => card.guid !== duplicateCard.guid)
}
