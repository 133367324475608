import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react'
import PropTypes from 'prop-types'
import { SearchInput } from '@toasttab/buffet-pui-text-input'
import MenuItems from '../Menus/MenuItems/MenuItems'
import { useDebounce } from '../../hooks/useDebounce'
import { useMenuSearch } from '../../hooks/use-menu-search'
import { useHistory, useLocation } from 'react-router'
import throttle from 'lodash/throttle'
import cx from 'classnames'
import styles from './MenuSearch.module.css'
import { CTA } from '@/il8n/en'
import { SearchBannerContainer } from './SearchBannerContainer'
import { usePaginateArray } from '../../hooks/use-paginate-array'
import { PartyReviewOrderButton } from '../PartyButtons/PartyReviewOrderButton'

export const Search = ({ node, menus }) => {
  const MAX_LENGTH = 50
  const EMPTY_ARRAY = []
  const searchBarRef = useRef()
  const history = useHistory()
  const location = useLocation()
  const [searchTerm, setSearchTerm] = useState(() => {
    const search = new URLSearchParams(location.search)
    return search.get('term') ? decodeURIComponent(search.get('term')) : ''
  })

  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value)
  }, [])
  const sanitizedSearchTerm = useMemo(() => {
    return encodeURIComponent(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  const { data: results, loading } = useMenuSearch(
    debouncedSearchTerm,
    menus || EMPTY_ARRAY
  )

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    if (search.has('term')) {
      if (sanitizedSearchTerm && sanitizedSearchTerm !== search.get('term')) {
        search.set('term', sanitizedSearchTerm)
        history.replace({ search: search.toString() })
      } else if (!sanitizedSearchTerm) {
        search.delete('term')
        history.replace({ search: search.toString() })
      }
    } else if (sanitizedSearchTerm) {
      search.set('term', sanitizedSearchTerm)
      history.replace({ search: search.toString() })
    }
  }, [history, sanitizedSearchTerm, location])

  const [resultsSlice, incrementResults] = usePaginateArray(
    results || EMPTY_ARRAY,
    { increment: 5, initial: 10, max: MAX_LENGTH }
  )

  const SearchBannerContainerProps = {
    loading,
    results,
    searchTerm,
    debouncedSearchTerm
  }

  useLayoutEffect(() => {
    if (!node) {
      return
    }
    const scrollCallback = throttle(
      () => {
        const diff = node.scrollHeight - node.offsetHeight
        if (diff - node.scrollTop < 50) {
          incrementResults()
        }
      },
      250,
      { leading: true }
    )

    node.addEventListener('scroll', scrollCallback)

    return () => {
      node.removeEventListener('scroll', scrollCallback)
    }
  }, [node, incrementResults])

  return (
    <div className={styles.menuSearchContainer}>
      <div className={styles.searchBar}>
        <SearchInput
          placeholder={CTA.SEARCH_MENU_PLACEHOLDER}
          onChange={handleSearch}
          value={searchTerm}
          ref={searchBarRef}
        />
      </div>
      <MenuItems
        items={searchTerm && searchTerm.length ? resultsSlice : []}
        nonResponsive
      />
      <SearchBannerContainer {...SearchBannerContainerProps} />
      <div
        data-testid='part-order-button'
        className={cx(
          'fixed bottom-0 z-[20] left-0 right-0 flex content-center bg-white',
          styles.placeOrderButton
        )}
      >
        <PartyReviewOrderButton />
      </div>
    </div>
  )
}

Search.propTypes = {
  node: PropTypes.shape({})
}
