import { usePermalinkEnabled } from './../../hooks/party/use-permalink-enabled'
import { useState, useRef, useCallback } from 'react'
import { useExpiringStateWithNamespacedStorage } from '../../utils/namespaced-storage'
import {
  restaurantStorageKeys,
  useRestaurantStorage
} from '../../utils/restaurant-storage'
import { MutationHookOptions, useMutation } from '@apollo/client'
import {
  MNP_CREATE_PARTY,
  OPT_JOIN_PARTY_SECURE,
  OPT_JOIN_PARTY,
  OPT_CREATE_PARTY,
  OPT_JOIN_OR_CREATE_PARTY_DYNAMIC_QR,
  STP_JOIN_OR_CREATE_PARTY,
  STP_CREATE_PARTY
} from '../../apollo/party/party.graphql'
import { useRestaurant } from '../RestaurantProvider/RestaurantProvider'
import { useDDIGlobals } from '../DDIGlobalsProvider/DDIGlobalsProvider'
import { dataByTypename } from '../../utils/apollo-helpers'
import { GuestInfo, useGuestInfo } from '../../hooks/use-guest-info'
import { sanitizeInputString, getRawPhoneNumber } from '../../utils/form-utils'
import useDynamicQrCode from '../../utils/dynamic-qr-helpers'
import { trackPartySuccess } from '../../utils/track-ecommerce'
import { addEventProperties } from '@toasttab/do-secundo-analytics'
import { useParty } from './PartyProvider'
import {
  ContactInfoType,
  Mnp_Create_PartyMutationVariables,
  OptPartyError,
  OptPartyRefreshV2,
  Opt_Create_PartyMutationVariables,
  Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables,
  Opt_Join_PartyMutationVariables,
  Opt_Join_Party_SecureMutationVariables,
  Stp_Join_Or_Create_PartyMutationVariables
} from '../../apollo/generated/OptWebGraphQLOperations'
import { DDIMode } from '../../types/DDIGlobals'
import { useGetPartyMode } from '../PartyQuery/PartyQuery'
import { PartyVisibility } from '@local/cornucopia/src/PartyListener/PartyListener.types'
import { useIntlSmsEnabled } from '../../hooks/party/use-intl-sms-enabled'
import { useRestaurantInfo } from '../../hooks/restaurant-info/use-restaurant-info'

interface InitializePartyMutationOptions extends MutationHookOptions {
  onCompleted(
    data: any,
    onPartyInitializedCompleted?: {
      partyMemberGuid?: string
      guestName?: string | null
      guestPhone?: string | null
      guestEmail?: string | null
    }
  ): void
}

// re-use this for all party initialization
export const useInitializeParty = (
  joiningPartyGuid: string | null | undefined,
  pinRequired: boolean,
  inviteCode: string | null | undefined,
  options: InitializePartyMutationOptions,
  expirationDuration: number
) => {
  const permalinkEnabled = usePermalinkEnabled()
  const intlSmsEnabled = useIntlSmsEnabled()
  const guestInfoRef = useRef<GuestInfo | null>(null)
  const { updateGuestName, updateGuestPhone, updateGuestEmail } = useGuestInfo()
  const restaurantStorage = useRestaurantStorage()
  const { checkGuid } = useDynamicQrCode()
  const [, setHasSplashed] = useExpiringStateWithNamespacedStorage(
    restaurantStorageKeys.HAS_SPLASHED,
    false,
    restaurantStorage,
    expirationDuration
  )
  const party = useParty()
  const { restaurantGuid } = useRestaurant()
  const { uniqueIdentifier } = useDDIGlobals()
  const { mode } = useGetPartyMode()
  const [errorState, setErrorState] = useState<
    OptPartyError | Error | undefined
  >(undefined)
  // We use this lower-level check intentionally instead of 'useSplitPaymentsEnabled',
  // because we do not want to deal with the difference between other edge cases
  // (especially loyalty on the check) that we guard against in the rest of the app
  const splitEvenEnabled = Boolean(
    useRestaurantInfo().data?.toastPayConfig?.splitPaymentsEnabled
  )

  let mutation = undefined
  switch (mode) {
    case DDIMode.STP:
      mutation = splitEvenEnabled ? STP_JOIN_OR_CREATE_PARTY : STP_CREATE_PARTY
      break
    case DDIMode.MNP:
      mutation = MNP_CREATE_PARTY
      break
    case DDIMode.TTS:
      mutation = OPT_JOIN_OR_CREATE_PARTY_DYNAMIC_QR
      break
    default:
      if (joiningPartyGuid) {
        mutation =
          pinRequired || inviteCode ? OPT_JOIN_PARTY_SECURE : OPT_JOIN_PARTY
      } else {
        mutation = OPT_CREATE_PARTY
      }
  }

  const [mutate, mutationResult] = useMutation(mutation, {
    ...options,
    onError(error) {
      setErrorState(new Error('Party initialization failure'))
      console.error(error)
    },
    onCompleted(data) {
      setErrorState(undefined)

      const { OPTPartyRefreshV2, OPTPartyError } = dataByTypename(
        data.optPartyRefresh
      ) as {
        OPTPartyRefreshV2?: OptPartyRefreshV2
        OPTPartyError?: OptPartyError
      }

      if (OPTPartyError) {
        setErrorState(OPTPartyError)
      }

      const consolidatedParty = OPTPartyRefreshV2 && OPTPartyRefreshV2.party

      if (consolidatedParty) {
        const { guid, memberAuthToken, members, pin, partyMemberGuid } =
          consolidatedParty

        if (!guestInfoRef.current?.name) {
          const partyMember = members?.find(
            (m) => m && m.partyMemberGuid === partyMemberGuid
          )

          guestInfoRef.current = {
            name: partyMember?.name ?? '',
            phone: guestInfoRef?.current?.phone
          }
        }

        if (!guid || !partyMemberGuid || !memberAuthToken) {
          console.error(
            'Party response is missing required parameters: '.concat(
              JSON.stringify({
                guid,
                partyMemberGuid,
                memberAuthToken
              })
            )
          )
          return
        }

        updateGuestName(guestInfoRef.current?.name)
        guestInfoRef.current?.phone &&
          updateGuestPhone(guestInfoRef.current?.phone)
        guestInfoRef.current?.email &&
          updateGuestEmail(guestInfoRef.current?.email)
        party.updatePartyProperties({
          partyGuid: guid,
          memberAuthToken,
          partyMemberGuid,
          pin
        })
        addEventProperties({
          partySize: members.length
        })
        trackPartySuccess({ partyGuid: guid, partyMemberGuid })
        // sets that initial splash screen has occurred,
        // for backwards compatibility with non-party
        setHasSplashed(true)
        if (options.onCompleted) {
          options.onCompleted(data, {
            partyMemberGuid,
            guestName: guestInfoRef.current?.name,
            guestPhone: guestInfoRef.current?.phone,
            guestEmail: guestInfoRef.current?.email
          })
        }
      }
    }
  })

  const handleInitialize = useCallback(
    ({
      name,
      phone,
      pinInput,
      email,
      visibility
    }: {
      name?: string
      phone?: string
      pinInput?: string
      email?: string
      visibility?: PartyVisibility
    }) => {
      if (uniqueIdentifier?.toLowerCase() === 'welcome') {
        return {}
      }

      if (!intlSmsEnabled) {
        phone = getRawPhoneNumber(phone)
      }

      const getContactInfo = () => {
        if (phone) {
          return { contact: phone, contactType: ContactInfoType.Phone }
        } else if (email) {
          return { contact: email, contactType: ContactInfoType.Email }
        } else {
          return undefined
        }
      }
      const contactInfo = getContactInfo()
      name = name && sanitizeInputString(name)
      guestInfoRef.current = { name, phone, email }

      // We are creating an input that corresponds to the mutation we expect in each case
      let input: (
        | Stp_Join_Or_Create_PartyMutationVariables
        | Mnp_Create_PartyMutationVariables
        | Opt_Join_Or_Create_Party_Dynamic_QrMutationVariables
        | Opt_Join_PartyMutationVariables
        | Opt_Join_Party_SecureMutationVariables
        | Opt_Create_PartyMutationVariables
      )['input']
      switch (mode) {
        case DDIMode.STP: {
          input = {
            checkGuid: uniqueIdentifier!,
            restaurantGuid: restaurantGuid!,
            name: undefined,
            contactInfo: permalinkEnabled ? contactInfo : undefined
          }
          break
        }
        case DDIMode.MNP:
          input = {
            tableName: uniqueIdentifier!,
            restaurantGuid: restaurantGuid!,
            name: null,
            contactInfo: permalinkEnabled ? contactInfo : undefined
          }
          break
        case DDIMode.TTS:
          input = {
            restaurantGuid: restaurantGuid!,
            checkGuid: checkGuid!,
            orderGuid: uniqueIdentifier,
            name,
            contactInfo
          }
          break
        default: {
          input = joiningPartyGuid
            ? {
                partyGuid: joiningPartyGuid,
                name,
                contactInfo,
                pin: pinRequired || inviteCode ? pinInput : undefined
              }
            : {
                tableName: uniqueIdentifier!,
                restaurantGuid: restaurantGuid!,
                name,
                contactInfo: permalinkEnabled ? contactInfo : undefined,
                visibility
              }
        }
      }
      const mutationInput = {
        variables: { input }
      }
      return mutate(mutationInput)
    },
    [
      permalinkEnabled,
      uniqueIdentifier,
      checkGuid,
      inviteCode,
      joiningPartyGuid,
      mode,
      mutate,
      pinRequired,
      restaurantGuid,
      intlSmsEnabled
    ]
  )

  return [
    handleInitialize,
    {
      ...mutationResult,
      error: mutationResult.error || errorState
    }
  ] as const
}

export const createUseInitializeParty = ({
  expirationDuration
}: {
  expirationDuration: number
}) => {
  return (
    joiningPartyGuid: string | null | undefined,
    pinRequired: boolean,
    inviteCode: string | null | undefined,
    options: InitializePartyMutationOptions
  ) =>
    useInitializeParty(
      joiningPartyGuid,
      pinRequired,
      inviteCode,
      options,
      expirationDuration
    )
}
